
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';
import { setLoadingRDA, downloadRDA as downloadRDAAction } from '../../actions/administration';
import { fetchBlobData, forbidden, unauthorized } from '../../sagas/fetchUtils';

import { downloadBlob } from '../../utils/exportUtils';

import getRDAVersion from './getRDAVersion';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/RDA`;

const params = {
    method: 'GET',
    headers: {
        Accept: 'application/octet-stream',
    },
};

export const downloadWithVersionName = async blob => {
    try {
        const rdaVersion = await getRDAVersion();
        downloadBlob(
            blob,
            `Remote Download Application ${rdaVersion}.msi`,
        );
    } catch (error) {
        console.log(error); // eslint-disable-line no-console
        downloadBlob(
            blob,
            'Remote Download Application.msi',
        );
    }
};

export const handleError = error => {
    switch (error) {
        case unauthorized:
        case forbidden:
            Notification.error(<FormattedMessage
                id={'error.401'}
                defaultMessage={
                    'Error : 401'
                }
            />);
            break;
        default:
            Notification.error(<FormattedMessage
                id={'error.fileDownload'}
                defaultMessage={'Download failed.'}
            />);
    }
};

const downloadRDA = async(
    dispatch,
    injectedFetch = fetchBlobData,
) => {
    dispatch(downloadRDAAction());
    dispatch(setLoadingRDA(true));

    const res = await injectedFetch(
        url,
        params,
    );

    if (res.error) {
        handleError(res.error);
    } else {
        await downloadWithVersionName(res);
    }

    dispatch(setLoadingRDA(false));
};

export default downloadRDA;
