import {
    formatDriver,
    formatDriverCard,
    formatIdentificationNumber,
    formatExportedTo,
    formatTransferDate,
    formatTransferAndContentType,
    formatTransferType,
    formatVehicleName,
    getFormatFile,
} from '../FormatUtils';
import bindToContext from '../../../utils/bindToContext';

const bindComponentFunctions = context => { // eslint-disable-line max-statements
    bindToContext(
        'loadNewPage',
        context,
    );
    bindToContext(
        'downloadAllFilesForFilters',
        context,
    );
    bindToContext(
        'downloadSelectedFiles',
        context,
    );
    bindToContext(
        'onRangeChange',
        context,
    );
    bindToContext(
        'getData',
        context,
    );
    bindToContext(
        'downloadFiles',
        context,
    );
    bindToContext(
        'onMergedSelectionChange',
        context,
    );
    bindToContext(
        'preventUnloading',
        context,
    );
    bindToContext(
        'setNormalTableView',
        context,
    );
    bindToContext(
        'setMultiCardsView',
        context,
    );
    bindToContext(
        'setSingleCardView',
        context,
    );
    bindToContext(
        'onSortChange',
        context,
    );
    bindToContext(
        'saveFilters',
        context,
    );
    bindToContext(
        'abortFilters',
        context,
    );
    bindToContext(
        'onRerenderRow',
        context,
    );

    context.formatExportedTo = formatExportedTo.bind(context);
    context.formatTransferAndContentType = formatTransferAndContentType.bind(context);
    context.formatTransferType = formatTransferType.bind(context);
    context.formatTransferDate = formatTransferDate.bind(context);
    context.formatFile = getFormatFile(
        context.downloadFiles,
        context.props.isExportEnabled,
    );
    context.formatVehicleName = formatVehicleName.bind(context);
    context.formatDriver = formatDriver.bind(context);
    context.formatDriverCard = formatDriverCard.bind(context);
    context.formatIdentificationNumber = formatIdentificationNumber.bind(context);
};

export default bindComponentFunctions;
