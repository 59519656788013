/* eslint-disable default-param-last */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { withRouter} from 'react-router-dom';

import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import isEqual from 'lodash/fp/isEqual';
import get from 'lodash/get';

import Spinner from '@rio-cloud/rio-uikit/Spinner';

import Tag from '@rio-cloud/rio-uikit/Tag';

import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

import Analytics from '../../components/common/Analytics';

import { dateProps } from '../../constants/common';
import getCompanyCards from '../../fetches/CompanyCards/getCompanyCards';
import editCompanyCardInBackend from '../../fetches/CompanyCards/editCompanyCard';
import removeCompanyCard from '../../fetches/CompanyCards/removeCompanyCard';
import restoreCompanyCard from '../../fetches/CompanyCards/restoreCompanyCard';
import TooltipTrigger from '../../components/common/TooltipTrigger';
import { getDiffDays } from '../Overview/helpers';

import {sortVehicles} from './helpers';

const getVehicleTagsByIds = (vehicles, ids, baseKey) => ids.map(vehicleId => {
    const foundVehicle = vehicles.find(vehicle => vehicle.id === vehicleId) || { id: vehicleId };
    return (
        <VehicleNameTag key={`${baseKey}-${foundVehicle.id}`}>
            { foundVehicle.name || foundVehicle.id }
        </VehicleNameTag>
    );
});

export const getAllCategoryIcons = (vehicles, ids, baseKey) => {
    const categorys = new Set();
    ids.forEach(vehicleId => {
        const foundVehicle = vehicles.find(vehicle => vehicle.id === vehicleId) || {};
        categorys.add(foundVehicle.type);
    });

    return Array.from(categorys)
        .map(type => <span key={`${baseKey}-${type}`} className={`rioglyph rioglyph-${type} margin-right-5`}/>);
};

const VehicleNameTag = props => (
    <div className={'col-ls-12 col-sm-6 col-md-4 col-lg-3 col-xl-3'}>
        <div
            className={
                'width-100pct border-style-solid border-width-1 border-color-light rounded ' +
                'text-center margin-bottom-10 margin-top-10 padding-5 ellipsis-1'
            }
        >
            {
                props.locked &&
                <TooltipTrigger
                    tooltip={
                        <FormattedMessage
                            id={'administration.vehicleLockedToCompanyCard'}
                            defaultMessage={'This vehicle is locked to the company card.'}
                        />
                    }
                    baseKey={'vehicleLockedToCompanyCardTooltip'}
                >
                    <span className={`rioglyph rioglyph-lock margin-right-10`}/>
                </TooltipTrigger>
            }
            {
                props.locked === false &&
                <TooltipTrigger
                    tooltip={
                        <FormattedMessage
                            id={'administration.vehicleNotLockedToCompanyCard'}
                            defaultMessage={'This vehicle is not locked to the company card.'}
                        />
                    }
                    baseKey={'vehicleNotLockedToCompanyCardTooltip'}
                >
                    <span className={`rioglyph rioglyph-lock-open text-muted margin-right-5`}/>
                </TooltipTrigger>
            }
            { props.children }
        </div>
    </div>
);

VehicleNameTag.propTypes = {
    locked: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

const marginBottomClass = 'margin-bottom-10-xs margin-bottom-10-sm margin-bottom-0-md';

const CARD_AVAILABLE = 1;
const CARD_BUSY = 2;
const RDA_OFFLINE = 3;

const getTracker = status => {
    switch (status) {
        case CARD_AVAILABLE:
            return (
                <span>
                    <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-ok'}/>
                    <FormattedMessage id={'administration.companyCardOnline'} defaultMessage={'Card available'}/>
                </span>
            );
        case CARD_BUSY:
            return (
                <span>
                    <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-refresh spinning'}/>
                    <FormattedMessage id={'administration.companyCardBusy'} defaultMessage={'Card busy'}/>
                </span>
            );
        case RDA_OFFLINE:
            return (
                <span>
                    <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-warning-sign'}/>
                    <FormattedMessage id={'administration.offlineRDA'} defaultMessage={'RDA is offline'}/>
                </span>
            );
        default:
            return (
                <span>
                    <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-warning-sign'}/>
                    <FormattedMessage id={'administration.companyCardOffline'} defaultMessage={'Card unavailable'}/>
                </span>
            );
    }
};

const filterBySelectedVehicles = (ids = [], selectedIds = []) => selectedIds.length ?
    ids.filter(id => selectedIds.includes(id)) :
    ids;

const getCardStatusSize = companycard => {
    if (companycard.returnRequestSent && companycard.traceNumber) {
        return 'col-md-3 text-right';
    } else if (companycard.returnRequestSent && !companycard.traceNumber) {
        return 'col-md-7 text-right';
    }
    return 'col-md-12 text-right';

};

const getExpireDateColor = companycard => {
    if (companycard.expirationDate) {
        const expireDate = new Date(companycard.expirationDate);
        if (getDiffDays(expireDate) > 0) {
            return 'text-color-danger';
            // eslint-disable-next-line no-magic-numbers
        } else if (getDiffDays(expireDate) > -90) {
            return 'text-color-warning';
        }
        return 'text-color-black';
    }
    return '';
};

export const getCompanyCardPanel = ( // eslint-disable-line max-lines-per-function, complexity
    vehicles = [],
    selectedVehicles = [],
    companyCard = {},
    editCompanyCard = () => {},
    openConfirmDelete = () => {},
    editable,
    showEditButton,
    active,
    open,
    toggleOpen,
    inCardHotel,
    isRDAOnline,
    isRDAStatusFetched,
    // eslint-disable-next-line max-params
) => (
    <div className={'row margin-bottom-20'} key={`CompanyCardPanel-${companyCard.companyChipId}`}>
        <div className={'col-xs-12 form-group'}>
            <div className={`panel panel-default ${active ? 'active' : ''}`}>
                <div className={'panel-body'}>
                    <div className={'row'}>
                        <div className={`col-md-3 ${marginBottomClass}`}>
                            <div className={'display-flex align-items-top'}>
                                <div className={'text-size-h2'}>
                                    <span
                                        className={`rioglyph ${inCardHotel ?
                                            'rioglyph-card-hotel' :
                                            'rioglyph-card-reader'}`}/>
                                </div>
                                <div className={'margin-left-5 ellipsis-1'}>
                                    <label className={'margin-bottom-0'}>
                                        <FormattedMessage
                                            id={'administration.companyCardName'}
                                            defaultMessage={'Company card name'}
                                        />
                                    </label>
                                    <br/>
                                    <span>
                                        {
                                            companyCard.companyCardName ||
                                            companyCard.companyCardNumber ||
                                            <div
                                                className={'btn btn-default'}
                                                onClick={() => editCompanyCard(
                                                    companyCard,
                                                    true,
                                                )}
                                            >
                                                <FormattedMessage
                                                    id={'companyCards.addName'}
                                                    defaultMessage={'Add a company card name'}
                                                />
                                            </div>
                                        }
                                    </span>
                                    <br/>
                                    {
                                        companyCard.companyCardName &&
                                        companyCard.companyCardNumber &&
                                        <span className={'text-muted text-size-12'}>
                                            { companyCard.companyCardNumber }
                                        </span>
                                    }
                                    {
                                        !companyCard.companyCardName &&
                                        !companyCard.companyCardNumber &&
                                            <TooltipTrigger
                                                baseKey={`notFetchedTooltip-${companyCard.companyChipId}`}
                                                tooltip={
                                                    <FormattedMessage
                                                        id={'companyCards.willBeFetchedAfterAuthentication'}
                                                        defaultMessage={
                                                            'Card information will be fetched after a ' +
                                                            'successful authentication'
                                                        }
                                                    />
                                                }
                                            >
                                                <span className={'text-muted text-size-12'}>
                                                    <FormattedMessage
                                                        id={'companyCards.notFetchedYet'}
                                                        defaultMessage={'Card information not fetched yet'}
                                                    />
                                                </span>
                                            </TooltipTrigger>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-2 ${marginBottomClass}`}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.companyName'}
                                    defaultMessage={'Company name'}
                                />
                            </label>
                            <br/>
                            <span>
                                { companyCard.companyName || '' }
                            </span>
                        </div>
                        <div className={`col-md-2 ${marginBottomClass}`}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.assignedVehicles'}
                                    defaultMessage={'Assigned vehicles'}
                                />
                            </label>
                            <br/>
                            {
                                getAllCategoryIcons(
                                    vehicles,
                                    companyCard.assignedEquipmentIds || [],
                                    `AssignedCategoryIcons-${companyCard.companyChipId}`,
                                )
                            }
                            <span>
                                { (companyCard.assignedEquipmentIds || []).length }
                            </span>
                        </div>
                        <div className={`col-md-2 ${marginBottomClass}`}>
                            <label className={'margin-bottom-0'}>
                                <FormattedMessage
                                    id={'administration.expiryDate'}
                                    defaultMessage={'Expiry Date'}
                                />
                            </label>
                            <br/>
                            <div className={getExpireDateColor(companyCard)}>
                                <span >
                                    {
                                        companyCard.expirationDate ?
                                            <FormattedDate value={companyCard.expirationDate} {...dateProps}/> :
                                            ''
                                    }
                                </span>
                                {getExpireDateColor(companyCard) === 'text-color-danger' &&
                                <span className={'margin-left-5'} >
                                    {
                                        <FormattedMessage
                                            id={'administration.expiredDate'}
                                            defaultMessage={'expired'}
                                        />
                                    }
                                </span>}
                                {getExpireDateColor(companyCard) === 'text-color-warning' &&
                                <span className={'margin-left-5'} >
                                    {
                                        <FormattedMessage
                                            id={'administration.expiredDateSoon'}
                                            defaultMessage={'expires soon'}
                                        />
                                    }
                                </span>}
                            </div>
                        </div>
                        <div className={`col-md-2 ${marginBottomClass}`}>
                            {inCardHotel ? <Tag>Card Hotel</Tag> : <Tag>RDA</Tag>}
                        </div>
                        {
                            editable &&
                            showEditButton &&
                            <div className={`col-md-1 ${marginBottomClass} text-right`}>
                                <div
                                    className={'btn btn-default btn-icon-only'}
                                    onClick={() => editCompanyCard(companyCard)}
                                >
                                    <span className={'rioglyph rioglyph-pencil'}/>
                                </div>
                            </div>
                        }
                        {
                            editable &&
                             // eslint-disable-next-line no-extra-parens
                             ((companyCard.returnRequestSent &&
                             // eslint-disable-next-line no-magic-numbers
                             companyCard.status === 4 &&
                             // eslint-disable-next-line no-magic-numbers
                             getDiffDays(new Date(companyCard.returnRequestDate)) > 5) ||
                             // eslint-disable-next-line no-extra-parens
                             (companyCard.inCardHotel && getDiffDays(new Date(companyCard.expirationDate)) > 0)) &&
                            <div className={`col-md-1 ${marginBottomClass} text-right`}>
                                <div
                                    className={'btn btn-default btn-icon-only'}
                                    onClick={() => {
                                        openConfirmDelete(companyCard);
                                    }}
                                >
                                    <span className={'rioglyph rioglyph-trash text-color-danger'}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={'row'}>
                        {companyCard.returnRequestSent && !companyCard.traceNumber && companyCard.status !== 0 &&
                        <div className={'col-md-3'}>
                            { <span>
                                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-drivercard-out'}/>
                                <FormattedMessage
                                    id={'administration.cardRequestSent'}
                                    defaultMessage={'Card return request sent'}/>
                            </span> }
                        </div>
                        }
                        {companyCard.returnRequestSent && (companyCard.traceNumber || companyCard.status === 0) &&
                        <div className={'col-md-3'}>
                            { <span>
                                <span
                                    style={{ paddingRight: '8px', marginLeft: '3px' }}
                                    className={'rioglyph rioglyph-drivercard-out'}/>
                                <FormattedMessage
                                    id={'administration.cardRequestAccepted'}
                                    defaultMessage={'Card return request accepted'}/>
                            </span> }
                        </div>
                        }
                        {companyCard.returnRequestSent &&
                        <div className={'col-md-2'}>
                            { <span>
                                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-question-sign'}/>
                                <FormattedMessage
                                    id={'administration.ticketNumber'}
                                    defaultMessage={'Ticket Number'}/>: {companyCard.ticketNumber}
                            </span>
                            }
                        </div>
                        }
                        {companyCard.returnRequestSent && companyCard.traceNumber &&
                        <div className={'col-md-2'}>
                            { <span>
                                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-envelope'}/>
                                <FormattedMessage
                                    id={'administration.traceNumber'}
                                    defaultMessage={'Mail Trace Number'}/>: {companyCard.traceNumber}
                            </span>
                            }
                        </div>
                        }
                        {companyCard.returnRequestSent && companyCard.traceNumber &&
                        <div className={'col-md-2'}>
                            { <span>
                                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-delivery'}/>
                                <FormattedMessage
                                    id={'administration.carrier'}
                                    defaultMessage={'Courier company'}/>: {companyCard.carrierCompany}
                            </span>
                            }
                        </div>
                        }
                        <div className={getCardStatusSize(companyCard)}>
                            {/* eslint-disable-next-line no-extra-parens */}
                            { (isRDAStatusFetched && isRDAOnline) || companyCard.inCardHotel ?
                                getTracker(companyCard.status) :
                                getTracker(3) }
                        </div>
                    </div>
                    <ExpanderPanel
                        bsStyle={'separator'}
                        iconLeft
                        open={open}
                        onToggle={toggleOpen}
                        title={
                            <span>
                                <FormattedMessage
                                    id={'administration.listOfAssignedVehicles'}
                                    defaultMessage={'List of assigned vehicles'}
                                />
                                {` (${get(
                                    companyCard,
                                    'assignedEquipmentIds.length',
                                    '0',
                                )})`}
                            </span>
                        }
                    >
                        {
                            companyCard.assignedEquipmentIds && companyCard.assignedEquipmentIds.length ?
                                <div className={'row'}>
                                    {
                                        getVehicleTagsByIds(
                                            vehicles,
                                            filterBySelectedVehicles(
                                                sortVehicles(
                                                    vehicles,
                                                    companyCard.assignedEquipmentIds,
                                                )
                                                ,
                                                selectedVehicles,
                                            ),
                                            companyCard.companyChipId,
                                        )
                                    }
                                </div> :
                                <span className={'text-muted'}>
                                    <FormattedMessage
                                        id={'administration.noVehiclesAssignedYet'}
                                        defaultMessage={'No vehicles assigned yet'}
                                    />
                                </span>
                        }
                    </ExpanderPanel>
                </div>
            </div>
        </div>
    </div>
);

const DATA_POLLING_MILLISECONDS = 10000;

export class CompanyCardsPage extends Component {
    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.state = {
            unAssignedVehiclesExpanded: this.props.unAssignedVehiclesExpanded,
            editedCard: false,
            confirmDeleteOpen: false,
            companyCard: {},
        };
        this.toggleOpenPanel = this.toggleOpenPanel.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.getRDAStatus = this.getRDAStatus.bind(this);
        this.editCompanyCard = this.editCompanyCard.bind(this);
        this.openConfirmDelete = this.openConfirmDelete.bind(this);
    }

    componentDidMount() {
        this.fetchData();
        this.dataPollingInterval = this.props.injectedWindow.setInterval(
            this.fetchData,
            DATA_POLLING_MILLISECONDS,
        );
        this.dataPollingInterval = this.props.injectedWindow.setInterval(
            this.getRDAStatus,
            DATA_POLLING_MILLISECONDS,
        );
    }

    componentWillUnmount() {
        this.props.injectedWindow.clearInterval(this.dataPollingInterval);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(
            this.props.changedCard,
            prevProps.changedCard,
        )) {
            this.props.setLoadingCompanyCards(true);

            this.props.editCompanyCardInBackend(this.props.changedCard)
                .then(() => this.fetchData());
        }

        if (!isEqual(
            this.props.deletedCard,
            prevProps.deletedCard,
        )) {
            this.props.setLoadingCompanyCards(true);

            this.props.removeCompanyCard(
                this.props.accessToken,
                this.props.deletedCard,
            )
                .then(() => this.fetchData());
        }

        if (!isEqual(
            this.props.restoredCard,
            prevProps.restoredCard,
        )) {
            this.props.setLoadingCompanyCards(true);

            this.props.restoreCompanyCard(
                this.props.accessToken,
                this.props.restoredCard,
            )
                .then(() => this.fetchData());
        }
    }

    fetchData() {
        this.props.getCompanyCards();
    }

    getRDAStatus() {
        this.props.getRDAStatus();
    }

    toggleOpenPanel() {
        this.setState(
            { unAssignedVehiclesExpanded: !this.state.unAssignedVehiclesExpanded },
            () => {
                this.props.setUnAssignedVehiclesExpanded(this.state.unAssignedVehiclesExpanded);
            },
        );
    }

    openConfirmDelete(companyCard) {
        this.setState({ companyCard, confirmDeleteOpen: true });
    }

    getNotAssignedRow() {
        return this.props.notAssignedVehicleIds.length > 0 && this.props.successfulCompanyCardRequest ?
            <div className={'row margin-bottom-20'}>
                <div className={'col-xs-12 form-group'}>
                    <ExpanderPanel
                        title={
                            <TooltipTrigger
                                tooltip={
                                    <FormattedMessage
                                        id={'administration.vehiclesNotAssignedToCompanyCardTooltip'}
                                        defaultMessage={
                                            'For those vehicles any available company card will be used for ' +
                                            'authentication. If you disagree with this approach, please ' +
                                            'assign the vehicles to a specific company card.'
                                        }
                                    />
                                }
                                width={400}
                                baseKey={'vehiclesNotAssignedToCompanyCardTooltip'}
                            >
                                <span>
                                    <span className={'rioglyph rioglyph-info-sign'}/>
                                    <span className={'margin-left-5'}>
                                        <FormattedMessage
                                            id={'administration.vehiclesNotAssignedToCompanyCard'}
                                            defaultMessage={
                                                'Vehicles that are not assigned to a specific company card.'
                                            }
                                        />
                                    </span>
                                </span>
                            </TooltipTrigger>
                        }
                        bsStyle={'info'}
                        open={this.state.unAssignedVehiclesExpanded}
                        onToggle={this.toggleOpenPanel}
                    >
                        <div className={'list-group list-group-horizontal'}>
                            {
                                getVehicleTagsByIds(
                                    this.props.vehicles,
                                    filterBySelectedVehicles(
                                        sortVehicles(
                                            this.props.vehicles,
                                            this.props.notAssignedVehicleIds,
                                        )
                                        ,
                                        this.props.selectedVehicles,
                                    ),
                                    'NotAssigned',
                                )
                            }
                        </div>
                    </ExpanderPanel>
                </div>
            </div> :
            null;
    }

    editCompanyCard(card, editName) {
        this.setState({ editedCard: card.companyChipId });
        this.props.editCompanyCard({ ...card, editName });
    }

    getConfirmDelete() {
        const content =
            <p>
                <FormattedMessage
                    id={'administration.confirmDeletionMessage'}
                    defaultMessage={'Are You sure that you want to delete this company card?'}
                />
            </p>;
        return (
            <ConfirmationDialog
                show={this.state.confirmDeleteOpen}
                onClickConfirm={() => {
                    this.setState({ confirmDeleteOpen: false });
                    this.props.sendDeletion(this.state.companyCard);
                }}
                onClickCancel={() => {
                    this.setState({ confirmDeleteOpen: false });
                }}
                cancelButtonText={<FormattedMessage id={'cancel'}/>}
                confirmButtonText={<FormattedMessage id={'confirm'}/>}
                title={<FormattedMessage id={'confirmRequest'} defaultMessage={'Confirm Request'}/>}
                content={content}
            />
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        if (!this.props.companyCards.length && this.props.loadingCompanyCards) {
            return <Spinner/>;
        }

        const { vehicles, editable, selectedVehicles, openedCompanyCards, toggleOpenCompanyCard } = this.props;

        const showEditButton = companyCard => {
            if (!companyCard.inCardHotel) {
                return true;
            } else if (companyCard.returnRequestSent &&
                // eslint-disable-next-line no-magic-numbers
                (companyCard.status === 0 || companyCard.status === 4)) {
                return false;
            } else if (companyCard.inCardHotel && getDiffDays(new Date(companyCard.expirationDate)) > 0) {
                return false;
            }
            return true;
        };

        const companyCardsPanels = this.props.companyCards
            .map(companyCard => getCompanyCardPanel(
                vehicles,
                selectedVehicles,
                companyCard,
                this.editCompanyCard,
                this.openConfirmDelete,
                editable,
                showEditButton(companyCard),
                this.state.editedCard === companyCard.companyChipId,
                openedCompanyCards.includes(companyCard.companyChipId),
                () => toggleOpenCompanyCard(companyCard.companyChipId),
                companyCard.inCardHotel,
                this.props.isRDAOnline,
                this.props.isRDAStatusFetched,
            ));

        return (
            <Analytics page={'/CompanyCardsPage'}>
                <div className={'CompanyCardsPage'}>
                    { this.getNotAssignedRow() }
                    { companyCardsPanels }
                    {this.getConfirmDelete()}
                </div>
            </Analytics>
        );
    }
}

CompanyCardsPage.defaultProps = {
    vehicles: [],
    selectedVehicles: [],
    tbm3Vehicles: [],
    editCompanyCard: () => {},
    openedCompanyCards: [],
    toggleOpenCompanyCard: () => {},
    getCompanyCards,
    editCompanyCardInBackend,
    removeCompanyCard,
    sendDeletion: () => {},
    restoreCompanyCard,
    setCompanyCards: () => {},
    injectedWindow: window,
    unAssignedVehiclesExpanded: true,
    setUnAssignedVehiclesExpanded: () => {},
};

CompanyCardsPage.propTypes = {
    editable: PropTypes.bool,
    accessToken: PropTypes.string,
    changedCard: PropTypes.object,
    deletedCard: PropTypes.object,
    restoredCard: PropTypes.object,
    injectedWindow: PropTypes.object,
    vehicles: PropTypes.array,
    tbm3Vehicles: PropTypes.array,
    editCompanyCard: PropTypes.func,
    openedCompanyCards: PropTypes.array,
    toggleOpenCompanyCard: PropTypes.func,
    getCompanyCards: PropTypes.func,
    editCompanyCardInBackend: PropTypes.func,
    removeCompanyCard: PropTypes.func,
    restoreCompanyCard: PropTypes.func,
    sendDeletion: PropTypes.func,
    setCompanyCards: PropTypes.func,
    selectedVehicles: PropTypes.array,
    setLoadingCompanyCards: PropTypes.func,
    notAssignedVehicleIds: PropTypes.array,
    companyCards: PropTypes.array,
    loadingCompanyCards: PropTypes.bool,
    successfulCompanyCardRequest: PropTypes.bool,
    location: PropTypes.object,
    unAssignedVehiclesExpanded: PropTypes.bool,
    setUnAssignedVehiclesExpanded: PropTypes.func,
    isRDAOnline: PropTypes.bool,
    isRDAStatusFetched: PropTypes.bool,
    getRDAStatus: PropTypes.func,

};

export default withRouter(CompanyCardsPage);
