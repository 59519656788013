
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CheckboxFilterComponent from './CheckboxFilterComponent';

const getToggle = (string, toggle) => () => toggle(string);

const ExportedToFilterComponent = props => (
    <div
        className={'ExportedToFilterComponent'}
        data-testid={'ExportedToFilterComponentId'}
    >
        <div>
            <p className={'text-bold text-color-darkest'}>
                <FormattedMessage id={'archive.filterExportStatus'} defaultMessage={'Filter Export Status'}/>
            </p>
            <CheckboxFilterComponent
                toggle={getToggle(
                    'notExported',
                    props.toggle,
                )}
                checked={props.notExported}
            >
                <span className={'LabelText'}>
                    <FormattedMessage id={'archive.notExported'} defaultMessage={'Not exported'}/>
                </span>
            </CheckboxFilterComponent>
            <CheckboxFilterComponent
                toggle={getToggle(
                    'computer',
                    props.toggle,
                )}
                checked={props.computer}
            >
                <span className={'LabelText'}>
                    <FormattedMessage id={'archive.exportedToComputer'} defaultMessage={'Exported to Computer'}/>
                </span>
            </CheckboxFilterComponent>
            <CheckboxFilterComponent
                toggle={getToggle(
                    'partner',
                    props.toggle,
                )}
                checked={props.partner}
            >
                <span className={'LabelText'}>
                    <FormattedMessage id={'archive.exportedToPartner'} defaultMessage={'Exported to partner'}/>
                </span>
            </CheckboxFilterComponent>
            <CheckboxFilterComponent
                toggle={getToggle(
                    'api',
                    props.toggle,
                )}
                checked={props.api}
            >
                <span className={'LabelText'}>
                    <FormattedMessage id={'archive.exportedViaAPI'} defaultMessage={'Exported via API'}/>
                </span>
            </CheckboxFilterComponent>
        </div>
    </div>
);

ExportedToFilterComponent.propTypes = {
    notExported: PropTypes.bool,
    computer: PropTypes.bool,
    partner: PropTypes.bool,
    api: PropTypes.bool,
    toggle: PropTypes.func,
};

export default ExportedToFilterComponent;
