import { env } from '../../env';
import { fetchData } from '../../sagas/fetchUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/RDAVersion`;

const getRDAVersion = (injectedFetch = fetchData) => injectedFetch(url)
    .then(res => {

        if (res.error) {
            throw new Error('tacho get rda version');
        }

        return res.version;
    });

export default getRDAVersion;
