const basePath = 'TACHOGRAPHSERVICES.TREE.';
export const SUCCESSFUL_FETCH_ASSETS = `${basePath}SUCCESSFUL_FETCH_ASSETS`;
export const SUCCESSFUL_FETCH_DRIVERS = `${basePath}SUCCESSFUL_FETCH_DRIVERS`;
export const SUCCESSFUL_FETCH_TAGS = `${basePath}SUCCESSFUL_FETCH_TAGS`;
export const SET_SELECTED_VEHICLES = `${basePath}SET_SELECTED_VEHICLES`;
export const SET_SELECTED_DRIVERS = `${basePath}SET_SELECTED_DRIVERS`;
export const SET_CURRENT_CATEGORY = `${basePath}SET_CURRENT_CATEGORY`;
export const IS_ASSET_TREE_OPEN = `${basePath}IS_ASSET_TREE_OPEN`;

export function successfulFetchAssets(array) {
    return {
        type: SUCCESSFUL_FETCH_ASSETS,
        payload: array,
    };
}

export function successfulFetchDrivers(array) {
    return {
        type: SUCCESSFUL_FETCH_DRIVERS,
        payload: array,
    };
}

export function successfulFetchTags(array) {
    return {
        type: SUCCESSFUL_FETCH_TAGS,
        payload: array,
    };
}

export function setSelectedVehicles(array) {
    return {
        type: SET_SELECTED_VEHICLES,
        payload: array,
    };
}

export function setSelectedDrivers(array) {
    return {
        type: SET_SELECTED_DRIVERS,
        payload: array,
    };
}

export function setCurrentCategory(array) {
    return {
        type: SET_CURRENT_CATEGORY,
        payload: array,
    };
}

export function setAssetTreeState(bool) {
    return {
        type: IS_ASSET_TREE_OPEN,
        payload: bool,
    };
}
