/* eslint-disable no-undefined */

const getQuery = ({
    limit,
    offset,
    sortField,
    sortOrder,
}) => `?${
    limit === undefined ? '' : `limit=${limit}`
}${
    offset === undefined ? '' : `&offset=${offset}`
}${
    sortField === undefined ? '' : `&sortField=${sortField}`
}${
    sortOrder === undefined ? '' : `&sortOrder=${sortOrder}`
}`;

export default getQuery;
