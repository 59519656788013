
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';

const { TACHO_SETTINGS_PATH } = env.runtimeConfig.backend;

const objectToBody = (key, value) => JSON.stringify({
    settingKey: key,
    settingValue: JSON.stringify(value),
});

const setTachoSettingFetch = (
    token,
    key,
    value,
    injectedFetch,
) => injectedFetch(
    TACHO_SETTINGS_PATH,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: objectToBody(
            key,
            value,
        ),
    },
);

const setTachoSetting = (
    token,
    key,
    value,
    displayNotification = false,
    injectedFetch = window.fetch,
    injectedNotification = Notification,
) => setTachoSettingFetch(
    token,
    key,
    value,
    injectedFetch,
)
    .then(res => {
        if (displayNotification) {
            if (res.ok) {
                injectedNotification.success(<FormattedMessage id={'saved'} defaultMessage={'Saved'}/>);
            } else {

                injectedNotification.error(<FormattedMessage
                    id={'saveTachoSettingFailed'}
                    defaultMessage={'Saving failed'}
                />);
            }
        }
    })
    .catch(err => {
        if (displayNotification) {
            injectedNotification.error(<FormattedMessage
                id={'savingFailed'}
                defaultMessage={'Saving failed'}
            />);
        }
        console.log(err); // eslint-disable-line no-console
    });

export default setTachoSetting;
