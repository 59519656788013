
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/analysisportal/enable`;

const setAnalysisPortalFetch = (
    token,
    body,
    injectedFetch,
) => injectedFetch(
    url,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    },
);
const setAnalysisPortal = (
    token,
    body,
    injectedFetch = window.fetch,
) => setAnalysisPortalFetch(
    token,
    body,
    injectedFetch,
)
    .then(res => {
        if (res.ok) {
            Notification.success(<FormattedMessage
                id={'administration.analysisPortalUpdated'}
                defaultMessage={'Analysis portal settings updated'}
            />);
            return true;
        }
        Notification.error(<FormattedMessage
            id={'administration.analysisPortalError'}
            defaultMessage={'Updating analysis portal settings failed!'}
        />);
        return false;
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default setAnalysisPortal;
