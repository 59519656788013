import {FormattedDate, FormattedMessage} from 'react-intl';

import {getDiffDays, getDiffText, getStatusColor} from '../helpers';
import {dateProps} from '../../../constants/common';

const formatDueDate = cell => {
    if (!cell) {
        return '';
    }

    const diffDays = getDiffDays(cell);
    const color = getStatusColor(diffDays);

    const diffText = getDiffText(diffDays);

    return (
        <div>
            <FormattedDate value={cell} {...dateProps}/>
            <span className={`margin-left-10 rioglyph rioglyph-sphere ${color}`}/>
            <span className={'margin-left-10'}>
                {diffText}
                <FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>
            </span>
        </div>
    );
};

export default formatDueDate;
