import { env } from '../../env';

const { RETENTION_PATH } = env.runtimeConfig.backend;

const setRetentionPeriodFetch = (
    token,
    newValue,
    injectedFetch,
) => injectedFetch(
    RETENTION_PATH,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(newValue),
    },
);

const setRetentionPeriod = (
    token,
    newValue,
    injectedFetch = window.fetch,
) => setRetentionPeriodFetch(
    token,
    newValue,
    injectedFetch,
)
    .then(res => {
        if (res.ok) {
            return true;
        }

        throw new Error('set retention period call failed');
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default setRetentionPeriod;
