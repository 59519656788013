import {FormattedDate, FormattedMessage} from 'react-intl';

import {getDiffText} from '../helpers';
import {dateProps, ONE_DAY_MILLISECONDS} from '../../../constants/common';

const formatPlannedDownloadDate = cell => {
    if (!cell) {
        return '';
    }

    const diffDays = Math.floor(new Date() / ONE_DAY_MILLISECONDS) - Math.floor(cell / ONE_DAY_MILLISECONDS);

    const diffText = getDiffText(diffDays);
    return (
        <div>
            <FormattedDate value={cell} {...dateProps}/>
            <span className={'margin-left-10'}>
                {diffText}
                <FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>
            </span>
        </div>
    );
};

export default formatPlannedDownloadDate;
