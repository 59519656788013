
import { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';

import { TIME_RANGE_TYPE } from '../../constants/archive';

import { setTimeRangeType } from '../../actions/archive';

const createDropdownItem = (
    item,
    onSelectTimeRangeType,
    selectedTimeRangType,
    setIsOpen,
) => (
    <li
        key={item.type}
        role={'presentation'}
        className={'width-200'}
        onClick={() => {
            onSelectTimeRangeType(item.type);
            setIsOpen(false);
        }}
    >
        {/* eslint-disable-next-line */}
        <a role={'menuitem'}>
            {selectedTimeRangType === item.type ?
                <span data-testid={'dropDownItemSelectedIcon'} className={'rioglyph rioglyph-ok margin-right-5'} /> :
                null}
            <FormattedMessage id={item.translationKey} />
        </a>
    </li>
);

const dropdownItems = [
    { type: TIME_RANGE_TYPE.TODAY, translationKey: 'today' },
    { type: TIME_RANGE_TYPE.LAST_7_DAYS, translationKey: 'last7Days' },
    { type: TIME_RANGE_TYPE.LAST_WEEK, translationKey: 'lastWeek' },
    { type: TIME_RANGE_TYPE.LAST_MONTH, translationKey: 'lastMonth' },
    { type: TIME_RANGE_TYPE.CUSTOM, translationKey: 'custom' },
];

const PreSelectionActiveMarker = props => {
    if (props.selectedTimeRangType !== TIME_RANGE_TYPE.CUSTOM) {
        return (
            <div
                className={
                    'bg-primary rounded-circle border-style-solid ' +
                    'border-color-white border-size-1 padding-5 position-absolute top--2 right--3 z-index-10'
                }
            />
        );
    }
    return null;
};

PreSelectionActiveMarker.propTypes = {
    selectedTimeRangType: PropTypes.string,
};

const PreSelectionDropdown = props => {
    const { selectedTimeRangType, onSelectTimeRangeType, disabled } = props;
    const [isOpen, setIsOpen] = useState(false);

    const customDropdown = dropdownItems.map(item => createDropdownItem(
        item,
        onSelectTimeRangeType,
        selectedTimeRangType,
        setIsOpen,
    ));

    return (
        <div data-testid={'TimeRangePreSelection'}>
            <div className={'position-relative margin-right-10'}>
                <ButtonDropdown
                    className={'position-relative'}
                    title={<span className={'rioglyph rioglyph rioglyph-settings'} aria-hidden={'true'} />}
                    dropdownClassName={'width-200'}
                    customDropdown={customDropdown}
                    iconOnly
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    disabled={disabled}
                />
                <PreSelectionActiveMarker selectedTimeRangType={selectedTimeRangType} />
            </div>
        </div>
    );
};

PreSelectionDropdown.propTypes = {
    selectedTimeRangType: PropTypes.string,
    onSelectTimeRangeType: PropTypes.func,
    disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    selectedTimeRangType: get(
        state,
        'tachographservices.archive.selectedTimeRangeType',
    ),
});

const mapDispatchToProps = dispatch => ({
    onSelectTimeRangeType: type => {
        dispatch(setTimeRangeType(type));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreSelectionDropdown);
