/* eslint-disable default-param-last */

import { setAnalysisPortalAccountInfo } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';
import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getAnalysisPortalAccountFetch = (
    store,
    fetch = window.fetch,
) => () => fetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/analysisportal`,
    {
        headers: {
            Authorization: `Bearer ${`${store.getState().tokenHandling.accessToken}`}`,
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    store.dispatch(setAnalysisPortalAccountInfo(data));
};

const configureFetchAnalysisPortalAccount = store => new Promise(resolve => {
    const analysisPortalAccountInfo = getAnalysisPortalAccountFetch(store);

    saveFetch(
        analysisPortalAccountInfo,
        'analysis portal account info',
        getHandleData(store),
        resolve,
        () => {},
    );
});

export default configureFetchAnalysisPortalAccount;
