import {
    cloneDeep,
    defaultTo,
    flow,
    forEach,
    has,
    head,
    keys,
    split,
} from 'lodash/fp';

import {
    CHANGE_LOCALE,
    LANGUAGE_DATA_FETCHED,
} from './actions';

import { DEFAULT_LANGUAGE } from './translationSetup';

const getDefaultMessages = messagesEN => ({
    en: messagesEN,
    [DEFAULT_LANGUAGE]: messagesEN,
});

export const extractLanguage = flow(
    defaultTo(DEFAULT_LANGUAGE),
    split('-'),
    head,
);

// istanbul ignore next
const getSupportedLocaleFromData = data => flow(
    locale => has(
        locale,
        data,
    ) ?
        locale :
        extractLanguage(locale),
    locale => has(
        locale,
        data,
    ) ?
        locale :
        navigator.language,
    locale => has(
        locale,
        data,
    ) ?
        locale :
        extractLanguage(navigator.language),
    locale => has(
        locale,
        data,
    ) ?
        locale :
        DEFAULT_LANGUAGE,
);

// istanbul ignore next
const getMessages = (
    { allMessages },
    locale,
    SUPPORTED_LANGUAGES = {},
) => allMessages[SUPPORTED_LANGUAGES[locale]] || allMessages[DEFAULT_LANGUAGE];
const isLangOnly = locale => !(/-/u).test(locale);

// istanbul ignore next
const defaultFor = (locale, SUPPORTED_LANGUAGES) => SUPPORTED_LANGUAGES[locale];

// istanbul ignore next
const patchMissingMessages = (knownMessages, messages) => {
    const result = cloneDeep(messages);

    flow(
        keys,
        forEach(key => {
            if (!has(
                key,
                result,
            )) {
                result[key] = knownMessages[key];
            }
        }),
    )(knownMessages);

    return result;
};

// istanbul ignore next
const applyLocale = (state, preferredLocale, SUPPORTED_LANGUAGES) => {
    const { allMessages } = state;

    const getDisplayLocale = getSupportedLocaleFromData(allMessages);
    const getSupported = getSupportedLocaleFromData(SUPPORTED_LANGUAGES);

    const displayLocale = getDisplayLocale(preferredLocale);

    const closest = getSupported(preferredLocale);
    const supportedLocale = isLangOnly(closest) ?
        defaultFor(
            closest,
            SUPPORTED_LANGUAGES,
        ) :
        closest;

    const canFetchSupportedLocale = displayLocale !== supportedLocale;

    const displayMessages = patchMissingMessages(
        getMessages(
            state,
            DEFAULT_LANGUAGE,
            SUPPORTED_LANGUAGES,
        ),
        getMessages(
            state,
            displayLocale,
            SUPPORTED_LANGUAGES,
        ),
    );

    const result = {
        allMessages,
        canFetchSupportedLocale,
        displayLocale,
        displayMessages,
        preferredLocale,
        supportedLocale,
    };

    return result;
};

export const getDefaultState = (messagesEN, SUPPORTED_LANGUAGES) => applyLocale(
    { allMessages: getDefaultMessages(messagesEN) },
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGES,
);

// istanbul ignore next
const isDefaultLocaleForLang = (locale, SUPPORTED_LANGUAGES) => SUPPORTED_LANGUAGES[locale] === locale;
// istanbul ignore next
const hasLocale = (locale, SUPPORTED_LANGUAGES) => Boolean(SUPPORTED_LANGUAGES[locale]);
// istanbul ignore next
const mergeLanguageData = (allMessages, languageData, locale, SUPPORTED_LANGUAGES) => {
    const baseLang = extractLanguage(locale);
    const messages = {
        [locale]: languageData,
    };

    if (isDefaultLocaleForLang(
        locale,
        SUPPORTED_LANGUAGES,
    ) || !hasLocale(
        baseLang,
        SUPPORTED_LANGUAGES,
    )) {
        messages[baseLang] = languageData;
    }

    return {
        ...allMessages,
        ...messages,
    };
};

const getLangReducer = (messagesEN, SUPPORTED_LANGUAGES) => (
    state = getDefaultState(
        messagesEN,
        SUPPORTED_LANGUAGES,
    ),
    // istanbul ignore next
    action = {},
) => {
    // istanbul ignore next
    switch (action.type) {
        case CHANGE_LOCALE: {
            return {
                ...state,
                ...applyLocale(
                    state,
                    action.payload,
                    SUPPORTED_LANGUAGES,
                ),
            };
        }
        case LANGUAGE_DATA_FETCHED: {
            const { locale, languageData } = action.payload;

            if (!languageData) {
                return {
                    ...state,
                    ...applyLocale(
                        state,
                        locale,
                        SUPPORTED_LANGUAGES,
                    ),
                };
            }

            const merged = {
                ...state,
                allMessages: mergeLanguageData(
                    state.allMessages,
                    languageData,
                    locale,
                    SUPPORTED_LANGUAGES,
                ),
            };

            return {
                ...merged,
                ...applyLocale(
                    merged,
                    locale,
                    SUPPORTED_LANGUAGES,
                ),
            };
        }
        default:
            return state;
    }
};

export default getLangReducer;
