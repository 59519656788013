
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import Carousel from '@rio-cloud/rio-uikit/Carousel';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

import { Component } from 'react';

import NumberDisplayWidget from '../../common/widgets/NumberDisplayWidget';

import { getAccessToken } from '../../../features/tokenHandling/selectors';
import { ONE_DAY_MILLISECONDS } from '../../../constants/common';

import {setCurrentCategory as setCurrentCategoryAction} from '../../../actions/tree';

import {REMOTE_DOWNLOAD_TAB, TIME_RANGE_TYPE, MANUAL_TAB} from '../../../constants/archive';

import {setTimeRange, setTimeRangeType} from '../../../actions/archive';

import {switchToTab, saveStateToRoute} from '../../../features/utils/routing';

import getRDAStatus from '../../../fetches/RDA/getRDAStatus';

import store from '../../../setup/store';

import getCompanyCards from '../../../fetches/CompanyCards/getCards';

import getNoOfSubscribedVehicles from '../../../utils/getNoOfSubscribedVehicles';

import {shouldEnableRDA} from '../../../utils/shouldEnableRDA';

import getNumberOfFiles from './getNumberOfFilesFetch';

const RDA_STATUS_POLLING_INTERVAL = 30000;

const getNumberOfCarouselItems = className => {
    const elements = document.querySelectorAll(`${className} .carousel-item`);
    return elements.length;
};

const getRdaStatusIcon = rdaStatus => {
    if (rdaStatus) {
        return 'rioglyph text-success rioglyph-off';
    }
    return 'rioglyph text-danger rioglyph-off';
};

const getRdaStatusLabel = rdaStatus => {
    if (rdaStatus) {
        return <FormattedMessage
            id={'widget.RDAOnline'}
            // eslint-disable-next-line max-len
            defaultMessage={'RDA online'}
        />;
    }
    return <FormattedMessage
        id={'widget.RDAOffline'}
        // eslint-disable-next-line max-len
        defaultMessage={'RDA offline'}
    />;
};

const getRdaMultipleVersionsStatusIcon = () => 'rioglyph text-danger rioglyph rioglyph-warning-sign';
class WidgetWrapper extends Component {
    constructor(props) { // eslint-disable-line max-statements
        super(props);
        this.state = {
        };

    }

    getNoOfOnlineCompanyCards(type, companyCards) {
        // eslint-disable-next-line immutable/no-let
        if (type === 'RDA') {
            return companyCards.filter(card => !card.inCardHotel && card.status !== 0 && this.props.isRDAOnline).length;
        }
        return companyCards.filter(card => card.inCardHotel && card.status !== 0).length;

    }

    startFetchRdaStatus() {
        this.props.getRDAStatus();
        this.rdaStatusPolling = window.setInterval(
            this.props.getRDAStatus,
            RDA_STATUS_POLLING_INTERVAL,
        );
    }

    startFetchCompanyCardsStatus() {
        this.props.getCompanyCards();
        this.companyCardsPolling = window.setInterval(
            this.props.getCompanyCards,
            RDA_STATUS_POLLING_INTERVAL,
        );
    }

    endFetching() {
        window.clearInterval(this.rdaStatusPolling);
        window.clearInterval(this.companyCardsPolling);

    }

    componentDidMount() {
        this.startFetchRdaStatus();
        this.startFetchCompanyCardsStatus();
    }

    componentWillUnmount() {
        this.endFetching();
    }

    // eslint-disable-next-line max-lines-per-function
    render() {
        return (<div className={'WidgetWrapper row'}>
            <div className={'col-md-3'}>
                <NumberDisplayWidget
                    header={
                        <FormattedMessage
                            id={'widget.TachographDownloads'}
                            defaultMessage={'Tachograph Downloads'}
                        />
                    }
                    subLabel={
                        <FormattedMessage
                            id={'last24Hours'}
                            defaultMessage={'Last 24 hours'}
                        />
                    }
                    getData={
                        () => getNumberOfFiles(
                            this.props.token,
                            {
                                fileType: 'Vehicle',
                                startUtc: new Date(Date.now() - ONE_DAY_MILLISECONDS).toISOString(),
                                endUtc: new Date().toISOString(),
                            },
                        )
                    }
                    onDataClick={
                        () => {
                            this.props.setCurrentCategory('AssetTree-vehicles');
                            saveStateToRoute({
                                currentCategoryId: 'AssetTree-vehicles',
                                onlyLatest: false,
                            });
                            this.props.onSelectTimeRangeType(TIME_RANGE_TYPE.CUSTOM);
                            this.props.onRangeChange(
                                new Date(Date.now() - ONE_DAY_MILLISECONDS),
                                new Date(),
                            );
                            this.props.selectArchiveTab();
                        }
                    }
                    info={<FormattedMessage
                        id={'widget.NumberOfTachoDownloadsL24h-1'}
                        defaultMessage={'Number of tachograph downloads in the last 24 hours'}
                    />}
                    showRefreshIcon={true}
                />
            </div>
            <div className={'col-md-3'}>
                <NumberDisplayWidget
                    header={
                        <FormattedMessage
                            id={'widget.DriverCardDownloads-1'}
                            defaultMessage={'Driver Card Downloads'}
                        />
                    }
                    subLabel={
                        <FormattedMessage
                            id={'last24Hours'}
                            defaultMessage={'Last 24 hours'}
                        />
                    }
                    getData={
                        () => getNumberOfFiles(
                            this.props.token,
                            {
                                fileType: 'Driver',
                                startUtc: new Date(Date.now() - ONE_DAY_MILLISECONDS).toISOString(),
                                endUtc: new Date().toISOString(),
                            },
                        )
                    }
                    onDataClick={
                        () => {
                            this.props.setCurrentCategory('AssetTree-drivers');
                            saveStateToRoute({
                                currentCategoryId: 'AssetTree-drivers',
                                onlyLatest: false,
                            });
                            this.props.onSelectTimeRangeType(TIME_RANGE_TYPE.CUSTOM);
                            this.props.onRangeChange(
                                new Date(Date.now() - ONE_DAY_MILLISECONDS),
                                new Date(),
                            );
                            this.props.selectArchiveTab();
                        }
                    }
                    info={<FormattedMessage
                        id={'widget.NumberOfDriverDownloadsL24h'}
                        defaultMessage={'Number of driver card downloads in the last 24 hours'}
                    />}
                    showRefreshIcon={true}
                />
            </div>
            <div className={'col-md-3'}>
                <Carousel
                    controls = {getNumberOfCarouselItems('.compliantSlide') > 1}
                    fade={false}
                    indicators={false}
                    interval={8000}
                    keyboard={false}
                    onSelect={() => {}}
                    pause={'hover'}
                    slide
                    touch
                    className={'offset-indicators compliantSlide'}
                >
                    {
                        getNoOfSubscribedVehicles(
                            '1',
                            this.props.vehiclesWithSubscription,
                        ) !== 0 &&
                        <Carousel.Item>
                            <NumberDisplayWidget
                                header={
                                    <FormattedMessage
                                        id={'widget.compliantS'}
                                        defaultMessage={'Compliant'}
                                    />
                                }
                                subLabel={
                                    null
                                }
                                getData={
                                    () => Promise.resolve(getNoOfSubscribedVehicles(
                                        '1',
                                        this.props.vehiclesWithSubscription,
                                    ))
                                }
                                onDataClick={
                                    () => {}
                                }
                                info={<FormattedMessage
                                    id={'widget.numberOfCompliantSubscriptions'}
                                    values={{type: ''}}
                                />}

                            />
                        </Carousel.Item>

                    }
                    {
                        <Carousel.Item>
                            <NumberDisplayWidget
                                header={
                                    <FormattedMessage
                                        id={'widget.compliantM'}
                                        defaultMessage={'Compliant M'}
                                    />
                                }
                                subLabel={
                                    null
                                }
                                getData={
                                    () => Promise.resolve(getNoOfSubscribedVehicles(
                                        '2',
                                        this.props.vehiclesWithSubscription,
                                    ))
                                }
                                onDataClick={
                                    () => {}
                                }
                                info={<FormattedMessage
                                    id={'widget.numberOfCompliantSubscriptions'}
                                    values={{type: 'M'}}
                                />}
                            />
                        </Carousel.Item>
                    }
                    {getNoOfSubscribedVehicles(
                        '3',
                        this.props.vehiclesWithSubscription,
                    ) !== 0 &&
                     <Carousel.Item>
                         <NumberDisplayWidget
                             header={
                                 <FormattedMessage
                                     id={'widget.comliantL'}
                                     defaultMessage={'Compliant L'}
                                 />
                             }
                             subLabel={
                                 null
                             }
                             getData={
                                 // eslint-disable-next-line no-use-before-define
                                 () => Promise.resolve(getNoOfSubscribedVehicles(
                                     '3',
                                     this.props.vehiclesWithSubscription,
                                 ))
                             }
                             onDataClick={
                                 () => {}
                             }
                             info={<FormattedMessage
                                 id={'widget.numberOfCompliantSubscriptions'}
                                 values={{type: 'L'}}
                             />}
                         />
                     </Carousel.Item> }
                </Carousel>
            </div>
            <div className={'col-md-3'}>
                <Carousel
                    controls = {getNumberOfCarouselItems('.cardsStatus') > 1}
                    fade={false}
                    indicators={false}
                    interval={8000}
                    keyboard={false}
                    onSelect={() => {}}
                    pause={'hover'}
                    slide
                    touch
                    className={'offset-indicators cardsStatus'}
                >
                    {(getNoOfSubscribedVehicles(
                        '1',
                        this.props.vehiclesWithSubscription,
                    ) !== 0 || shouldEnableRDA()) &&
                    <Carousel.Item>
                        <NumberDisplayWidget
                            header={
                                <div>
                                    <FormattedMessage
                                        id={'widget.RDAOnlineStatus'}
                                        defaultMessage={'RDA Status'}
                                    />
                                    <OverlayTrigger
                                        placement={'bottom-start'}
                                        overlay={
                                            <Tooltip id={'tooltip'} allowOnTouch>
                                                {getRdaStatusLabel(this.props.isRDAOnline)}
                                            </Tooltip>
                                        }
                                    >
                                        <span
                                            style={{ paddingLeft: '8px' }}
                                            className={getRdaStatusIcon(this.props.isRDAOnline)}
                                        />
                                    </OverlayTrigger>

                                    {this.props.multipleRdaOnline &&
                                    <OverlayTrigger
                                        placement={'bottom-start'}
                                        overlay={
                                            <Tooltip id={'tooltip'} allowOnTouch>
                                                <FormattedMessage
                                                    id={'widget.mutltipleRDAWarning'}
                                                    // eslint-disable-next-line max-len
                                                    defaultMessage={'Multiple RDA versions detected. Please make sure that only latest RDA version is installed.'}
                                                />
                                            </Tooltip>
                                        }
                                    >
                                        <span
                                            style={{ paddingLeft: '8px' }}
                                            className={getRdaMultipleVersionsStatusIcon()}
                                        />
                                    </OverlayTrigger> }
                                </div>
                            }
                            subLabel={
                                null
                            }
                            getData={
                                () => Promise.resolve(
                                    this.props.getCompanyCards(),
                                    this.props.getRDAStatus(),
                                )
                            }
                            onDataClick={
                                () => {
                                    this.props.selectGuidanceTab();
                                }
                            }
                            showRefreshIcon={true}
                            value={ this.getNoOfOnlineCompanyCards(
                                'RDA',
                                this.props.companyCards,
                            )}
                            info={<FormattedMessage
                                id={'widget.numberOfConnectedRDACards'}
                                // eslint-disable-next-line max-len
                                defaultMessage={'Number of company cards connected to the Remote Download Application'}
                            />}
                        />
                    </Carousel.Item>
                    }
                    <Carousel.Item>
                        <NumberDisplayWidget
                            header={
                                <FormattedMessage
                                    id={'widget.cardHotelCards-1'}
                                    defaultMessage={'Card Hotel Cards'}
                                />
                            }
                            subLabel={
                                null
                            }
                            getData={
                                () => Promise.resolve(this.props.getCompanyCards())
                            }
                            onDataClick={
                                () => {
                                    this.props.selectGuidanceTab();
                                }
                            }
                            showRefreshIcon={true}
                            value={ this.getNoOfOnlineCompanyCards(
                                'Hotel',
                                this.props.companyCards,
                            )}
                            info={<FormattedMessage
                                id={'widget.numberOfcardHotelOnlineCards'}
                                defaultMessage={'Number of company cards hosted in the card hotel'}
                            />}

                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>);
    }
}

const mapStateToProps = state => ({
    token: getAccessToken(state),
    rawVehicles: state.tachographservices.tree.rawVehicles,
    vehicles: state.tachographservices.tree.vehicles,
    drivers: state.tachographservices.tree.drivers,
    vehiclesWithSubscription: state.tachographservices.tree.vehiclesWithSubscription,
    loadingRDA: state.tachographservices.administration.loadingRDA,
    isRDAOnline: state.tachographservices.administration.isRDAOnline,
    multipleRdaOnline: state.tachographservices.administration.multipleRdaOnline,
    isRDAStatusFetched: state.tachographservices.administration.isRDAStatusFetched,
    isCompanyCardAvailable: state.tachographservices.administration.isCompanyCardAvailable,
    companyCards: state.tachographservices.notifications.companyCards,
});

const toDate = value => value instanceof Date ? value : value.toDate();

const mapDispatchToProps = dispatch => ({
    setCurrentCategory: payload => dispatch(setCurrentCategoryAction(payload)),
    selectArchiveTab: () => {
        switchToTab(REMOTE_DOWNLOAD_TAB);
    },
    selectGuidanceTab: () => {
        switchToTab(MANUAL_TAB);
    },
    onRangeChange: (startValue, endValue) => {
        const start = startValue === null ? null : toDate(startValue);
        const end = endValue === null ? null : toDate(endValue);
        dispatch(setTimeRange({ start, end }));
    },
    onSelectTimeRangeType: type => {
        dispatch(setTimeRangeType(type));
    },
    getRDAStatus: () => {
        getRDAStatus(
            store.getState().tachographservices.administration,
            dispatch,
        );
    },
    getCompanyCards: () => {
        getCompanyCards(dispatch);
    },
});

WidgetWrapper.defaultProps = {
    rawVehicles: [],
    vehicles: [],
    drivers: [],
    vehiclesWithSubscription: [],
    companyCards: [],
    companyCardsItems: [],
    loadingRDA: false,
    isRDAOnline: false,
    multipleRdaOnline: false,
    isRDAStatusFetched: false,
    isCompanyCardAvailable: false,
    setCurrentCategory: () => {},
    selectArchiveTab: () => {},
    selectGuidanceTab: () => {},
    onRangeChange: () => {},
};

WidgetWrapper.propTypes = {
    token: PropTypes.string.isRequired,
    rawVehicles: PropTypes.array,
    vehicles: PropTypes.array,
    drivers: PropTypes.array,
    vehiclesWithSubscription: PropTypes.array,
    companyCards: PropTypes.array,
    loadingRDA: PropTypes.bool,
    isRDAOnline: PropTypes.bool,
    multipleRdaOnline: PropTypes.bool,
    isRDAStatusFetched: PropTypes.bool,
    isCompanyCardAvailable: PropTypes.bool,
    setCurrentCategory: PropTypes.func,
    selectArchiveTab: PropTypes.func,
    selectGuidanceTab: PropTypes.func,
    onRangeChange: PropTypes.func,
    onSelectTimeRangeType: PropTypes.func,
    getRDAStatus: PropTypes.func,
    getCompanyCards: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WidgetWrapper);
