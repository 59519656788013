import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Switch from '@rio-cloud/rio-uikit/Switch';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import store from '../../../setup/store';

import { getAccessToken } from '../../../features/tokenHandling/selectors';

import TooltipTrigger from '../../../components/common/TooltipTrigger';
import setAnalysisPortal from '../../../fetches/Settings/setAnalysisPortal';
import { setAnalysisPortalAccountInfo } from '../../../actions/common';
import { ANALYSIS_PORTAL } from '../../../constants/archive';
import { switchToTab } from '../../../features/utils/routing';

const borderClass = 'border-width-1 border-color-lighter border-style-solid border-right-0 border-left-0 border-top-0';
export const paddingClass = 'padding-20';
export const rowClass = `${borderClass} ${paddingClass}`;
export const lastRowClass = `${rowClass} border-bottom-0`;

class AnalysisPortalSettingsSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDialogOpen: false,
            analysisPortalValue: false,
            isEnabled: false,
        };

        this.closeSidebar = this.closeSidebar.bind(this);
        this.save = this.save.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.abortConfirm = this.abortConfirm.bind(this);
        this.goToAnalyisPortal = this.goToAnalyisPortal.bind(this);
        this.changeAnalysisPortalValue = this.changeAnalysisPortalValue.bind(this);
    }

    componentDidMount() {
        if (this.props.analysisPortalAccount.isActive) {
            this.setState({isEnabled: true});
        }
        this.setState({analysisPortalValue: this.props.analysisPortalAccount.isActive});
    }

    closeSidebar() {
        this.props.setSettingsSidebarOpen(false);
    }

    save() {
        this.setState({
            confirmDialogOpen: true,
        });
        try {
            document.getElementsByClassName('ApplicationHeader')[0].classList.add('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    goToAnalyisPortal() {
        this.props.selectAnalysisPortalTab();
    }

    closeConfirm() {
        const body = {enable: this.state.isEnabled};
        setAnalysisPortal(
            getAccessToken(store.getState()),
            body,
        ).then(res => {
            if (res) {
                const data = {ident: this.props.analysisPortalAccount.ident, isActive: body};
                this.props.setAnalysisPortalAccountInfo(data);
                this.setState({analysisPortalValue: data.isActive});
            }
        });
        this.setState({
            confirmDialogOpen: false,
        });

        try {
            document
                .getElementsByClassName('ApplicationHeader')[0]
                .classList
                .remove('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }

    }

    abortConfirm() {
        this.setState({
            confirmDialogOpen: false,
        });

        try {
            document
                .getElementsByClassName('ApplicationHeader')[0]
                .classList
                .remove('z-index-1');
        } catch (error) {
            console.log(error); // eslint-disable-line no-console
        }
    }

    changeAnalysisPortalValue(value) {
        this.setState({ analysisPortalValue: value });
    }

    analysisPortalValueChanged() {
        return this.state.analysisPortalValue !== this.state.isEnabled;
    }

    getFooter() {
        const hasChanges = this.analysisPortalValueChanged();
        return (
            <div className={'btn-toolbar float-right'}>
                <a
                    className={'btn btn-default'}
                    onClick={this.closeSidebar}
                >
                    <FormattedMessage id={'cancel'} defaultMessage={'Cancel'} />
                </a>
                <a
                    className={`btn btn-primary ${hasChanges ? '' : 'disabled'}`}
                    onClick={this.save}
                >
                    <FormattedMessage id={'save'} defaultMessage={'Save'} />
                </a>
            </div>
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        return (
            <div className={'col-md-12'}>

                <Sidebar
                    {...this.props.sidebarProps}
                    footer={this.getFooter()}
                >
                    {this.props.sidebarNavigation}
                    <div className={rowClass}>

                        <div className={'display-block'}>
                            <div className={'SidebarHeader padding-0'}>
                                <FormattedMessage
                                    id={'administration.analysisPortalSettings'}
                                    defaultMessage={'Analysis Portal Settings'}
                                />
                            </div>
                            <p>
                                <FormattedMessage
                                    id={'administration.analysisPortalSettingsInfo'}
                                    // eslint-disable-next-line max-len
                                    defaultMessage={'Activate the analysis portal to analyze your tachograph files and driver card files and to get country-specific infringement reports.'}
                                />
                            </p>
                        </div>
                        <div className={'IntervalSettingsCustomSwitch'}>
                            <Switch
                                checked={this.state.isEnabled}
                                onChange={() => this.setState({isEnabled: !this.state.isEnabled})}
                                labelPosition={'right'}
                            >
                                <span >
                                    <FormattedMessage
                                        id={'settings.enableAnalysisPortal'}
                                        defaultMessage={'Enable Analysis Portal'}
                                    />
                                    <TooltipTrigger
                                        baseKey={'settings.speedDataDownload'}
                                        tooltip={
                                            <FormattedMessage
                                                id={'settings.analysisPortalToolTip'}
                                                defaultMessage={
                                                    'Enable sending files to Analysis Portal'
                                                }
                                            />
                                        }
                                    >
                                        <span className={'rioglyph rioglyph-info-sign text-primary margin-left-5'}/>
                                    </TooltipTrigger>
                                </span>
                            </Switch>
                        </div>
                        <br />
                        <Link
                            className={'btn btn-primary'}
                            disabled={!this.state.analysisPortalValue}
                            target={'_blank'}
                            to={{ pathname: `/${ANALYSIS_PORTAL}`}}>
                            <FormattedMessage
                                id={'settings.analysisPortalLink'}
                                defaultMessage={
                                    'Go to the Analysis Portal'
                                }/>
                        </Link>

                    </div>
                    <div className={rowClass}>
                        <FormattedMessage
                            id={'settings.analysisPortalNote'}
                            // eslint-disable-next-line max-len
                            defaultMessage={'Analysis can be done for those files that are successfully downloaded after activation. The download interval can be modified in the settings.'}/>
                    </div>
                    <ConfirmationDialog
                        title={<FormattedMessage
                            id={'analysisPortalTitle'}
                            defaultMessage={'Analysis Portal Settings'}/>}
                        content={
                            <div>
                                <FormattedMessage
                                    id={'analysisPortalConfirm'}
                                    defaultMessage={'Are you sure that you want to update Analysis Portal settings?'}/>
                            </div>
                        }
                        show={this.state.confirmDialogOpen}
                        onClickConfirm={this.closeConfirm}
                        onClickCancel={this.abortConfirm}
                        cancelButtonText={<FormattedMessage id={'no'} defaultMessage={'No'}/>}
                        confirmButtonText={<FormattedMessage id={'yes'} defaultMessage={'Yes'}/>}
                    />
                </Sidebar>
            </div>
        );
    }

}

AnalysisPortalSettingsSidebar.defaultProps = {
    sidebarProps: { closed: true },
};

AnalysisPortalSettingsSidebar.propTypes = {
    accessToken: PropTypes.string.isRequired,
    setSettingsSidebarOpen: PropTypes.func,
    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
    analysisPortalValue: PropTypes.bool,
    analysisPortalAccount: PropTypes.object,
    setAnalysisPortalAccountInfo: PropTypes.func,
    selectAnalysisPortalTab: PropTypes.func,
};

export const mapDispatchToProps = dispatch => ({
    setAnalysisPortalAccountInfo: payload => {
        dispatch(setAnalysisPortalAccountInfo(payload));
    },

    selectAnalysisPortalTab: () => {
        switchToTab(ANALYSIS_PORTAL);
    },
});

export const mapStateToProps = state => ({
    analysisPortalAccount: get(
        state,
        'tachographservices.common.analysisPortalAccount',
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalysisPortalSettingsSidebar);
