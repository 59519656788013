
import PropTypes from 'prop-types';
import InfoDialog from '@rio-cloud/rio-uikit/InfoDialog';

import {FormattedMessage} from 'react-intl';

import withPermissions from '../../utils/withPermissions';

const CloudStoragePeriodDialog = props => withPermissions(
    props.permissions,
    'retention-period.update',
    <InfoDialog
        {...props}
        content={
            <div className={'text-center'}>
                <div className={'margin-bottom-25'}>
                    <h2>
                        <FormattedMessage id={'callForAction'} defaultMessage={'Call for Action'}/>
                    </h2>
                    <p>
                        <FormattedMessage id={'callForAction.cloudStorage'} />
                    </p>
                </div>
                <button className={'btn btn-primary margin-bottom-20'} onClick={props.onClose}>
                    <FormattedMessage id={'confirm'} defaultMessage={'Confirm'}/>
                </button>
            </div>
        }
    />,
);

CloudStoragePeriodDialog.propTypes = {
    onClose: PropTypes.func,
    permissions: PropTypes.array,
    show: PropTypes.bool,
};

export default CloudStoragePeriodDialog;
