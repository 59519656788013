import { env } from '../../env';
import { setRetentionPeriod } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';

const { RETENTION_PATH } = env.runtimeConfig.backend;

const getFetchRetentionPeriod =
    (store, fetch = window.fetch) => () => fetch(
        RETENTION_PATH,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.getState().tokenHandling.accessToken}`,
            },
            method: 'GET',
        },
    );

export const getHandleData = store => data => {
    store.dispatch(setRetentionPeriod(data));
};

const configureFetchRetentionPeriod = store => new Promise(resolve => {
    const fetchRetentionPeriod = getFetchRetentionPeriod(store);

    saveFetch(
        fetchRetentionPeriod,
        'retention period',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchRetentionPeriod;
