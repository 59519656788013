// This is the runtime configuration being used for tests

export const testConfig = {
    homeRoute: 'https://www.home.rio.cloud',
    id: 'env.test',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        mockLocale: 'de-DE',
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
};

