
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {ISUPLOADHIDDEN as DEFAULT_ISUPLOADHIDDEN} from '../../../../constants/archive';

import CheckboxFilterComponent from './CheckboxFilterComponent';

const TransferTypeFilterComponent = ({
    autoDownload,
    manualDownload,
    speedData,
    upload,
    toggle,
    ISUPLOADHIDDEN,
    isDriver,
}) => {
    const uploadCheckbox = (
        <CheckboxFilterComponent
            toggle={() => {
                toggle('upload');
            }}
            checked={upload}
        >
            <span className={'LabelText'}><FormattedMessage id={'archive.upload'}/></span>
        </CheckboxFilterComponent>
    );

    return (
        <div
            className={'TransferTypeFilterComponent form-group padding-bottom-1'}
            data-testid={'TransferTypeFilterComponentId'}
        >
            <div>
                <p className={'text-bold text-color-darkest'}>
                    <FormattedMessage id={'archive.filterTransferType'} defaultMessage={'Filter Transfer Type'}/>
                </p>
                <CheckboxFilterComponent
                    toggle={() => {
                        toggle('autoDownload');
                    }}
                    checked={autoDownload}
                >
                    <span className={'LabelText'}><FormattedMessage id={'archive.autoDownload'}/></span>
                </CheckboxFilterComponent>

                <CheckboxFilterComponent
                    toggle={() => {
                        toggle('manualDownload');
                    }}
                    checked={manualDownload}
                >
                    <span className={'LabelText'}><FormattedMessage id={'archive.manualDownload'}/></span>
                </CheckboxFilterComponent>

                {isDriver ?
                    null :
                    <CheckboxFilterComponent
                        toggle={() => {
                            toggle('speedData');
                        }}
                        checked={speedData}
                    >
                        <span className={'LabelText'}><FormattedMessage id={'archive.speedData'}/></span>
                    </CheckboxFilterComponent> }

                {!ISUPLOADHIDDEN && uploadCheckbox}
            </div>
        </div>
    );
};

TransferTypeFilterComponent.propTypes = {
    ISUPLOADHIDDEN: DEFAULT_ISUPLOADHIDDEN,
};

TransferTypeFilterComponent.propTypes = {
    autoDownload: PropTypes.bool,
    manualDownload: PropTypes.bool,
    speedData: PropTypes.bool,
    upload: PropTypes.bool,
    toggle: PropTypes.func,
    ISUPLOADHIDDEN: PropTypes.bool,
    isDriver: PropTypes.bool,

};

export default TransferTypeFilterComponent;
