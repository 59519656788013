import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

const NumberDisplayWidget = ({
    header,
    subHeader,
    subLabel,
    value,
    info,
    getData = () => {},
    onDataClick = () => {},
    showRefreshIcon,
}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const refreshData = useCallback(
        () => {
            const canceledTimes = [];
            if (!canceledTimes.length) {
                setLoading(true);
            }

            getData().then(newData => {
                if (!canceledTimes.length) {
                    setData(newData);
                    setLoading(false);
                }
            });

            return () => canceledTimes.push(1);
        },
        [setLoading, setData],
    );

    useEffect(
        () => refreshData(),
        [],
    );

    return (
        <div className={'panel panel-info margin-bottom-20'}>
            <div className={'panel-body'}>
                <div className={'lead margin-bottom-0'} style={{fontSize: 18}}>
                    <div className={'display-flex justify-content-between'}>
                        <span>
                            {header || ''}
                            <div className={'text-size-14'}>
                                {subHeader || ''}
                            </div>
                        </span>
                        <div className={'btn btn-link btn-icon-only z-index-10'} onClick={refreshData}>
                            {showRefreshIcon && <span className={'rioglyph rioglyph-refresh'}/>}
                        </div>
                    </div>
                </div>
                <div className={`text-center text-size-14 cursor-pointer`} onClick={onDataClick}>
                    <span className={'text-size-300pct'}>

                        {
                            loading ?
                                <span
                                    className={'rioglyph rioglyph-spinner spinning text-size-150pct text-primary'}
                                /> :
                                value || data || '0'
                        }
                    </span>
                    <div style={{ marginTop: '-10px' }}>
                        <label className={'margin-bottom-0'}>
                            {subLabel || ''}
                        </label>
                    </div>
                </div>
                {info && <div className={'z-index-10'} style={{position: 'absolute', left: '15px', bottom: '25px'}}>
                    <OverlayTrigger
                        placement={'bottom-start'}
                        overlay={
                            <Tooltip id={'tooltip'} allowOnTouch>
                                {info}
                            </Tooltip>
                        }
                    >
                        <span
                            className={'rioglyph rioglyph-info-sign text-size-20 text-info'}
                        />
                    </OverlayTrigger>
                </div>}
            </div>
        </div>
    );
};

NumberDisplayWidget.propTypes = {
    header: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    subHeader: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    subLabel: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    info: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    showRefreshIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
    getData: PropTypes.func,
    onDataClick: PropTypes.func,
};

export default NumberDisplayWidget;
