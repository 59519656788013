/* eslint-disable */
import '@babel/polyfill';
import 'classlist-polyfill';
import 'fetch';
import 'unorm';
import 'intl';
import 'es6-set/implement';

// Ahhh, IE11, why won't you let us be
(function() {

    if (typeof window.CustomEvent === 'function') {
        return false;
    }

    function CustomEvent(event, params = { bubbles: false, cancelable: false, detail: undefined }) {
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
}());

// disabling false error message
const disableGetDefaultPropsWarning = () => {
    const warning = 'Warning: getDefaultProps is only used on classic React.createClass definitions. Use a static property named `defaultProps` instead.';
    const consoleError = console.error.bind(console);

    console.error = (...args) => {
        if (args[0] === warning) return;
        return consoleError(...args);
    };
};

disableGetDefaultPropsWarning();
