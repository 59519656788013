import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';

import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import { setSettingsSidebarOpen } from '../../actions/administration';

import { getAccessToken } from '../../features/tokenHandling/selectors';

import shouldEnableAnalysisPortal from '../../utils/shouldEnableAnalysisPortal';

import IntervalSettingsContainer from './IntervalSettings/IntervalSettingsContainer';
import CloudStorageSettingsContainer from './CloudStorageSettings/CloudStorageSettingsContainer';
import NotificationSettingsContainer from './NotificationSettings/NotificationSettingsContainer';
import { AnalysisPortalSettingsContainer } from './AnalysisPortal/AnalysisPortalSettingsContainer';

const getSidebarProps = props => ({
    titleClassName: 'padding-left-10',
    title: <FormattedMessage id={'administration.settings'} defaultMessage={'Settings'} />,
    fly: true,
    position: Sidebar.Right,
    width: 500,
    onClose: () => props.setSettingsSidebarOpen(false),
});

const getSidebarNavigation = props => (
    <div className={'display-flex justify-content-around'}>
        <RadioButton
            defaultChecked={!props.selectedSettingsSidebarTab ||
                props.selectedSettingsSidebarTab === 'downloadInterval'}
            onClick={() => props.setSelectedSettingsSidebarTab('downloadInterval')}
            label={<FormattedMessage id={'settings.downloadInterval'} defaultMessage={'Download Interval'} />}
            icon={'rioglyph-tachograph'}
            iconSize={40}
        />
        <RadioButton
            defaultChecked={props.selectedSettingsSidebarTab === 'cloudStorage'}
            onClick={() => props.setSelectedSettingsSidebarTab('cloudStorage')}
            label={<FormattedMessage id={'settings.cloudStorage'} defaultMessage={'Cloud Storage'} />}
            icon={'rioglyph-cloud'}
            iconSize={40}
        />
        {
            props.permissions.includes('rda-assign.read') ?
                <RadioButton
                    defaultChecked={props.selectedSettingsSidebarTab === 'notificationSettings'}
                    onClick={() => props.setSelectedSettingsSidebarTab('notificationSettings')}
                    label={<FormattedMessage id={'settings.notifications'} defaultMessage={'Notifications'} />}
                    icon={'rioglyph-info-sign'}
                    iconSize={40}
                /> :
                null
        }
        {shouldEnableAnalysisPortal() && <RadioButton
            defaultChecked={props.selectedSettingsSidebarTab === 'analysisPortal'}
            onClick={() => props.setSelectedSettingsSidebarTab('analysisPortal')}
            label={<FormattedMessage id={'settings.analysisPortal'} defaultMessage={'Analysis Portal'} />}
            icon={'rioglyph-stats'}
            iconSize={40}
        />}
    </div>
);

const getDisplayedSidebar = props => {
    const usedProps = {
        ...props,
        sidebarProps: getSidebarProps(props),
        sidebarNavigation: getSidebarNavigation(props),
    };

    switch (props.selectedSettingsSidebarTab) {
        case 'notificationSettings':
            return props.permissions.includes('rda-assign.read') ?
                <NotificationSettingsContainer {...usedProps}/> :
                <IntervalSettingsContainer {...usedProps}/>;
        case 'downloadInterval':
            return <IntervalSettingsContainer {...usedProps}/>;
        case 'cloudStorage':
            return <CloudStorageSettingsContainer {...usedProps}/>;
        case 'analysisPortal':
            return <AnalysisPortalSettingsContainer {...usedProps}/>;
        default:
            return <IntervalSettingsContainer {...usedProps}/>;
    }
};

export const SettingsSidebarContainer = props => {
    const [selectedSettingsSidebarTab, setSelectedSettingsSidebarTab] = useState('downloadInterval');
    const location = useLocation();
    const history = useHistory();

    useEffect(
        () => {
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.has('isSettingsSidebarOpen')) {
                queryParams.delete('isSettingsSidebarOpen');
                props.setSettingsSidebarOpen(true);
                if (queryParams.has('selectedTab')) {
                    setSelectedSettingsSidebarTab(queryParams.get('selectedTab'));
                    queryParams.delete('selectedTab');
                }
                history.replace({
                    search: queryParams.toString(),
                });
            }
        },
        [],
    );

    return (
        <ApplicationLayout.Sidebar className={'right'}>
            {
                props.isSettingsSidebarOpen &&
                getDisplayedSidebar({
                    ...props,
                    selectedSettingsSidebarTab,
                    setSelectedSettingsSidebarTab,
                })
            }
        </ApplicationLayout.Sidebar>
    );
};

SettingsSidebarContainer.propTypes = {
    isSettingsSidebarOpen: PropTypes.bool,
    permissions: PropTypes.array,
    setSettingsSidebarOpen: PropTypes.func,
};

export const mapDispatchToProps = dispatch => ({
    setSettingsSidebarOpen: payload => {
        dispatch(setSettingsSidebarOpen(payload));
    },
});

export const mapStateToProps = state => ({
    accessToken: getAccessToken(state),
    permissions: state.tachographservices.common.permissions,
    isSettingsSidebarOpen: state.tachographservices.administration.isSettingsSidebarOpen,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsSidebarContainer);
