import { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';

const getNotifications = (list, type = 'danger') => (
    <div className={'list-group border rounded'} key={`notifications-list-${type}`}>
        {
            list.map((item, index) => (
                <div
                    key={`notifications-item-${type}-${index}`}
                    className={`list-group-item list-group-item-${type}`}
                >
                    {item}
                </div>
            ))
        }
    </div>
);

class NotificationDialog extends Component {
    render() {

        const getNotificationEmtyMsg = this.props.areNotificationsDisabled ?
            <div >
                <span className={'text-muted'}>
                    <FormattedMessage
                        id={'notifications.disabled'}
                        defaultMessage={'Notifications are currently disabled. You can enable notifications in the '}
                    />
                </span>
                <span
                    className={'text-color-highlight cursor-pointer'}
                    onClick={() => {
                        this.props.toggleDialog();
                        this.props.openIntervalSettings();
                    }}
                >
                    <FormattedMessage
                        id={'notifications.settingsLink'}
                        defaultMessage ={'settings.'}
                    />
                </span>
            </div> :
            <span className={'text-muted'}>
                <FormattedMessage
                    id={'notifications.empty'}
                    defaultMessage={'There are no notifications for You. Everything is running fine.'}
                />
            </span>;

        return <Dialog
            show={this.props.show}
            title={<FormattedMessage id={'notifications'} defaultMessage={'Notifications'}/>}
            body={
                this.props.errorNotifications.length ||
                this.props.warningNotifications.length ||
                this.props.infoNotifications.length ?
                    <div>
                        {getNotifications(
                            this.props.errorNotifications,
                            'danger',
                        )}
                        {getNotifications(
                            this.props.warningNotifications,
                            'warning',
                        )}
                        {getNotifications(
                            this.props.infoNotifications,
                            'info',
                        )}
                    </div> :
                    <div>
                        {getNotificationEmtyMsg}
                    </div>
            }
            footer={
                <button className={'btn btn-default'} onClick={this.props.toggleDialog}>
                    <FormattedMessage id={'close'} defaultMessage={'Close'}/>
                </button>
            }
            onHide={this.props.toggleDialog}
            useOverflow={true}
        />;
    }
}

NotificationDialog.propTypes = {
    toggleDialog: PropTypes.func,
    show: PropTypes.bool,
    errorNotifications: PropTypes.array,
    warningNotifications: PropTypes.array,
    infoNotifications: PropTypes.array,
    areNotificationsDisabled: PropTypes.bool,
    openIntervalSettings: PropTypes.func,
};

export default NotificationDialog;
