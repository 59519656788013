
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.filters.step1.title'}
            defaultMessage={'Transfer Period'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.filters.step2.title'}
            defaultMessage={'Latest Files'}
        />,
    }, {
        icon: '3',
        label: <FormattedMessage
            id={'glossary.compliant.filters.step3.title'}
            defaultMessage={'Filter Button'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.filters.step1.text'}
                        defaultMessage={'In the "Archive" tab you can use filters to ' +
                        'find desired files easier. By default "All Files" button is selected. ' +
                        'Please choose the desired  time range in the "Transfer Period" ' +
                        'menu to filter the files.'}
                    />
                </div>
                <ReleaseNotesImage image={'filtersStep1.png'} widthClass={'max-width-100pct'}/>
            </div>;
        case 1:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.filters.step2.text'}
                        defaultMessage={
                            'Click on the "Latest Files" button if you wish to see only the latest files.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'filtersStep2.png'} widthClass={'max-width-100pct'}/>
            </div>;
        case 2:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.filters.step33.text'}
                        defaultMessage={
                            'Click on the filter button if you wish to filter ' +
                            'files by transfer type or export status. ' +
                            'Select desired options and click "Apply".'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'filtersStep3.png'} widthClass={'max-width-100pct'}/>
            </div>;
        default:
            return <div/>;
    }
};

const FiltersExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default FiltersExplanation;
