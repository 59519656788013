import { env } from '../../env';

import { fetchDataPOST } from '../../sagas/fetchUtils';

import getQuery from './getQuery';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const TACHO_ARCHIVE_PATH = '/filearchive/Customers/Files';

export const getUrl = queryProps => TACHOGRAPH_SERVICES_SERVICE +
    TACHO_ARCHIVE_PATH +
    getQuery(queryProps);

const getFiles = (
    {
        startValue,
        endValue,
        type,
        queryProps = {},
    },
    injectedFetch = fetchDataPOST,
) => injectedFetch(
    getUrl(queryProps),
    {
        /* eslint-disable-next-line no-undefined */
        startUtc: startValue instanceof Date ? startValue.toISOString() : undefined,
        /* eslint-disable-next-line no-undefined */
        endUtc: endValue instanceof Date ? endValue.toISOString() : undefined,
        fileType: type,
    },
)
    .then(res => {
        if (res.error) {
            throw new Error('tacho archive call failed');
        }

        return res;
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getFiles;
