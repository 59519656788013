/* eslint-disable default-param-last */
import configureStorage from './configureStorage';

export const extractAccessTokenFromWindowLocation = window => {
    if (!window || !window.location || !window.location.href) {
        return null;
    }

    if (typeof window.location.href !== 'string') {
        return null;
    }

    let token = null; // eslint-disable-line immutable/no-let

    window.location.href.replace( // eslint-disable-next-line prefer-named-capture-group
        /access_token=([^&]+)/u,
        (_, it) => {
            token = it;
        },
    );

    return token;
};

export const configureAccessToken = (window, storage) => {

    const urlToken = extractAccessTokenFromWindowLocation(typeof window === 'undefined' ? null : window);

    if (urlToken) {
        storage.saveAccessToken(urlToken);
    }

    return storage;
};

export const accessToken = configureAccessToken(
    window,
    configureStorage(
        window,
        process,
    ),
);

