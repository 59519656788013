/* eslint-disable max-len */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ExpanderList from '@rio-cloud/rio-uikit/ExpanderList';

import { DataTab, DataTabs } from '@rio-cloud/rio-uikit';

import Analytics from '../../components/common/Analytics';

import { env } from '../../env';
import getRDAVersion from '../../fetches/RDA/getRDAVersion';
import CompliantWidget from '../../components/common/widgets/CompliantWidget';
import NumberDisplayWidget from '../../components/common/widgets/NumberDisplayWidget';

import getNoOfSubscribedVehicles from '../../utils/getNoOfSubscribedVehicles';

import {shouldEnableRDA} from '../../utils/shouldEnableRDA';

import downloadLetter from './downloadLetter';

const getNoOfOnlineCompanyCards = companyCards => companyCards.filter(card => card.inCardHotel && card.status !== 0).length;
const RDA_STATUS_POLLING_INTERVAL = 15000;
const listItemsRDA = {
    ok: [
        {
            header:
            (<span>
                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-ok'}/>
                <FormattedMessage id={'administration.rdaOnline'} />
            </span>),
            open: false,
        },
    ],
    loading: [
        {
            header: <Spinner text={<FormattedMessage id={'loading'} />}/>,
            open: false,
        },
    ],
    failed: [
        {
            header:
            (<span>
                <span className={'rioglyph rioglyph-user text-danger'} style={{ paddingRight: '8px' }}/>
                <FormattedMessage id={'administration.rdaNotOnline'} />
            </span>),
            body: <span><FormattedMessage id={'administration.rdaNotOnlineDescription'} /></span>,
            className: 'list-group-item-danger',
            open: true,
        },
    ],
};

const listItemsCompanyCard = {
    ok: [
        {
            header:
            (<span>
                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-ok'}/>
                <FormattedMessage id={'administration.companyCardAvailable'} />
            </span>),
            open: false,
        },
    ],
    failed: [
        {
            header:
            (<span>
                <span className={'rioglyph rioglyph-credit-card text-danger'} style={{ paddingRight: '8px' }}/>
                <FormattedMessage id={'administration.companyCardNotAvailable'} />
            </span>),
            body: <span><FormattedMessage id={'administration.companyCardNotAvailableText'} /></span>,
            className: 'list-group-item-danger',
            open: true,
        },
    ],
    waitingForRDA: [
        {
            header:
            (<span>
                <span style={{ paddingRight: '8px' }} className={'rioglyph rioglyph-off'}/>
                <FormattedMessage id={'administration.rdaNotAvailableCompanyCardText'} />
            </span>),
            open: false,
        },
    ],
};

const getRDAAutoDownloadStep = (openSettings, type) => (
    <div className={'col-md-6 RDAColumn form-group'}>
        <div className={'well RDAAutoDownloadsSection RDAWell margin-0 height-100pct'}>
            {/* <h4 className={'RDATitel'}>
                <FormattedMessage id={'administration.step'}>
                    { txt => <span>{`${txt} 4`}</span>
                    }
                </FormattedMessage>
            </h4> */}
            <div className={'media-left'}>
                <span className={'media-object rioglyph rioglyph-cloud-download MediaImageSpan'} />
            </div>
            <div className={'media-body'}>
                <p className={'media-heading'}>
                    <FormattedMessage id={'administration.autoDownloads'} />
                </p>
                {type === 'CardHotel' ?
                    <div className={'form-group'}>
                        <FormattedMessage id={'administration.autoDownloadsInfo'} />
                    </div> :
                    <div className={'form-group'}>
                        <FormattedMessage id={'guidance.autoDownloadsInfoCardHotel-1'} defaultMessage={'Make sure your vehicles are online (ignition on). Remote downloads will start automatically according to the defined download intervals. You can change the remote download intervals for your vehicles and drivers in the settings. There you can also adjust the cloud storage period of saved files.'} />
                    </div>}

                <div>
                    <button className={'btn btn-default'} onClick={openSettings}>
                        <FormattedMessage id={'administration.settings'} />
                    </button>
                </div>
            </div>
        </div>
    </div>
);
class GuidancePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rdaVersion: false,
            tabKey: getNoOfSubscribedVehicles(
                '1',
                this.props.vehiclesWithSubscription,
            ) === 0 ?
                'tab2' :
                'tab1',
            tabKey2: 'tab01',
            authenticationIcon: '',
            listItems: [
                {
                    id: 1,
                    header: <FormattedMessage id={'guidance.faqQuestion1'} defaultMessage={'What are my benefits using company card hosting?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer1'} defaultMessage={'With company card hosting we ensure that your company cards are safely stored and can be used 24/7 for tachograph authentication. Means we have the possibility to perform tachograph remote downloads at any time for your vehicles. You don\'t need to take care of managing company cards yourself. You don\'t need a card reader and you don\'t need to use our deprecated Remote Download Application. In our card hotel your company cards are available and online all the time.'} />,
                },
                {
                    id: 2,
                    header: <FormattedMessage id={'guidance.faqQuestion2'} defaultMessage={'How can I send you my company cards?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer2'} defaultMessage={'Please follow the step by step introduction for company card hosting on top of this page. Here we explain you how to send company cards to our card hotel. The address of our card hotel is also mentioned in the step by step introduction.'} />,
                },
                {
                    id: 3,
                    header: <FormattedMessage id={'guidance.faqQuestion3'} defaultMessage={'How many company cards can I send to the card hotel?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer3'} defaultMessage={'Basically you can send us as much company cards as you want. We recommend to keep always one company card at your place and only send us cards that you can spare. If you want us to perform tachograph remote downloads from different companies, then please provide us one card for each company. In order to get new cards, please contact the authority responsible to issue company cards in your area.'} />,
                },
                {
                    id: 5,
                    header: <FormattedMessage id={'guidance.faqQuestion5'} defaultMessage={'How can I get my company cards back?'} />,
                    body: <div>
                        <FormattedMessage
                            id={'guidance.faqAnswer5'}
                            values={{
                                companycards: <a onClick={() => this.handleCompanyCardsLinkClick()}>
                                    <FormattedMessage id={'administration.companyCards'} defaultMessage={'Company Cards'} />
                                </a>,
                            }} />
                    </div>,

                },
                {
                    id: 6,
                    header: <FormattedMessage id={'guidance.faqQuestion6'} defaultMessage={'What happens when my company cards expire?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer6'} defaultMessage={'We will remind you 90 days in advance of the expiry date that the card will be outdated soon. We will safely destroy the card on your behalf automatically after the expiry date. If you want to have the card back instead, please send us a card return request before the expiry date. Be aware that we cannot perform tachograph remote downloads when there is no company card at the card hotel. Please take care of ordering a new card early and send us the new company card before the other card expires.'} />,

                },
                {
                    id: 7,
                    header: <FormattedMessage id={'guidance.faqQuestion7'} defaultMessage={'What happens when I cancel Compliant for all my vehicles?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer7'} defaultMessage={'tbd. When there is no active Compliant subscription in your fleet for 30 days, then we will ask you whether we should send the cards back to you or if we should destroy the cards.'} />,

                },
                {
                    id: 8,
                    header: <FormattedMessage id={'guidance.faqQuestion8'} defaultMessage={'Can I still use the Remote Download Application in parallel?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer8'} defaultMessage={'For customers that are familiar with our deprecated Remote Download Application we currently still support the use of it. But be aware that we will stop the support of the Remote Download Application soon. In the long run company card hosting will be mandatory for our Compliant product.'} />,

                },
                {
                    id: 9,
                    header: <FormattedMessage id={'guidance.faqQuestion9'} defaultMessage={'Do I need to send in my company cards to the card hotel?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer9'} defaultMessage={'With Compliant M you can benefit from our company card hosting service. If you don\'t want to send in your company cards, you can still use the card reader approach with inserted company cards and installed Remote Download Application (RDA) locally at your place for your Compliant and Compliant M vehicles. But be aware that we will stop the support of the Remote Download Application in the near future. In the long run company card hosting will be mandatory for our Compliant M product.'} />,

                },
                {
                    id: 10,
                    header: <FormattedMessage id={'guidance.faqQuestion10'} defaultMessage={'Will Compliant be phased out with the introduction of Compliant M?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer10'} defaultMessage={'Compliant will not be removed or canceled for existing subscriptions. However it will no longer be possible to book the Compliant product with the launch of Compliant M. If Compliant is canceled for a vehicle, only Compliant M can be booked under the new conditions (price and terms). Compliant M is available for booking with time-based contracts in the Marketplace.'} />,

                },
                {
                    id: 11,
                    header: <FormattedMessage id={'guidance.faqQuestion11'} defaultMessage={'Can I use Compliant and Compliant M in parallel?'} />,
                    body: <FormattedMessage id={'guidance.faqAnswer11'} defaultMessage={'You can have vehicles with Compliant subscriptions and others with Compliant M subscriptions in your fleet. You can manage all vehicles with your company cards and the Remote Download Application (RDA) locally or you can send in your company cards to card hotel. Be aware that company cards at the card hotel can only be used for Compliant M vehicles. For Compliant vehicles you still need to manage your company cards locally with a card reader and the Remote Download Application (RDA) installed.'} />,

                },
            ],
            expanded: false,
        };

        this.handleToggleAll = this.handleToggleAll.bind(this);
        this.handleCompanyCardsLinkClick = this.handleCompanyCardsLinkClick.bind(this);
        this.handlePrintDoucmentClick = this.handlePrintDoucmentClick.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    async handlePrintDoucmentClick() {
        await downloadLetter();
    }

    handleToggleAll() {
        this.setState({expanded: !this.state.expanded});
        this.state.listItems.forEach(element => {
            element.open = !this.state.expanded;
        });
        this.setState({
            listItems: this.state.listItems,
        });

    }

    handleCompanyCardsLinkClick() {
        this.props.selectCompanyCardsTab();
    }

    setTabKey(selectedTab) {
        this.setState({tabKey: selectedTab});
    }

    setTabKey2(selectedTab) {
        this.setState({tabKey2: selectedTab});
    }

    fetchRDAVersion() {
        getRDAVersion()
            .then(res => {
                if (res) {
                    this.setState({
                        rdaVersion: res,
                    });
                } else {
                    this.setState({
                        rdaVersion: '4.1.3',
                    });
                }
            })
            .catch(() => {
                this.setState({
                    rdaVersion: '4.1.3',
                });
            });
    }

    startFetchCompanyCardsStatus() {
        this.props.getCompanyCards();
        this.companyCardsPolling = window.setInterval(
            this.props.getCompanyCards,
            RDA_STATUS_POLLING_INTERVAL,
        );
    }

    startFetchRdaStatus() {
        this.props.getRDAStatus();
        this.rdaStatusPolling = window.setInterval(
            this.props.getRDAStatus,
            RDA_STATUS_POLLING_INTERVAL,
        );
    }

    endFetchStatus() {
        window.clearInterval(this.rdaStatusPolling);
        window.clearInterval(this.companyCardsPolling);
    }

    componentDidMount() {
        this.fetchRDAVersion();
        this.startFetchRdaStatus();
        this.startFetchCompanyCardsStatus();
    }

    componentWillUnmount() {
        this.endFetchStatus();
    }

    getDownloadRDAButton() {
        const content = this.props.loadingRDA ?
            <Spinner text={<FormattedMessage id={'loading'} />} isInverse={true}/> :
            <FormattedMessage id={'administration.download'} />;

        return (
            <div>
                <div
                    className={`btn btn-primary ${this.props.loadingRDA ? 'active' : ''}`}
                    onClick={this.props.downloadRDA}>
                    {content}
                </div>
                <div>
                    <label className={'display-flex justify-content-start align-items-center'}>
                        <span className={'margin-right-5'}>
                            <FormattedMessage id={'version'} defaultMessage={'Version'}/>
                            {':'}
                        </span>
                        {
                            this.state.rdaVersion ?
                                this.state.rdaVersion :
                                <Spinner/>
                        }
                    </label>
                </div>
            </div>
        );
    }

    getCompanyCardListItems() {
        if (this.props.isRDAOnline) {
            return this.props.isCompanyCardAvailable ? listItemsCompanyCard.ok : listItemsCompanyCard.failed;
        }

        return listItemsCompanyCard.waitingForRDA;
    }

    getRDAAccessColumn() {
        const listItems = this.getCompanyCardListItems();

        const statusExpander = <ExpanderList items={listItems} className={'statusExpander'}/>;

        return (
            <div className={'col-md-6 RDAColumn form-group'}>
                <div className={' well RDAAccessSection RDAWell margin-0 height-100pct'}>
                    <div className={'media-left'}>
                        <span className={'media-object rioglyph rioglyph-key MediaImageSpan'} />
                    </div>
                    <div className={'media-body'}>
                        <p className={'media-heading'}>
                            <FormattedMessage id={'administration.authentication'} />
                        </p>
                        <div className={'form-group'}>
                            <FormattedMessage id={'administration.rdaCredentialsAccessInfo'} />
                        </div>
                        {statusExpander}
                    </div>
                </div>
            </div>
        );
    }

    getRDADownloadColumn() {
        return (
            <div className={'col-md-6 RDAColumn form-group'}>
                <div className={' well downloadRDASection RDAWell margin-0 height-100pct'}>
                    <h4 className={'RDATitel'}>
                        <FormattedMessage id={'administration.step'}>
                            { txt => <span>{`${txt} 1`}</span>
                            }
                        </FormattedMessage>
                    </h4>
                    <div className={'media-left'}>
                        <span className={'media-object rioglyph rioglyph-download MediaImageSpan'}/>
                    </div>
                    <div className={'media-body'}>
                        <p className={'media-heading'}>
                            <FormattedMessage id={'administration.remoteDownloadApplication'} />
                        </p>
                        <div className={'form-group'}>
                            <FormattedMessage id={'administration.rdaStep'} /><br/>
                            <FormattedMessage id={'administration.rdaStepHint'} />
                        </div>
                        {this.getDownloadRDAButton()}
                    </div>
                </div>
            </div>
        );
    }

    getRDAListItems() {
        if (this.props.isRDAStatusFetched) {
            return this.props.isRDAOnline ? listItemsRDA.ok : listItemsRDA.failed;
        }

        return listItemsRDA.loading;
    }

    getRDALoginStep() {
        const listItems = this.getRDAListItems();

        const statusExpander = <ExpanderList items={listItems} className={'statusExpander'}/>;

        return (
            <div className={'col-md-6 RDAColumn form-group'}>
                <div className={'well RDALoginSection RDAWell margin-0 height-100pct'}>
                    <div className={'media-left'}>
                        <span className={'media-object rioglyph rioglyph-user MediaImageSpan'} />
                    </div>
                    <div className={'media-body'}>
                        <p className={'media-heading'}><FormattedMessage id={'administration.login'} /></p>
                        <div className={'form-group'}>
                            <FormattedMessage id={'administration.loginInfo'} />
                        </div>
                        {statusExpander}
                    </div>
                </div>
            </div>
        );
    }

    getLogin() {
        if (this.props.isRDAOnline) {
            return (
                <div>
                    <div><FormattedMessage id={'administration.login'} />
                        <span
                            style={{ paddingLeft: '8px' }}
                            className={'rioglyph rioglyph-ok'}/></div><div className={'text-bold text-size-18'}>
                        <FormattedMessage id={'guidance.step'} values={{number: 2}} />
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div>
                    <FormattedMessage id={'administration.login'} />
                    <span
                        className={'rioglyph rioglyph-off text-danger'}
                        style={{ paddingLeft: '8px' }}/></div><div className={'text-bold text-size-18'}>
                    <FormattedMessage id={'guidance.step'} values={{number: 2}} />
                </div>
            </div>
        );

    }

    getAuthentication() {
        // eslint-disable-next-line immutable/no-let
        let icon = '';
        if (this.props.isRDAOnline) {
            icon = this.props.isCompanyCardAvailable ?
                <span style={{ paddingLeft: '8px' }} className={'rioglyph rioglyph-ok'}/> :
                <span
                    className={'rioglyph rioglyph rioglyph-drivercard-in text-danger'}
                    style={{ paddingLeft: '8px' }}/>;
        } else {
            icon = <span style={{ paddingLeft: '8px' }} className={'rioglyph rioglyph-off text-danger'}/>;
        }

        return (
            <div><div><FormattedMessage id={'administration.authentication'} /> {icon}</div><div className={'text-bold text-size-18'}><FormattedMessage id={'guidance.step'} values={{number: 3}} /></div></div>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    getCompanyCardHosting() {
        return <div>
            <div
                className={'text-bold text-size-18'}>
                <FormattedMessage id={'guidance.companyCardHosting'} defaultMessage={'Company Card Hosting'} />
                <span style={{float: 'right'}} className={'label label-success label-filled'}><FormattedMessage id={'new'} /></span>
            </div>
        </div>;
    }

    getHelpStep() {
        return (
            <div className={'row RDARow equal-height'}>
                <div className={'col-md-12 RDAColumn form-group'}>
                    <div className={'well helpStep RDAWell margin-0 height-100pct'}>
                        <h4 className={'RDATitel'}>
                            <FormattedMessage id={'administration.help'} />
                        </h4>
                        <div className={'media-left'}>
                            <span className={'media-object rioglyph rioglyph-pdf-file MediaImageSpan'} />
                        </div>
                        <div className={'media-body'}>
                            <p className={'media-heading'}>
                                <FormattedMessage id={'administration.instructionManual'} />
                            </p>
                            <div className={'form-group'}>
                                <FormattedMessage id={'administration.helpText'} />
                            </div>
                            <a
                                className={'btn btn-default'}
                                href={this.props.intl.formatMessage({ id: 'administration.manualLink' })}
                                download={true}
                                target={'_blank'}
                                rel={'noreferrer'}
                                onClick={this.props.downloadManual}
                            >
                                <FormattedMessage id={'administration.download'} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isExistAuthenticationerror() {
        if (this.props.isRDAOnline) {
            return !this.props.isCompanyCardAvailable;
        }
        return true;
    }

    render() {
        const Title = ({ text, value }) => (
            <div>
                <div>{text}</div>
                <div className={'text-bold text-size-18'}>{value}</div>
            </div>
        );

        return (
            <Analytics page={'/GuidancePage'}>
                <div className={'RDAPage'}>
                    <div className={'WidgetWrapper row'}>
                        {(getNoOfSubscribedVehicles(
                            '1',
                            this.props.vehiclesWithSubscription,
                        ) !== 0 || shouldEnableRDA()) && <div className={'col-md-6'}>
                            <CompliantWidget
                                header = {'Compliant'}
                                description= {<div>
                                    <FormattedMessage
                                        id={'widget.compliantSLine1'}
                                        // eslint-disable-next-line max-len
                                        defaultMessage={'With Compliant you must use a card reader and work with the Remote Download Application to manage your company cards.'}
                                    />
                                    <br/>
                                    <div>
                                        <div style={{marginTop: 48}}>
                                            <FormattedMessage
                                                id={'widget.compliantSLine2-1'}
                                                defaultMessage={'Upgrade now to use company card hosting instead.'}
                                            />
                                            <br/>
                                            <span style={{float: 'left'}}>
                                                <FormattedMessage
                                                    id={'widget.compliantSLine3-1'}
                                                    defaultMessage={'Book Compliant M to use company card hosting.'}
                                                />
                                            </span>
                                            <iframe
                                                className={'buyButton margin-left-10'}
                                                style={{width: 65, float: 'left', height: 19}}
                                                // eslint-disable-next-line max-len
                                                src={`https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&noPrice&notButtonText&textOnly&buttonLeft`}>
                                            </iframe>
                                        </div>
                                    </div></div>
                                }
                                footer={<span>
                                    <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                                    <span className={'margin-right-5'}>
                                        {getNoOfSubscribedVehicles(
                                            '1',
                                            this.props.vehiclesWithSubscription,
                                        )}/{this.props.rawVehicles.length}
                                    </span>
                                    <FormattedMessage
                                        id={'widget.vehiclesWithCompliantSSubscription-1'}
                                        defaultMessage={'Vehicles with Compliant subscription'}
                                    />
                                    <span style={{float: 'right'}} className={'rioglyph rioglyph-card-reader margin-right-5 text-size-h4'}/>

                                </span>}
                            />
                        </div>}
                        <div
                            className={`col-md-${getNoOfSubscribedVehicles(
                                '1',
                                this.props.vehiclesWithSubscription,
                            ) === 0 && !shouldEnableRDA() ?
                                '12' :
                                '6'}`}>
                            <CompliantWidget
                                header = {'Compliant M'}
                                description= {<div><div className={'text-size-14'}>
                                    <FormattedMessage
                                        id={'widget.compliantMLine1'}
                                        // eslint-disable-next-line max-len
                                        defaultMessage={'Send us your company cards. We host them for you in our card hotel.'}
                                    />
                                    <br/>
                                    <FormattedMessage
                                        id={'widget.compliantMLine2-1'}
                                        // eslint-disable-next-line max-len
                                        defaultMessage={'Benefit from our safe and secure company card hosting.'}
                                    />
                                </div>
                                <div className={'text-size-14'}>
                                    <ul>
                                        <li><FormattedMessage
                                            id={'widget.compliantMLine3'}
                                            defaultMessage={'Your company cards are online 24/7'}
                                        /></li>
                                        <li><FormattedMessage
                                            id={'widget.compliantMLine4-1'}
                                            defaultMessage={'Get remote downloads more often'}
                                        /></li>
                                    </ul>
                                </div>
                                <iframe
                                    className={'buyButton'}
                                    style={{float: 'left'}}
                                    // eslint-disable-next-line max-len
                                    src={`https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&buttonLeft`}>
                                </iframe></div>
                                }
                                footer={<span>
                                    <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                                    <span className={'margin-right-5'}>
                                        {getNoOfSubscribedVehicles(
                                            '2',
                                            this.props.vehiclesWithSubscription,
                                        )}/{this.props.rawVehicles.length}
                                    </span>
                                    <FormattedMessage
                                        id={'widget.vehiclesWithCompliantMSubscription-1'}
                                        defaultMessage={'Vehicles with Compliant M subscription'}
                                    />
                                    <span style={{float: 'right'}} className={'rioglyph rioglyph-card-hotel margin-right-5 text-size-h4'}/>
                                </span>}
                                newFeature = {true}
                            />
                        </div>
                    </div>
                    {
                        getNoOfSubscribedVehicles(
                            '1',
                            this.props.vehiclesWithSubscription,
                        ) === 0 && !shouldEnableRDA() ?
                            <DataTabs activeKey={this.state.tabKey} onSelectTab={tabKey => this.setTabKey(tabKey)}>
                                {/* Company Card Hosting Tab */}
                                {this.getCompanyCardHostingTab(Title)}
                            </DataTabs> :
                            <DataTabs activeKey={this.state.tabKey} onSelectTab={tabKey => this.setTabKey(tabKey)}>
                                {/* Remote Download Application Tab */}
                                {this.getRDATab(Title)}

                                {/* Company Card Hosting Tab */}
                                {this.getCompanyCardHostingTab(Title)}
                            </DataTabs>
                    }

                </div>
            </Analytics>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    getCompanyCardHostingTab(Title) {
        return <DataTab tabKey={'tab2'} title={<Title text={''} value={this.getCompanyCardHosting()} />}>
            {getNoOfSubscribedVehicles(
                '2',
                this.props.vehiclesWithSubscription,
            ) || getNoOfSubscribedVehicles(
                '3',
                this.props.vehiclesWithSubscription,
            ) ?
                <div>
                    <DataTabs activeKey={this.state.tabKey2} onSelectTab={tabKey => this.setTabKey2(tabKey)} vertical>
                        <DataTab
                            tabKey={'tab01'}
                            title={<Title
                                text={<FormattedMessage
                                    id={'guidance.printDocumentStepTitle'}
                                    defaultMessage={'Print Document'}
                                />}
                                value={ <FormattedMessage id={'guidance.step'} values={{number: 1}} />} />}>
                            <div className={'col-md-6 RDAColumn form-group'}>
                                <div className={' well downloadRDASection RDAWell margin-0 height-100pct'}>
                                    <div className={'media-left'}>
                                        <span className={'media-object rioglyph rioglyph-print MediaImageSpan'} />
                                    </div>
                                    <div className={'media-body'}>
                                        <p className={'media-heading'}>
                                            <FormattedMessage
                                                id={'guidance.printDocumentStepTitle'}
                                                defaultMessage={'Print Document'}
                                            />
                                        </p>
                                        <div className={'form-group'}>
                                            <FormattedMessage
                                                id={'guidance.printDocumentStepLine1'}
                                                defaultMessage={'Press the button to create the document. Print the document and put it in the same envelope that you will use to send the company cards.'}
                                            />
                                            <br />
                                        </div>
                                        <div
                                            className={`btn btn-primary`}
                                            onClick={this.handlePrintDoucmentClick}
                                        >
                                            <FormattedMessage
                                                id={'guidance.document'}
                                                defaultMessage={'Document'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DataTab>
                        <DataTab
                            tabKey={'tab02'}
                            title={<Title
                                text={
                                    <FormattedMessage id={'guidance.mailLetterStepTitle-1'} defaultMessage={'Mail Letter'}/>
                                }
                                value={ <FormattedMessage id={'guidance.step'} values={{number: 2}} />} />}>
                            <div className={'col-md-6 RDAColumn form-group'}>
                                <div className={' well downloadRDASection RDAWell margin-0 height-100pct'}>
                                    <div className={'media-left'}>
                                        <span className={'media-object rioglyph rioglyph-envelope MediaImageSpan'} />
                                    </div>
                                    <div className={'media-body'}>
                                        <p className={'media-heading'}>
                                            <FormattedMessage id={'guidance.mailLetterStepTitle-1'} defaultMessage={'Mail Letter'}/>
                                        </p>
                                        <div className={'form-group'}>
                                            <FormattedMessage id={'guidance.mailLetter'} defaultMessage={'Put the company cards together with the printed document in an envelope. Recommendation is to use a well padded envelope where the company card cannot slip out. Send the letter with sufficient postage to the following address.'}/>
                                            <br/>
                                            <div className={'margin-top-20 line-height-10'}>
                                                <p>Cardhotel</p>
                                                <p>MAN Truck & Bus</p>
                                                <p>Brüsseler Straße 22</p>
                                                <p>07747 Jena</p>
                                                <p>Germany</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DataTab>
                        <DataTab
                            tabKey={'tab03'}
                            title={<Title
                                text={
                                    <FormattedMessage id={'administration.companyCards'} defaultMessage={'Company Cards'}/>
                                }
                                value={ <FormattedMessage id={'guidance.step'} values={{number: 3}} />} />}>
                            <div className={'WidgetWrapper row'}>
                                <div className={' well downloadRDASection RDAWell margin-0 height-100pct'}>
                                    <div className={'media-left'}>
                                        <span className={'media-object rioglyph rioglyph-card-hotel MediaImageSpan'} />
                                    </div>
                                    <div className={'media-body'}>
                                        <p className={'media-heading'}>
                                            <FormattedMessage
                                                id={'guidance.companyCardsStepTitle'}
                                                // eslint-disable-next-line max-len
                                                defaultMessage={'Company Cards in Card Hotel'}
                                            />
                                        </p>
                                        <div className={'form-group'}>
                                            <FormattedMessage
                                                id={'guidance.companyCardsStepLine1-1'}
                                                // eslint-disable-next-line max-len
                                                defaultMessage={'When company card is received, it will be displayed as online. The number of all online company cards hosted in the card hotel is displayed in this widget.'}
                                            />
                                            <br />
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <NumberDisplayWidget
                                                    header={
                                                        <FormattedMessage
                                                            id={'widget.cardHotelOnlineCards-1'}
                                                            defaultMessage={'Online Card Hotel Cards'}
                                                        />
                                                    }
                                                    subLabel={
                                                        null
                                                    }
                                                    getData={
                                                        () => Promise.resolve(this.props.getCompanyCards())
                                                    }
                                                    onDataClick={
                                                        () => {
                                                        }
                                                    }
                                                    showRefreshIcon={true}
                                                    value={ getNoOfOnlineCompanyCards(this.props.companyCards)}
                                                />
                                            </div>
                                        </div>
                                        <div className={'form-group'}>
                                            <FormattedMessage
                                                id={'guidance.companyCardsStepLine2'}
                                                defaultMessage={'Here you can overview and manage your hosted company cards.'}
                                            />
                                            <br/>
                                            <br/>
                                            <button className={'btn btn-primary'} onClick={this.handleCompanyCardsLinkClick}>Company Cards</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </DataTab>
                        <DataTab
                            tabKey={'tab04'}
                            title={<Title
                                text={<FormattedMessage
                                    id={'administration.autoDownloads'}
                                    defaultMessage={'Automatic Downloads'}
                                />}
                                value={ <FormattedMessage id={'guidance.step'} values={{number: 4}} />} />}>
                            {getRDAAutoDownloadStep(
                                this.props.openSettingsSidebar,
                                'RDA',
                            )}
                        </DataTab>
                    </DataTabs>
                    <br />
                    <div className={'row'}>
                        <h4 className={'RDATitel well'} style={{ float: 'left', marginBottom: 0, paddingBottom: 0 }}>
                        FAQ
                        </h4>
                        <div className={'RDATitel well'}>
                            <span className={'margin-top-10'} style={{float: 'right', marginRight: -10}}><span className={`btn btn-default btn-icon-only rioglyph rioglyph-angle-double-down text-size-20 ${this.state.expanded ? 'rotateUp' : 'rotateDown'}`} onClick={this.handleToggleAll}></span></span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <h6 className={'RDATitel well'} style={{ float: 'left', marginBottom: 0, paddingTop: 0}}>
                            <FormattedMessage
                                id={'guidance.FAQTitle'}
                                defaultMessage={'Frequently Asked Questions'}
                            />
                        </h6>
                    </div>
                    <ExpanderList items={this.state.listItems} />
                </div> :
                <div className={'display-flex flex-column align-items-center margin-0 panel panel-default padding-5pct border-none rounded-none'}>
                    <div className={'text-size-h2 margin-bottom-15'}>
                        <span className={'rioglyph text-color-primary rioglyph-stars text-size-300pct'}></span>
                    </div>
                    <div className={'text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-bold max-width-500 text-center'}>
                        Premium feature
                    </div>
                    <div
                        className={'text-color-dark margin-top-15 line-height-125rel display-flex flex-column align-items-center max-width-500 width-100pct'}>
                        <div className={'text-medium margin-bottom-20 text-size-14 text-size-18-sm text-center margin-x-10pct-ls'} id={'headline'}>Book <span className={'text-bold text-color-primary margin-x-2'}>Compliant M</span>&nbsp;now and get access to the following features.</div>
                        <ul className={'feature-list text-center margin-x-10pct-ls margin-y-10 text-size-12 text-size-14-sm'} id={'features'}>
                            <li className={'rounded-circle padding-x-20 bg-highlight-decent'}><FormattedMessage
                                id={'guidance.premiumFeatureLine1'}
                                defaultMessage={'Downloading of tachograph and driver card data is carried out fully automatically at predefined time intervals via remote access.'}
                            /></li>
                            <li className={'rounded-circle padding-x-20 bg-highlight-decent'}><FormattedMessage
                                id={'guidance.premiumFeatureLine2'}
                                defaultMessage={'For analysis in any evaluation software, the stored tachograph and driver card data can be exported to your computer at any time.'}
                            /></li>
                            <li className={'rounded-circle padding-x-20 bg-highlight-decent'}><FormattedMessage
                                id={'guidance.premiumFeatureLine3'}
                                defaultMessage={'Benefit from our safe and secure company card hosting. Send us your company cards. We host them for you in our card hotel.'}
                            /></li>
                        </ul></div><div className={'max-width-500 width-100pct margin-top-25'}></div>
                    <iframe
                        className={'buyButton'}
                        src={'https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&amp;'}>
                    </iframe>
                </div>}
        </DataTab>;
    }

    getRDATab(Title) {
        return <DataTab tabKey={'tab1'} title={<Title text={''} value={'Remote Download Application'} />}>
            <div>
                <DataTabs activeKey={this.state.tabKey2} onSelectTab={tabKey => this.setTabKey2(tabKey)} vertical>
                    <DataTab
                        tabKey={'tab01'}
                        title={<Title
                            text={'Remote Download Application'}
                            value={ <FormattedMessage id={'guidance.step'} values={{number: 1}} />}
                            className={'list-group-item-warning'} />}>
                        <div className={'col-md-6 RDAColumn form-group'}>
                            <div className={' well downloadRDASection RDAWell margin-0 height-100pct'}>
                                <div className={'media-left'}>
                                    <span className={'media-object rioglyph rioglyph-download MediaImageSpan'} />
                                </div>
                                <div className={'media-body'}>
                                    <p className={'media-heading'}>
                                        <FormattedMessage id={'administration.remoteDownloadApplication'} />
                                    </p>
                                    <div className={'form-group'}>
                                        <FormattedMessage id={'administration.rdaStep'} /><br />
                                        <FormattedMessage id={'administration.rdaStepHint'} />
                                    </div>
                                    {this.getDownloadRDAButton()}
                                </div>
                            </div>
                        </div>
                    </DataTab>
                    <DataTab
                        // eslint-disable-next-line no-negated-condition
                        className={`${!this.props.isRDAOnline ? 'GuidanceError' : ''}`}
                        tabKey={'tab02'}
                        title={this.getLogin()}>
                        {this.getRDALoginStep()}
                    </DataTab>
                    <DataTab className={`${this.isExistAuthenticationerror() ? 'GuidanceError' : ''}`} tabKey={'tab03'} title={this.getAuthentication()}>
                        {this.getRDAAccessColumn()}
                    </DataTab>
                    <DataTab
                        tabKey={'tab04'}
                        title={<Title
                            text={<FormattedMessage
                                id={'administration.autoDownloads'}
                                defaultMessage={'Automatic Downloads'}
                            />}
                            value={ <FormattedMessage id={'guidance.step'} values={{number: 4}} />} />}>
                        {getRDAAutoDownloadStep(
                            this.props.openSettingsSidebar,
                            'CardHotel',
                        )}
                    </DataTab>
                </DataTabs>
            </div>
        </DataTab>;
    }
}

GuidancePage.propTypes = {
    rdaAccessEnabled: PropTypes.bool,
    loadingRDA: PropTypes.bool,
    openSettingsSidebar: PropTypes.func,
    downloadRDA: PropTypes.func,
    downloadManual: PropTypes.func,
    isRDAOnline: PropTypes.bool,
    isCompanyCardAvailable: PropTypes.bool,
    isRDAStatusFetched: PropTypes.bool,
    intl: PropTypes.object,
    copyInputToCache: PropTypes.func,
    injectedFetch: PropTypes.func,
    backend: PropTypes.string,
    getRDAStatus: PropTypes.func,
    companyCards: PropTypes.array,
    getCompanyCards: PropTypes.func,
    vehiclesWithSubscription: PropTypes.array,
    rawVehicles: PropTypes.array,
    selectCompanyCardsTab: PropTypes.func,
};

GuidancePage.defaultProps = {
    rdaAccessEnabled: true,
    companyCards: [],
    backend: env.runtimeConfig.backend.TACHOGRAPH_SERVICES_SERVICE,
    // eslint-disable-next-line no-warning-comments
    // TODO make sure its updated from api
};

export default injectIntl(GuidancePage);
