/* eslint-disable default-param-last */
const withPermissions = (permissions = [], unlockPermission, child, alternative = <div/>) => {

    if (!unlockPermission || unlockPermission === '') {
        return child;
    }

    if (permissions.includes(unlockPermission)) {
        return child;
    }

    return alternative;
};

export default withPermissions;
