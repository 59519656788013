/* eslint-disable default-param-last */
import { env } from '../../env';

import { accessToken } from './accessToken';
import { ACCESS_TOKEN_STORED } from './actions';

// istanbul ignore next
if (env.isLocalEnv) {
    const dummyToken = 'dummy-access-token';

    accessToken.saveAccessToken(dummyToken);

    // eslint-disable-next-line no-console
    console.warn(`[feature/tokenHandling] Using a dummy access token "${dummyToken}" for local development.`);
}

const initialState = {
    accessToken: accessToken.getAccessToken(),
};

export default function tokenHandlingReducer(state = initialState, action) {
    switch (action.type) {
        case ACCESS_TOKEN_STORED:
            return {
                ...state,
                accessToken: action.payload,
            };
        default:
            return state;
    }
}
