import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';

import noop from 'lodash/fp/noop';

import TransferTypeFilterComponent
    from '../Filters/OptionsFilter/OptionsComponents/TransferTypeFilterComponent';
import ExportedToFilterComponent
    from '../Filters/OptionsFilter/OptionsComponents/ExportedToFilterComponent';

import PreSelectionDropdown from './PreSelectionDropdownContainer';
import TimeRangeSelection from './TimeRangeSelectionContainer';

class TableFilterBar extends Component {
    /* istanbul ignore next */
    constructor(props) { // eslint-disable-line max-statements
        super(props);

        this.state = {
            showFilters: false,
            filterWasOpen: false,
            searchValue:
                this.props.currentCategoryId === 'AssetTree-vehicles' ?
                    this.props.searchedVehicleIdentification :
                    this.props.searchedDriverCard,
            lastSubmittedValue:
            this.props.currentCategoryId === 'AssetTree-vehicles' ?
                this.props.submittedVehicleIdentification :
                this.props.submittedDriverCard,
        };

        this.abortFilter = this.abortFilter.bind(this);
        this.saveFilter = this.saveFilter.bind(this);

        this.resetOnlyLatest = this.resetOnlyLatest.bind(this);
        this.setOnlyLatest = this.setOnlyLatest.bind(this);
        this.openFilter = this.openFilter.bind(this);
        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleSearchEnter = this.handleSearchEnter.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCategoryId !== this.props.currentCategoryId) {
            this.setState({
                searchValue:
                        this.props.currentCategoryId === 'AssetTree-vehicles' ?
                            this.props.searchedVehicleIdentification :
                            this.props.searchedDriverCard,
                lastSubmittedValue:
                    this.props.currentCategoryId === 'AssetTree-vehicles' ?
                        this.props.submittedVehicleIdentification :
                        this.props.submittedDriverCard,
            });
        }
    }

    closeFilter() {
        this.setState({ showFilters: false });
    }

    saveFilter() {
        this.props.saveFilters();
        this.closeFilter();
    }

    abortFilter() {
        this.props.abortFilters();
        this.closeFilter();
    }

    openFilter() {
        this.setState({
            showFilters: true,
        });

        if (!this.props.didShowFilterButton) {
            this.props.setFilterButtonShown();
        }

        this.props.setEventOnFilterClick();
    }

    handleSearchValueChange(searchValue) {
        this.setState(
            { searchValue },
            () => {
                if (this.state.lastSubmittedValue !== '' && this.state.searchValue === '') {
                    this.setState({lastSubmittedValue: this.state.searchValue});
                    if (this.props.currentCategoryId === 'AssetTree-vehicles') {
                        this.props.setSearchedVehicleIdentification(this.state.searchValue);
                        this.props.setSubmittedVehicleIdentification(this.state.searchValue);
                    } else {
                        this.props.setSearchedDriverCard(this.state.searchValue);
                        this.props.setSubmittedDriverCard(this.state.searchValue);
                    }
                    this.props.sendRequest({searchString: this.state.searchValue});
                }
            },
        );
    }

    handleSubmitSearch() {
        if (this.state.searchValue !== '') {
            this.setState(
                {lastSubmittedValue: this.state.searchValue},
                () => {
                    if (this.props.currentCategoryId === 'AssetTree-vehicles') {
                        this.props.setSearchedVehicleIdentification(this.state.searchValue);
                        this.props.setSubmittedVehicleIdentification(this.state.searchValue);
                    } else {
                        this.props.setSearchedDriverCard(this.state.searchValue);
                        this.props.setSubmittedDriverCard(this.state.searchValue);
                    }
                    this.props.sendRequest({searchString: this.state.searchValue});
                },
            );
        }
    }

    clearSearch() {
        if (this.state.lastSubmittedValue !== '') {
            this.props.sendRequest({searchString: ''});
        }
        this.setState(
            {
                searchValue: '',
                lastSubmittedValue: '',
            },
            () => {
                if (this.props.currentCategoryId === 'AssetTree-vehicles') {
                    this.props.setSubmittedVehicleIdentification('');
                    this.props.setSearchedVehicleIdentification('');
                } else {
                    this.props.setSubmittedDriverCard('');
                    this.props.setSearchedDriverCard('');
                }
            },
        );
    }

    handleSearchEnter(e) {
        if (e.key === 'Enter') {
            this.handleSubmitSearch();
        }
    }

    getFilterButton() {
        const isFilterSelected = this.getChosenFilters().find(filter => filter.chosenOptions.length > 0);

        return (
            <div className={'FilterToggleButton btn-group'}>
                <div
                    className={`btn btn-default btn-icon-only`}
                    onClick={this.openFilter}
                    data-testid={'FilterToggleButton'}
                >
                    <span className={`rioglyph rioglyph-filter${isFilterSelected ? '-active' : ''}`}/>
                </div>
            </div>
        );
    }

    getFilterDialog() {
        return (
            <div>
                <Dialog
                    show={this.state.showFilters}
                    onHide={this.abortFilter}
                    bsSize={'sm'}
                    title={
                        <FormattedMessage
                            id={'archive.displayOptions'}
                            defaultMessage={'Display Options Archive'}
                        />
                    }
                    body={this.getFilters()}
                    footer={
                        <div className={'display-flex justify-content-end btn-toolbar'}>
                            <div
                                className={'btn btn-default'}
                                onClick={this.abortFilter}
                                data-testid={'AbortFilterButton'}
                            >
                                <FormattedMessage id={'abort'} defaultMessage={'Abort'}/>
                            </div>
                            <div
                                className={'btn btn-primary'}
                                onClick={this.saveFilter}
                                data-testid={'ApplyFilterButton'}
                            >
                                <FormattedMessage id={'apply'} defaultMessage={'Apply'}/>
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }

    getFilters() {
        const transferTypeFilter = <TransferTypeFilterComponent
            {...this.props.chosenFilters.transferType}
            ISUPLOADHIDDEN={this.props.ISUPLOADHIDDEN}
            isDriver={this.props.currentCategoryId === 'AssetTree-drivers'}
        />;

        const exportedToFilter =
                <ExportedToFilterComponent
                    {...this.props.chosenFilters.exportedTo}
                />;

        return (
            <div className={'Filters'}>
                {transferTypeFilter}
                {exportedToFilter}
            </div>
        );
    }

    getChosenFilters() {
        const transferTypeFilter = this.getChosenTransferTypeFilter();
        const exportedToFilter = this.getChosenExportedToFilter();

        return [
            transferTypeFilter,
            exportedToFilter,
        ];
    }

    // eslint-disable-next-line max-statements
    getChosenTransferTypeFilter() {
        const activeFilter = this.props.chosenFilters.transferType;
        const chosenOptions = [];
        if (activeFilter.autoDownload) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.autoDownload' }));
        }

        if (activeFilter.manualDownload) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.manualDownload' }));
        }

        if (activeFilter.upload) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.upload' }));
        }
        if (activeFilter.speedData && this.props.currentCategoryId === 'AssetTree-vehicles') {
            chosenOptions.push(this.props.intl.formatMessage({
                id: 'archive.speedData',
                defaultMessage: 'Speed Data',
            }));
        }

        return {
            title: this.props.intl.formatMessage({ id: 'archive.transferType' }),
            chosenOptions,
        };
    }

    // eslint-disable-next-line max-statements
    getChosenExportedToFilter() {
        const activeFilter = this.props.chosenFilters.exportedTo;

        const chosenOptions = [];
        if (activeFilter.notExported) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.notExported' }));
        }
        if (activeFilter.computer) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.exportedToComputer' }));
        }

        if (activeFilter.api) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.exportedViaAPI' }));
        }

        if (activeFilter.partner) {
            chosenOptions.push(this.props.intl.formatMessage({ id: 'archive.partner.partner' }));
        }

        return {
            title: this.props.intl.formatMessage({ id: 'archive.exportedTo' }),
            chosenOptions,
        };
    }

    setOnlyLatest() {
        this.props.chosenFilters.setOnlyLatest(true);
    }

    resetOnlyLatest() {
        this.props.chosenFilters.setOnlyLatest(false);
    }

    getLatestFilesSwitch() {
        return (
            <div className={`LatestFilesSwitch btn-group`}>
                <ToggleButton
                    className={`NotOnlyLatestButton `}
                    active={!this.props.chosenFilters.onlyLatest}
                    onClick={this.resetOnlyLatest}
                >
                    <FormattedMessage id={'archive.allFiles'} defaultMessage={'All Files'}/>
                </ToggleButton>
                <ToggleButton
                    className={`OnlyLatestButton `}
                    active={this.props.chosenFilters.onlyLatest}
                    onClick={this.setOnlyLatest}
                >
                    <FormattedMessage id={'archive.latestFiles'} defaultMessage={'Latest Files'}/>
                </ToggleButton>
            </div>
        );
    }

    getTableSearch() {
        return (
            <div
                className={'input-group margin-right-10 width-350'}
                data-testid={'ArchiveTableSearch'}>
                <ClearableInput
                    placeholder={this.props.currentCategoryId === 'AssetTree-vehicles' ?
                        this.props.intl.formatMessage({
                            id: 'search.vehicleIdentificationNumber',
                            defaultMessage: 'Search by vehicle identification number ...',
                        }) :
                        this.props.intl.formatMessage({
                            id: 'search.driverCardNumber',
                            defaultMessage: 'Search by driver card number ...',
                        })}
                    value={this.state.searchValue}
                    onChange={this.handleSearchValueChange}
                    onClear={this.clearSearch}
                    onKeyPress = {this.handleSearchEnter}
                />
                <span className={'input-group-btn'}>
                    <button
                        data-testid={'submitSearchButton'}
                        className={'btn btn-primary btn-icon-only'}
                        type={'button'}
                        onClick={this.handleSubmitSearch}>
                        <span className={'rioglyph rioglyph-search'}></span>
                    </button>
                </span>
            </div>
        );
    }

    getTableViewToggles() {
        const { singleCard, multiCards } = this.props;
        return (
            <div className={'btn-group '}>
                <ToggleButton
                    key={'Archive-TableView-table-button'}
                    className={'btn-icon-only'}
                    data-testid={'ArchiveTableViewButton'}
                    active={!multiCards && !singleCard}
                    onClick={() => {
                        this.props.setNormalTableView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-table-view'}> </span>
                </ToggleButton>
                <ToggleButton
                    key={'Archive-TableView-singleCard-button'}
                    className={'btn-icon-only'}
                    data-testid={'ArchiveSingleCardViewButton'}
                    active={singleCard}
                    onClick={() => {
                        this.props.setSingleCardView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-th-list'}> </span>
                </ToggleButton>
                <ToggleButton
                    key={'Archive-TableView-multiCards-button'}
                    data-testid={'ArchiveMultiCardsViewButton'}
                    className={'btn-icon-only'}
                    active={multiCards}
                    onClick={() => {
                        this.props.setMultiCardsView();
                        this.props.setEventOnTableView();
                    }}
                >
                    <span className={'rioglyph rioglyph-split-view'} > </span>
                </ToggleButton>
            </div>
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        return (
            <div className={'TableFilterBar'}>
                <TableToolbar>
                    <div className={'table-toolbar-container'}>
                        <div className={'table-toolbar-group-left'}>
                            <div
                                className={'table-toolbar-column'}>
                                <label className={'chosenPeriod'}>
                                    <FormattedMessage id={`archive.${this.props.chosenFilters.period}`}/>
                                </label>
                                <div className={'display-flex'}>
                                    <PreSelectionDropdown disabled={this.props.chosenFilters.onlyLatest}/>
                                    <TimeRangeSelection
                                        fetchData={this.props.onDateRangeChange}
                                        disabled={this.props.chosenFilters.onlyLatest}/>
                                </div>
                            </div>
                            <div className={'table-toolbar-column'}>
                                <div className={'btn-toolbar'}>
                                    {this.getLatestFilesSwitch()}
                                    {this.getFilterButton()}
                                </div>
                            </div>
                        </div>
                        <div className={'table-toolbar-group-right'}>
                            <div className={'table-toolbar-column'}>
                                <div className={'btn-toolbar'}>
                                    {this.getTableSearch()}
                                </div>
                            </div>
                            <div className={'table-toolbar-column'}>
                                <div className={'btn-toolbar'}>
                                    {this.getTableViewToggles()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row no-gutter'}>
                        {this.getFilterDialog()}
                    </div>
                </TableToolbar>
            </div>
        );
    }
}

TableFilterBar.propTypes = {
    selectedRows: PropTypes.array,
    isDownloadable: PropTypes.bool,
    downloadAllFilesForFilters: PropTypes.func,
    downloadSelectedFiles: PropTypes.func,
    loadingRowSelected: PropTypes.bool,
    shownTable: PropTypes.string,
    sendRequest: PropTypes.func,
    onDateRangeChange: PropTypes.func,
    chosenFilters: PropTypes.shape({
        period: PropTypes.string,
        onlyLatest: PropTypes.bool,
        setOnlyLatest: PropTypes.func,
        transferType: PropTypes.shape({
            autoDownload: PropTypes.bool,
            manualDownload: PropTypes.bool,
            upload: PropTypes.bool,
            speedData: PropTypes.bool,
            toggle: PropTypes.func,
        }),
        exportedTo: PropTypes.shape({
            notExported: PropTypes.bool,
            computer: PropTypes.bool,
            partner: PropTypes.bool,
            api: PropTypes.bool,
            toggle: PropTypes.func,
        }),
    }),
    refresh: PropTypes.func,
    vehicleSettingsValue: PropTypes.number,
    driverSettingsValue: PropTypes.number,
    setVehicleSettings: PropTypes.func,
    setDriverSettings: PropTypes.func,
    ISUPLOADHIDDEN: PropTypes.bool,
    intl: PropTypes.object,

    singleCard: PropTypes.bool,
    multiCards: PropTypes.bool,
    setNormalTableView: PropTypes.func,
    setMultiCardsView: PropTypes.func,
    setSingleCardView: PropTypes.func,

    saveFilters: PropTypes.func,
    abortFilters: PropTypes.func,
    didShowFilterButton: PropTypes.bool,
    setFilterButtonShown: PropTypes.func,

    isExportEnabled: PropTypes.bool,
    areAllFilteredFilesDownloadable: PropTypes.bool,
    currentCategoryId: PropTypes.string,
    isAssetTreeOpen: PropTypes.bool,
    searchedDriverCard: PropTypes.string,
    searchedVehicleIdentification: PropTypes.string,
    submittedDriverCard: PropTypes.string,
    submittedVehicleIdentification: PropTypes.string,
    setSearchedDriverCard: PropTypes.func,
    setSearchedVehicleIdentification: PropTypes.func,
    setSubmittedDriverCard: PropTypes.func,
    setSubmittedVehicleIdentification: PropTypes.func,
    setEventOnFilterClick: PropTypes.func,
    setEventOnTableView: PropTypes.func,
};

TableFilterBar.defaultProps = {
    shownTable: '',
    selectedRows: [],
    setFilterButtonShown: noop,
    isExportEnabled: true,
    areAllFilteredFilesDownloadable: true,
};

export default injectIntl(TableFilterBar);
