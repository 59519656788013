
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.manageCards.step1.title'}
            defaultMessage={'"Company Cards" tab'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.manageCards.step2.title'}
            defaultMessage={'Edit Company Card'}
        />,
    }, {
        icon: '3',
        label: <FormattedMessage
            id={'glossary.compliant.manageCards.step3.title'}
            defaultMessage={'Vehicle Assingement'}
        />,
    }, {
        icon: '4',
        label: <FormattedMessage
            id={'glossary.compliant.manageCards.step4.title'}
            defaultMessage={'Company Cards Notifications'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manageCards.step1.text'}
                            defaultMessage={'Open "Company Cards" tab if you want to manage your company cards ' +
                            'and assign them to vehicles. Go to the company card you want to manage ' +
                            'and select "Edit" button.'}
                        />
                    </div>
                    <ReleaseNotesImage image={'manageCardsStep1.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 1:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manageCards.step2.text'}
                            defaultMessage={
                                'Company Card sidebar will open. ' +
                                'Click on "Add vehicles" button to assign vehicles.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'manageCardsStep2.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 2:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manageCards.step3.text'}
                            defaultMessage={
                                'Select the vehicles ' +
                                'to be assigned to the company card and save the changes.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'manageCardsStep3.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 3:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manageCards.step4.text'}
                            defaultMessage={
                                'If you want to receive notifications about company cards, ' +
                                'you can activate them in the Settings.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'manageCardsStep4.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        default:
            return <div/>;
    }
};

const ManageCardsExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default ManageCardsExplanation;
