/* eslint-disable immutable/no-mutation */
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/get';

import {
    getFormattedAssetTreeVehicleItem,
    getFormattedAssetTreeDriverItem,
} from '../fetchUtils';
import { MANDOWNLOAD, AUTODOWNLOAD, MANDOWNLOADTRIGGER } from '../../constants/archive';

import store from '../../setup/store';

/* eslint-disable-next-line no-confusing-arrow */
export const getNameFilter = search => search === '' ? '' : `&nameFilter=${encodeURIComponent(search)}`;

export const getBodyTimeRange = (startValue, endValue, onlyLatest, body) => {
    if (startValue && !onlyLatest) {
        body.startUtc = startValue.toISOString();
        body.endUtc = endValue.toISOString();
    }
};

export const getBodySelectedIds = (currentCategoryId, selectedIds, body) => {
    if (currentCategoryId === 'AssetTree-vehicles' && !isEmpty(selectedIds)) {
        body.externalEquipmentReferences = selectedIds;
    }

    if (currentCategoryId === 'AssetTree-drivers' && !isEmpty(selectedIds)) {
        body.externalDriverReferences = selectedIds;
    }

};
export const getDataListRequestBody = ( // eslint-disable-line max-statements
    startValue,
    endValue,
    selectedIds,
    onlyLatest,
    authorizedParties,
    overviewSelected,
    notExported,
    currentCategoryId,
    entityIdentifier,
    hasSpeedData,
) => {
    const body = {};

    getBodyTimeRange(
        startValue,
        endValue,
        onlyLatest,
        body,
    );

    body.fileType = currentCategoryId === 'AssetTree-vehicles' ? 'Vehicle' : 'Driver';
    body.entityIdentifier = entityIdentifier;

    getBodySelectedIds(
        currentCategoryId,
        selectedIds,
        body,
    );

    if (!overviewSelected && !isEmpty(authorizedParties)) {
        body.authorizedParties = authorizedParties;
    }

    if (!overviewSelected && notExported) {
        body.isExported = !notExported;
    }

    if (!overviewSelected && hasSpeedData && currentCategoryId === 'AssetTree-vehicles') {
        body.HasSpeedData = hasSpeedData;
    }

    return body;
};

export function getFormattedItem(item, currentCategoryId) { // eslint-disable-line max-statements
    const { vehicles = [], drivers = [] } = get(
        store.getState(),
        'tachographservices.tree',
        {},
    );
    const { retentionPeriod, identifiers = [] } = get(
        store.getState(),
        'tachographservices.common',
        {},
    );
    const filteredIdentifiers = identifiers.filter(identifier => identifier.permissions.includes('file-data.read'));

    const newItem = item;

    if (!newItem.status) {
        newItem.status = 'successful';
    }

    if (!newItem.isDownloadPermitted) {
        newItem.isDownloadPermitted = filteredIdentifiers
            .some(filteredIdentifier => newItem.tags.includes(filteredIdentifier.identifier));
    }

    newItem.transferType = newItem.downloadTrigger === MANDOWNLOADTRIGGER ?
        MANDOWNLOAD :
        AUTODOWNLOAD;

    if (currentCategoryId === 'AssetTree-vehicles' && newItem.vehicleInfo !== null) {
        getFormattedAssetTreeVehicleItem(
            newItem,
            vehicles,
            newItem.vehicleInfo.externalEquipmentReference,
        );
    }

    if (currentCategoryId === 'AssetTree-drivers' && newItem.driverInfo !== null) {
        getFormattedAssetTreeDriverItem(
            newItem,
            drivers,
            newItem.driverInfo.externalDriverReference,
        );
    }

    const dateWithRetention = new Date(newItem.timeCreatedUtc);
    dateWithRetention.setMonth(dateWithRetention.getMonth() + retentionPeriod);
    newItem.deletionDate = dateWithRetention;
    return newItem;
}

