import get from 'lodash/get';

import { env } from '../../env';
import { successfulFetchAssets, successfulFetchTags, successfulFetchDrivers } from '../../actions/tree';

import { getAuthenticatedFetchJSON } from '../../utils/fetch';

const { isLocalEnv, runsInSandbox } = env;

const getQuery = endpoint => {
    switch (endpoint) {
        case 'assets':
            return '?embed=(tags,master_data)';
        case 'drivers':
            return '?status=active&embed=(tags,identifications)';
        default:
            return '';
    }
};

const getUrl = endpoint => {
    if (isLocalEnv) {
        return `/${endpoint}`;
    }

    if (runsInSandbox) {
        return `https://api.remotedownload-sandbox.rio.cloud/backendmock/admin/${endpoint}`;
    }

    return `https://api.${endpoint}.rio.cloud/${endpoint}${getQuery(endpoint)}`;
};

const getAllItems = async(url, injectedFetch) => {
    const answer = await injectedFetch(url);

    if (answer instanceof Error) {
        return [];
    }

    const nextUrl = get(
        answer,
        '_links.next.href',
        '',
    );
    const items = get(
        answer,
        'items',
        [],
    );
    if (nextUrl) {
        return [
            ...items, ...await getAllItems(
                nextUrl,
                injectedFetch,
            ),
        ];
    }

    return items;
};

const fetchMFH = (path, rezolve, onSuccess, injectedFetch) => {
    const configuredFetchJSON = injectedFetch || getAuthenticatedFetchJSON(
        fetch,
        document,
    );

    getAllItems(
        getUrl(path),
        configuredFetchJSON,
    )
        .then(items => {
            onSuccess(items);
            rezolve();
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            rezolve();
        });
};

const fetchAssets = (resolve, handleData, injectedFetch) => {
    fetchMFH(
        'assets',
        resolve,
        handleData,
        injectedFetch,
    );
};

const fetchDrivers = (resolve, handleData, injectedFetch) => {
    fetchMFH(
        'drivers',
        resolve,
        handleData,
        injectedFetch,
    );
};

const fetchTags = (resolve, handleData, injectedFetch) => {
    fetchMFH(
        'tags',
        resolve,
        handleData,
        injectedFetch,
    );
};

const getHandleData = (store, dispatchAction) => data => {
    store.dispatch(dispatchAction(data));
};

const configureFetchAssets = store => new Promise(resolve => {
    fetchAssets(
        resolve,
        getHandleData(
            store,
            successfulFetchAssets,
        ),
    );

});

export const configureFetchDrivers = store => new Promise(resolve => {
    fetchDrivers(
        resolve,
        getHandleData(
            store,
            successfulFetchDrivers,
        ),
    );

});

export const configureFetchTags = store => new Promise(resolve => {
    fetchTags(
        resolve,
        getHandleData(
            store,
            successfulFetchTags,
        ),
    );
});

export default configureFetchAssets;
