import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { setTachoSetting as setTachoSettingAction } from '../../actions/common';

import setTachoSetting from '../../fetches/Settings/setTachoSetting';
import store from '../../setup/store';
import CloudStoragePeriodDialog from '../CallForAction/CloudStoragePeriodDialog';
import InstallRDADialogComponent from '../CallForAction/InstallRDADialog';

const SIZE = 'sm';

export const CallForActionContainerComponent = props => (
    <div className={'CallForActionContainer'}>
        <InstallRDADialogComponent
            onClose={props.setCallForActionInstallRDAShown}
            permissions={props.permissions}
            show={!props.callForActionInstallRDAShown}
            bsSize={SIZE}
        />
        <CloudStoragePeriodDialog
            onClose={props.setCallForActionCloudStoragePeriodShown}
            permissions={props.permissions}
            show={!props.callForActionCloudStoragePeriodShown}
            bsSize={SIZE}
        />
    </div>
);

CallForActionContainerComponent.propTypes = {
    permissions: PropTypes.array,
    callForActionCloudStoragePeriodShown: PropTypes.bool,
    setCallForActionCloudStoragePeriodShown: PropTypes.func,
    setCallForActionInstallRDAShown: PropTypes.func,
    callForActionInstallRDAShown: PropTypes.bool,
};

const getTachoSetting = (state, setting) => get(
    state,
    `tachographservices.common.tachoSettings.${setting}`,
    false,
);

export const mapStateToProps = state => ({ // eslint-disable-line max-lines-per-function
    permissions: state.tachographservices.common.permissions,

    callForActionInstallRDAShown: getTachoSetting(
        state,
        'callForActionInstallRDAShown5',
    ),

    callForActionCloudStoragePeriodShown: getTachoSetting(
        state,
        'callForActionCloudStoragePeriodShown',
    ),
});

// eslint-disable-next-line max-lines-per-function
export const mapDispatchToProps = dispatch => ({
    setCallForActionCloudStoragePeriodShown: () => {
        dispatch(setTachoSettingAction({ callForActionCloudStoragePeriodShown: true }));
        setTachoSetting(
            store.getState().tokenHandling.accessToken,
            'callForActionCloudStoragePeriodShown',
            true,
        );
    },

    setCallForActionInstallRDAShown: () => {
        dispatch(setTachoSettingAction({ callForActionInstallRDAShown5: true }));
        setTachoSetting(
            store.getState().tokenHandling.accessToken,
            'callForActionInstallRDAShown5',
            true,
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CallForActionContainerComponent);
