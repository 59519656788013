import { env } from '../../env';
import { setPermissions, setIdenfiers } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';
import { fetchRdaStatus } from '../../actions/administration';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getFetchTachoPermissions =
    (store, fetch = window.fetch) => () => fetch(
        `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/permissions`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.getState().tokenHandling.accessToken}`,
            },
            method: 'GET',
        },
    );

export const extractPermissions = data => {
    if (data.length > 0) {
        const unfilteredPermissions = data.reduce(
            (acc, item) => {
                item.permissions.map(permission => acc.push(permission));
                return acc;
            },
            [],
        );
        const filteredPermissions = [...new Set(unfilteredPermissions)];
        return filteredPermissions;
    }
    return [];
};

const getIdentifiers = data => {
    const result = [];
    data.forEach(item => {
        item.scope.identifiers.forEach(identifier => result.push({identifier, permissions: item.permissions}));
    });
    return result;
};

const extractIdentifiers = data => {
    if (data.length > 0) {
        const dublicateIdentifiers = getIdentifiers(data);
        const mergedIdentifiers = Object.values(dublicateIdentifiers.reduce(
            (c, {identifier, permissions}) => {
                c[identifier] = c[identifier] || {identifier, permissions: []};
                c[identifier].permissions = c[identifier].permissions
                    .concat(Array.isArray(permissions) ? permissions : [permissions]);
                return c;
            },
            {},
        ));

        const identifiersWihtFilteredPermissions = mergedIdentifiers.map(item => ({
            identifier: item.identifier,
            permissions: [...new Set(item.permissions)],
        }));
        return identifiersWihtFilteredPermissions;
    }
    return [];
};

export const getHandleData = store => data => {
    const permissions = extractPermissions(data);
    const identifiers = extractIdentifiers(data);

    store.dispatch(setPermissions(permissions));
    store.dispatch(setIdenfiers(identifiers));

    const rdaEnabled = permissions.includes('rda-installer.get');

    if (permissions.includes('file-list.read') && rdaEnabled) {
        store.dispatch(fetchRdaStatus());
    }
};

const configureFetchTachoPermissions = store => new Promise(resolve => {
    const fetchTachoPermissions = getFetchTachoPermissions(store);

    saveFetch(
        fetchTachoPermissions,
        'permissions',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchTachoPermissions;
