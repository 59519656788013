import { fetchDataPOST } from '../../sagas/fetchUtils';

import getFiles from './getFiles';

const getFilesTotalCount = (
    startValue,
    endValue,
    type,
    injectedFetch = fetchDataPOST,
) => getFiles(
    {
        startValue,
        endValue,
        type,
        queryProps: {
            limit: 1,
        },
    },
    injectedFetch,
)
    .then(data => data.totalCount)
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getFilesTotalCount;
