const runWithSentry = (main, shouldSendMetrics) => {
    // Sentry configuration
    if (window.Sentry) {
        const sentryConfigUrl = 'https://11f2f183bb314bdd8b026eba146f571b@sentry.io/1319651';
        if (shouldSendMetrics) {
            window.Sentry.init({
                dsn: sentryConfigUrl,
                environment: 'production',
            });
        }
        window.Sentry.wrap(main);
    } else {
        // eslint-disable-next-line no-console
        console.warn('No Sentry found, you should tread lightly...');
        main();
    }
};

export default runWithSentry;
