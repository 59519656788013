import { Component } from 'react';
import PropTypes from 'prop-types';

import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';

import { FormattedMessage } from 'react-intl';

import { SHOULD_INITIALLY_BE_RESPONSIVE } from '../../../constants/common';

import DefaultDownloadIntervalsExplanation from './compliant/DefaultDownloadIntervalsExplanation';
import CustomizedDownloadIntervalsExplanation from './compliant/CustomizedDownloadIntervalsExplanation';
import CloudStoragePeriodExplanation from './compliant/CloudStoragePeriodExplanation';
import ManualDownloadExplanation from './compliant/ManualDownloadExplanation';
import SpeedDataExplanation from './compliant/SpeedDataExplanation';
import ManualSpeedDataExplanation from './compliant/ManualSpeedDataExplanation';
import FiltersExplanation from './compliant/FiltersExplanation';
import ManageCardsExplanation from './compliant/ManageCardsExplanation';

const header = <FormattedMessage id={'administration.help'} defaultMessage={'Help'}/>;

export const getItem = (shownContent, setShownContent, key) => ({
    key,
    item: <a
        key={`help-navitem-${key}`}
        onClick={() => setShownContent(key)}
        className={shownContent === key ? 'active' : ''}
    >
        <FormattedMessage id={`glossary.${key}`} />
    </a>,
});

const getMenuItems = (shownContent, setShownContent) => [
    {
        group: <FormattedMessage id={'module.name'} defaultMessage={'Compliant'} />,
        navItems: [
            getItem(
                shownContent,
                setShownContent,
                'compliant.defaultDownloadIntervals',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.customizedDownloadIntervals',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.cloudStoragePeriod',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.manualDownload',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.speedData',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.manualSpeedData',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.filters',
            ),
            getItem(
                shownContent,
                setShownContent,
                'compliant.manageCards',
            ),
        ],
    },
];

const getBody = shownContent => {
    switch (shownContent) {
        case 'compliant.defaultDownloadIntervals':
            return <DefaultDownloadIntervalsExplanation/>;
        case 'compliant.customizedDownloadIntervals':
            return <CustomizedDownloadIntervalsExplanation/>;
        case 'compliant.cloudStoragePeriod':
            return <CloudStoragePeriodExplanation/>;
        case 'compliant.manualDownload':
            return <ManualDownloadExplanation/>;
        case 'compliant.speedData':
            return <SpeedDataExplanation/>;
        case 'compliant.manualSpeedData':
            return <ManualSpeedDataExplanation/>;
        case 'compliant.filters':
            return <FiltersExplanation/>;
        case 'compliant.manageCards':
            return <ManageCardsExplanation/>;

        default:
            return <div/>;
    }
};

class GlossaryDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shownContent: props.defaultGlossaryPage || 'compliant.defaultDownloadIntervals',
        };

        this.setShownContent = this.setShownContent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultGlossaryPage !== this.props.defaultGlossaryPage) {
            this.setShownContent(this.props.defaultGlossaryPage);
        }
    }

    setShownContent(content) {
        this.setState({ shownContent: content });
    }

    render() {
        if (this.props.SHOULD_INITIALLY_BE_RESPONSIVE) {
            return <SimpleDialog
                show={this.props.show}
                title={header}
                content={
                    <div data-testid={'gloassaryDialog-notResponsive'}>
                        <div className={'form-group'}>
                            <ListMenu
                                key={this.state.shownContent}
                                menuItems={getMenuItems(
                                    this.state.shownContent,
                                    this.setShownContent,
                                )}
                                groupClassName={'padding-left-0 GlossaryListMenu'}
                            />
                        </div>
                        <hr/>
                        {getBody(this.state.shownContent)}
                    </div>
                }
                bsSize={'lg'}
                onClose={this.props.toggleDialog}
            />;
        }

        return <SplitDialog
            data-testid={'gloassaryDialog-responsive'}
            show={this.props.show}
            title={header}
            leftContent={
                <ListMenu
                    key={this.state.shownContent}
                    menuItems={getMenuItems(
                        this.state.shownContent,
                        this.setShownContent,
                    )}
                    groupClassName={'padding-left-0 GlossaryListMenu'}
                />
            }
            rightContent={getBody(this.state.shownContent)}
            bsSize={'lg'}
            onClose={this.props.toggleDialog}
        />;
    }
}

GlossaryDialog.defaultProps = {
    SHOULD_INITIALLY_BE_RESPONSIVE,
};

GlossaryDialog.propTypes = {
    toggleDialog: PropTypes.func,
    show: PropTypes.bool,
    defaultGlossaryPage: PropTypes.string,
    SHOULD_INITIALLY_BE_RESPONSIVE: PropTypes.bool,
};

export default GlossaryDialog;
