/* eslint-disable camelcase */
import { createHashHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import root from 'window-or-global';

import { env } from '../env';

import {
    SET_TIME_RANGE,
    DOWNLOAD_ALL_ARCHIVE_FILES,
    ADD_DOWNLOADING_FILES,
} from '../actions/archive';

import {
    DOWNLOAD_RDA,
    DOWNLOAD_MANUAL,
    SET_DEFAULT_DOWNLOAD_INTERVAL,
    SET_CUSTOMIZED_DOWNLOAD_INTERVAL,
    SET_EVENT_ON_MANUAL_DOWNLOAD,
} from '../actions/administration';

import { CLOUD_STORAGE_CHANGED } from '../actions/common';

import { isUserBlacklisted } from '../constants/analytics';

import { SET_EVENT_ON_HELP_PAGE } from '../actions/about';

import {SET_EVENT_ON_FILTER_CLICK, SET_EVENT_ON_TABLE_VIEW} from '../actions/tableFilters';

import sagas from './sagas';

import rootReducer from './rootReducer';

const { isRunningOnProd, featureToggles: { noAnalytics } } = env;

const composeEnhancers = composeWithDevTools({});

export const getUserConfirmation = (
    message,
    callback,
    injectedWindow = window,
) => callback(injectedWindow.confirm(message)); // eslint-disable-line no-alert

export const history = createHashHistory({
    getUserConfirmation,
    hashType: 'noslash',
});

export const analyticsEvents = {
    [SET_TIME_RANGE]: {
        name: 'timeRangeChanged',
        event_category: 'archive',
        event_label: 'Changed time range through period chooser',
    },
    [DOWNLOAD_ALL_ARCHIVE_FILES]: {
        name: 'downloadAllFiles',
        event_category: 'archive',
        event_label: 'Exported all files with current filters',
    },
    [ADD_DOWNLOADING_FILES]: {
        name: 'downloadFiles',
        event_category: 'archive',
        event_label: action => `${action.payload.length.toString()} file(s) were exported`,
    },
    [DOWNLOAD_RDA]: {
        name: 'downloadRDA',
        event_category: 'administration',
        event_label: 'RDA was downloaded',
    },
    [DOWNLOAD_MANUAL]: {
        name: 'downloadInstructionManual',
        event_category: 'administration',
        event_label: 'Instruction Manual was downloaded',
    },
    [SET_EVENT_ON_MANUAL_DOWNLOAD]: {
        name: 'manualDownloadRequest',
        event_category: 'archive',
        event_label: 'Manual download file was requested',
    },
    [CLOUD_STORAGE_CHANGED]: {
        name: 'cloudStorage',
        event_category: 'administration',
        event_label: 'Cloud storage was changed',
    },
    [SET_DEFAULT_DOWNLOAD_INTERVAL]: {
        name: 'defaultDownloadInterval',
        event_category: 'administration',
        event_label: 'Default download interval was changed',
    },
    [SET_CUSTOMIZED_DOWNLOAD_INTERVAL]: {
        name: 'customizedDownloadInterval',
        event_category: 'administration',
        event_label: 'Customized download interval was changed',
    },
    [SET_EVENT_ON_HELP_PAGE]: {
        name: 'helpPage',
        event_category: 'common',
        event_label: 'Help page was clicked',
    },
    [SET_EVENT_ON_FILTER_CLICK]: {
        name: 'filterButtonClick',
        event_category: 'common',
        event_label: 'Filter button was clicked',
    },
    [SET_EVENT_ON_TABLE_VIEW]: {
        name: 'tableViewChange',
        event_category: 'common',
        event_label: 'Table view changed',
    },
};

export const createAnalyticsMiddleware = ({useAnalytics, rootObject}) => () => next => action => {

    if (useAnalytics && rootObject.dataLayer && !isUserBlacklisted()) {
        try {
            if (analyticsEvents[action.type]) {
                const {name, event_category, event_label} = analyticsEvents[action.type];
                rootObject.dataLayer.push({
                    event: 'ga_event',
                    eventCategory: event_category,
                    eventLabel: typeof event_label === 'function' ? event_label(action) : event_label,
                    eventAction: name,
                });
            }
        } catch (error) { // eslint-disable-line no-empty

        }
    }

    return next(action);
};
/* eslint-enable camelcase */

const analyticsMiddleware = createAnalyticsMiddleware({
    useAnalytics:
        // istanbul ignore next
        isRunningOnProd && !noAnalytics,
    rootObject: root,
});

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(
    ...middleware,
    analyticsMiddleware,
))(createStore);

const store = createStoreWithMiddleware(rootReducer);

sagas.forEach(saga => {
    sagaMiddleware.run(saga);
});

export default store;
