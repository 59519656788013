import {FormattedMessage} from 'react-intl';
import Tag from '@rio-cloud/rio-uikit/Tag';

import TooltipTrigger from '../../../components/common/TooltipTrigger';

const vehicleNotInFleetMessage =
    <FormattedMessage id={'archive.vehicleNotInFleet'} defaultMessage={'The vehicle is not in the fleet'}/>;
const driverNotInFleetMessage =
    <FormattedMessage id={'archive.driverNotInFleet'} defaultMessage={'The driver is not in the fleet'}/>;

const getFormatDownloadInterval = isDriverSelected => (cell, row) => cell ?
    <Tag>
        <span className={'margin-right-5'}>{ cell }</span>
        <FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>
    </Tag> :
    <TooltipTrigger
        tooltip={ isDriverSelected ? driverNotInFleetMessage : vehicleNotInFleetMessage }
        baseKey={`file-${row.fileName}-download-interval-empty`}
    >
        <div className={'padding-5'}>{'-'}</div>
    </TooltipTrigger>;

export default getFormatDownloadInterval;
