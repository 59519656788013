
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.customizedDownloadIntervals.step1.title'}
            defaultMessage={'Overview Page'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.customizedDownloadIntervals.step2.title'}
            defaultMessage={'Edit download interval'}
        />,
    }, {
        icon: '3',
        label: <FormattedMessage
            id={'glossary.compliant.customizedDownloadIntervals.step3.title'}
            defaultMessage={'Settings Overview'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.customizedDownloadIntervals.step1.text'}
                        defaultMessage={
                            'Go to the page "Overview" if you would like to set customized download intervals for' +
                            ' individual vehicles and drivers. Select one or more vehicles or drivers and click ' +
                            'on “Edit download interval”.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'customizedDownloadIntervalsStep1.png'} widthClass={'max-width-100pct'}/>
            </div>;
        case 1:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.customizedDownloadIntervals.step2.text'}
                        defaultMessage={
                            'Edit the download intervals for the selected vehicles or drivers and click “Save”.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'customizedDownloadIntervalsStep2.png'} widthClass={'max-width-100pct'}/>
            </div>;
        case 2:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.customizedDownloadIntervals.step3.text'}
                        defaultMessage={
                            'You can find an overview of all customized download intervals in the “Settings”. ' +
                            'Here you can also delete customized download intervals for vehicles or drivers.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'customizedDownloadIntervalsStep3.png'} widthClass={'max-width-100pct'}/>
            </div>;
        default:
            return <div/>;
    }
};

const CustomizedDownloadIntervalsExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default CustomizedDownloadIntervalsExplanation;
