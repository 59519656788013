
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const getListItems = settings => settings.map(setting => (
    <div key={setting.id} className={'list-group-item display-flex align-items-center'}>
        <div className={'display-flex justify-content-between align-items-center flex-wrap width-100pct'}>
            <div>
                <span className={`rioglyph rioglyph-${setting.type} margin-right-5`}/>
                <span>
                    {
                        setting.name ?
                            setting.name :
                            <span className={'text-muted'}>
                                <FormattedMessage id={'notFound'} defaultMessage={'Not Found'}/>
                            </span>
                    }
                </span>
            </div>
            <div>
                <span>{setting.detail}</span>
            </div>
            <div>
                <div className={'input-group'}>
                    <input
                        className={'form-control width-50 text-center'}
                        type={'text'}
                        disabled
                        step={1}
                        value={setting.downloadInterval}
                    />
                    <div className={'input-group-addon'}>
                        <FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>
                    </div>
                </div>
            </div>
        </div>
        <div className={'margin-left-10'}>
            <button className={'btn btn-link btn-icon-only'} onClick={setting.onRemove}>
                <span className={'rioglyph rioglyph-remove'}/>
            </button>
        </div>
    </div>
));

const IntervalSettingsListGroup = props => props.customizedIntervalSettings.length === 0 ?
    <div className={'list-group border rounded'}>
        <div className={'list-group-item text-center'}>
            <span className={'text-muted'}>
                <FormattedMessage
                    id={'administration.noCustomizedSettingsMade'}
                    defaultMessage={'No customized settings made'}
                />
            </span>
        </div>
    </div> :
    <div className={'list-group border rounded'}>
        { getListItems(props.customizedIntervalSettings) }
    </div>;

IntervalSettingsListGroup.defaultProps = {
    customizedIntervalSettings: [],
};

IntervalSettingsListGroup.propTypes = {
    customizedIntervalSettings: PropTypes.array,
};

export default IntervalSettingsListGroup;
