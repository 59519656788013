import { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ReleaseNotes from '@rio-cloud/rio-uikit/ReleaseNotes';

import forOwn from 'lodash/forOwn';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { dateProps } from '../../../constants/common';

import releaseNotes from './releaseNotes';

const getReleases = toggleGlossaryDialog => {
    const releases = [];

    forOwn(
        releaseNotes,
        (note, version, index) => {
            const notePoints =
            note.content.map((point, i) => <li key={`${version}-${index}-note-${i}`}>
                { point(toggleGlossaryDialog) }
            </li>);

            releases.push(<div className={'form-group'} key={`${version}-${index}-note`}>
                <ReleaseNotes
                    releaseNotes={{
                        [version]: {
                            date: <FormattedDate value={new Date(note.date)} {...dateProps} />,
                            content: <ul className={'padding-left-10'}> {notePoints} </ul>,
                        },
                    }}
                />
            </div>);
        },
    );

    return releases;
};

class ReleaseNotesDialog extends Component {
    render() {
        const header = <FormattedMessage id={'whatsNew'} defaultMessage={'What`s new?'}/>;

        const body = (
            <div>
                { getReleases(this.props.toggleGlossaryDialog) }
            </div>
        );

        const footer =
            (<button
                data-component={'CloseButton'}
                type={'button'}
                className={'btn btn-default'}
                onClick={this.props.closeReleaseNotes}
            >
                <FormattedMessage id={'close'} defaultMessage={'Close'}/>
            </button>);

        return (<Dialog
            useOverflow={true}
            show={this.props.showReleaseNotes}
            title={header}
            body={body}
            footer={footer}
            onHide={this.props.closeReleaseNotes}
        />);
    }
}

ReleaseNotesDialog.propTypes = {
    showReleaseNotes: PropTypes.bool,
    toggleGlossaryDialog: PropTypes.func,
    closeReleaseNotes: PropTypes.func,
};

export default ReleaseNotesDialog;
