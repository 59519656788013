
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import {successfulFetchListOfVehicles} from '../../actions/administration';
import {fetchData, forbidden, noContent, notFound, unauthorized} from '../../sagas/fetchUtils';

import {getDownloadSchedulerURL} from './getListOfRequests';

const getListOfVehicles = (
    dispatch,
    injectedFetch = fetchData,
) => injectedFetch(`${getDownloadSchedulerURL()}list-of-vehicles`)
    .then((res = {}) => {
        if (res.error) {
            switch (res.error) {
                case noContent:
                    break;
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                case notFound:
                    Notification.error(<FormattedMessage
                        id={'error.404'}
                        defaultMessage={
                            'Service not found. Please try again later.'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.500'}
                        defaultMessage={
                            'An internal error has occurred. Please try again later.'
                        }
                    />);
            }
        } else if (res.length) {
            dispatch(successfulFetchListOfVehicles(res));
            return res;
        }

        dispatch(successfulFetchListOfVehicles([]));
        throw new Error('tacho get list of vehicles');
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getListOfVehicles;
