const basePath = 'TACHOGRAPHSERVICES.ABOUT.';
export const TOGGLE_GLOSSARY_DIALOG = `${basePath}.TOGGLE_GLOSSARY_DIALOG`;
export const SET_EVENT_ON_HELP_PAGE = `${basePath}.SET_EVENT_ON_HELP_PAGE`;

export const toggleGlossaryDialog = page => ({
    type: TOGGLE_GLOSSARY_DIALOG,
    payload: page,
});

export const setEventOnHelpPage = () => ({
    type: SET_EVENT_ON_HELP_PAGE,
});

