import {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';

import {
    ROWLIMIT,
    LOADINGDATA,
    ISUPLOADHIDDEN,
    WAITINGFORDATA,
    AREPERMISSIONSHIDDEN,
} from '../../constants/archive';

import ForbiddenPage from '../../features/DefaultPages/ForbiddenPage';
import withPermissions from '../../utils/withPermissions';

import setTachoDownloadInterval from '../../fetches/DownloadInterval/setTachoDownloadInterval';

import {
    setSelectedRows,
    setTimeRange as setTimeRangeAction,
    addDownloadingFiles,
    removeDownloadingFiles,
    setSortingBehaviour,
    setListFilesData,
    refetchArchiveData,
} from '../../actions/archive';

import fetchListFiles from '../../fetches/Archive/fetchListFiles';
import downloadFiles from '../../fetches/Archive/downloadFiles';
import downloadAllFiles from '../../fetches/Archive/downloadAllFiles';

import {toggleTransferType,
    setSingleCard,
    setMultiCards,
    setEventOnTableView as setEventOnTableViewAction,
    setEventOnFilterClick as setEventOnFilterClickAction,
    toggleExportedTo,
    abortTempFilters,
    setSearchedDriverCard as setSearchedDriverCardAction,
    setSearchedVehicleIdentification as setSearchedVehicleIdentificationAction,
    setSubmittedDriverCard as setSubmittedDriverCardAction,
    setSubmittedVehicleIdentification as setSubmittedVehicleIdentificationAction} from '../../actions/tableFilters';

import { setTachoSetting as setTachoSettingAction } from '../../actions/common';

import { getAccessToken} from '../../features/tokenHandling/selectors';

import setTachoSetting from '../../fetches/Settings/setTachoSetting';
import getTachoDownloadIntervals from '../../fetches/DownloadInterval/getTachoDownloadIntervals';
import store from '../../setup/store';

import ArchivePage from './ArchivePage';

import { setCustomizedDownloadInterval } from './../../actions/administration';

export const ArchiveContainer = props => {

    useEffect(
        () => {
            if (props.isDownloadIntervalEnabled) {
                props.getDownloadIntervals();
            }
        },
        [],
    );

    const renderComponent = (
        <div className={'ArchiveContainer'}>
            <ArchivePage
                {...props}

                // for Tests and coverage we have to be able to manually set the constants
                ROWLIMIT={ROWLIMIT}
                LOADINGDATA={LOADINGDATA}
                ISUPLOADHIDDEN={ISUPLOADHIDDEN}
                WAITINGFORDATA={WAITINGFORDATA}
            />
        </div>
    );

    return withPermissions(
        props.permissions,
        AREPERMISSIONSHIDDEN ? '' : 'file-list.read',
        renderComponent,
        <ForbiddenPage/>,
    );
};

ArchiveContainer.propTypes = {
    roles: PropTypes.array,
    permissions: PropTypes.array,
    isDownloadIntervalEnabled: PropTypes.bool,
    getDownloadIntervals: PropTypes.func,
};

// eslint-disable-next-line max-lines-per-function
export const mapStateToProps = state => ({
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    isExportEnabled:
        get(
            state,
            'tachographservices.common.permissions',
            [],
        ).includes('file-data.read'),
    isDownloadIntervalEnabled:
        get(
            state,
            'tachographservices.common.permissions',
            [],
        ).includes('download-interval.read'),

    listFilesData: get(
        state,
        'tachographservices.archive.listFilesData',
        [],
    ),
    listFilesTotalFilesCount: get(
        state,
        'tachographservices.archive.listFilesTotalFilesCount',
    ),
    listFilesActivePage: get(
        state,
        'tachographservices.archive.listFilesActivePage',
    ),
    selectedRows: get(
        state,
        'tachographservices.archive.selectedRows',
        [],
    ),
    sortFieldArchive: get(
        state,
        'tachographservices.archive.sortFieldArchive',
        'timeCreatedUtc',
    ),
    sortFieldOverview: get(
        state,
        'tachographservices.archive.sortFieldOverview',
        'timeCreatedUtc',
    ),
    sortOrderOverview: get(
        state,
        'tachographservices.archive.sortOrderOverview',
        'desc',
    ),
    sortOrderArchive: get(
        state,
        'tachographservices.archive.sortOrderArchive',
        'desc',
    ),
    start: get(
        state,
        'tachographservices.archive.start',
    ),
    end: get(
        state,
        'tachographservices.archive.end',
    ),
    activePage: get(
        state,
        'tachographservices.archive.activePage',
    ),
    totalFilesCount: get(
        state,
        'tachographservices.archive.totalFilesCount',
        {},
    ),
    limit: get(
        state,
        'tachographservices.archive.limit',
    ),
    downloadingFiles: get(
        state,
        'tachographservices.archive.downloadingFiles',
    ),

    shouldRefetchArchive: get(
        state,
        'tachographservices.archive.shouldRefetchArchive',
    ),

    didShowFilterButton: get(
        state,
        'tachographservices.common.tachoSettings.didShowFilterButton',
    ),

    vehicles: get(
        state,
        'tachographservices.tree.vehicles',
        [],
    ),
    selectedVehicles: get(
        state,
        'tachographservices.tree.selectedVehicles',
        [],
    ),
    drivers: get(
        state,
        'tachographservices.tree.drivers',
        [],
    ),
    selectedDrivers: get(
        state,
        'tachographservices.tree.selectedDrivers',
        [],
    ),
    currentCategoryId: get(
        state,
        'tachographservices.tree.currentCategoryId',
        'AssetTree-vehicles',
    ),
    isAssetTreeOpen: get(
        state,
        'tachographservices.tree.isAssetTreeOpen',
    ),
    singleCard: get(
        state,
        'tachographservices.tableFilters.singleCard',
    ),
    multiCards: get(
        state,
        'tachographservices.tableFilters.multiCards',
    ),
    autoDownload: get(
        state,
        'tachographservices.tableFilters.transferTypeFilters.autoDownload',
    ),
    manualDownload: get(
        state,
        'tachographservices.tableFilters.transferTypeFilters.manualDownload',
    ),
    speedData: get(
        state,
        'tachographservices.tableFilters.transferTypeFilters.speedData',
    ),
    upload: get(
        state,
        'tachographservices.tableFilters.transferTypeFilters.upload',
    ),
    notExported: get(
        state,
        'tachographservices.tableFilters.exportedToFilters.notExported',
    ),
    computer: get(
        state,
        'tachographservices.tableFilters.exportedToFilters.computer',
    ),
    partner: get(
        state,
        'tachographservices.tableFilters.exportedToFilters.partner',
    ),
    api: get(
        state,
        'tachographservices.tableFilters.exportedToFilters.api',
    ),
    searchedDriverCard: get(
        state,
        'tachographservices.tableFilters.searchedDriverCard',
    ),
    searchedVehicleIdentification: get(
        state,
        'tachographservices.tableFilters.searchedVehicleIdentification',
    ),
    submittedDriverCard: get(
        state,
        'tachographservices.tableFilters.submittedDriverCard',
    ),
    submittedVehicleIdentification: get(
        state,
        'tachographservices.tableFilters.submittedVehicleIdentification',
    ),
    overviewTable: get(
        state,
        'tachographservices.common.tachoSettings.overviewTable',
        {
            driver: {},
            vehicle: {},
        },
    ),
});

export const mapDispatchToProps = dispatch => ({
    setSelectedRows: payload => {
        dispatch(setSelectedRows(payload));
    },
    setTimeRange: payload => {
        dispatch(setTimeRangeAction(payload));
    },
    onSortChange: (sortField, sortOrder, isOverviewSelected = false) => {
        dispatch(setSortingBehaviour({ sortField, sortOrder, isOverviewSelected }));
    },
    fetchListFiles: async payload => {
        dispatch(setListFilesData(WAITINGFORDATA));
        dispatch(refetchArchiveData(false));
        await fetchListFiles(
            dispatch,
            payload,
        );
    },
    downloadAllFiles: async payload => {
        await downloadAllFiles(
            payload,
            dispatch,
        );
    },
    downloadFiles: async payload => {
        dispatch(addDownloadingFiles(payload));
        await downloadFiles(payload);
        dispatch(removeDownloadingFiles(payload));
    },
    setEventOnFilterClick: () => {
        dispatch(setEventOnFilterClickAction());
    },

    setFilterButtonShown: () => {
        dispatch(setTachoSettingAction({ didShowFilterButton: true }));
        setTachoSetting(
            getAccessToken(store.getState()),
            'didShowFilterButton',
            true,
        );
    },

    getDownloadIntervals: () => {
        getTachoDownloadIntervals(dispatch);
    },

    setDownloadInterval: payload => {
        dispatch(setCustomizedDownloadInterval());
        setTachoDownloadInterval(
            payload.ids,
            payload.interval,
            payload.isDriver,
            dispatch,
        );
    },
    setOverviewTableSettings: status => {
        dispatch(setTachoSettingAction({ overviewTable: status }));
        setTachoSetting(
            getAccessToken(store.getState()),
            'overviewTable',
            status,
        );
    },
    setSingleCard: payload => dispatch(setSingleCard(payload)),
    setMultiCards: payload => dispatch(setMultiCards(payload)),
    setEventOnTableView: () => dispatch(setEventOnTableViewAction()),
    toggleTransferType: filterType => dispatch(toggleTransferType(filterType)),
    toggleExportedTo: filterType => dispatch(toggleExportedTo(filterType)),
    abortTempFilters: payload => dispatch(abortTempFilters(payload)),
    setSearchedDriverCard: payload => dispatch(setSearchedDriverCardAction(payload)),
    setSearchedVehicleIdentification: payload => dispatch(setSearchedVehicleIdentificationAction(payload)),
    setSubmittedDriverCard: payload => dispatch(setSubmittedDriverCardAction(payload)),
    setSubmittedVehicleIdentification: payload => dispatch(setSubmittedVehicleIdentificationAction(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ArchiveContainer));
