import React from 'react';
import PropTypes from 'prop-types';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

const EmptyDataState = props => (
    <div className={'display-flex justify-content-center height-75vh align-items-center'}>
        <EmptyState
            key={`${props.baseKey}-empty-state`}
            headline={props.noDataHeadLine}
            message={props.noDataText}
        />
    </div>
);

EmptyDataState.propTypes = {
    baseKey: PropTypes.string.isRequired,
    noDataHeadLine: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    noDataText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

export default EmptyDataState;
