
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { env } from '../../env';

import {fetchData, forbidden, unauthorized } from '../../sagas/fetchUtils';

import { downloadFile } from '../../utils/exportUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getUrl = payload => {

    const baseUrl = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/Customers/`;

    const url = payload.length < 2 ? /* eslint-disable-line no-magic-numbers */
        `${baseUrl}FilesDownload/${encodeURIComponent(payload[0])}` :
        `${baseUrl}FilesDownload/`;

    return url;
};

const getParams = payload => {
    const params = payload.length < 2 ? /* eslint-disable-line no-magic-numbers */
        {} :
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };

    return params;
};

const downloadFiles = (
    payload,
    injectedFetch = fetchData,
) => injectedFetch(
    getUrl(payload),
    getParams(payload),
)
    .then(res => {
        if (res.error) {
            switch (res.error) {
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.fileExport'}
                        defaultMessage={
                            'File export failed. Please try again later.'
                        }
                    />);
            }
            throw new Error('downloadFiles call failed');
        }
        return res;
    })
    .then(result => {
        downloadFile(
            result.fileContent,
            result.type,
            result.name,
        );
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default downloadFiles;
