/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';

const maxButtons = 5;

const getLi = (page, activePage, onSelect) => (
    <li key={`pagination-button-${page}`} className={`${page === activePage ? 'active bg-light' : ''}`}>
        <a
            onClick={() => onSelect(page)}
            className={`${page === activePage ? 'text-color-dark' : 'text-color-gray hover-text-color-dark'}`}>
            {`${page}`}
        </a>
    </li>
);

const getStartingPageShifter = (activePage, itemCount) => { // eslint-disable-line max-statements
    if (activePage <= 1) {
        return 0;
    }

    if (activePage === 2) {
        return 1;
    }

    if (activePage === 3) {
        return 2;
    }

    if (activePage === itemCount - 1) {
        return 3;
    }

    if (activePage >= itemCount) {
        if (itemCount < maxButtons) {
            return itemCount - 1;
        }

        return 4;
    }

    return 2;
};

const getPagination = (items, activePage, onSelect) => {
    const buttonCount = items >= maxButtons ? maxButtons : items;
    const buttonArray = [];

    const startingPageShifter = getStartingPageShifter(
        activePage,
        items,
    );

    while (buttonArray.length < buttonCount) {
        buttonArray.push(activePage - startingPageShifter + buttonArray.length);
    }

    return (
        <ul className={'pagination'}>
            <li key={`pagination-button-first`} className={`${activePage === 1 ? 'disabled' : ''}`}>
                <a onClick={() => activePage === 1 ? {} : onSelect(1)}>
                    {`«`}
                </a>
            </li>

            {
                buttonArray[0] !== 1 && (
                    <li key={`pagination-button-first-points`} className={'disabled'}>
                        <a >
                            {`…`}
                        </a>
                    </li>
                )
            }

            { buttonArray.map(page => getLi(
                page,
                activePage,
                onSelect,
            )) }

            {
                buttonArray[buttonArray.length - 1] !== items && (
                    <li key={`pagination-button-last-points`} className={'disabled'}>
                        <a >
                            {`…`}
                        </a>
                    </li>
                )
            }

            <li
                key={`pagination-button-last`}
                className={`${activePage === items ? 'disabled' : ''}`}
                data-testid={`pagination-arrow-next-btn`}>
                <a onClick={() => activePage === items ? {} : onSelect(items)}>
                    {`»`}
                </a>
            </li>
        </ul>
    );
};

const PaginationComponent = props => {
    const { pageCount, activePage, loadNewPage } = props;

    const active = activePage ? activePage : 1;

    return (
        <div className={'PaginationComponent text-center'}>
            {
                getPagination(
                    pageCount > 0 ? pageCount : 1,
                    pageCount > 0 ? active : 1,
                    selectedPage => {
                        loadNewPage(selectedPage);
                    },
                )
            }
        </div>
    );
};

PaginationComponent.defaultProps = {
};

PaginationComponent.propTypes = {
    pageCount: PropTypes.number,
    activePage: PropTypes.number,
    loadNewPage: PropTypes.func,
};

export default PaginationComponent;
