
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.defaultDownloadIntervals.step1.title'}
            defaultMessage={'Settings Button'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.cloudStorageSettings.step2.title'}
            defaultMessage={'Cloud storage period'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.defaultDownloadIntervals.step1.text'}
                        defaultMessage={'Click on the Settings Button.'}
                    />
                </div>
                <ReleaseNotesImage image={'defaultDownloadIntervalsStep1.png'} widthClass={'max-width-100pct'}/>
            </div>;
        case 1:
            return <div>
                <div>
                    <FormattedMessage
                        id={'glossary.compliant.cloudStorageSettings.step2.text'}
                        defaultMessage={
                            'Edit the cloud storage period and click “Save”.'
                        }
                    />
                </div>
                <ReleaseNotesImage image={'cloudStorageSettingsStep2.png'} widthClass={'max-width-100pct'}/>
            </div>;
        default:
            return <div/>;
    }
};

const CloudStoragePeriodExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default CloudStoragePeriodExplanation;
