import {getRouteParam} from '../../../../src/features/utils/routing';

const getInitialState = context => ({ // eslint-disable-line max-lines-per-function
    search: '',
    limit: context.props.ROWLIMIT,
    tempChosenFilters: false,
    chosenFilters: {
        period: 'transferPeriod',
        onlyLatest: getRouteParam('onlyLatest') === 'true' || false,
        setOnlyLatest: context.setOnlyLatest.bind(context),
        transferType: {
            autoDownload: context.props.autoDownload,
            manualDownload: context.props.manualDownload,
            upload: context.props.upload,
            speedData: context.props.speedData,
            toggle: context.toggleTransferType.bind(context),
        },
        exportedTo: {
            notExported: context.props.notExported,
            computer: context.props.computer,
            partner: context.props.partner,
            api: context.props.api,
            toggle: context.toggleExportedTo.bind(context),
        },
    },
    downloadedFiles: {},
    forceRerenderRow: false,
});

export default getInitialState;
