
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import get from 'lodash/get';

import {
    setCompanyCardsSidebarOpen,
    setSendCompanyCardChanges,
    setSendCompanyCardDeletion,
    setSendCompanyCardRestoration,
} from '../../actions/administration';
import {getAccessToken} from '../../features/tokenHandling/selectors';

import {COMPANY_CARDS_TAB} from '../../constants/archive';

import { getLocale } from '../../common/tacho-lang/selectors';

import CompanyCardsSidebar from './CompanyCardsSidebar';

export const CompanyCardsSidebarContainer = props => (
    <ApplicationLayout.Sidebar className={'right'}>
        {
            props.isCompanyCardsSidebarOpen &&
            <CompanyCardsSidebar {...props}/>
        }
    </ApplicationLayout.Sidebar>
);

CompanyCardsSidebarContainer.propTypes = {
    isCompanyCardsSidebarOpen: PropTypes.bool,
};

export const mapDispatchToProps = dispatch => ({
    setCompanyCardsSidebarOpen: payload => {
        dispatch(setCompanyCardsSidebarOpen(payload));
    },
    sendChanges: payload => {
        dispatch(setSendCompanyCardChanges(payload));
    },
    sendDeletion: payload => {
        dispatch(setSendCompanyCardDeletion(payload));
    },
    sendRestoration: payload => {
        dispatch(setSendCompanyCardRestoration(payload));
    },
});

export const mapStateToProps = state => ({
    vehicles: get(
        state,
        'tachographservices.tree.vehicles',
        [],
    ),
    tbm3Vehicles: get(
        state,
        'tachographservices.common.tbm3Vehicles',
        [],
    ),
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
        [],
    ),
    isRDAOnline: get(
        state,
        'tachographservices.administration.isRDAOnline',
    ),
    isCompanyCardsSidebarOpen:
        window.location.hash.includes(COMPANY_CARDS_TAB) &&
        state.tachographservices.administration.isCompanyCardsSidebarOpen,
    companyCard: state.tachographservices.administration.editingCompanyCard,

    locale: getLocale(state),
    accessToken: getAccessToken(state),
    email: get(
        state,
        'login.userProfile.email',
        '',
    ),
    name: get(
        state,
        'login.userProfile.name',
        '',
    ),
    accountInfo: get(
        state,
        'tachographservices.common.accountInfo',
        '',
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompanyCardsSidebarContainer);
