const BASE = 'TACHOGRAPHSERVICES.ADMINISTRATION.';
export const SET_SELECTED_BUTTON = `${BASE}SET_SELECTED_BUTTON`;
export const SET_SELECTED_COMPANY_CARDS = `${BASE}SET_SELECTED_COMPANY_CARDS`;
export const SET_LOADING_RDA = `${BASE}SET_LOADING_RDA`;
export const DOWNLOAD_RDA = `${BASE}DOWNLOAD_RDA`;
export const FETCH_RDA_STATUS = `${BASE}FETCH_RDA_STATUS`;
export const SUCCESSFUL_FETCH_RDA_STATUS = `${BASE}SUCCESSFUL_FETCH_RDA_STATUS`;
export const SET_RDA_STATUS_FETCHED = `${BASE}SET_RDA_STATUS_FETCHED`;
export const SUCCESSFUL_FETCH_REQUESTED_LIST = `${BASE}SUCCESSFUL_FETCH_REQUESTED_LIST`;
export const FETCH_LIST_OF_VEHICLES = `${BASE}FETCH_LIST_OF_VEHICLES`;
export const SUCCESSFUL_FETCH_LIST_OF_VEHICLES = `${BASE}SUCCESSFUL_FETCH_LIST_OF_VEHICLES`;
export const SET_SUCCESSFUL_REQUEST_MANUAL_DOWNLOAD = `${BASE}SET_SUCCESSFUL_REQUEST_MANUAL_DOWNLOAD`;
export const SET_LIST_OF_REQUESTS = `${BASE}SET_LIST_OF_REQUESTS`;
export const DOWNLOAD_MANUAL = `${BASE}DOWNLOAD_MANUAL`;
export const SET_SETTINGS_SIDEBAR_OPEN = `${BASE}SET_SETTINGS_SIDEBAR_OPEN`;
export const SET_COMPANY_CARDS_SIDEBAR_OPEN = `${BASE}SET_COMPANY_CARDS_SIDEBAR_OPEN`;
export const SET_EDITING_COMPANY_CARD = `${BASE}SET_EDITING_COMPANY_CARD`;
export const SET_DEFAULT_INTERVAL_SETTINGS = `${BASE}SET_DEFAULT_INTERVAL_SETTINGS`;
export const SET_DEFAULT_DOWNLOAD_INTERVAL = `${BASE}SET_DEFAULT_DOWNLOAD_INTERVAL`;
export const SET_CUSTOMIZED_DOWNLOAD_INTERVAL = `${BASE}SET_CUSTOMIZED_DOWNLOAD_INTERVAL`;
export const SET_DOWNLOAD_INTERVALS = `${BASE}SET_DOWNLOAD_INTERVALS`;
export const SET_SEND_COMPANY_CARD_CHANGES = `${BASE}SET_SEND_COMPANY_CARD_CHANGES`;
export const SET_SEND_COMPANY_CARD_DELETION = `${BASE}SET_SEND_COMPANY_CARD_DELETION`;
export const SET_SEND_COMPANY_CARD_RESTORATION = `${BASE}SET_SEND_COMPANY_CARD_RESTORATION`;
export const SET_EVENT_ON_MANUAL_DOWNLOAD = `${BASE}SET_EVENT_ON_MANUAL_DOWNLOAD`;

export const setSelectedButton = number => ({
    type: SET_SELECTED_BUTTON,
    payload: number,
});

export const setSelectedCompanyCards = array => ({
    type: SET_SELECTED_COMPANY_CARDS,
    payload: array,
});

export const setLoadingRDA = bool => ({
    type: SET_LOADING_RDA,
    payload: bool,
});

export const downloadRDA = () => ({
    type: DOWNLOAD_RDA,
});

export const fetchRdaStatus = () => ({
    type: FETCH_RDA_STATUS,
});

export const successfulFetchRdaStatus = object => ({
    type: SUCCESSFUL_FETCH_RDA_STATUS,
    payload: object,
});

export const setRDAStatusFetched = () => ({
    type: SET_RDA_STATUS_FETCHED,
});

export const successfulFetchRequestedList = array => ({
    type: SUCCESSFUL_FETCH_REQUESTED_LIST,
    payload: array,
});

export const successfulFetchListOfVehicles = array => ({
    type: SUCCESSFUL_FETCH_LIST_OF_VEHICLES,
    payload: array,
});

export const setSuccessfulRequestDownload = bool => ({
    type: SET_SUCCESSFUL_REQUEST_MANUAL_DOWNLOAD,
    payload: bool,
});

export const setEventOnManualDownload = () => ({
    type: SET_EVENT_ON_MANUAL_DOWNLOAD,
});

export const setListOfRequests = array => ({
    type: SET_LIST_OF_REQUESTS,
    payload: array,
});

export const downloadManual = () => ({
    type: DOWNLOAD_MANUAL,
});

export const setSettingsSidebarOpen = bool => ({
    type: SET_SETTINGS_SIDEBAR_OPEN,
    payload: bool,
});

export const setCompanyCardsSidebarOpen = bool => ({
    type: SET_COMPANY_CARDS_SIDEBAR_OPEN,
    payload: bool,
});

export const setEditingCompanyCard = bool => ({
    type: SET_EDITING_COMPANY_CARD,
    payload: bool,
});

export const setDefaultIntervalSettings = object => ({
    type: SET_DEFAULT_INTERVAL_SETTINGS,
    payload: object,
});

export const setDefaultDownloadInterval = () => ({
    type: SET_DEFAULT_DOWNLOAD_INTERVAL,
});

export const setCustomizedDownloadInterval = () => ({
    type: SET_CUSTOMIZED_DOWNLOAD_INTERVAL,
});

export const setDownloadIntervals = object => ({
    type: SET_DOWNLOAD_INTERVALS,
    payload: object,
});

export const setSendCompanyCardChanges = object => ({
    type: SET_SEND_COMPANY_CARD_CHANGES,
    payload: object,
});

export const setSendCompanyCardDeletion = object => ({
    type: SET_SEND_COMPANY_CARD_DELETION,
    payload: object,
});

export const setSendCompanyCardRestoration = object => ({
    type: SET_SEND_COMPANY_CARD_RESTORATION,
    payload: object,
});
