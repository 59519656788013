
import PropTypes from 'prop-types';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';

const TooltipTrigger = props => {
    const { children, tooltip, baseKey, width } = props;

    if (!tooltip) {
        return <div>{ children }</div>;
    }

    return <OverlayTrigger
        placement={'top'}
        overlay={
            <Tooltip
                key={baseKey}
                id={baseKey}
                width={width || 'auto'}
            >
                { tooltip }
            </Tooltip>
        }
    >
        { children }
    </OverlayTrigger>;
};

TooltipTrigger.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node,
    ]),
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    baseKey: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default TooltipTrigger;
