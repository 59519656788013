
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import NotificationSettingsSidebar from '../../../components/About/notifications/NotificationSettingsSidebar';
import { setDisabledNotifications } from '../../../actions/notifications';
import setTachoSetting from '../../../fetches/Settings/setTachoSetting';
import { getAccessToken } from '../../../features/tokenHandling/selectors';
import store from '../../../setup/store';

export const NotificationsSettingsContainer = props => <NotificationSettingsSidebar {...props}/>;
NotificationsSettingsContainer.propTypes = {
    disabledNotifications: PropTypes.array,
    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
};

export const mapDispatchToProps = dispatch => ({
    setDisabledNotifications: payload => {
        dispatch(setDisabledNotifications(payload));
        setTachoSetting(
            getAccessToken(store.getState()),
            'disabledNotifications',
            payload,
        );
    },
});

export const mapStateToProps = state => ({
    disabledNotifications: state.tachographservices.notifications.disabledNotifications,
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationsSettingsContainer);
