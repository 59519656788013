/* eslint-disable default-param-last */
import Notification from '@rio-cloud/rio-uikit/Notification';

import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const returnCompanyCardFetch = (
    token,
    card = {},
    injectedFetch,
) => injectedFetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard/restore/${card.companyChipId || ''}`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(card),
    },
);

const restoreCompanyCard = (
    token,
    card = {},
    injectedFetch = window.fetch,
) => returnCompanyCardFetch(
    token,
    card,
    injectedFetch,
)
    .then(res => {
        if (res.ok) {
            Notification.success('Company card return request submitted');
            return res.json();
        }
        throw new Error('tacho restore company card call failed');
    })
    .catch(err => {
        Notification.error('Company card return request failed');
        console.log(err); // eslint-disable-line no-console
    });

export default restoreCompanyCard;
