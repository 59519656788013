/* eslint-disable react/prop-types */
import { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage } from 'react-intl';

import { RETENTION_PERIOD_MAX_MONTHS } from '../../../constants/administration';
import getFilesTotalCount from '../../../fetches/Files/getFilesTotalCount';

export const RetentionPeriodsContent = props => (
    <div>
        <h5 className={'margin-top-0'}>
            <FormattedMessage
                id={'administration.cloudStoragePeriodSettings'}
                defaultMessage={'Cloud storage period settings'}
            />
        </h5>
        <div className={'display-flex form-group'}>
            <span className={'rioglyph rioglyph-info-sign margin-right-5 text-size-20'}/>
            <FormattedMessage
                id={'administration.cloudStoragePeriodSettingsInfoText'}
            />
        </div>

        <div className={'display-flex form-group'}>
            <span className={'rioglyph rioglyph-info-sign margin-right-5 text-size-20'}/>
            <div>
                <div className={'margin-bottom-10'}>
                    <FormattedMessage
                        id={'administration.filesWillBeDeletedText'}
                        defaultMessage={
                            'According to your settings the following number of files will be deleted today:'
                        }
                    />
                </div>

                {
                    props.loading &&
                    <Spinner />
                }

                {
                    !props.loading &&
                    <div className={'tag margin-right-10'}>
                        <span className={'margin-right-5'}>
                            <span className={'rioglyph rioglyph-truck margin-right-5'}/>
                            <FormattedMessage
                                id={'tachographFiles'}
                                defaultMessage={'Tachograph files'}
                            />:
                        </span>
                        {props.retentionPeriodVehicles}
                    </div>
                }

                {
                    !props.loading &&
                    <div className={'tag'}>
                        <span className={'margin-right-5'}>
                            <span className={'rioglyph rioglyph-driver margin-right-5'}/>
                            <FormattedMessage
                                id={'driverCardFiles'}
                                defaultMessage={'Driver card files'}
                            />:
                        </span>
                        {props.retentionPeriodDrivers}
                    </div>
                }
            </div>
        </div>
        <div className={'form-group'}>
            <FormattedMessage
                id={'administration.confirmChangeSettings'}
                defaultMessage={'Are you sure that you want to change the current settings?'}
            />
        </div>
    </div>
);

export const IntervalsContent = () => (
    <div>
        <h5 className={'margin-top-0'}>
            <FormattedMessage
                id={'administration.downloadIntervalSettings'}
                defaultMessage={'Download interval settings'}
            />
        </h5>
        <FormattedMessage
            id={'administration.confirmChangeDownloadIntervalText'}
            defaultMessage={'Are you sure that you want to change the current settings?'}
        />
    </div>
);

const zero = 0;
const next = 1;

export const setPreMonths = (date, months) => {
    const newDate = new Date(date);
    newDate.setUTCMonth(newDate.getUTCMonth() - months);
    newDate.setUTCDate(newDate.getUTCDate() + next);
    newDate.setUTCHours(
        zero,
        zero,
        zero,
        zero,
    );
    return newDate;
};

class ConfirmDownloadIntervalDialog extends Component {
    constructor() {
        super();

        this.state = {
            loadingTriggered: false,
            loading: false,
            retentionPeriodVehicles: zero,
            retentionPeriodDrivers: zero,
        };
    }

    componentDidUpdate(prevProps) {
        const { open, retentionPeriodChanged, accessToken } = this.props;
        if (accessToken && open && retentionPeriodChanged && !this.state.loadingTriggered) {
            this.getDriverAndVehiclesFilesInArchive();
        }

        if (prevProps.retentionPeriodMonths !== this.props.retentionPeriodMonths) {
            this.setState({
                loadingTriggered: false,
            });
        }
    }

    getDriverAndVehiclesFilesInArchive() {
        const { retentionPeriodMonths} = this.props;
        const endDate = setPreMonths(
            new Date(),
            retentionPeriodMonths,
        );
        const startDate = setPreMonths(
            new Date(),
            RETENTION_PERIOD_MAX_MONTHS + next,
        );

        const loadVehicleFiles = getFilesTotalCount(
            startDate,
            endDate,
            'Vehicle',
        );
        const loadDriverFiles = getFilesTotalCount(
            startDate,
            endDate,
            'Driver',
        );
        const triggerLoading = new Promise(resolve => {
            this.setState({
                loadingTriggered: true,
                loading: true,
            });

            Promise
                .all([
                    loadVehicleFiles,
                    loadDriverFiles,
                ])
                .then(values => {
                    this.setState({
                        retentionPeriodVehicles: values[zero],
                        retentionPeriodDrivers: values[next],
                    });

                    resolve();
                })
                .catch(err => {
                    console.log(err); // eslint-disable-line no-console
                    resolve();
                });
        });

        Promise
            .resolve(triggerLoading)
            .then(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { retentionPeriodChanged, intervalSettingsChanged, open, confirm, abort } = this.props;
        const { retentionPeriodVehicles, retentionPeriodDrivers, loading } = this.state;
        return (
            <ConfirmationDialog
                title={<FormattedMessage id={'confirmSettings'} defaultMessage={'Confirm settings'}/>}
                content={
                    <div>
                        {
                            retentionPeriodChanged &&
                            <RetentionPeriodsContent
                                retentionPeriodVehicles={retentionPeriodVehicles}
                                retentionPeriodDrivers={retentionPeriodDrivers}
                                loading={loading}
                            />
                        }
                        { retentionPeriodChanged && intervalSettingsChanged && <hr/> }
                        { intervalSettingsChanged && <IntervalsContent/> }
                    </div>
                }
                show={open}
                onClickConfirm={confirm}
                onClickCancel={abort}
                cancelButtonText={<FormattedMessage id={'no'} defaultMessage={'No'}/>}
                confirmButtonText={<FormattedMessage id={'yes'} defaultMessage={'Yes'}/>}
            />
        );
    }
}

ConfirmDownloadIntervalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    abort: PropTypes.func.isRequired,
    intervalSettingsChanged: PropTypes.bool,
    retentionPeriodChanged: PropTypes.bool,
    retentionPeriodMonths: PropTypes.number,
};

export default ConfirmDownloadIntervalDialog;
