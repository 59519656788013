import React, { Component } from 'react';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

class ErrorPage extends Component {

    // eslint-disable-next-line class-methods-use-this
    render() {
        return <ErrorState
            headline={'Oh snap! Something went wrong.'}
            message={'Something went wrong please try again later, If the issue still persists please contact support'}
            buttons={[
                {
                    text: <span>Try again</span>,
                    className: 'foobar',
                    onClick: () => window.location.reload(false),
                },
            ]}
        />;
    }
}

export default ErrorPage;
