import { env } from '../../env';
import { setTBM3Vehicles } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';

const { VEHICLES_WITH_SUBSCRIPTION_PATH } = env.runtimeConfig.backend;

const getTBM3VehiclesFetch = (
    token,
    injectedFetch = window.fetch,
) => () => injectedFetch(
    `${VEHICLES_WITH_SUBSCRIPTION_PATH}?vehicleType=tbm3`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    store.dispatch(setTBM3Vehicles(data));
};

const configureFetchTBM3Vehicles = store => new Promise(resolve => {
    const fetchVehiclesWithSubscription = getTBM3VehiclesFetch(store.getState().tokenHandling.accessToken);

    saveFetch(
        fetchVehiclesWithSubscription,
        'tbm3 vehicles',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchTBM3Vehicles;
