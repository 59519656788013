/* eslint-disable default-param-last */
import { env } from '../../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getNumberOfFilesRawFetch = (
    token,
    body = {},
    injectedFetch,
) => injectedFetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/Customers/Files?limit=1`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    },
);

const getNumberOfFilesFetch = (
    token,
    body = {},
    injectedFetch = window.fetch,
) => getNumberOfFilesRawFetch(
    token,
    body,
    injectedFetch,
)
    .then(res => {
        if (res.ok) {
            return res.json();
        }

        throw new Error('tacho getNumberOfFiles call failed');
    })
    .then(data => data.totalCount)
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getNumberOfFilesFetch;
