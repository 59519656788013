import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Switch from '@rio-cloud/rio-uikit/Switch';

import { notificationTypes } from '../../../reducers/notifications';

import { rowClass, lastRowClass } from '../../../views/Settings/IntervalSettings/IntervalSettingsSidebar';
import getNoOfSubscribedVehicles from '../../../utils/getNoOfSubscribedVehicles';
import { shouldEnableRDA } from '../../../utils/shouldEnableRDA';

class NotificationsSettingsSidebar extends Component {
    getSwitchLi(id, defaultMessage, values) {
        return (
            <li className={'list-group-item'}>
                <Switch
                    checked={!this.props.disabledNotifications.includes(id)}
                    onChange={
                        () => this.props.disabledNotifications.includes(id) ?
                            this.props.setDisabledNotifications(this.props.disabledNotifications
                                .filter(type => type !== id)) :
                            this.props.setDisabledNotifications([
                                ...this.props.disabledNotifications,
                                id,
                            ])
                    }
                    labelPosition={'left'}
                >
                    <FormattedMessage
                        id={`notifications.${id}`}
                        defaultMessage={defaultMessage}
                        values={values}
                    />
                </Switch>
            </li>
        );
    }

    getNotificationTypeForm() {
        const active = notificationTypes.length !== this.props.disabledNotifications.length;

        return (
            <div className={`${rowClass} padding-bottom-0`}>
                <div className={'SidebarHeader padding-0 height-25 display-flex justify-content-between'}>
                    <FormattedMessage
                        id={'notifications'}
                        defaultMessage={'Notifications'}
                    />
                    <Switch
                        checked={active}
                        onChange={
                            () => active ?
                                this.props.setDisabledNotifications(notificationTypes) :
                                this.props.setDisabledNotifications([])
                        }
                    />
                </div>
            </div>
        );
    }

    getCompanyCardsForm() {
        return (
            <div className={rowClass}>
                <div className={'SidebarHeader padding-0 height-25'}>
                    <FormattedMessage
                        id={'archive.companyCards'}
                        defaultMessage={'Company Cards'}
                    />
                </div>
                {
                    (getNoOfSubscribedVehicles(
                        '1',
                        this.props.vehiclesWithSubscription,
                    ) !== 0 || shouldEnableRDA()) && <ul className={'list-group border rounded'}>
                        {
                            this.getSwitchLi(
                                'rdaOffline',
                                'No {rda} online',
                                { rda: 'Remote Download Application'},
                            )
                        }
                        { this.getSwitchLi(
                            'noCompanyCardsOnline',
                            'No company card online',
                        ) }
                    </ul>
                }

                {
                    <ul className={'list-group border rounded'}>
                        {this.getSwitchLi(
                            'companyCardExpired',
                            'Card is expired',
                        )}
                        {this.getSwitchLi(
                            'companyCardAboutToExpire',
                            'Card is about to expire',
                        )}
                        {
                            this.getSwitchLi(
                                'vehiclesNotAssignedToAnyCard',
                                'Vehicles not assigned to any company card',
                            )
                        }
                        {
                            this.getSwitchLi(
                                'companyCardsWithoutAssignedVehicles',
                                'Company cards without assigned vehicles',
                            )
                        }
                        {
                            this.getSwitchLi(
                                'companyCardsUnavailable',
                                'Company cards status unavailable',
                            )
                        }
                    </ul>
                }
            </div>
        );
    }

    getDueDateForm() {
        return (
            <div className={lastRowClass}>
                <div className={'SidebarHeader padding-0 height-25'}>
                    <FormattedMessage
                        id={'notifications.dueDate'}
                        defaultMessage={'Due Date'}
                    />
                </div>
                <ul className={'list-group border rounded'}>
                    { this.getSwitchLi(
                        'vehiclesDueDateWarning',
                        'Vehicles will reach due date',
                    ) }
                    { this.getSwitchLi(
                        'vehiclesDueDateDanger',
                        'Vehicles exceeded due date',
                    ) }
                    { this.getSwitchLi(
                        'driversDueDateWarning',
                        'Drivers will reach due date',
                    ) }
                    { this.getSwitchLi(
                        'driversDueDateDanger',
                        'Drivers exceeded due date',
                    ) }
                </ul>
            </div>
        );
    }

    render() {
        return (
            <Sidebar {...this.props.sidebarProps}>
                { this.props.sidebarNavigation }

                <div className={'NotificationSwitches'}>
                    { this.getNotificationTypeForm() }
                    { this.getCompanyCardsForm() }
                    { this.getDueDateForm() }
                </div>
            </Sidebar>
        );
    }
}

NotificationsSettingsSidebar.defaultProps = {
    sidebarProps: { closed: true },
    disabledNotifications: [],
    setDisabledNotifications: () => {},
};

NotificationsSettingsSidebar.propTypes = {
    intl: PropTypes.object,
    accessToken: PropTypes.string.isRequired,

    disabledNotifications: PropTypes.array,
    setDisabledNotifications: PropTypes.func,

    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
    vehiclesWithSubscription: PropTypes.array,
};

export default injectIntl(NotificationsSettingsSidebar);
