/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

import ImagePreloader from '@rio-cloud/rio-uikit/ImagePreloader';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

export const getChildren = props => ({ status, image }) => {
    if (status === ImagePreloader.STATUS_LOADED) {
        // When image has been loaded, render the image tag
        return <img
            key={image.src}
            className={
                `margin-top-20 ${props.widthClass} border-style-solid border-width-1 border-color-lighter fade-in-15`
            }
            src={image.src}
            alt={props.alt ? props.alt : props.image}
        />;
    }

    if (status === ImagePreloader.STATUS_FAILED) {
        return <div className={'text-center'}>
            {props.alt ? props.alt : props.image}
        </div>;
    }

    return <div className={`display-flex justify-content-center align-items-center min-height-50vh`}>
        <Spinner/>
    </div>;
};

const ReleaseNotesImage = props => <ImagePreloader
    src={`./static/${props.image}`}
    className={`margin-top-20 border-style-solid border-width-1 border-color-lighter`}
>
    { getChildren(props) }
</ImagePreloader>;

ReleaseNotesImage.defaultProps = {
    widthClass: 'width-100pct',
};

ReleaseNotesImage.propTypes = {
    widthClass: PropTypes.string,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string,
};

export default ReleaseNotesImage;
