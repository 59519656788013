/* eslint-disable default-param-last */

import {setRDASupported } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';
import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getRDASupported = (
    store,
    fetch = window.fetch,
) => () => fetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/RdaSetting`,
    {
        headers: {
            Authorization: `Bearer ${`${store.getState().tokenHandling.accessToken}`}`,
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    store.dispatch(setRDASupported(data));
};

const configureFetchRdaSupported = store => new Promise(resolve => {
    const rdaSupported = getRDASupported(store);

    saveFetch(
        rdaSupported,
        'RDA Supported',
        getHandleData(store),
        resolve,
        () => {},
    );
});

export default configureFetchRdaSupported;
