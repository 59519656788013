/* eslint-disable default-param-last */

import { setAccountInfo } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';

const getAccountInfoFetch = (
    store,
    fetch = window.fetch,
) => () => fetch(
    `https://api.iam.rio.cloud/accounts/${`${store.getState().login.userProfile.account}`}`,
    {
        headers: {
            Authorization: `Bearer ${`${store.getState().tokenHandling.accessToken}`}`,
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    store.dispatch(setAccountInfo(data));
};

const configureFetchAccountInfo = store => new Promise(resolve => {
    const accountInfo = getAccountInfoFetch(store);

    saveFetch(
        accountInfo,
        'account info',
        getHandleData(store),
        resolve,
        () => {},
    );
});

export default configureFetchAccountInfo;
