/* eslint-disable default-param-last*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import sumBy from 'lodash/fp/sumBy';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import TableCardsSorting from '@rio-cloud/rio-uikit/TableCardsSorting';

import TableRow from './TableRow';
import EmptyDataState from './EmptyDataState';
import {
    DATA_ATTRIBUTE,
    getSortOrder,
    renderTableCol,
    getTextAlignClass,
    highlightRow,
    removeHighlightFromRow,
    selectRow,
    deselectRow,
    setHeaderCheckboxState,
} from './TableUtils';

const STOPPING_NODES = ['A', 'BUTTON', 'INPUT', 'LABEL'];
const STOPPING_CLASSES = ['btn'];

const noElement = -1;

export default class Table extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sortField: props.sortField || '',
            sortOrder: props.sortOrder || 'asc',
            selectedRowIds: (props.selectedRowIds || []).map(rowId => `${rowId}`),

            columnOrder: props.columnOrder,
            hiddenColumns: props.hiddenColumns,
            columnsDetails: props.columnsDetails,
        };

        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleCardSortChange = this.handleCardSortChange.bind(this);
        this.handleActiveRowChange = this.handleActiveRowChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleToggleAll = this.handleToggleAll.bind(this);
    }

    componentDidMount() {
        this.updateRows();
    }

    componentDidUpdate() {
        this.updateRows();

        if (this.getShouldRerenderRows()) {
            const {
                columnOrder,
                hiddenColumns,
                columnsDetails,
            } = this.props;

            this.setState({
                columnOrder,
                hiddenColumns,
                columnsDetails,
            });

            if (typeof this.props.onRerenderRow === 'function') {
                this.props.onRerenderRow();
            }
        }
    }

    updateRows() {
        const { lastActiveRowId, activeRowId, selectedRowIds = [] } = this.state;

        if (!this.tableRef) {
            return;
        }

        const rows = [...this.tableRef.rows];

        if (lastActiveRowId !== activeRowId) {
            removeHighlightFromRow(
                rows,
                lastActiveRowId,
            );
            highlightRow(
                rows,
                activeRowId,
            );
        }

        selectedRowIds.forEach(rowId => selectRow(
            rows,
            rowId,
            DATA_ATTRIBUTE,
        ));

        setHeaderCheckboxState(
            rows,
            selectedRowIds,
        );
    }

    handleActiveRowChange(event) { // eslint-disable-line max-statements

        // rejects on STOPPING - tags or classes
        if (event.type === 'click') {
            const hasWrongClass = STOPPING_CLASSES
                .some(className => event.target.classList.contains(className));

            if (
                STOPPING_NODES.indexOf(event.target.nodeName) !== noElement ||
                hasWrongClass ||
                event.defaultPrevented
            ) {
                return false;
            }
        }

        event.preventDefault();
        event.stopPropagation();

        if (this.props.enableSelection) {
            const { activeRowId } = this.state;
            const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

            this.handleSelection(event);
            this.setState(() => ({
                lastActiveRowId: activeRowId,
                activeRowId: activeRowId === rowId ? '' : rowId,
            }));
        }
        return true;
    }

    handleSelection(event) { // eslint-disable-line max-statements
        const { selectedRowIds } = this.state;
        const selectedRowId = `${event.currentTarget.getAttribute(DATA_ATTRIBUTE)}`;

        if (selectedRowIds.includes(selectedRowId)) {
            const newSelectedRowIds = selectedRowIds.filter(rowId => rowId !== selectedRowId);

            this.setState(() => ({
                selectedRowIds: newSelectedRowIds,
            }));
            this.deselectRows([selectedRowId]);

            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(newSelectedRowIds);
            }

        } else if (this.props.multiSelect) {
            const newSelectedRowIds = [...this.state.selectedRowIds, selectedRowId];

            this.setState(() => ({
                selectedRowIds: newSelectedRowIds,
            }));

            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(newSelectedRowIds);
            }
        } else {
            const newSelectedRowIds = [selectedRowId];

            this.setState(() => ({
                selectedRowIds: newSelectedRowIds,
            }));

            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(newSelectedRowIds);
            }
        }
    }

    // eslint-disable-next-line max-statements
    handleToggleAll(shouldSelect) {
        const allCurrentPageRowIds = this.props.isOverviewSelected ?
            this.props.data.map(item => `${item[this.props.keyField]}`) :
            this.props.data.filter(row => row.isDownloadPermitted === true).map(item => `${item[this.props.keyField]}`);
        const { selectedRowIds } = this.state;

        const newSelectedRowIds = [...new Set(selectedRowIds.concat(allCurrentPageRowIds))];

        // Deselect all rows when there is at least one row selected, else select all rows
        if (shouldSelect) {
            this.setState(() => ({ selectedRowIds: newSelectedRowIds }));
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(newSelectedRowIds);
            }
        } else {
            this.setState(() => ({ selectedRowIds: [] }));
            this.deselectRows(newSelectedRowIds);
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange([]);
            }
        }
    }

    deselectRows(rowIds) {
        const rows = [...this.tableRef.rows];
        rowIds.forEach(rowId => deselectRow(
            rows,
            rowId,
            DATA_ATTRIBUTE,
        ));
    }

    handleSortChange(event) {
        const sortField = event.currentTarget.getAttribute('data-sortfield');

        if (this.props.onSortChange) {
            this.props.onSortChange(
                sortField,
                getSortOrder(
                    sortField,
                    this.props.sortField,
                    this.props.sortOrder,
                ),
            );
        } else {
            this.setState({
                sortField,
                sortOrder: getSortOrder(
                    sortField,
                    this.state.sortField,
                    this.state.sortOrder,
                ),
            });
        }
    }

    handleCardSortChange(sortField, sortOrder) {
        if (this.props.onSortChange) {
            this.props.onSortChange(
                sortField,
                sortOrder,
            );
        } else {
            this.setState({ sortField, sortOrder });
        }
    }

    // May be extracted as a dedicated component but for demo purpose it's shown here
    renderTableHead(column, label, columnDetails = {}, sortField, sortOrder) {
        const tableHeadClassNames = 'user-select-none sort-column';

        const arrows = sortField === column ? <SortArrows direction={sortOrder} /> : <SortArrows />;

        const textAlignClass = getTextAlignClass(columnDetails);
        const dataSortClass = columnDetails.dataSort ? tableHeadClassNames : '';
        const thClassName = columnDetails.thClassName ? columnDetails.thClassName : '';
        const columnClass = `${dataSortClass} ${thClassName} ${textAlignClass}`;

        return (
            <th
                key={column}
                className={columnClass}
                onClick={
                    columnDetails.dataSort ?
                        this.handleSortChange :
                        () => {} // eslint-disable-line no-empty-function
                }
                data-field={column}
                data-sortfield={column}
                style={columnDetails.thStyle}
            >
                <div
                    style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {columnDetails.dataSort && arrows}
                    <span
                        className={columnDetails.dataSort && 'th-sort-label'}
                        data-testid ={columnDetails.dataSort && `th-sort-label-${column}`}>{label}</span>
                </div>
            </th>
        );
    }

    getShouldRerenderRows() {
        const {
            columnOrder,
            hiddenColumns,
            columnsDetails,
            forceRerenderRow,
        } = this.props;

        if (forceRerenderRow) {
            return true;
        }

        if (columnOrder && !isEqual(
            columnOrder,
            this.state.columnOrder,
        )) {
            return true;
        }

        if (hiddenColumns && !isEqual(
            hiddenColumns,
            this.state.hiddenColumns,
        )) {
            return true;
        }

        return !!(columnsDetails && !isEqual(
            columnsDetails,
            this.state.columnsDetails,
        ));
    }

    render() { // eslint-disable-line max-lines-per-function, max-statements, complexity
        const {
            columnOrder,
            columnLabels,
            hiddenColumns,
            viewType,
            columnsDetails,
            enableSelection,
            isOverviewSelected,
            multiSelect,
            onSortChange,
            data,
            selectAllText,
            deSelectAllText,
            additionalBatchButtonItems,

            keyField,
            baseKey,
            noDataHeadLine,
            noDataText,
            trClassName,
            className,
        } = this.props;

        const {
            sortField,
            sortOrder,
        } = onSortChange ? this.props : this.state;

        // filter for hidden columns
        const columns = columnOrder.filter(name => !hiddenColumns.includes(name));

        // Sort rows according to the sortField and sortOrder settings
        const rows = data;

        const tableClassNames =
            'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled' +
            ' width-100pct ' +
            `${viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD ? 'table-cards table-single-card ' : ''}` +
            `${viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS ? 'table-cards table-multi-cards ' : ''}`;

        const cardSortingSelectOptions = columns
            .filter(column => columnsDetails[column] && columnsDetails[column].dataSort)
            .filter(column => !hiddenColumns.includes(column))
            .map(column => ({
                id: column,
                label: columnLabels[column],
                selected: column === sortField,
                disabled: false,
            }));

        const selectionBatchButtonItems =
            multiSelect ?
                [
                    {
                        value: <div>{selectAllText}</div>,
                        onSelect: () => this.handleToggleAll(true),
                    }, {
                        value: <div>{deSelectAllText}</div>,
                        onSelect: () => this.handleToggleAll(false),
                    },
                ] :
                [];

        const customBatchButtonItems =
            additionalBatchButtonItems && additionalBatchButtonItems.length ?
                [...additionalBatchButtonItems] :
                [];

        if (selectionBatchButtonItems.length && customBatchButtonItems.length) {
            customBatchButtonItems.push({ divider: true });
        }

        const batchButtonItems = [
            ...customBatchButtonItems,
            ...selectionBatchButtonItems,
        ];

        const batchButton = (
            <ButtonDropdown
                title={<span className={'rioglyph rioglyph rioglyph-checkboxes'} />}
                className={'btn-s inline-block'}
                id={'customBatchButton'}
                bsStyle={'link'}
                iconOnly
                items={batchButtonItems}
                dropdownClassName={'DropdownFitContent text-normal'}
            />
        );

        const fullWidth = sumBy(
            columnsDetails || [],
            'width',
        );

        return (
            <div
                id={`${this.props.baseKey}-table`}
                style={{
                    width: '100%',
                }}
                className={className}
            >
                {
                    rows.length ?
                        <div
                            style={{
                                minWidth: `${fullWidth}px`,
                            }}
                        >
                            {
                                sortField &&
                                viewType &&
                                viewType !== TableViewToggles.VIEW_TYPE_TABLE &&
                                <TableCardsSorting
                                    selectOptions={cardSortingSelectOptions}
                                    sortName={sortField}
                                    sortOrder={sortOrder}
                                    onSortChange={this.handleCardSortChange}
                                    className={multiSelect && 'multiselect-table'}
                                />
                            }
                            <div>
                                <table
                                    // eslint-disable-next-line no-return-assign
                                    ref={node => this.tableRef = node}
                                    className={tableClassNames}
                                    data-testid={`test-${tableClassNames}`}>
                                    <colgroup>
                                        {
                                            multiSelect &&
                                            <col className={'table-checkbox'}/>
                                        }
                                        {
                                            columns.map(column => renderTableCol(
                                                column,
                                                columnsDetails[column] || {},
                                            ))
                                        }
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            {
                                                multiSelect &&
                                            <th className={'table-checkbox'}>
                                                {batchButtonItems.length && batchButton}
                                            </th>
                                            }
                                            {
                                                columns.map(column => this.renderTableHead(
                                                    column,
                                                    columnLabels[column],
                                                    columnsDetails[column] || {},
                                                    sortField,
                                                    sortOrder,
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map(row => (
                                            <TableRow
                                                keyField={keyField}
                                                key={`row-${row[keyField]}`}
                                                shouldRerender={this.getShouldRerenderRows()}
                                                row={row}
                                                columns={columns}
                                                colLabels={columnLabels}
                                                columnsDetails={columnsDetails}
                                                trClassName={trClassName}
                                                enableSelection={enableSelection}
                                                isOverviewSelected = {isOverviewSelected}
                                                multiSelect={multiSelect}
                                                onClick={this.handleActiveRowChange}
                                                onSelectCheckbox={this.handleSelection}
                                            />
                                        ))}

                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                        <tr className={'table-card-placeholder'}/>
                                    </tbody>
                                </table>
                            </div>

                        </div> :
                        <EmptyDataState
                            baseKey={`empty-data-table-${baseKey}`}
                            key={`empty-data-table-${baseKey}`}
                            noDataHeadLine={noDataHeadLine}
                            noDataText={noDataText}
                        />
                }
            </div>
        );
    }
}

Table.defaultProps = {
    data: [],
    keyField: 'id',
    baseKey: 'table',

    columnOrder: [],
    columnLabels: {},
    hiddenColumns: [],
    columnsDetails: {},
};

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyField: PropTypes.string.isRequired,
    baseKey: PropTypes.string.isRequired,
    className: PropTypes.string,

    columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnLabels: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    columnsDetails: PropTypes.object,

    viewType: PropTypes.string,

    selectedRowIds: PropTypes.array,
    enableSelection: PropTypes.bool,
    isOverviewSelected: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    multiSelect: PropTypes.bool,
    selectAllText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    deSelectAllText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),

    onSortChange: PropTypes.func,
    sortField: PropTypes.string,
    sortOrder: PropTypes.string,

    trClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),

    noDataHeadLine: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    noDataText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),

    additionalBatchButtonItems: PropTypes.arrayOf(PropTypes.object),

    forceRerenderRow: PropTypes.bool,
    onRerenderRow: PropTypes.func,

};

