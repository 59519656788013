import { cloneDeep, get } from 'lodash/fp';

import { env } from '../env';

export const getConfig = get('config');

export const getApiGateway = get('config.apiGateway');

export const getHomeRoute = get('config.homeRoute');

export const getLoginClientId = get('config.login.clientId');

export const getLoginCallbackUri = get('config.login.callbackUri');

export const getAuthenticationBackend = get('config.backend.AUTHENTICATION_SERVICE');

export const getTachoBackend = get('config.backend.TACHOGRAPH_SERVICES_SERVICE');

export const getAppsBackend = get('config.backend.APPS_JSON');

const initial = cloneDeep(env.runtimeConfig);

const configReducer = (state = initial, action = {}) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default configReducer;
