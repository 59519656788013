/* eslint-disable camelcase, immutable/no-mutation */
import { Children } from 'react';
import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';
import root from 'window-or-global';
import findLast from 'lodash/findLast';

import { env } from '../../env';

import { isUserBlacklisted } from '../../constants/analytics';

const { isRunningOnProd } = env;

const Analytics = props => Children.only(props.children);

export function reduceProps(propsList) {
    const { page } = findLast(
        propsList,
        'page',
    ) || {};

    // istanbul ignore next
    return { page, rootObject: root, useAnalytics: isRunningOnProd && !isUserBlacklisted() };
}

export function handleStateChangeOnClient(props) {
    const { page, rootObject, useAnalytics } = props;

    // istanbul ignore next
    if (useAnalytics && page && rootObject.dataLayer && !isUserBlacklisted()) {
        const url = `https://remotedownload.rio.cloud/#${page}`;
        if (rootObject.page_path !== url) {
            rootObject.page_path = url;
            rootObject.dataLayer.push({
                event: 'pageview',
                page_location: url,
                page_title: 'MAN Compliant',
            });
        }
    }
}

Analytics.propTypes = {
    page: PropTypes.string.isRequired,
};

export default withSideEffect(
    reduceProps,
    handleStateChangeOnClient,
)(Analytics);
