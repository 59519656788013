import { combineReducers } from 'redux';

import about from './about';
import administration from './administration';
import archive from './archive';
import common from './common';
import notifications from './notifications';
import tree from './tree';
import tableFilters from './tableFilters';

export default combineReducers({
    about,
    administration,
    archive,
    common,
    notifications,
    tree,
    tableFilters,
});
