
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';

import { refetchArchiveData } from '../../actions/archive';

import { TACHO_DOWNLOAD_INTERVALS_PATH } from './getTachoDownloadIntervals';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const setTachoDownloadIntervalsFetch = (
    token,
    value,
    injectedFetch,
) => injectedFetch(
    `${TACHOGRAPH_SERVICES_SERVICE}${TACHO_DOWNLOAD_INTERVALS_PATH}`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(value),
    },
);

const setTachoDownloadIntervals = (
    token,
    value,
    dispatch,
    displayNotification = false,
    injectedFetch = window.fetch,
    injectedNotification = Notification,
) => setTachoDownloadIntervalsFetch(
    token,
    value,
    injectedFetch,
)
    .then(res => {
        if (displayNotification) {
            if (res.ok) {
                injectedNotification.success(<FormattedMessage id={'saved'} defaultMessage={'Saved'}/>);
            } else {

                injectedNotification.error(<FormattedMessage
                    id={'saveTachoSettingFailed'}
                    defaultMessage={'Saving failed'}
                />);
            }
        }

        if (res.ok) {
            dispatch(refetchArchiveData(true));
        }
    })
    .catch(err => {
        if (displayNotification) {
            injectedNotification.error(<FormattedMessage
                id={'savingFailed'}
                defaultMessage={'Saving failed'}
            />);
        }
        console.log(err); // eslint-disable-line no-console
    });

export default setTachoDownloadIntervals;
