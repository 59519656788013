import { connect } from 'react-redux';

import AnalysisPortalSettingsSidebar from './AnalysisPortalSettingsSidebar';

export const AnalysisPortalSettingsContainer = props => <AnalysisPortalSettingsSidebar {...props}/>;
AnalysisPortalSettingsContainer.propTypes = {

};

// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = dispatch => ({

});

// eslint-disable-next-line no-unused-vars
export const mapStateToProps = state => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalysisPortalSettingsContainer);
