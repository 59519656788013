import { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getCompanyCards from '../../../fetches/CompanyCards/getCompanyCards';
import getOverviewFiles from '../../../fetches/Files/getOverviewFiles';
import { setOverviewFiles } from '../../../actions/notifications';
import { getAccessToken } from '../../../features/tokenHandling/selectors';
import { notificationTypes } from '../../../reducers/notifications';

import store from '../../../setup/store';

import { setSettingsSidebarOpen } from '../../../actions/administration';

import NotificationDialog from './NotificationDialog';
import getRDAStatus from './../../../fetches/RDA/getRDAStatus';

export class NotificationContainerComponent extends Component {
    componentDidMount() {
        if (this.props.permissions.includes('rda-assign.read')) {
            this.fetchCompanyCards();
        }

        if (this.props.permissions.includes('rda-installer.get')) {
            this.props.getRDAStatus();
        }
        this.fetchOverviewFiles();
    }

    fetchCompanyCards() {
        this.props.getCompanyCards();
    }

    fetchOverviewFiles() {
        getOverviewFiles()
            .then(items => {
                if (items && items.length) {
                    this.props.setOverviewFiles(items);
                }
            });
    }

    render() {

        const disabled = this.props.disabledNotifications.length === this.props.notificationTypes.length;

        return (
            <NotificationDialog
                show={this.props.show}
                toggleDialog={this.props.toggleDialog}
                errorNotifications={this.props.errorNotifications}
                warningNotifications={this.props.warningNotifications}
                infoNotifications={this.props.infoNotifications}
                areNotificationsDisabled={disabled}
                openIntervalSettings={this.props.openIntervalSettings}
            />
        );
    }
}

NotificationContainerComponent.propTypes = {
    toggleDialog: PropTypes.func,
    show: PropTypes.bool,
    accessToken: PropTypes.string,
    errorNotifications: PropTypes.array,
    warningNotifications: PropTypes.array,
    infoNotifications: PropTypes.array,
    setOverviewFiles: PropTypes.func,
    permissions: PropTypes.array,
    getRDAStatus: PropTypes.func,
    disabledNotifications: PropTypes.array,
    openIntervalSettings: PropTypes.func,
    notificationTypes: PropTypes.array,
    getCompanyCards: PropTypes.func,
};

NotificationContainerComponent.defaultProps = {
    notificationTypes,
};

export const mapStateToProps = state => ({
    errorNotifications: state.tachographservices.notifications.errorNotifications,
    warningNotifications: state.tachographservices.notifications.warningNotifications,
    infoNotifications: state.tachographservices.notifications.infoNotifications,
    accessToken: getAccessToken(state),
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    disabledNotifications: get(
        state,
        'tachographservices.notifications.disabledNotifications',
        [],
    ),

});

export const mapDispatchToProps = dispatch => ({

    setOverviewFiles: payload => {
        dispatch(setOverviewFiles(payload));
    },

    getRDAStatus: () => {
        getRDAStatus(
            store.getState().tachographservices.administration,
            dispatch,
        );
    },

    getCompanyCards: () => {
        getCompanyCards(dispatch);
    },

    openIntervalSettings: () => {
        dispatch(setSettingsSidebarOpen(true));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationContainerComponent);
