import { env } from '../../env';

import { fetchData } from '../../sagas/fetchUtils';

import { setCompanyCards, setSuccessfulCompanyCardRequest } from '../../actions/notifications';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard`;

const getCompanyCards = (dispatch, injectedFetch = fetchData) => injectedFetch(url)
    .then(res => {
        if (res.error) {
            dispatch(setSuccessfulCompanyCardRequest(false));
            dispatch(setCompanyCards({
                unassignedEquipmentIds: [],
                companyAssignedVehicleData: [],
            }));

            throw new Error('tacho company cards call failed');
        }

        return res;
    })
    .then(result => {
        dispatch(setSuccessfulCompanyCardRequest(true));
        dispatch(setCompanyCards(result));
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getCompanyCards;

