import { Component } from 'react';
import PropTypes from 'prop-types';
import NumberInput from '@rio-cloud/rio-uikit/NumberInput';
import Slider from '@rio-cloud/rio-uikit/Slider';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import reduce from 'lodash/reduce';

import ConfirmDownloadIntervalDialog from './ConfirmDownloadIntervalDialog';

const BIG_DIALOG_ASSET_COUNT = 24;

export const getAssets = assets => assets.map(asset => (
    <div
        key={`editDownloadInterval-asset-${asset.key}`}
        className={
            'display-inline-block border-width-1 border-color-light border-style-solid padding-10 ' +
                    'margin-right-10 margin-bottom-10'
        }
    >
        <span className={`rioglyph rioglyph-${asset.category} padding-right-5`}/>
        <span>{asset.asset}</span>
    </div>
));

const getVehicleInfo = asset => asset.vehicleInfo && asset.vehicleInfo.externalEquipmentReference;
const getDriverInfo = asset => asset.driverInfo && asset.driverInfo.driverCardNumber;

export const getAssetInfo = asset => getVehicleInfo(asset) || getDriverInfo(asset);

const DEFAULT_DOWNLOAD_INTERVAL = 10;

class EditDownloadIntervalDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentValue: props.downloadInterval,
            confirmDialogOpen: false,
        };

        this.changeValue = this.changeValue.bind(this);
        this.confirm = this.confirm.bind(this);
        this.abortConfirmation = this.abortConfirmation.bind(this);
        this.completeConfirmation = this.completeConfirmation.bind(this);
    }

    getSelectedDownloadInterval() {
        const allSelectedIntervals = {};
        this.props.selectedAssets.forEach(assetInfo => {
            if (assetInfo.downloadInterval && !Number.isNaN(assetInfo.downloadInterval)) {
                if (allSelectedIntervals[assetInfo.downloadInterval]) {
                    allSelectedIntervals[assetInfo.downloadInterval] += 1;
                } else {
                    allSelectedIntervals[assetInfo.downloadInterval] = 1;
                }
            }
        });

        return reduce(
            allSelectedIntervals,
            (result, value, key) => {
                if (!allSelectedIntervals[result] || allSelectedIntervals[result] < value) {
                    return parseInt(key);
                }

                return parseInt(result);
            },
            DEFAULT_DOWNLOAD_INTERVAL,
        );
    }

    componentDidUpdate(prevProps) {
        const { currentValue } = this.state;
        const { max, min } = this.props;

        /* istanbul ignore next */
        if (currentValue > max) {
            this.setState({ currentValue: max });
        }

        if (currentValue < min) {
            this.setState({ currentValue: min });
        }

        if (!prevProps.open && this.props.open) {
            this.setState({ currentValue: this.getSelectedDownloadInterval() });
        }
    }

    changeValue(event) {
        if (typeof event === 'number') {
            this.setState({
                currentValue: event,
            });
        }

        if (event && event.target && event.target.value) {
            this.setState({
                currentValue: event.target.value,
            });
        }
    }

    getContent() {
        return (
            <div>
                { getAssets(this.props.selectedAssets) }
            </div>
        );
    }

    confirm() {
        this.props.hideDialog();

        this.setState({
            confirmDialogOpen: true,
        });
    }

    abortConfirmation() {
        this.setState({
            confirmDialogOpen: false,
        });
    }

    completeConfirmation() {
        this.props.saveIntervals(
            this.props.selectedAssets
                .map(getAssetInfo)
                .filter(id => !!id),
            this.state.currentValue,
        );
        this.setState({
            confirmDialogOpen: false,
        });
    }

    getFooter() {
        return (
            <div>
                <div className={'text-left padding-15 padding-top-25 padding-bottom-0'}>
                    <label className={'text-size-16'}>
                        <FormattedMessage
                            id={'administration.setIntervalForSelectedAssets'}
                            defaultMessage={'Set interval for selected assets'}
                        />
                    </label>
                </div>
                <div
                    className={
                        'display-flex align-items-center padding-15 padding-bottom-25 padding-top-0 ' +
                        'border-style-solid border-width-1 border-color-darkest border-right-0 border-top-0 ' +
                        'border-left-0 border-color-lighter'
                    }
                >
                    <Slider
                        value={this.state.currentValue}
                        minValue={this.props.min}
                        maxValue={this.props.max}
                        onChange={this.changeValue}
                        onDragEnd={this.saveValues}
                    />
                    <div className={'width-100 margin-left-10'}>
                        <NumberInput
                            min={this.props.min}
                            max={this.props.max}
                            value={this.state.currentValue}
                            unit={<FormattedMessage id={'administration.days'} defaultMessage={'Days'}/>}
                            onValueChanged={this.changeValue}
                            className={'no-controls width-50 text-center'}
                        />
                    </div>
                </div>
                <div className={'pull-right btn-toolbar padding-15'}>
                    <button className={'btn btn-default'} onClick={this.props.hideDialog}>
                        <FormattedMessage id={'cancel'} defaultMessage={'Cancel'}/>
                    </button>
                    <button className={'btn btn-primary'} onClick={this.confirm}>
                        <FormattedMessage id={'save'} defaultMessage={'Save'}/>
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Dialog
                    title={
                        <FormattedMessage
                            id={'administration.editDownloadInterval'}
                            defaultMessage={'Edit download interval'}
                        />
                    }
                    body={this.getContent()}
                    show={this.props.open}
                    footer={this.getFooter()}
                    showCloseButton={false}
                    disableEsc={true}
                    useOverflow={true}
                    bodyClassName={'IntervalSettingsModalBody'}
                    footerClassName={'padding-0'}
                    bsSize={this.props.selectedAssets.length > BIG_DIALOG_ASSET_COUNT ? 'lg' : undefined} // eslint-disable-line
                />
                <ConfirmDownloadIntervalDialog
                    open={this.state.confirmDialogOpen}
                    intervalSettingsChanged={true}
                    confirm={this.completeConfirmation}
                    abort={this.abortConfirmation}
                />
            </div>
        );
    }
}

EditDownloadIntervalDialog.defaultProps = {
    downloadInterval: 2,
    selectedAssets: [],
    min: 2,
    max: 90,
    open: false,
};

EditDownloadIntervalDialog.propTypes = {
    downloadInterval: PropTypes.number,
    selectedAssets: PropTypes.array,
    min: PropTypes.number,
    max: PropTypes.number,
    open: PropTypes.bool,
    hideDialog: PropTypes.func,
    saveIntervals: PropTypes.func,
};

export default EditDownloadIntervalDialog;
