import { setTachoSettings } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';
import { env } from '../../env';
import { setDisabledNotifications } from '../../actions/notifications';

const { TACHO_SETTINGS_PATH } = env.runtimeConfig.backend;

const getFetchTachoSettings =
    (store, fetch = window.fetch) => () => fetch(
        TACHO_SETTINGS_PATH,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.getState().tokenHandling.accessToken}`,
            },
            method: 'GET',
        },
    );

export const getHandleData = store => data => {
    const tachoSettings = {};

    const getParsedValue = value => {
        try {
            return JSON.parse(value);
        } catch (err) {
            return value;
        }
    };

    // eslint-disable-next-line no-return-assign
    data.forEach(setting => tachoSettings[setting.settingKey] =
        getParsedValue(setting.settingValue));

    store.dispatch(setTachoSettings(tachoSettings));

    if (tachoSettings.disabledNotifications) {
        store.dispatch(setDisabledNotifications(tachoSettings.disabledNotifications));
    }
};

const configureFetchTachoSettings = store => new Promise(resolve => {
    const fetchTachoSettings = getFetchTachoSettings(store);

    saveFetch(
        fetchTachoSettings,
        'tachoSettings',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchTachoSettings;
