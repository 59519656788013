import get from 'lodash/get';

import store from '../setup/store';

export const shouldEnableRDA = () => get(
    store.getState(),
    'tachographservices.common.RDASupported',
    '',
);

export default shouldEnableRDA;
