
import Analytics from '../../components/common/Analytics';
import ForbiddenState from '../../components/common/ForbiddenState';

const ForbiddenPage = () => (
    <Analytics page={'/forbidden'}>
        <ForbiddenState/>
    </Analytics>
);

export default ForbiddenPage;
