import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';

import get from 'lodash/get';

import { setTachoSetting as setTachoSettingAction } from '../../actions/common';
import { currentVersion, RELEASE_NOTES_ITEM, isNewVersion } from '../../constants/releaseNotes';

import { toggleGlossaryDialog, setEventOnHelpPage as setEventOnHelpPageAction} from '../../actions/about';

import setTachoSetting from '../../fetches/Settings/setTachoSetting';

import store from '../../setup/store';

import { getLanguageData, getLocale } from '../../common/tacho-lang/selectors';

import { env } from '../../env';

import GlossaryDialog from './glossary/GlossaryDialog';
import ReleaseNotesDialog from './releaseNotes/ReleaseNotesDialog';

import CallForActionContainer from './CallForActionContainer';

import FeedbackNotificationContainer from './FeedbackNotificationContainer';

import NotificationContainer from './notifications/NotificationContainer';

const { isRunningOnProd } = env;
// eslint-disable-next-line max-len
const FEEDBACK_LINK = 'https://forms.office.com/Pages/ResponsePage.aspx?id=qj3GzEvYvUqY_U7rg53ic6l0vyVqkP5NjE1Y8OY-BHNUOEoxNDBOVTY5QUhFSTdYNTFBQlgxNDZUNC4u&lang=';

export class ServiceInfoContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openNotifications: false,
            openReleaseNotes: false,
        };

        this.toggleNotificationsDialog = this.toggleNotificationsDialog.bind(this);
        this.openReleaseNotes = this.openReleaseNotes.bind(this);
        this.closeReleaseNotes = this.closeReleaseNotes.bind(this);
        this.onHelpClickHandler = this.onHelpClickHandler.bind(this);
        this.onFeedbackClick = this.onFeedbackClickHandler.bind(this);

    }

    toggleNotificationsDialog() {
        this.setState(prevState => ({ openNotifications: !prevState.openNotifications }));
    }

    onHelpClickHandler() {
        this.props.setEventOnHelpPage();
        this.props.toggleGlossaryDialog();
    }

    openReleaseNotes() {
        this.setState({ openReleaseNotes: true });
    }

    closeReleaseNotes() {
        this.setState({ openReleaseNotes: false });
        this.props.setReleaseNotesShownVersion();
    }

    onFeedbackClickHandler() {
        this.props.setFeedbackNotificationConfig({submitted: true});
    }

    getPopoverContent(hasNewReleaseNotes) {
        return (
            <div>
                <div className={'line-height-20 padding-top-2 padding-bottom-2'}>
                    <a
                        className={'ShowReleaseNotesLink cursor-pointer'}
                        onClick={this.openReleaseNotes}>
                        <FormattedMessage id={'whatsNew'} defaultMessage={'What`s new?'}/>
                        {
                            hasNewReleaseNotes &&
                                <span className={'float-right badge bg-primary margin-left-5'}>{'1'}</span>
                        }
                    </a>
                </div>

                {!isRunningOnProd && <div className={'line-height-20 padding-top-2 padding-bottom-2'}>
                    <a
                        href={FEEDBACK_LINK + this.props.userLocale}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={'ShowReleaseNotesLink cursor-pointer'}
                        onClick={this.onFeedbackClick}>
                        <FormattedMessage id={'FeedbackNav'} defaultMessage={'Feedback'}/>
                    </a>
                </div>}

                {
                    this.props.permissions.includes('rda-assign.read') ?
                        <div className={'line-height-20 padding-top-2 padding-bottom-2'}>
                            <a
                                className={'ShowNotificationsLink cursor-pointer'}
                                onClick={this.toggleNotificationsDialog}
                            >
                                <FormattedMessage id={'notifications'} defaultMessage={'Notifications'}/>
                                {
                                    this.props.errorNotifications.length ?
                                        <span className={'float-right badge bg-danger margin-left-5'}>
                                            {this.props.errorNotifications.length}
                                        </span> :
                                        ''
                                }
                                {
                                    this.props.warningNotifications.length ?
                                        <span className={'float-right badge bg-warning margin-left-5'}>
                                            {this.props.warningNotifications.length}
                                        </span> :
                                        ''
                                }
                                {
                                    this.props.infoNotifications.length ?
                                        <span className={'float-right badge bg-info margin-left-5'}>
                                            {this.props.infoNotifications.length}
                                        </span> :
                                        ''
                                }
                            </a>
                        </div> :
                        ''
                }
                <div className={'line-height-20 padding-top-2 padding-bottom-2'}>
                    <a
                        className={'ShowHelpLink cursor-pointer'}
                        onClick={this.onHelpClickHandler}
                    >
                        <FormattedMessage id={'administration.help'} defaultMessage={'Help'}/>
                    </a>
                </div>
            </div>
        );
    }

    // eslint-disable-next-line complexity
    render() {
        const hasPermission = this.props.permissions.includes('rda-assign.read');
        const hasNewReleaseNotes = isNewVersion(window.localStorage.getItem(RELEASE_NOTES_ITEM));
        const actionBarItemTitle = `${this.props.translations['module.name']} v.${currentVersion}`;

        const isNotificationInfoBadgeShown = this.props.infoNotifications.length > 0;
        const isNotificationWarningBadgeShown = this.props.warningNotifications.length > 0;
        const isNotificationErrorBadgeShown = this.props.errorNotifications.length > 0;

        const areNotificationsBadgeShown =
            [
                ...this.props.infoNotifications,
                ...this.props.warningNotifications,
                ...this.props.errorNotifications,
            ].length > 0;
        const releaseBadgeStyle = areNotificationsBadgeShown ?
            'badge  badge-indicator badge-indicator-border bg-primary margin-right--15' :
            'badge badge-indicator badge-indicator-border bg-primary';

        return (
            <ActionBarItem id={'serviceInfo'}>
                <ActionBarItem.Icon>
                    <span className={'icon rioglyph rioglyph-info-sign'}/>
                    { hasNewReleaseNotes && <span className={releaseBadgeStyle}>{'1'}</span> }
                    {
                        hasPermission && isNotificationInfoBadgeShown ?
                            <span className={'badge badge-indicator badge-indicator-border bg-info'}>
                                {this.props.infoNotifications.length}
                            </span> :
                            ''
                    }
                    {
                        hasPermission && isNotificationWarningBadgeShown ?
                            <span className={'badge badge-indicator badge-indicator-border bg-warning'}>
                                {this.props.warningNotifications.length}
                            </span> :
                            ''
                    }
                    {
                        hasPermission && isNotificationErrorBadgeShown ?
                            <span className={'badge badge-indicator badge-indicator-border bg-danger'}>
                                {this.props.errorNotifications.length}
                            </span> :
                            ''
                    }
                </ActionBarItem.Icon>
                <ActionBarItem.Popover title={actionBarItemTitle}>
                    { this.getPopoverContent(hasNewReleaseNotes) }
                </ActionBarItem.Popover>
                <CallForActionContainer/>
                {!isRunningOnProd &&
                <FeedbackNotificationContainer
                    setFeedbackNotificationConfig={this.props.setFeedbackNotificationConfig}/>}
                <ReleaseNotesDialog
                    closeReleaseNotes={this.closeReleaseNotes}
                    showReleaseNotes={this.props.showReleaseNotes || this.state.openReleaseNotes}
                    toggleGlossaryDialog={this.props.toggleGlossaryDialog}
                />
                <GlossaryDialog
                    show={this.props.showGlossaryDialog}
                    toggleDialog={this.props.toggleGlossaryDialog}
                    defaultGlossaryPage={this.props.defaultGlossaryPage}
                />
                <NotificationContainer
                    show={this.state.openNotifications}
                    toggleDialog={this.toggleNotificationsDialog}
                />
            </ActionBarItem>
        );
    }
}

ServiceInfoContainer.propTypes = {
    errorNotifications: [],
    warningNotifications: [],
    infoNotifications: [],
};

ServiceInfoContainer.propTypes = {
    toggleGlossaryDialog: PropTypes.func,
    defaultGlossaryPage: PropTypes.string,
    showGlossaryDialog: PropTypes.bool,
    showReleaseNotes: PropTypes.bool,
    translations: PropTypes.shape({
        'module.name': PropTypes.string,
    }).isRequired,
    errorNotifications: PropTypes.array,
    warningNotifications: PropTypes.array,
    infoNotifications: PropTypes.array,
    setReleaseNotesShownVersion: PropTypes.func,
    permissions: PropTypes.array,
    setEventOnHelpPage: PropTypes.func,
    setFeedbackNotificationConfig: PropTypes.func,
    feedbackNotificationConfig: PropTypes.any,
    setAnalysisPortalFeedback: PropTypes.func,
    setAnalysisPortalFeedbackConfig: PropTypes.func,
    userLocale: PropTypes.string,
};

export const mapStateToProps = state => ({
    translations: getLanguageData(state),
    locale: state.lang.displayLocale,
    showGlossaryDialog: state.tachographservices.about.showGlossaryDialog,
    defaultGlossaryPage: state.tachographservices.about.defaultGlossaryPage,

    errorNotifications: state.tachographservices.notifications.errorNotifications,
    warningNotifications: state.tachographservices.notifications.warningNotifications,
    infoNotifications: state.tachographservices.notifications.infoNotifications,

    showReleaseNotes:
        isNewVersion(get(
            state,
            'tachographservices.common.tachoSettings.releaseNotesShownVersion',
            window.localStorage.getItem(RELEASE_NOTES_ITEM),
        )),
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    userLocale: getLocale(state),
});

export const mapDispatchToProps = dispatch => ({
    setEventOnHelpPage: () => {
        dispatch(setEventOnHelpPageAction());
    },
    toggleGlossaryDialog: payload => {
        dispatch(toggleGlossaryDialog(payload));
    },

    setReleaseNotesShownVersion: () => {
        dispatch(setTachoSettingAction({ releaseNotesShownVersion: currentVersion }));
        setTachoSetting(
            store.getState().tokenHandling.accessToken,
            'releaseNotesShownVersion',
            currentVersion,
        );

        try {
            window.localStorage.setItem(
                RELEASE_NOTES_ITEM,
                currentVersion,
            );
        } catch (e) {
            console.log(e); // eslint-disable-line no-console
        }
    },

    setFeedbackNotificationConfig: status => {
        dispatch(setTachoSettingAction({ feedbackNotificationConfig: status }));
        setTachoSetting(
            store.getState().tokenHandling.accessToken,
            'feedbackNotificationConfig',
            status,
        );
    },

    setAnalysisPortalFeedback: status => {
        dispatch(setTachoSettingAction({ analysisPortalFeedback: status }));
        setTachoSetting(
            store.getState().tokenHandling.accessToken,
            'analysisPortalFeedback',
            status,
        );
    },

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ServiceInfoContainer);
