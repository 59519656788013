import noop from 'lodash/fp/noop';

import { configureStorage } from './storage';

const getSaveCurrentHashRoute = (injectedWindow = window, trace = noop) => {
    const storage = configureStorage(injectedWindow);

    return () => {
        if (injectedWindow.location.hash !== '' || injectedWindow.location.hash !== '#analysisportal') {
            const initialRoute =
                [
                    window.location.pathname,
                    window.location.hash,
                ].join('').replace(
                    /^\/#/u,
                    '',
                );
            storage.saveRoute(initialRoute);
        }

        trace('saving Route');
    };
};

export default getSaveCurrentHashRoute;
