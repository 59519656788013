
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage } from 'react-intl';

const ForbiddenState = () => (
    <div className={'ForbiddenState display-flex justify-content-center align-items-center height-100pct'}>
        <CustomState
            headline={<FormattedMessage id={'forbiddenHeadline'} defaultMessage={'403 Forbidden'}/>}
            message={<FormattedMessage
                id={'forbiddenMessage'}
                defaultMessage={'You don`t have the necessary permission'}
            />}
            icons={[
                {
                    name: 'rioglyph-ban-circle',
                    className: 'text-size-300pct',
                },
            ]}
        />
    </div>
);

export default ForbiddenState;
