import Spinner from '@rio-cloud/rio-uikit/Spinner';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { get } from 'lodash';

import { env } from '../../env';
import { getAccessToken } from '../../features/tokenHandling/selectors';

import { getLocale } from '../../common/tacho-lang/selectors';

import ErrorPage from './ErrorPage';

const { ANALYSIS_PORTAL } = env.runtimeConfig.backend;

class AnalysisPortalPage extends Component {

    constructor(props) {
        super(props);
        // eslint-disable-next-line react/prop-types
        const { location } = this.props;
        // eslint-disable-next-line react/prop-types
        const queryParams = new URLSearchParams(location.search);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.mainForm = React.createRef();
        this.state = {
            username: {},
            error: queryParams.has('error'),
        };
    }

    // eslint-disable-next-line class-methods-use-this
    handleSubmit() {
        this.mainForm.current.submit();
    }

    setUsername(res) {
        this.setState({username: res});
    }

    componentDidMount() {
        if (!this.state.error) {
            setTimeout(
                () => {
                    this.handleSubmit();
                },
                // eslint-disable-next-line no-magic-numbers
                1000 / 60,
            );
        }
        // eslint-disable-next-line react/prop-types
        this.props.history.replace({
            search: '',
        });
    }

    // eslint-disable-next-line class-methods-use-this
    render() {
        if (!this.state.error && this.props.analysisPortalAccount.isActive) {
            return <div><form
                style={{ display: 'none' }}
                action={ANALYSIS_PORTAL}
                id={'mainform'}
                method={'post'}
                ref={this.mainForm}
                onSubmit={this.handleSubmit}>
                <input
                    name={'account'}
                    type={'hidden'}
                    value={`${this.props.analysisPortalAccount.ident}`}
                    id={'account'} />
                <input name={'sso'} type={'hidden'} value={'man'} id={'sso'} />
                <input
                    name={'lang'}
                    type={'hidden'}
                    value={this.props.userLocale.substring(
                        0,
                        2,
                    )}
                    id={'lang'} />
                <input
                    name={'redir'}
                    type={'hidden'}
                    value={'https://remotedownload.rio.cloud/#analysisportal?error=true'}
                    id={'redir'} />
                <input name={'token'} type={'hidden'} value={this.props.accessToken} id={'token'} />
                <input type={'submit'} value={'Analysis Portal'} />
            </form>
            <Spinner isFullScreen show />
            </div>;
        }
        return <ErrorPage></ErrorPage>;
    }
}

export const mapStateToProps = state => ({
    accessToken: getAccessToken(state),
    analysisPortalAccount: get(
        state,
        'tachographservices.common.analysisPortalAccount',
    ),
    userLocale: getLocale(state),
});

AnalysisPortalPage.propTypes = {
    accessToken: PropTypes.string.isRequired,
    analysisPortalAccount: PropTypes.object,
    userLocale: PropTypes.string,
};

export default withRouter(connect(mapStateToProps)(AnalysisPortalPage));
