
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

/*
 * notice that the cell will not be wrapped in a div
 * so it will be pointed out when overflow the table
 * when pointed out, the tooltip is important to what text is there
 */
const formatAsSpan = (cell, tooltip) => <span>
    <OverlayTrigger
        placement={'top'}
        overlay={
            <Tooltip id={`${Math.random()}`}>
                { tooltip ? tooltip : cell }
            </Tooltip>
        }
    >
        <span>{cell}</span>
    </OverlayTrigger>
</span>;

export default formatAsSpan;

