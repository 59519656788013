import configureFetchRetentionPeriod from './configureFetchRetentionPeriod';
import configureFetchTachoSettings from './configureFetchTachoSettings';
import configureFetchTachoPermissions from './configureFetchTachoPermissions';
import configureFetchVehiclesWithSubscription from './configureFetchVehiclesWithSubscription';
import configureFetchTBM3Vehicles from './configureFetchTBM3Vehicles';
import configureFetchAssets, {configureFetchTags, configureFetchDrivers} from './configureFetchAssets';
import configureFetchAccountInfo from './configureFetchAccountInfo';
import configureFetchRdaInstalledVersions from './configureFetchRdaInstalledVersions';
import configureFetchAnalysisPortalAccount from './configureFetchAnalysisPortalAccount';
import configureFetchRdaSupported from './configureFetchRdaSupported';
import configureFetchDownloadInterval from './configureFetchDownloadInterval';

// these fetches have to be solved (or have failed) before rendering the whole page
const renderAfterFetches = async(store, renderFunc) => {
    // fetches that have to be solved one after another
    await configureFetchRetentionPeriod(store);
    await configureFetchVehiclesWithSubscription(store);
    await configureFetchTBM3Vehicles(store);
    await configureFetchDownloadInterval(store);

    // fetches that can run in parallel
    await Promise.all([
        configureFetchTachoSettings(store),
        configureFetchTachoPermissions(store),
        configureFetchAssets(store),
        configureFetchTags(store),
        configureFetchDrivers(store),
        configureFetchAnalysisPortalAccount(store),
        configureFetchAccountInfo(store),
        configureFetchRdaInstalledVersions(store),
        configureFetchRdaSupported(store),
    ])
        .then(renderFunc)
        .catch(renderFunc);
};

export default renderAfterFetches;
