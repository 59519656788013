export const ROWLIMIT = 30;

export const LOADING_REQUESTS = [
    {
        vehicle: {
            status: 'loading',
        },
    },
];

export const RETENTION_PERIOD_MAX_MONTHS = 84;
export const RETENTION_PERIOD_MIN_MONTHS = 12;
