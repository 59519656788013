export const defaultTranslationBase = 'https://translations.rio.cloud/tachographservices/tachographservices_';

const getFetchTranslation = (
    DEFAULT_LANGUAGE = 'en-GB',
    defaultMessages = {},
    translationBase = defaultTranslationBase,
) => (
    locale = DEFAULT_LANGUAGE,
    injectedFetch = fetch,
    injectedTranslationBase = translationBase,
) => injectedFetch(`${injectedTranslationBase}${locale}.json`)
    .then(response => response.json())
    .catch(() => defaultMessages);

export default getFetchTranslation;
