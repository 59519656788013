import cloneDeep from 'lodash/fp/cloneDeep';

import {
    SET_ERROR_MESSAGE_ID,
    SET_TACHO_SETTINGS,
    SET_TACHO_SETTING,
    SET_PERMISSIONS,
    SET_IDENTIFIERS,
    SET_RETENTION_PERIOD,
    SET_TBM3_VEHICLES,
    SET_SUCCESSFUL_COMPANY_CARD_REQUEST,
    SET_COMPANY_CARDS,
    SET_LOADING_COMPANY_CARDS,
    SET_RDA_VERSION_SUPPORTED,
    SET_ACCOUNT_INFO,
    SET_ANALYSISPORTAL_ACCOUNT_INFO,
    SET_RDA_SUPPORTED,
} from '../actions/common';

export const initialState = {
    errorMessageId: false,
    tachoSettings: {},
    permissions: [],
    identifiers: [],
    retentionPeriod: 30,
    tbm3Vehicles: [],
    successfulCompanyCardRequest: false,
    companyCards: [],
    loadingCompanyCards: true,
    notAssignedVehicleIds: [],
    rdaBannerVisible: false,

};

const extendedCompanyCardsAction = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SUCCESSFUL_COMPANY_CARD_REQUEST:
            return {
                ...state,
                successfulCompanyCardRequest: action.payload,
            };
        case SET_COMPANY_CARDS:
            return {
                ...state,
                companyCards: action.payload.companyAssignedVehicleData,
                notAssignedVehicleIds: action.payload.unassignedEquipmentIds,
            };
        case SET_LOADING_COMPANY_CARDS:
            return {
                ...state,
                loadingCompanyCards: action.payload,
            };
        default:
            return state;
    }
};

// eslint-disable-next-line complexity
const common = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ERROR_MESSAGE_ID:
            return {
                ...state,
                errorMessageId: action.payload,
            };
        case SET_TACHO_SETTINGS:
            return {
                ...state,
                tachoSettings: action.payload,
            };
        case SET_TACHO_SETTING:
            return {
                ...state,
                tachoSettings: {
                    ...state.tachoSettings,
                    ...action.payload,
                },
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: cloneDeep(action.payload),
            };
        case SET_IDENTIFIERS:
            return {
                ...state,
                identifiers: cloneDeep(action.payload),
            };
        case SET_RETENTION_PERIOD:
            return {
                ...state,
                retentionPeriod: action.payload,
            };
        case SET_TBM3_VEHICLES:
            return {
                ...state,
                tbm3Vehicles: action.payload.map(vehicle => vehicle.assetId),
            };
        case 'HISTORY_CHANGED':
            return {
                ...state,
                history: action.payload,
            };
        case SET_RDA_VERSION_SUPPORTED:
            return {
                ...state,
                RDAVersionSupported: action.payload,
            };
        case SET_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: action.payload,
            };
        case SET_ANALYSISPORTAL_ACCOUNT_INFO:
            return {
                ...state,
                analysisPortalAccount: action.payload,
            };
        case SET_RDA_SUPPORTED:
            return {
                ...state,
                RDASupported: action.payload,
            };
        default:
            return extendedCompanyCardsAction(
                state,
                action,
            );
    }
};

export default common;
