
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
    setCompanyCardsSidebarOpen,
    setEditingCompanyCard,
    setSendCompanyCardDeletion,
} from '../../actions/administration';

import {
    setCompanyCards,
} from '../../actions/notifications';

import {
    setTachoSetting as setTachoSettingAction,
    setLoadingCompanyCards,
} from '../../actions/common';

import setTachoSetting from '../../fetches/Settings/setTachoSetting';

import { AREPERMISSIONSHIDDEN } from '../../constants/archive';
import ForbiddenPage from '../../features/DefaultPages/ForbiddenPage';
import withPermissions from '../../utils/withPermissions';

import { getAccessToken } from '../../features/tokenHandling/selectors';

import store from '../../setup/store';

import getCompanyCards from '../../fetches/CompanyCards/getCards';

import getRDAStatus from '../../fetches/RDA/getRDAStatus';

import CompanyCardsPageContainer from './CompanyCardsPage';

export const CompanyCardsContainer = props => {

    const component =
        (<CompanyCardsPageContainer
            {...props}
            editable={props.permissions.includes('rda-assign.write')}
            toggleOpenCompanyCard={props.toggleOpenCompanyCard}
        />);

    const renderComponentwithPermission = () => withPermissions(
        props.permissions,
        'rda-assign.read',
        component,
    );
    return withPermissions(
        props.permissions,
        AREPERMISSIONSHIDDEN ? '' : 'file-list.read',
        renderComponentwithPermission(),
        <ForbiddenPage/>,
    );
};

export const mapStateToProps = state => ({
    accessToken: getAccessToken(state),

    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    vehicles: get(
        state,
        'tachographservices.tree.vehicles',
        [],
    ),
    selectedVehicles: get(
        state,
        'tachographservices.tree.selectedVehicles',
        [],
    ),
    changedCard: get(
        state,
        'tachographservices.administration.changedCard',
    ),
    deletedCard: get(
        state,
        'tachographservices.administration.deletedCard',
    ),
    restoredCard: get(
        state,
        'tachographservices.administration.restoredCard',
    ),
    tbm3Vehicles: get(
        state,
        'tachographservices.common.tbm3Vehicles',
        [],
    ),
    openedCompanyCards:
        get(
            state,
            'tachographservices.common.tachoSettings.openedCompanyCards',
            [],
        ),
    successfulCompanyCardRequest: get(
        state,
        'tachographservices.common.successfulCompanyCardRequest',
        false,
    ),
    companyCards: get(
        state,
        'tachographservices.common.companyCards',
        [],
    ),
    loadingCompanyCards: get(
        state,
        'tachographservices.common.loadingCompanyCards',
        true,
    ),
    notAssignedVehicleIds: get(
        state,
        'tachographservices.common.notAssignedVehicleIds',
        [],
    ),
    unAssignedVehiclesExpanded: get(
        state,
        'tachographservices.common.tachoSettings.unAssignedVehiclesExpanded',
        true,
    ),
    isRDAOnline: get(
        state,
        'tachographservices.administration.isRDAOnline',
    ),
    isRDAStatusFetched: get(
        state,
        'tachographservices.administration.isRDAStatusFetched',
    ),
});

export const mapDispatchToProps = dispatch => ({
    editCompanyCard: payload => {
        dispatch(setCompanyCardsSidebarOpen(true));
        dispatch(setEditingCompanyCard(payload));
    },

    setCompanyCards: payload => {
        dispatch(setCompanyCards(payload));
    },

    toggleOpenCompanyCard: card => {
        const oldOpenedCards =
                get(
                    store.getState(),
                    'tachographservices.common.tachoSettings.openedCompanyCards',
                    [],
                );

        const newOpenedCards = oldOpenedCards.includes(card) ?
            oldOpenedCards.filter(id => id !== card) :
            oldOpenedCards.concat(card);

        dispatch(setTachoSettingAction({ openedCompanyCards: newOpenedCards }));
        setTachoSetting(
            getAccessToken(store.getState()),
            'openedCompanyCards',
            newOpenedCards,
        );
    },
    getCompanyCards: () => {
        getCompanyCards(dispatch);
    },
    setLoadingCompanyCards: payload => {
        dispatch(setLoadingCompanyCards(payload));
    },
    setUnAssignedVehiclesExpanded: status => {
        dispatch(setTachoSettingAction({ unAssignedVehiclesExpanded: status }));
        setTachoSetting(
            getAccessToken(store.getState()),
            'unAssignedVehiclesExpanded',
            status,
        );
    },
    sendDeletion: payload => {
        dispatch(setSendCompanyCardDeletion(payload));
    },
    getRDAStatus: () => {
        getRDAStatus(
            store.getState().tachographservices.administration,
            dispatch,
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CompanyCardsContainer);
