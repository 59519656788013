import getSaveCurrentHashRoute from './getSaveCurrentHashRoute';

const addOnHashChange = (injectedWindow = window) => {
    try {
        const saveCurrentHash = getSaveCurrentHashRoute(injectedWindow);
        const onHashChange = () => saveCurrentHash();

        injectedWindow.addEventListener(
            'hashchange',
            onHashChange,
            false,
        );
    } catch (error) {

        // eslint-disable-next-line no-console
        console.log('onHashChange not supported');
    }
};

export default addOnHashChange;
