import { env } from '../../env';
import { setDownloadIntervals } from '../../actions/administration';
import {fetchData} from '../../sagas/fetchUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

export const TACHO_DOWNLOAD_INTERVALS_PATH = '/downloadscheduler/fleets/download-interval';

const getTachoDownloadIntervals = (
    dispatch,
    injectedFetch = fetchData,
) => injectedFetch(`${TACHOGRAPH_SERVICES_SERVICE}${TACHO_DOWNLOAD_INTERVALS_PATH}`)
    .then(res => {
        if (res.error) {
            throw new Error('tacho download interval call failed');
        }

        return res;
    })
    .then(data => {
        dispatch(setDownloadIntervals(data));
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
        dispatch(setDownloadIntervals({
            fleetDownloadIntervals: {
                vehicleDownloadInterval: 30,
                driverDownloadInterval: 7,
            },
            customDownloadIntervals: {
                vehicleDownloadIntervals: [],
                driverDownloadIntervals: [],
            },
            useSpeedData: false,
        }));
    });

export default getTachoDownloadIntervals;
