
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';
import {setListOfRequests} from '../../actions/administration';
import {fetchData, forbidden, noContent, notFound, unauthorized} from '../../sagas/fetchUtils';
import {LOADING_REQUESTS} from '../../constants/administration';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const offset = 0;
const limit = 500;

export function getDownloadSchedulerURL() {
    return `${TACHOGRAPH_SERVICES_SERVICE}/downloadscheduler/`;
}

const getUrl = () => `${getDownloadSchedulerURL()}manual-download?offset=${offset}&limit=${limit}`;

const getListOfRequests = (
    dispatch,
    injectedFetch = fetchData,
) => {
    dispatch(setListOfRequests(LOADING_REQUESTS));

    return injectedFetch(getUrl())
        .then((res = {}) => {
            if (res.error) {
                switch (res.error) {
                    case noContent:
                        return [];
                    case unauthorized:
                    case forbidden:
                        Notification.error(<FormattedMessage
                            id={'error.401'}
                            defaultMessage={
                                'Error : 401'
                            }
                        />);
                        break;
                    case notFound:
                        Notification.error(<FormattedMessage
                            id={'error.404'}
                            defaultMessage={
                                'Service not found. Please try again later.'
                            }
                        />);
                        break;
                    default:
                        Notification.error(<FormattedMessage
                            id={'error.500'}
                            defaultMessage={
                                'An internal error has occurred. Please try again later.'
                            }
                        />);
                }
            } else if (res.length) {
                return res;
            }

            dispatch(setListOfRequests([]));
            throw new Error('tacho get list of requests');
        })
        .then((data = []) => {
            dispatch(setListOfRequests(data));
            return data;
        })
        .catch(err => {
            console.log(err); // eslint-disable-line no-console
        });
};

export default getListOfRequests;
