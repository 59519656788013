
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { env } from '../../env';

import {fetchBlobData, forbidden, unauthorized } from '../../sagas/fetchUtils';

import { downloadBlob } from '../../utils/exportUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getUrl = () => `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard/CreateLetter`;

const getParams = () => {
    const params =
        {
            method: 'GET',
            headers: {
                Accept: 'application/pdf',
            },
        };

    return params;
};

const downloadLetter = (injectedFetch = fetchBlobData) => injectedFetch(
    getUrl(),
    getParams(),
)
    .then(res => {
        if (res.error) {
            switch (res.error) {
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.letterDownload'}
                        defaultMessage={
                            'Letter download failed. Please try again later.'
                        }
                    />);
            }
            throw new Error('letter download call failed');
        }
        return res;
    })
    .then(result => {
        downloadBlob(
            result,
            'MAN Company Card Hotel Letter.pdf',
        );
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default downloadLetter;
