/* eslint-disable no-magic-numbers */
import { Component } from 'react';
import PropTypes from 'prop-types';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { connect } from 'react-redux';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { setTachoSetting as setTachoSettingAction } from '../../actions/common';
import { getLocale } from '../../common/tacho-lang/selectors';

// eslint-disable-next-line max-len
const FEEDBACK_LINK = `https://forms.office.com/Pages/ResponsePage.aspx?id=qj3GzEvYvUqY_U7rg53ic6l0vyVqkP5NjE1Y8OY-BHNUOEoxNDBOVTY5QUhFSTdYNTFBQlgxNDZUNC4u&lang=`;

class FeedbackNotificationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    randomize() {
        const randomNumber = Math.floor(Math.random() * 4) + 4;
        if (
            this.props.feedbackNotificationConfig.noOfTimesProductEntered === randomNumber ||
            this.props.feedbackNotificationConfig.noOfTimesProductEntered > 6) {
            return true;
        }
        return false;
    }

    shouldShowNotification() {
        if (!this.props.feedbackNotificationConfig.submitted &&
            this.props.feedbackNotificationConfig.noOfTimesClosed < 4 &&
            this.randomize()) {
            return true;
        }
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    renderNotificationFeedback() {
        if (!this.props.feedbackNotificationProcessed && !this.props.feedbackNotificationConfig.submitted) {
            this.props.setFeedbackNotificationConfig({
                noOfTimesProductEntered:
                    this.props.feedbackNotificationConfig.noOfTimesProductEntered + 1,
                noOfTimesClosed:
                    this.props.feedbackNotificationConfig.noOfTimesClosed,
            });
            this.props.setFeedbackNotificationProcessed();
            if (this.shouldShowNotification()) {
                this.props.setFeedbackNotificationConfig({
                    noOfTimesProductEntered:
                        0,
                    noOfTimesClosed:
                        this.props.feedbackNotificationConfig.noOfTimesClosed,
                });
                Notification.info(
                    <span>
                        <a
                            href={FEEDBACK_LINK + this.props.userLocale}
                            target={'_blank'}
                            rel={'noreferrer'}
                            className={'text-color-white text-decoration-underline'}>
                            <FormattedMessage id={'feedbackMessage'} defaultMessage={'Leave us your feedback'}/>
                        </a>
                        <span className={'notification-close'}>
                            <span className={'rioglyph rioglyph-remove'} />
                        </span>
                    </span>
                    ,
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                        href={FEEDBACK_LINK + this.props.userLocale}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={'text-color-white text-decoration-underline'}>
                        <FormattedMessage id={'feedbackTitle'} defaultMessage={'How do you like this app?'}/>
                    </a>,
                    10000,
                    () => {
                        this.props.setFeedbackNotificationConfig({
                            noOfTimesProductEntered:
                                0,
                            noOfTimesClosed:
                                this.props.feedbackNotificationConfig.noOfTimesClosed + 1,
                        });
                    },
                );
            }
        }
    }

    componentDidMount() {
        setTimeout(
            () => {
                this.renderNotificationFeedback();
            },
            8000,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    render() {
        return null;
    }
}

FeedbackNotificationContainer.propTypes = {
    feedbackNotificationConfig: PropTypes.any,
    setFeedbackNotificationConfig: PropTypes.func,
    feedbackNotificationProcessed: PropTypes.bool,
    setFeedbackNotificationProcessed: PropTypes.func,
    userLocale: PropTypes.string,
};

export const mapStateToProps = state => ({
    feedbackNotificationConfig: get(
        state,
        'tachographservices.common.tachoSettings.feedbackNotificationConfig',
        {noOfTimesProductEntered: 0, noOfTimesClosed: 0, submitted: false},
    ),

    feedbackNotificationProcessed: get(
        state,
        'tachographservices.common.tachoSettings.feedbackNotificationProcessed',
        false,
    ),
    userLocale: getLocale(state),

});

export const mapDispatchToProps = dispatch => ({
    setFeedbackNotificationProcessed: () => {
        dispatch(setTachoSettingAction({ feedbackNotificationProcessed: true }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedbackNotificationContainer);
