/* eslint-disable react/prop-types, class-methods-use-this, no-confusing-arrow */

import { connect } from 'react-redux';

import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import {
    successfulFetchAssets as successfulFetchAssetsAction,
    successfulFetchDrivers as successfulFetchDriversAction,
    successfulFetchTags as successfulFetchTagsAction,
    setSelectedVehicles as setSelectedVehiclesAction,
    setSelectedDrivers as setSelectedDriversAction,
    setCurrentCategory as setCurrentCategoryAction,
    setAssetTreeState as setAssetTreeStateAction,
} from '../actions/tree';

import AssetTreeComponent from '../components/common/AssetTreeComponent';

const AppObjectTree = props => (
    <ApplicationLayout.Sidebar>
        <AssetTreeComponent {...props}/>
    </ApplicationLayout.Sidebar>
);

const mapStateToProps = state => ({
    objectTreeState: state.objectTree,

    tags: state.tachographservices.tree.tags,
    vehicleGroupIds: state.tachographservices.tree.vehicleGroupIds,
    driverGroupIds: state.tachographservices.tree.driverGroupIds,
    vehicles: state.tachographservices.tree.vehicles,
    drivers: state.tachographservices.tree.drivers,
    vehicleIdsByGroup: state.tachographservices.tree.vehicleIdsByGroup,
    driverIdsByGroup: state.tachographservices.tree.driverIdsByGroup,
    currentCategoryId: state.tachographservices.tree.currentCategoryId,
    isAssetTreeOpen: state.tachographservices.tree.isAssetTreeOpen,
    rawVehicles: state.tachographservices.tree.rawVehicles,
    rawDrivers: state.tachographservices.tree.rawDrivers,

});

const mapDispatchToProps = dispatch => ({
    successfulFetchAssets: payload => dispatch(successfulFetchAssetsAction(payload)),
    successfulFetchDrivers: payload => dispatch(successfulFetchDriversAction(payload)),
    successfulFetchTags: payload => dispatch(successfulFetchTagsAction(payload)),
    setSelectedVehicles: payload => dispatch(setSelectedVehiclesAction(payload)),
    setSelectedDrivers: payload => dispatch(setSelectedDriversAction(payload)),
    setCurrentCategory: payload => dispatch(setCurrentCategoryAction(payload)),
    setAssetTreeState: payload => dispatch(setAssetTreeStateAction(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppObjectTree);
