import PropTypes from 'prop-types';
import Fade from '@rio-cloud/rio-uikit/Fade';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';

import { NavLink } from 'react-router-dom/cjs/react-router-dom';

import { setTachoSetting as setTachoSettingAction } from '../../../actions/common';
import setTachoSetting from '../../../fetches/Settings/setTachoSetting';
import store from '../../../setup/store';
import { getAccessToken } from '../../../features/tokenHandling/selectors';
import { MANUAL_TAB } from '../../../constants/archive';

const RDABanner = props => (<Fade
    duration={0.5}
    animationStyle={Fade.FROM_LEFT}
    // eslint-disable-next-line no-extra-parens, max-len
    show={!props.RDAVersionSupported}>
    <div className={'alert alert-info'}>
        <div className={'display-flex'}>
            {/* eslint-disable-next-line max-len */}
            <span className={'text-color-info text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-exclamation-sign'}></span>
            <div>
                <strong className={'text-size-16'}><FormattedMessage
                    id={'announcement'}
                    defaultMessage={'Announcement'} /></strong>
                <div className={'margin-y-5'}><FormattedMessage
                    id={'rda.banner.warning'}
                    // eslint-disable-next-line max-len
                    defaultMessage={'We will stop the support of old {rda} (RDA) versions on Monday 15.01.2024. This means that remote downloads can no longer be performed with RDA versions 4.1.4 or lower. If you still use an old RDA version, please deinstall it and install RDA version {rda420} or higher. Another option is to use our company card hosting service where you don\'t need to use the RDA at all.'}
                    values={{
                        rda: <NavLink
                            to={{ pathname: `/${MANUAL_TAB}`}}>
                            Remote Download Application</NavLink>,
                        rda420: <NavLink
                            to={{ pathname: `/${MANUAL_TAB}`}}>
                            4.2.0</NavLink>,
                    }
                    }
                />
                </div>
            </div>
        </div>
    </div><br /></Fade>);

export const mapStateToProps = state => ({
    RDABanner: get(
        state,
        'tachographservices.common.tachoSettings.RDABanner',
        { noOfDismiss: 0 },
    ),
    RDAVersionSupported: get(
        state,
        'tachographservices.common.RDAVersionSupported',
        false,
    ),
});

RDABanner.propTypes = {
    RDABanner: PropTypes.object,
    setRDABanner: PropTypes.func,
    RDAVersionSupported: PropTypes.bool,
};

export const mapDispatchToProps = dispatch => ({

    setRDABanner: (setIsBannerVisible, status) => {
        dispatch(setTachoSettingAction({ RDABanner: status }));
        setIsBannerVisible(false);
        setTachoSetting(
            getAccessToken(store.getState()),
            'RDABanner',
            status,
        );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RDABanner);
