/* eslint-disable import/no-named-as-default, import/no-unassigned-import, react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Route, Switch, Redirect} from 'react-router-dom';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import get from 'lodash/get';
import isEqual from 'lodash/fp/isEqual';

import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';

// Import language configuration
import { DEFAULT_LANGUAGE } from '../lang/translationSetup';

import SettingsSidebarContainer from '../views/Settings/SettingsSidebarContainer';
import CompanyCardsSidebarContainer from '../views/CompanyCards/CompanyCardsSidebarContainer';

import ArchiveContainer from '../views/Archive/ArchiveContainer';
import GuidanceContainer from '../views/Guidance/GuidanceContainer';
import CompanyCardsContainer from '../views/CompanyCards/CompanyCardsContainer';
import ManualDownloadContainer from '../views/ManualDownload/ManualDownloadContainer';
import ErrorMessagesContainer from '../components/About/ErrorMessagesContainer';

// Import application style
import '../../style/css/rio-tachographservices.less';

import reloadPage from '../utils/reloadPage';

import {
    ANALYSIS_PORTAL,
    COMPANY_CARDS_TAB,
    MANUAL_DOWNLOAD_TAB,
    MANUAL_TAB,
    OVERVIEW_TAB,
    REMOTE_DOWNLOAD_TAB,
} from '../constants/archive';

import { getLanguageData, getLocale } from '../common/tacho-lang/selectors';

import RDABanner from '../components/common/widgets/RDABanner';

import AnalysisPortalPage from '../views/AnalysisPortal/AnalysisPortalPage';

import shouldEnableAnalysisPortal from '../utils/shouldEnableAnalysisPortal';

import AppObjectTree from './AppObjectTree';
import AppHeader from './AppHeader';

const shouldUpdate = (prevProps, nextProps) => isEqual(
    prevProps,
    nextProps,
);

/* dispatch */
export const mapStateToProps = state => ({
    languageData: getLanguageData(state),
    userLocale: getLocale(state),
    userSessionExpired: get(
        state,
        'login.userSessionExpired',
        false,
    ),
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
    ),
});

const AppLayout = React.memo(
    ({ userLocale, languageData, userSessionExpired }) => (
        <ApplicationLayout className={'TachographServices'}>
            <>
                <AppHeader />
                <AppObjectTree />
                <CompanyCardsSidebarContainer/>
                <SettingsSidebarContainer/>
                <ApplicationLayout.Body
                >
                    <RDABanner />
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={reloadPage}
                        show={userSessionExpired}
                    />
                    <Switch>
                        <Route
                            path={`/${MANUAL_TAB}`}
                            component={() => <GuidanceContainer/>}
                        />
                        <Route
                            path={`/${REMOTE_DOWNLOAD_TAB}`}
                            component={() => <ArchiveContainer isOverviewSelected={false}/>}
                        />
                        <Route
                            path={`/${MANUAL_DOWNLOAD_TAB}`}
                            component={() => <ManualDownloadContainer/>}
                        />
                        <Route
                            path={`/${COMPANY_CARDS_TAB}`}
                            component={() => <CompanyCardsContainer/>}
                        />
                        <Route
                            path={`/${OVERVIEW_TAB}`}
                            component={() => <ArchiveContainer isOverviewSelected/>}
                        />
                        {shouldEnableAnalysisPortal() && <Route
                            path={`/${ANALYSIS_PORTAL}`}
                            component={() => <AnalysisPortalPage />}
                        />}
                        <Route
                            path={'/remotedownload'}
                            render={() => <Redirect to={`/${REMOTE_DOWNLOAD_TAB}`}/>}
                        />
                        <Redirect to={`/${REMOTE_DOWNLOAD_TAB}`}/>
                    </Switch>
                    <ErrorMessagesContainer defaultMessages={languageData}/>
                </ApplicationLayout.Body>
            </>
        </ApplicationLayout>
    ),
    shouldUpdate,
);

const ConnectedAppLayout = connect(mapStateToProps)(AppLayout);

const AppContainer = ({ languageData, userLocale }) => (
    <IntlProvider
        key={`intl-provider-${userLocale}`}
        locale={userLocale}
        messages={languageData}
        defaultLocale={DEFAULT_LANGUAGE}
        textComponent={'span'}
    >
        <ConnectedAppLayout/>
    </IntlProvider>
);

export default connect(mapStateToProps)(AppContainer);
