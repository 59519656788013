/* eslint-disable no-use-before-define */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';

import isEqual from 'lodash/fp/isEqual';

import { getTextAlignClass } from './TableUtils';

const firstElement = 0;

const getTdClassName = (details = {}) => {
    const columnClassName = details.columnClassName || '';
    const columnClassNameString = typeof columnClassName === 'function' ? columnClassName() : columnClassName;

    return `${columnClassNameString} ${getTextAlignClass(details)}`;
};

const getTrClassName = (row, trClassName) => {
    const rowClassName = trClassName || '';
    return typeof rowClassName === 'function' ? rowClassName(row) : rowClassName;
};

/*
 * A custom component used to prevent re-rendering the individual row.
 */
class TableRow extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.shouldRerender || !isEqual(
            nextProps.row,
            this.props.row,
        );
    }

    render() { // eslint-disable-line max-lines-per-function
        const {
            row,
            columns,
            colLabels,
            columnsDetails,
            onClick,
            enableSelection,
            multiSelect,
            keyField,
            trClassName,
            isOverviewSelected,
        } = this.props;

        return (
            <tr
                key={`tr-${row[keyField]}`}
                data-key={`${row[keyField]}`}
                // eslint-disable-next-line no-nested-ternary,  no-empty-function, max-len,
                onClick={ isOverviewSelected ? enableSelection ? onClick : () => {} : enableSelection && row.isDownloadPermitted ? onClick : () => {} }
                className={getTrClassName(
                    row,
                    trClassName,
                )}
            >
                {
                    multiSelect &&
                    <td key={`tr-${row[keyField]}-col-selection`} className={'table-checkbox'}>
                        <Checkbox disabled = {isOverviewSelected ? false : !row.isDownloadPermitted}/>
                    </td>
                }
                {
                    columns.map(col => {
                        const details = columnsDetails[col] || {};
                        const label = colLabels[col] || '';
                        return (
                            <td
                                key={`tr-${row[keyField]}-col-${col}`}
                                className={getTdClassName(details)}
                                style={details.tdStyle}
                            >
                                <div className={'table-td-card-label'} key={`tr-${row[keyField]}-col-${col}-label`}>
                                    {
                                        label[firstElement] && label[firstElement].type === OnboardingTip ?
                                            label[firstElement].props.children :
                                            label
                                    }
                                </div>
                                <div className={'table-td-content'} key={`tr-${row[keyField]}-col-${col}-content`}>
                                    {
                                        details.dataFormat ?
                                            details.dataFormat(
                                                row[col],
                                                row,
                                            ) :
                                            row[col]
                                    }
                                </div>
                            </td>
                        );
                    })
                }
            </tr>
        );
    }
}

TableRow.defaultProps = {
    onClick: () => {}, // eslint-disable-line no-empty-function
    columnsDetails: {},
};

TableRow.propTypes = {
    row: PropTypes.object.isRequired,
    keyField: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    colLabels: PropTypes.object.isRequired,
    columnsDetails: PropTypes.object,
    onClick: PropTypes.func,
    enableSelection: PropTypes.bool,
    multiSelect: PropTypes.bool,
    isOverviewSelected: PropTypes.bool,

    trClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),

    shouldRerender: PropTypes.bool,
};

export default TableRow;
