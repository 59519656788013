/* eslint-disable camelcase, no-console */
import { InMemoryWebStorage, UserManager, WebStorageStateStore } from 'oidc-client/lib/oidc-client';
import join from 'lodash/fp/join';
import getOr from 'lodash/fp/getOr';

import { configureStorage } from './storage';
import { mapUserProfile } from './userProfile';
import getSaveCurrentHashRoute from './getSaveCurrentHashRoute';

const getWindow = () => typeof window === 'undefined' ? {} : window;

export const param = (window, regex, defaultValue = null) => {
    // eslint-disable-next-line immutable/no-let
    let result = defaultValue;
    decodeURI(window.location.href).replace(
        regex,
        (_, it) => {
            result = it;
        },
    );
    return result;
};

const pullLocale = getOr(
    'en-GB',
    'profile.locale',
);

// eslint-disable-next-line no-empty-function
const trace = () => {};

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    locale: pullLocale(result),
    profile: mapUserProfile(result.profile),
});

export const configureAuth = configuredEnv => {
    const { runtimeConfig } = configuredEnv;

    const { login: { callbackUri, silentRedirectUri }} = runtimeConfig;

    const settings = {
        authority: `${runtimeConfig.login.authority}`,
        client_id: `${runtimeConfig.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${callbackUri}`,
        response_type: `code`,
        scope: join(
            ' ',
            runtimeConfig.login.oauthScope,
        ),
        silent_redirect_uri: `${silentRedirectUri || callbackUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAge: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    return new UserManager(settings);
};

export const configureRetrieveInitialState = storage => () => ({
    initialRoute: storage.getRoute(),
});

export const retrieveInitialState =
    configureRetrieveInitialState(configureStorage(getWindow()));

// eslint-disable-next-line max-lines-per-function
export const configureSetupOAuth = (configuredEnv, createAuth, storage, window) => {
    const auth = createAuth(configuredEnv);

    const { runtimeConfig } = configuredEnv;

    const saveCurrentRoute = getSaveCurrentHashRoute(window);

    return config => { // eslint-disable-line max-lines-per-function

        const isFreshRedirect = Boolean(param(
            window,
            /.*code=([^&]+)/u, // eslint-disable-line prefer-named-capture-group
        ));

        const trySignin = () => auth.signinSilent().then(result => {
            const { initialRoute } = retrieveInitialState();

            trace(
                'initialRoute lookup',
                initialRoute,
            );
            if (isFreshRedirect) {
                trace(`Go to location "/${initialRoute || ''}"`);
                window.history.replaceState(
                    window.history.state,
                    '',
                    `/#${initialRoute || ''}`,
                );
            }

            config.onTokenRenewed(adaptPublishedInfo(result));

            return result;
        })
            .catch(error => {
                trace(
                    'oidc.signinSilent failed',
                    error,
                );

                if (!isFreshRedirect) {
                    saveCurrentRoute();
                }
                config.onTokenExpired();
                return Promise.reject(error);
            });

        auth.events.addAccessTokenExpiring(() => trySignin());

        auth.events.addAccessTokenExpired((...args) => {
            trace(
                'oidc.accessTokenExpired',
                ...args,
            );
            config.onTokenExpired();
        });

        auth.events.addSilentRenewError((...args) => {
            trace(
                'oidc.silentRenewError',
                ...args,
            );
            config.onTokenExpired();
        });

        auth.events.addUserLoaded((...args) => {
            trace(
                'oidc.userLoaded',
                ...args,
            );
        });

        auth.events.addUserSignedOut((...args) => {
            trace(
                'oidc.userSignedOut',
                ...args,
            );
            config.onTokenExpired();
        });

        return trySignin().catch(error => {
            trace(
                'oidc.signinSilent failed, trying page redirect...',
                error,
            );

            const mightBeSuspicious = isFreshRedirect;

            if (runtimeConfig.login.preventRedirect) {
                // eslint-disable-next-line no-console
                console.warn('[feature/login] redirect prevented due to config');
            } else if (mightBeSuspicious) {
                trace(
                    'oidc.signinSilent.error',
                    'redirect prevented due to supsicious signin error',
                    error,
                );
                storage.discardRoute();
            } else {
                saveCurrentRoute();
                auth.signinRedirect();
            }

            return Promise.reject(new Error(`Need to sign in`));
        });
    };
};

export const createAuthentication = configuredEnv => configureAuth(configuredEnv);

export const getSetupOAuth = configuredEnv => configureSetupOAuth(
    configuredEnv,
    createAuthentication,
    configureStorage(getWindow()),
    getWindow(),
);

export const mockOAuth = ({ onTokenRenewed }, testAccessToken) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    onTokenRenewed(adaptPublishedInfo({
        access_token: testAccessToken || 'valid-mocked-oauth-bogus-token',
        // eslint-disable-next-line no-magic-numbers
        expires_in: 60 * 60 * 24 * 365,
        profile: {
            account: 'mockaccount',
            azp: 'test-client',
            email: 'test@example.com',
            family_name: 'Client',
            given_name: 'Test',
            name: 'Test Client',
            sub: 'prod-rio-users:mock-user',
            locale: 'en-GB',
        },
    }));

    return Promise.resolve();
};

