import { TOGGLE_GLOSSARY_DIALOG } from '../actions/about';

export const initialState = {
    showGlossaryDialog: false,
};

// eslint-disable-next-line max-lines-per-function
export default function about(state = initialState, action = {}) {
    switch (action.type) {
        case TOGGLE_GLOSSARY_DIALOG:
            return {
                ...state,
                showGlossaryDialog: !state.showGlossaryDialog,
                defaultGlossaryPage: action.payload,
            };
        default:
            return state;
    }
}
