import { setDownloadIntervals } from '../../../src/actions/administration';

import { env } from '../../env';
import saveFetch from '../../features/utils/saveFetch';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

export const TACHO_DOWNLOAD_INTERVALS_PATH = '/downloadscheduler/fleets/download-interval';

const getFetchDownloadInterval =
    (store, fetch = window.fetch) => () => fetch(
        `${TACHOGRAPH_SERVICES_SERVICE}${TACHO_DOWNLOAD_INTERVALS_PATH}`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.getState().tokenHandling.accessToken}`,
            },
            method: 'GET',
        },
    );

export const getHandleData = store => data => {
    try {
        store.dispatch(setDownloadIntervals(data));
    } catch (err) {
        console.log(err); // eslint-disable-line no-console
        store.dispatch(setDownloadIntervals({
            fleetDownloadIntervals: {
                vehicleDownloadInterval: 30,
                driverDownloadInterval: 7,
            },
            customDownloadIntervals: {
                vehicleDownloadIntervals: [],
                driverDownloadIntervals: [],
            },
            useSpeedData: false,
        }));
    }
    store.dispatch(setDownloadIntervals(data));

};

const configureFetchDownloadInterval = store => new Promise(resolve => {
    const fetchRetentionPeriod = getFetchDownloadInterval(store);

    saveFetch(
        fetchRetentionPeriod,
        'download interval',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchDownloadInterval;
