import { Component } from 'react';
import PropTypes from 'prop-types';

import SteppedProgressBar from '@rio-cloud/rio-uikit/SteppedProgressBar';

import {SHOULD_INITIALLY_BE_RESPONSIVE} from '../../../constants/common';

class ExplanationContainer extends Component {
    constructor() {
        super();

        this.state = {
            selectedStep: 0,
        };

        this.onSelectedChanged = this.onSelectedChanged.bind(this);
        this.switchLeft = this.switchLeft.bind(this);
        this.switchRight = this.switchRight.bind(this);
    }

    onSelectedChanged(selected) {
        this.setState({ selectedStep: selected });
    }

    getSwitchLeftEnabled() {
        return this.state.selectedStep > 0;
    }

    getSwitchRightEnabled() {
        return this.state.selectedStep < this.props.steps.length - 1;
    }

    switchLeft() {
        if (this.getSwitchLeftEnabled()) {
            this.setState({ selectedStep: this.state.selectedStep - 1 });
        }
    }

    switchRight() {
        if (this.getSwitchRightEnabled()) {
            this.setState({ selectedStep: this.state.selectedStep + 1 });
        }
    }

    render() {
        const buttonBaseClasses = 'btn btn-link btn-icon-only text-size-20';
        return (
            <div className={'row equal-height'}>
                {
                    !this.props.beResponsive &&
                    <div className={'col-sm-1'}>
                        <div className={'position-absolute top-50pct'}>
                            <button
                                className={`${buttonBaseClasses} ${this.getSwitchLeftEnabled() ? '' : 'disabled'}`}
                                onClick={this.switchLeft}
                            >
                                <span className={'rioglyph rioglyph-chevron-left'}/>
                            </button>
                        </div>
                    </div>
                }
                <div className={this.props.beResponsive ? 'col-xs-12' : 'col-sm-10'}>
                    <div className={'text-center padding-left-20 padding-right-20'}>
                        <SteppedProgressBar
                            selectedStepNumber={this.state.selectedStep}
                            onSelectedChanged={this.onSelectedChanged}
                            labels={this.props.steps}
                            disableFollowingPages={this.props.steps.length}
                        />
                        {this.props.getExplanation(this.state.selectedStep)}
                    </div>
                </div>
                {
                    !this.props.beResponsive &&
                    <div className={'col-sm-1'}>
                        <div className={'position-absolute top-50pct'}>
                            <button
                                className={`${buttonBaseClasses} ${this.getSwitchRightEnabled() ? '' : 'disabled'}`}
                                onClick={this.switchRight}
                            >
                                <span className={'rioglyph rioglyph-chevron-right'} />
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ExplanationContainer.defaultProps = {
    beResponsive: SHOULD_INITIALLY_BE_RESPONSIVE,
};

ExplanationContainer.propTypes = {
    steps: PropTypes.array.isRequired,
    getExplanation: PropTypes.func.isRequired,
    beResponsive: PropTypes.bool,
};

export default ExplanationContainer;
