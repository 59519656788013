import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

import {
    formatDriver,
    formatDriverCard,
    formatIdentificationNumber,
    formatTransferDate,
    formatTransferAndContentType,
    formatTransferType,
    formatVehicleName,
} from '../../components/ArchiveTable/FormatUtils';
import ListFilesTable from '../../components/ArchiveTable/Tables/ListFilesTable';
import {Column} from '../../components/common/TableWrapper';

import formatDueDate from './Formats/formatDueDate';
import formatPlannedDownloadDate from './Formats/formatPlannedDownloadDate';
import getFormatDownloadInterval from './Formats/getFormatDownloadInterval';
import formatSubscription from './Formats/formatSubscription';

const formatDriverDownloadInterval = getFormatDownloadInterval(true);
const formatVehicleDownloadInterval = getFormatDownloadInterval(false);

const OverviewTable = props => ( // eslint-disable-line max-lines-per-function
    <ListFilesTable {...props}>
        { !props.isDriver &&
            <Column
                width={'20%'}
                dataField={'vehicle'}
                dataFormat={formatVehicleName}
            >
                <FormattedMessage id={'vehicle'} />{''}
            </Column>
        }
        { props.isDriver &&
            <Column
                width={'20%'}
                dataField={'driver'}
                dataFormat={formatDriver}
            >
                <FormattedMessage id={'driver'}/>{''}
            </Column>
        }
        <Column
            width={'15%'}
            dataField={'timeCreatedUtc'}
            dataSort
            dataFormat={formatTransferDate}>
            <FormattedMessage id={'archive.transferDate'} />{''}
        </Column>
        {props.isDriver ?
            <Column
                width={'15%'}
                dataField={'driverCard'}
                dataFormat={formatDriverCard}>
                <FormattedMessage id={'driverCard'}/>{''}
            </Column> :
            <Column
                width={'16%'}
                dataField={'identification'}
                dataFormat={formatIdentificationNumber}>
                <FormattedMessage id={'identification'} defaultMessage={'Identification'}/>{''}
            </Column>
        }
        {props.isDriver ?
            <Column
                width={'19%'}
                dataField={'transferType'}
                dataFormat={formatTransferType}>
                <FormattedMessage id={'archive.transferType'}/>{''}
            </Column> :
            <Column
                width={'19%'}
                dataAlign={'center'}
                dataField={'transferType'}
                dataFormat={formatTransferAndContentType}>
                <FormattedMessage id={'archive.transferType'}/>{''}
            </Column>
        }
        <Column
            width={'20%'}
            dataField={'dueDate'}
            dataSort
            dataFormat={formatDueDate}>
            <FormattedMessage id={'archive.dueDate'} defaultMessage={'Due date'}/>{''}
        </Column>
        <Column
            width={'15%'}
            dataField={'downloadInterval'}
            dataSort
            dataAlign={'center'}
            dataFormat={props.isDriver ? formatDriverDownloadInterval : formatVehicleDownloadInterval}>
            <FormattedMessage id={'administration.downloadInterval'} defaultMessage={'Download Interval'}/>{''}
        </Column>
        <Column
            width={'20%'}
            dataField={'plannedDownloadDate'}
            dataSort
            dataFormat={formatPlannedDownloadDate}>
            <FormattedMessage id={'archive.plannedDownloadDate'} defaultMessage={'Planned download date'}/>{''}
        </Column>
        {<Column
            width={'10%'}
            dataField={'currentLevel'}
            dataFormat={formatSubscription}
        >
            <FormattedMessage id={'archive.subscriptionType'} defaultMessage={'Subscription Type'}/>{''}
        </Column>}
    </ListFilesTable>
);

OverviewTable.propTypes = {
    isDriver: PropTypes.bool,
};

export default OverviewTable;
