/* eslint-disable filenames/no-index, import/no-unassigned-import */
import './polyfills';

import { render } from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

// eslint-disable-line import/no-unresolved
import { EVENT_USER_LANGUAGE_CHANGED } from '@rio-cloud/rio-user-menu-component';

import { mockOAuth, getSetupOAuth, param } from './common/tacho-login/login';
import addOnHashChange from './common/tacho-login/addOnHashChange';
import {
    userProfileObtained,
    userSessionExpired,
    userSessionRenewed,
} from './common/tacho-login/actions';

import getSaveCurrentHashRoute from './common/tacho-login/getSaveCurrentHashRoute';

import { getLocale } from './common/tacho-lang/selectors';
import { extractLanguage } from './common/tacho-lang/getLangReducer';
import { configureFetchLanguageData } from './common/tacho-lang/services';

import getFetchTranslation from './common/tacho-lang/getFetchTranslation';

import store, { history } from './setup/store';

import AppContainer from './features/AppContainer';
import NoMatch from './features/DefaultPages/NoMatch';
import { DEFAULT_LANGUAGE, defaultMessages } from './lang/translationSetup';

import { accessToken } from './features/tokenHandling/accessToken';
import { accessTokenStored } from './features/tokenHandling/actions';

import { env } from './env';
import runWithSentry from './utils/runWithSentry';

import renderAfterFetches from './fetches/preFetches/renderAfterFetches';

const renderAfterTokenRetryTime = 100;

const translationBasePath = 'https://translations.remotedownload.rio.cloud/tachographservices_';

const trace = env.featureToggles.tracing ?
    // eslint-disable-next-line no-console
    (...args) => console.log(
        `[src/index]`,
        ...args,
    ) :
    () => {}; // eslint-disable-line no-empty-function

function subscribeLangToStore(injectedStore) {
    injectedStore.subscribe(() => {
        const lang = extractLanguage(getLocale(injectedStore.getState()));
        const html = document.querySelector('html');

        if (html?.getAttribute('lang') !== lang) {
            html?.setAttribute(
                'lang',
                lang,
            );
        }
    });
}

function listenToLangChange(fetchLanguageData) {
    document.addEventListener(
        EVENT_USER_LANGUAGE_CHANGED,
        ({ detail = {} }) => fetchLanguageData(detail.language),
    );
}

const configureRenderIntoRoot = root => () => {
    render(
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route path={'/error'} component={NoMatch}/>
                    <Route path={'/'} component={AppContainer}/>
                    <Route component={NoMatch}/>
                </Switch>
            </Router>
        </Provider>,
        root,
    );
};

// eslint-disable-next-line max-statements
function main() {
    if (env.isLocalEnv) {
        import('../mocks/serviceMock').then(({ worker }) => {
            worker.start();
            renderMain();
        });
    } else {
        renderMain();
    }
}

runWithSentry(
    main,
    env.shouldSendMetrics,
);

export default main;
function renderMain() {
    const fetchLanguageData = configureFetchLanguageData(
        store,
        getFetchTranslation(
            DEFAULT_LANGUAGE,
            defaultMessages,
            translationBasePath
        )
    );

    listenToLangChange(fetchLanguageData);

    /*
     * We want the `<html lang>` attribute to be synced with the
     * language currently displayed
     */
    subscribeLangToStore(store);

    const root = document.querySelector('#root');
    const renderIntoRoot = configureRenderIntoRoot(root);

    // should not rerender whole page after first render
    let renderAfterToken = () => {
        if (store.getState().tokenHandling.accessToken) {
            if (window.localStorage) {
                const initialRoute = localStorage.getItem('oauth_initial_route');
                history.replace(`/${initialRoute}`);
            }

            renderAfterToken = () => { };

            renderAfterFetches(
                store,
                renderIntoRoot
            ).then();
        } else {
            window.setTimeout(
                renderAfterToken,
                renderAfterTokenRetryTime
            );
        }
    };
    addOnHashChange(window);

    const isFreshRedirect = Boolean(param(
        window,
        /.*code=([^&]+)/u
    ));

    if (!isFreshRedirect) {
        const saveCurrentRoute = getSaveCurrentHashRoute(window);
        saveCurrentRoute();
    }

    // enables mocking of authentication in production
    const testAccessToken = sessionStorage && sessionStorage.getItem('test_access_token');

    const oauthBehavior = settings => {
        const isAllowedToMockAuth = env.isLocalEnv || env.isTestEnv;
        const mockAuth = isAllowedToMockAuth && env.runtimeConfig.login.mockAuthorization;
        return mockAuth || testAccessToken ?
            mockOAuth(
                settings,
                testAccessToken
            ) :
            getSetupOAuth(env)(
                settings,
                history
            );
    };

    const oauthConfig = {
        onTokenExpired: () => {
            // eslint-disable-next-line no-console
            console.log('index.onTokenExpired');

            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onTokenRenewed: result => {
            trace(
                'index.onTokenRenewed',
                result
            );

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(userProfileObtained(result.profile));
            store.dispatch(userSessionRenewed());
            fetchLanguageData(result.locale).then(renderAfterToken);
        },
    };

    if (env.runsInSandbox) {
        renderAfterToken();
    } else {
        oauthBehavior(oauthConfig).catch(error => trace(
            'auth problem?',
            error
        ));
    }
}

