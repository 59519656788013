/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableCardsSorting from '@rio-cloud/rio-uikit/TableCardsSorting';
import { SortDirection } from '@rio-cloud/rio-uikit/SortDirection';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { sortByProperty } from '@rio-cloud/rio-uikit/SortUtils';
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

import Tag from '@rio-cloud/rio-uikit//Tag';

import { dateProps } from '../../constants/common';

const defaultColumnOrder = [
    'requestDate', 'vehicle', 'periodStart', 'periodEnd',
    'statusVehicle', 'transferDateVehicle',
    'driver', 'statusDriver', 'transferDateDriver', 'action',
];

const columnLabels = {
    requestDate: <FormattedMessage
        id={'administration.requestDate'}
        defaultMessage={'Request date'}
    />,
    vehicle: <FormattedMessage id={'vehicle'} />,
    periodStart: <FormattedMessage
        id={'administration.periodStart'}
        defaultMessage={'Period Start'}
    />,
    periodEnd: <FormattedMessage
        id={'administration.periodEnd'}
        defaultMessage={'Period End'}
    />,
    statusVehicle: <FormattedMessage
        id={'administration.status'}
        defaultMessage={'Status'}
    />,
    transferDateVehicle: <FormattedMessage id={'archive.transferDate'} />,
    driver: <FormattedMessage id={'driver'} />,
    statusDriver: <FormattedMessage
        id={'administration.status'}
        defaultMessage={'Status'}
    />,
    transferDateDriver: <FormattedMessage id={'archive.transferDate'} />,
    action: <FormattedMessage
        id={'archive.action'}
        defaultMessage={'Action'} />,
};

const demoColumnsDetails = {
    requestDate: {
        width: 130,
        defaultWidth: 200,
        maxWidth: 350,
    },
    // Note: 0 means auto width
    vehicle: {
        width: 0,
        defaultWidth: 0,
        maxWidth: 350,
    },
    periodStart: {
        width: 0,
        defaultWidth: 0,
        maxWidth: 350,
    },
    periodEnd: {
        width: 0,
        defaultWidth: 0,
        maxWidth: 350,
    },
    statusVehicle: {
        width: 0,
        defaultWidth: 0,
    },
    transferDateVehicle: {
        width: 0,
        defaultWidth: 0,
    },
    driver: {
        width: 0,
        defaultWidth: 0,
    },
    statusDriver: {
        width: 0,
        defaultWidth: 0,
    },
    transferDateDriver: {
        width: 0,
        defaultWidth: 0,
    },
    action: {
        width: 100,
        defaultWidth: 100,
        maxWidth: 75,
    },
};

const sortableColumns = [
    'requestDate', 'periodStart', 'periodEnd',
    'transferDateVehicle', 'transferDateDriver',
];

const REQUESTED = 1;
const CANCELLED = 2;
const COMPLETED = 3;

const getStatusText = status => {
    switch (status) {
        case REQUESTED:
            return <FormattedMessage id={'administration.downloadRequested'} />;
        case CANCELLED:
            return <FormattedMessage id={'administration.downloadCancelled'} />;
        case COMPLETED:
            return <FormattedMessage id={'administration.downloadCompleted'} />;
        default:
            return <FormattedMessage id={'administration.downloadRequested'} />;
    }
};

export const formatDriver = (cell, row) => {

    if (row.notIdentifiedYet) {
        return <span className={'text-muted'}>
            <FormattedMessage
                id={'administration.notIdentifiedYet'}
                defaultMessage={'Not identified yet'}
            />
        </span>;
    }

    return cell;
};

const formatStatus = cell => {
    if (!cell) {
        return '';
    }

    if (cell === 'loading') {
        return <Spinner />;
    }

    return (
        <Tag size={'small'}>
            {getStatusText(cell)}
        </Tag>
    );
};

const formatDate = cell => {
    if (!cell) {
        return '';
    }

    return <span>
        <FormattedDate value={new Date(cell)} {...dateProps} />
        {', '}
        <FormattedTime value={new Date(cell)} />
    </span>;
};

const getSortDir = (sortDir, sortBy, previousSortBy) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.DESCENDING ? SortDirection.ASCENDING : SortDirection.DESCENDING;
    }
    return SortDirection.DESCENDING;
};

// eslint-disable-next-line complexity
const ManualDownloadTable = props => {
    // eslint-disable-next-line no-unused-vars
    const { viewType: externalViewType, vehicleList, openCancelDialog, intl } = props;

    const [searchValue, setSearchValue] = useState('');
    const [sortBy, setSortBy] = useState('requestDate');
    const [sortDir, setSortDir] = useState(SortDirection.DESCENDING);
    // eslint-disable-next-line no-unused-vars
    const [columnOrder, setColumnOrder] = useState(defaultColumnOrder);
    // eslint-disable-next-line no-unused-vars
    const [hiddenColumns, setHiddenColumns] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
    const [viewType, setViewType] = useState(externalViewType || TableViewToggles.VIEW_TYPE_TABLE);

    const getCancelStatus =
    (vehicleStatus, driverStatus, assetId, vehicleDownloadRequestId, driverDownloadRequestId) => {
        if (vehicleStatus === REQUESTED || driverStatus === REQUESTED) {
            return <button
                onClick={() => {
                    props.openCancelDialog(
                        assetId,
                        vehicleDownloadRequestId,
                        driverDownloadRequestId,
                    );
                }}
                className={'btn btn-danger btn-icon-only btn-xs'}>
                <span className={'rioglyph rioglyph-remove'} aria-hidden={'true'}>
                </span></button>;
        }
        return '';

    };

    const formatCancelStatus =
    (vehicleStatus, driverStatus, assetId, vehicleDownloadRequestId, driverDownloadRequestId) => {
        if (!vehicleStatus && !driverStatus) {
            return '';
        }

        if (driverStatus === 'loading' || vehicleStatus === 'loading') {
            return <Spinner />;
        }

        return (
            getCancelStatus(
                vehicleStatus,
                driverStatus,
                assetId,
                vehicleDownloadRequestId,
                driverDownloadRequestId,
            )
        );
    };

    const handleViewTypeChange = newViewType => setViewType(newViewType);
    const handleSearchValueChange = newSearchValue => setSearchValue(newSearchValue);

    const handleCardSortChange = (newSortBy, newSortDir) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    const handleSortChange = event => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        handleCardSortChange(
            newSortBy,
            getSortDir(
                sortDir,
                newSortBy,
                sortBy,
            ),
        );
    };

    // eslint-disable-next-line no-shadow
    const renderTableHead = (column, label, sortBy, sortDir) => {

        const tableHeadClassNames = sortableColumns.includes(column) ?
            classNames(
                'user-select-none',
                'sort-column',
            ) :
            '';

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                style={column === 'action' ? { textAlign: 'right' } : {}}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
            >
                <span>
                    {sortBy === column && sortableColumns.includes(column) ?
                        <SortArrows direction={sortDir} /> :
                        <SortArrows />}
                    <span>{label}</span>
                </span>
            </th>
        );
    };

    const renderTableCaption = (column, columnDetails) => {
        const style =
            columnDetails && columnDetails.width ?
                {
                    minWidth: columnDetails.width,
                    width: columnDetails.width,
                } :
                {};

        return <col key={column} style={style} />;
    };

    // filter for hidden columns
    const columns = columnOrder.filter(name => !hiddenColumns.includes(name));

    // filter data to omit hidden columns
    const withoutHidden = omit(hiddenColumns);
    const filteredRows = vehicleList.map(vehicle => ({ ...withoutHidden(vehicle) }));

    // in case a search value is given, filter the data accordingly
    // eslint-disable-next-line no-negated-condition
    const searchResult = !searchValue ?
        filteredRows :
        filteredRows.filter(row => columns.some(() => row.vehicle.toString().toLowerCase()
            .includes(searchValue.toLowerCase())));

    // Sort rows according to the sortBy and sortDir settings
    const rows = sortBy ?
        sortByProperty(
            searchResult,
            sortBy,
            sortDir,
        ) :
        searchResult;

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards',
    );

    const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;
    const cardSortingSelectOptions = sortableColumns.map(column => ({
        id: column,
        label: columnLabels[column],
        selected: column === sortBy,
        disabled: false,
    }));
    return (
        <div id={'ManualDownloadTable'}>
            <TableToolbar>
                <div className={'table-toolbar-container'} style={{marginTop: '-49px'}}>

                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <TableSearch
                                value={searchValue}
                                onChange={handleSearchValueChange}
                                placeholder={props.intl.formatMessage({ id: 'searchVehicle' })}
                            />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <TableViewToggles
                                initialViewType={viewType}
                                onViewTypeChange={handleViewTypeChange}
                                tableViewTooltipContent={'Table View'}
                                singleCardViewTooltipContent={'List View'}
                                multiCardsViewTooltipContent={'Cards View'}
                            />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            {sortBy && !isViewTypeTable && (
                <TableCardsSorting
                    selectOptions={cardSortingSelectOptions}
                    sortName={sortBy}
                    sortOrder={sortDir}
                    onSortChange={handleCardSortChange}
                />
            )}
            <div>
                {isEmpty(rows) && !isViewTypeTable &&
                    <NotFoundState headline={'Nothing found'} message={'Please refine your search'} />
                }
                <table className={tableClassNames}>
                    <colgroup>
                        {columns.map(column => renderTableCaption(
                            column,
                            columnsDetails[column],
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            {columns.map(column => renderTableHead(
                                column,
                                columnLabels[column],
                                sortBy,
                                sortDir,
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isEmpty(rows) && isViewTypeTable && (
                            <tr>
                                <td colSpan={columns.length + 1}>
                                    <NotFoundState
                                        outerClassName={'border-none'}
                                        headline={'Nothing found'}
                                        message={'Please refine your search'}
                                    />
                                </td>
                            </tr>
                        )}
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td style={{ borderRight: '1px solid #e5ebf0' }}>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.requestDate
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            formatDate(row.requestDate)
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.vehicle
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            <span>{row.vehicle}</span>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.periodStart
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            formatDate(row.periodStart)
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.periodEnd
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            formatDate(row.periodEnd)
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.statusVehicle
                                        }
                                    </div>
                                    <div
                                        className={'table-td-content'}
                                        style={{maxWidth: columnsDetails.action.maxWidth}}>
                                        {
                                            <span>{formatStatus(row.statusVehicle)}</span>
                                        }
                                    </div>
                                </td>
                                <td style={{ borderRight: '1px solid #e5ebf0', textAlign: 'right' }}>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.transferDateVehicle
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            <span>{formatDate(row.transferDateVehicle)}</span>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.driver
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            <span>{formatDriver(
                                                row.driver,
                                                row,
                                            )}</span> }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.statusDriver
                                        }
                                    </div>
                                    <div
                                        className={'table-td-content'}
                                        style={{maxWidth: columnsDetails.action.maxWidth}}>
                                        {
                                            <span>{formatStatus(row.statusDriver)}</span>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.transferDateDriver
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            <span>{formatDate(row.transferDateDriver)}</span>
                                        }
                                    </div>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <div className={'table-td-card-label'}>
                                        {
                                            columnLabels.action
                                        }
                                    </div>
                                    <div className={'table-td-content'}>
                                        {
                                            <span>{formatCancelStatus(
                                                row.statusVehicle,
                                                row.statusDriver,
                                                row.assetId,
                                                row.vehicleDownloadRequestId,
                                                row.driverDownloadRequestId,
                                            )}</span> }
                                    </div>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ManualDownloadTable.propTypes = {
    viewType: PropTypes.string,
    openCancelDialog: PropTypes.func,
    vehicleList: PropTypes.array,
    intl: PropTypes.object,
};

export default injectIntl(ManualDownloadTable);
