
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { dateProps } from '../../constants/common';

import ListFilesTable from '../../components/ArchiveTable/Tables/ListFilesTable';

import {
    formatTransferDate,
    formatTransferType,
    formatTransferAndContentType,
    formatDriverCard,
    formatIdentificationNumber,
    formatWithNameFallbacks,
    formatExportedTo,
    formatDriver,
} from '../../../src/components/ArchiveTable/FormatUtils';

const formatDate = date => date instanceof Date ? <FormattedDate value={date} {...dateProps}/> : '';

const ArchiveTable = props => (
    <ListFilesTable {...props}>
        <div
            width={'10%'}
            dataField={'fileName'}
            dataFormat={props.formatFile}>
            <FormattedMessage id={'archive.file'} />{''}
        </div>
        {
            props.isDriver &&
            <div
                width={'20%'}
                dataField={'driver'}
                dataFormat={formatDriver}
            >
                <FormattedMessage id={'driver'}/>{''}
            </div>
        }
        {
            !props.isDriver &&
            <div
                width={'20%'}
                dataField={'vehicle'}
                dataFormat={formatWithNameFallbacks}
            >
                <FormattedMessage id={'vehicle'} />{''}
            </div>
        }
        <div
            width={'15%'}
            dataField={'timeCreatedUtc'}
            dataSort
            dataFormat={formatTransferDate}>
            <FormattedMessage id={'archive.transferDate'} />{''}
        </div>

        {
            props.isDriver ?
                <div
                    width={'15%'}
                    dataField={'driverCard'}
                    dataFormat={formatDriverCard}>
                    <FormattedMessage id={'driverCard'}/>{''}
                </div> :
                <div
                    width={'15%'}
                    dataField={'identification'}
                    dataFormat={formatIdentificationNumber}>
                    <FormattedMessage id={'identification'} defaultMessage={'Identification'}/>{''}
                </div>
        }
        {
            props.isDriver ?
                <div
                    width={'20%'}
                    dataField={'transferType'}
                    dataFormat={formatTransferType}>
                    <FormattedMessage id={'archive.transferType'} />{''}
                </div> :
                <div
                    width={'20%'}
                    dataField={'transferType'}
                    dataFormat={formatTransferAndContentType}>
                    <FormattedMessage id={'archive.transferType'} />{''}
                </div>
        }
        <div
            width={'20%'}
            dataField={'exports'}
            dataFormat={formatExportedTo}>
            <FormattedMessage id={'archive.exportStatus'} defaultMessage={'Export Status'}/>{''}
        </div>
        <div
            width={'10%'}
            dataField={'deletionDate'}
            dataSort
            dataFormat={formatDate}>
            <FormattedMessage id={'archive.deletionDate'} defaultMessage={'Deletion Date'}/>{''}
        </div>
    </ListFilesTable>
);

ArchiveTable.propTypes = {
    isDriver: PropTypes.bool,
    formatFile: PropTypes.func.isRequired,
};

export default ArchiveTable;
