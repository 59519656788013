import { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/fp/cloneDeep';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import reduce from 'lodash/reduce';
import { injectIntl } from 'react-intl';

import Spinner from '@rio-cloud/rio-uikit/Spinner';

import Analytics from '../../components/common/Analytics';

import PaginationComponent from '../../components/common/PaginationComponent';

import {
    WAITINGFORDATA,
    LOADINGDATA,
    MANDOWNLOADTRIGGER,
    AUTODOWNLOADTRIGGER,
    FRONTENDAZPS,
    PARTNERAZPS,
    APIAZPS,
} from '../../constants/archive';

import TableFilterBar from '../../components/ArchiveTable/TableFilterBar';

import { displayDownloadForbidden } from '../../components/ArchiveTable/FormatUtils';

import getInitialState from '../../components/ArchiveTable/WrapperUtilities/getInitialState';

import bindComponentFunctions from '../../components/ArchiveTable/WrapperUtilities/bindComponentFunctions';

import OverviewPage from '../Overview/OverviewPage';

import { saveStateToRoute } from '../../features/utils/routing';

import ArchiveTable from './ArchiveTable';

const toDate = value => value instanceof Date ? value : value.toDate();

class ArchivePage extends Component {
    /* istanbul ignore next */
    constructor(props) { // eslint-disable-line max-statements
        super(props);

        this.state = getInitialState(this);
        this.downloadingFiles = this.props.downloadingFiles;
        bindComponentFunctions(this);
    }

    downloadFiles(ids) {
        this.props.downloadFiles(ids);

        const newDownloadedFiles = cloneDeep(this.state.downloadedFiles);
        ids.forEach(id => {
            newDownloadedFiles[id] = new Date();
        });

        this.setState({
            downloadedFiles: newDownloadedFiles,
            forceRerenderRow: true,
        });
    }

    saveFilters() {
        if (this.state.tempChosenFilters) {

            this.getData({ chosenFilters: cloneDeep(this.state.tempChosenFilters) });
            this.setState(prevState => ({
                chosenFilters: cloneDeep(prevState.tempChosenFilters),
                tempChosenFilters: false,
            }));
        }
    }

    abortFilters() {
        this.props.abortTempFilters(this.state.chosenFilters);
        this.setState({ tempChosenFilters: false });
    }

    preventUnloading(event) {
        if (this.props.downloadingFiles && this.props.downloadingFiles.length > 0) {
            // stop unloading
            event.preventDefault();

            // for chrome
            event.returnValue = '';
        }
    }

    isDownloadable() {
        return !isEmpty(this.props.selectedRows);
    }

    setNormalTableView() {
        this.props.setSingleCard(false);
        this.props.setMultiCards(false);
    }

    setSingleCardView() {
        this.props.setSingleCard(true);
        this.props.setMultiCards(false);
    }

    setMultiCardsView() {
        this.props.setSingleCard(false);
        this.props.setMultiCards(true);
    }

    setOnlyLatest(isLatestSelected) {
        const newChosenFilters = cloneDeep(this.state.chosenFilters);
        newChosenFilters.onlyLatest = isLatestSelected;

        this.setState({ chosenFilters: newChosenFilters });
        saveStateToRoute({ onlyLatest: isLatestSelected});
        this.getData({ onlyLatest: isLatestSelected });
    }

    toggleTransferType(type) {
        const newChosenFilters = cloneDeep(this.state.tempChosenFilters || this.state.chosenFilters);
        newChosenFilters.transferType.autoDownload = type === 'autoDownload' ?
            !newChosenFilters.transferType.autoDownload :
            newChosenFilters.transferType.autoDownload;

        newChosenFilters.transferType.manualDownload = type === 'manualDownload' ?
            !newChosenFilters.transferType.manualDownload :
            newChosenFilters.transferType.manualDownload;

        newChosenFilters.transferType.speedData = type === 'speedData' ?
            !newChosenFilters.transferType.speedData :
            newChosenFilters.transferType.speedData;

        newChosenFilters.transferType.upload = type === 'upload' ?
            !newChosenFilters.transferType.upload :
            newChosenFilters.transferType.upload;

        this.props.toggleTransferType(type);

        this.setState({ tempChosenFilters: newChosenFilters });
    }

    toggleExportedTo(type) {
        const newChosenFilters = cloneDeep(this.state.tempChosenFilters || this.state.chosenFilters);
        newChosenFilters.exportedTo.notExported = type === 'notExported' ?
            !newChosenFilters.exportedTo.notExported :
            newChosenFilters.exportedTo.notExported;

        newChosenFilters.exportedTo.computer = type === 'computer' ?
            !newChosenFilters.exportedTo.computer :
            newChosenFilters.exportedTo.computer;

        newChosenFilters.exportedTo.partner = type === 'partner' ?
            !newChosenFilters.exportedTo.partner :
            newChosenFilters.exportedTo.partner;

        newChosenFilters.exportedTo.api = type === 'api' ?
            !newChosenFilters.exportedTo.api :
            newChosenFilters.exportedTo.api;

        this.props.toggleExportedTo(type);

        this.setState({ tempChosenFilters: newChosenFilters });
    }

    getRangeStart(startValue) {
        return startValue || startValue === null ? startValue : this.props.start;
    }

    getRangeEnd(endValue) {
        return endValue || endValue === null ? endValue : this.props.end;
    }

    getAnalyzedSelectedIds() {
        if (this.props.currentCategoryId === 'AssetTree-vehicles' && !isEmpty(this.props.selectedVehicles)) {
            return this.props.selectedVehicles;
        }

        if (this.props.currentCategoryId === 'AssetTree-drivers' && !isEmpty(this.props.selectedDrivers)) {
            return reduce(
                this.props.selectedDrivers,
                (result, assetId) => {
                    const assetTreeDriver = this.props.drivers.find(driver => driver.id === assetId) || {};

                    if (assetTreeDriver.driverIds) {
                        return result.concat(assetTreeDriver.driverIds);
                    }
                    result.push(assetId);

                    return result;
                },
                [],
            );
        }

        return [];
    }

    getSelectedAZPs(exportedTo = this.state.chosenFilters.exportedTo) {
        const { computer, partner, api } = exportedTo;
        const AZPs = [];

        if (computer) {
            FRONTENDAZPS.forEach(azp => AZPs.push(azp));
        }

        if (partner) {
            PARTNERAZPS.forEach(azp => AZPs.push(azp));
        }

        if (api) {
            APIAZPS.forEach(azp => AZPs.push(azp));
        }

        return AZPs;
    }

    // eslint-disable-next-line max-statements
    getFetchDataPayload(request) { // eslint-disable-line max-lines-per-function, complexity
        const analyzedSelectedIds = this.getAnalyzedSelectedIds();

        const autoDownload =
            request.chosenFilters &&
            request.chosenFilters.transferType ?
                request.chosenFilters.transferType.autoDownload :
                this.state.chosenFilters.transferType.autoDownload;
        const manualDownload =
            request.chosenFilters &&
            request.chosenFilters.transferType ?
                request.chosenFilters.transferType.manualDownload :
                this.state.chosenFilters.transferType.manualDownload;

        const isTransferTypeFiltered = autoDownload || manualDownload;
        const areBothSelected = autoDownload && manualDownload;

        const shouldRequestTransferType =
            isTransferTypeFiltered &&
            !areBothSelected;

        const requestingDownloadTrigger =
            manualDownload ?
                MANDOWNLOADTRIGGER :
                AUTODOWNLOADTRIGGER;

        const sortField =
            request.sortField || (this.props.isOverviewSelected ?
                this.props.sortFieldOverview :
                this.props.sortFieldArchive);

        const sortOrder =
            request.sortOrder || (this.props.isOverviewSelected ?
                this.props.sortOrderOverview :
                this.props.sortOrderArchive);

        const searchString =
        this.props.currentCategoryId === 'AssetTree-vehicles' ?
            this.props.searchedVehicleIdentification :
            this.props.searchedDriverCard;

        return {
            startValue: this.getRangeStart(request.startValue),
            endValue: this.getRangeEnd(request.endValue),
            selectedIds: analyzedSelectedIds,
            activePage: request.activePage ? request.activePage : 1,
            rawFilter: false,
            downloadTrigger: shouldRequestTransferType ? requestingDownloadTrigger : null,
            hasSpeedData: request.chosenFilters &&
                request.chosenFilters.transferType ?
                request.chosenFilters.transferType.speedData :
                this.state.chosenFilters.transferType.speedData,
            limit: this.props.ROWLIMIT,

            vehicles: this.props.vehicles,
            drivers: this.props.drivers,
            currentCategoryId: this.props.currentCategoryId,

            // eslint-disable-next-line no-undefined
            onlyLatest: request.onlyLatest === undefined ? this.state.chosenFilters.onlyLatest : request.onlyLatest,
            sortField: sortField === 'deletionDate' ? 'timeCreatedUtc' : sortField,
            sortOrder,
            notExported: request.chosenFilters && request.chosenFilters.exportedTo ?
                request.chosenFilters.exportedTo.notExported :
                this.state.chosenFilters.exportedTo.notExported,
            authorizedParties: this.getSelectedAZPs(request.chosenFilters && request.chosenFilters.exportedTo),
            // eslint-disable-next-line no-undefined
            entityIdentifier: request.searchString === undefined ? searchString : request.searchString,
            // for selecting newest data
            requestTimeStamp: new Date(),
        };
    }

    downloadAllFilesForFilters() {
        if (this.props.isExportEnabled) {
            const payload = this.getFetchDataPayload({});
            this.props.downloadAllFiles(payload);
        } else {
            displayDownloadForbidden();
        }
    }

    getData(request = {}) {
        const payload = this.getFetchDataPayload(request);

        if (this.props.isOverviewSelected) {
            payload.onlyLatest = true;
            payload.overviewSelected = true;
            payload.entityIdentifier = '';
            payload.downloadTrigger = null;
        }

        this.setState({
            downloadedFiles: {},
            forceRerenderRow: true,
        });
        return this.props.fetchListFiles(payload);
    }

    loadNewPage(selectedPage) {
        this.getData({ activePage: selectedPage });
    }

    onRangeChange(startValue, endValue) {
        const newStart = startValue === null ? null : toDate(startValue);
        const newEnd = endValue === null ? null : toDate(endValue);

        this.props.setTimeRange({
            start: newStart,
            end: newEnd,
        });

        this.getData({
            startValue: newStart,
            endValue: newEnd,
        });
    }

    getSelectedIds(selectedRows) { // eslint-disable-line class-methods-use-this
        return selectedRows.filter(row => !Array.isArray(row));
    }

    downloadSelectedFiles() {
        if (this.props.isExportEnabled) {
            this.downloadFiles(this.getSelectedIds(this.props.selectedRows));
        } else {
            displayDownloadForbidden();
        }
    }

    didTreeChange(prevProps) {
        const isAssetTreeCategoryIdEqual = isEqual(
            prevProps.currentCategoryId,
            this.props.currentCategoryId,
        );
        const isAssetTreeSelectedVehicleIdsEqual = isEqual(
            prevProps.selectedVehicles,
            this.props.selectedVehicles,
        );
        const isAssetTreeSelectedDriverIdsEqual = isEqual(
            prevProps.selectedDrivers,
            this.props.selectedDrivers,
        );

        return !isAssetTreeCategoryIdEqual ||
            !isAssetTreeSelectedVehicleIdsEqual ||
            !isAssetTreeSelectedDriverIdsEqual;
    }

    addPreventUnloading(injectedWindow = window) {
        if (injectedWindow) {
            injectedWindow.addEventListener(
                'beforeunload',
                this.preventUnloading,
            );
        }
    }

    componentDidMount() {
        this.addPreventUnloading();
        this.getData({ });
    }

    componentDidUpdate(prevProps) { // eslint-disable-line max-statements
        if (!isEqual(
            this.downloadingFiles,
            this.props.downloadingFiles,
        )) {
            this.setState({
                forceRerenderRow: true,
            });
            this.downloadingFiles = [...this.props.downloadingFiles];
        }

        if (this.didTreeChange(prevProps)) {
            this.getData();
            return true;
        }

        if (this.props.shouldRefetchArchive) {
            this.getData({ activePage: this.props.listFilesActivePage });
        }

        return false;
    }

    getPagination() {
        const filesCount = this.props.listFilesTotalFilesCount;
        const activePage = this.props.listFilesActivePage;
        const pageCount = Math.ceil(filesCount / this.props.ROWLIMIT);

        if (pageCount > 1) {
            return (
                <PaginationComponent
                    pageCount={pageCount}
                    activePage={activePage}
                    loadNewPage={this.loadNewPage}
                />
            );
        }

        return <div className={'form-group'}/>;
    }

    getDownloadingRows(realData) {
        realData.forEach(row => {
            row.downloading = this.props.downloadingFiles.includes(row.fileId);
            row.downloadedByFrontend = this.state.downloadedFiles[row.fileId];
        });

        return realData;
    }

    onMergedSelectionChange(selected) {
        if (isEmpty(selected)) {
            this.props.setSelectedRows(selected);
            return;
        }

        this.props.setSelectedRows(selected.filter(item => item !== WAITINGFORDATA[0].fileId));
    }

    onRerenderRow() {
        this.setState({
            forceRerenderRow: false,
        });
    }

    onSortChange(sortField, sortOrder, isOverviewSelected = false) {
        this.props.onSortChange(
            sortField,
            sortOrder,
            isOverviewSelected,
        );
        this.getData({ sortField, sortOrder });
    }

    render() { // eslint-disable-line max-lines-per-function, complexity
        const isLoading = this.props.listFilesData.length === 1 &&
            this.props.listFilesData[0].fileId === LOADINGDATA;

        const sortField = this.props.isOverviewSelected ? this.props.sortFieldOverview : this.props.sortFieldArchive;
        const sortOrder = this.props.isOverviewSelected ? this.props.sortOrderOverview : this.props.sortOrderArchive;

        const options = {
            noDataText: this.props.intl.formatMessage({ id: 'noData' }),
            defaultSortName: sortField,
            defaultSortOrder: sortOrder,
            onSortChange: this.onSortChange,
        };

        const shownTableType = this.props.currentCategoryId === 'AssetTree-vehicles' ?
            'vehicle' :
            'driver';

        const shownTable = <ArchiveTable
            data={this.getDownloadingRows(this.props.listFilesData)}
            options={options}
            selectionEnabled={this.props.isExportEnabled || undefined} // eslint-disable-line no-undefined
            isOverviewSelected = {this.props.isOverviewSelected}
            selectedElements={this.props.selectedRows}
            onSelectionChange={this.onMergedSelectionChange}
            trClassName={this.props.trClassName}
            singleCard={this.props.singleCard}
            multiCards={this.props.multiCards}
            isExportEnabled={this.props.isExportEnabled}
            isDownloadable={this.isDownloadable()}
            areAllFilteredFilesDownloadable={this.props.listFilesData.length > 0}
            downloadAllFilesForFilters={this.downloadAllFilesForFilters}
            downloadSelectedFiles={this.downloadSelectedFiles}
            forceRerenderRow={this.state.forceRerenderRow}
            onRerenderRow={this.onRerenderRow}
            isDriver={this.props.currentCategoryId === 'AssetTree-drivers'}
            formatFile={this.formatFile}

        />;

        if (this.props.isOverviewSelected) {
            return (
                <Analytics page={'/overview'}>
                    <div className={'OverviewTable'} data-testid={'OverviewPage'}>
                        {
                            <OverviewPage
                                isLoading={isLoading}
                                listFilesData={
                                    isLoading ?
                                        [] :
                                        this.props.listFilesData
                                }
                                listFilesActivePage={this.props.listFilesActivePage}
                                listFilesTotalFilesCount={this.props.listFilesTotalFilesCount}
                                shownTableType={shownTableType}
                                tableOptions={options}
                                setDownloadInterval={this.props.setDownloadInterval}
                                isDriver={shownTableType === 'driver'}
                                isDownloadIntervalEnabled={this.props.isDownloadIntervalEnabled}
                                isOverviewSelected = {this.props.isOverviewSelected}
                                shouldRefetchArchive= {this.props.shouldRefetchArchive}
                                singleCard = {this.props.singleCard}
                                multiCards = {this.props.multiCards}
                                setSingleCardView = {this.setSingleCardView}
                                setMultiCardsView = {this.setMultiCardsView}
                                setNormalTableView = {this.setNormalTableView}
                                setEventOnTableView = {this.props.setEventOnTableView}
                                overviewTable={this.props.overviewTable}
                                setOverviewTableSettings = {this.props.setOverviewTableSettings}
                            />
                        }

                        {!isLoading && this.getPagination()}
                    </div>
                </Analytics>
            );
        }

        return (
            <Analytics page={'/archive'}>
                <div className={'ArchiveTable'} data-testid={'ArchivePage'}>
                    <TableFilterBar
                        selectedRows={this.props.selectedRows}
                        isDownloadable={this.isDownloadable()}
                        areAllFilteredFilesDownloadable={this.props.listFilesData.length > 0}
                        downloadAllFilesForFilters={this.downloadAllFilesForFilters}
                        downloadSelectedFiles={this.downloadSelectedFiles}
                        loadingRowSelected={false}
                        DateRangeStart={this.props.start}
                        DateRangeEnd={this.props.end}
                        onDateRangeChange={this.onRangeChange}
                        sendRequest={this.getData}
                        chosenFilters={this.state.tempChosenFilters || this.state.chosenFilters}
                        shownTable={shownTableType}
                        refresh={this.getData}
                        limit={this.props.ROWLIMIT}
                        vehicleSettingsValue={this.props.vehicleSettingsValue}
                        driverSettingsValue={this.props.driverSettingsValue}
                        setVehicleSettings={this.props.setVehicleSettings}
                        setDriverSettings={this.props.setDriverSettings}
                        ISUPLOADHIDDEN={this.props.ISUPLOADHIDDEN}
                        ROWLIMIT={this.props.ROWLIMIT}
                        openSettingsDialog={this.props.openSettingsDialog}

                        setNormalTableView={this.setNormalTableView}
                        setMultiCardsView={this.setMultiCardsView}
                        setSingleCardView={this.setSingleCardView}
                        multiCards={this.props.multiCards}
                        singleCard={this.props.singleCard}

                        saveFilters={this.saveFilters}
                        abortFilters={this.abortFilters}
                        searchedDriverCard={this.props.searchedDriverCard}
                        searchedVehicleIdentification={this.props.searchedVehicleIdentification}
                        submittedDriverCard={this.props.submittedDriverCard}
                        submittedVehicleIdentification={this.props.submittedVehicleIdentification}
                        setSearchedDriverCard={this.props.setSearchedDriverCard}
                        setSearchedVehicleIdentification={this.props.setSearchedVehicleIdentification}
                        setSubmittedDriverCard={this.props.setSubmittedDriverCard}
                        setSubmittedVehicleIdentification={this.props.setSubmittedVehicleIdentification}
                        didShowFilterButton={this.props.didShowFilterButton}
                        setFilterButtonShown={this.props.setFilterButtonShown}

                        isExportEnabled={this.props.isExportEnabled}
                        currentCategoryId= {this.props.currentCategoryId}
                        isAssetTreeOpen={this.props.isAssetTreeOpen}
                        setEventOnFilterClick = {this.props.setEventOnFilterClick}
                        setEventOnTableView = {this.props.setEventOnTableView}
                    />

                    { isLoading && <div data-testid={'ArchivePageSpinner'}><Spinner/></div> }
                    {!isLoading && shownTable}

                    {!isLoading && this.getPagination()}
                </div>
            </Analytics>
        );
    }
}

ArchivePage.propTypes = {
    listFilesData: PropTypes.array,
    selectedRows: PropTypes.array,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    activePage: PropTypes.object,
    totalFilesCount: PropTypes.object,
    setSelectedRows: PropTypes.func,
    setTimeRange: PropTypes.func,
    setTotalFilesCount: PropTypes.func,
    fetchListFiles: PropTypes.func,
    downloadAllFiles: PropTypes.func,
    downloadFiles: PropTypes.func,
    downloadingFiles: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
    vehicleSettingsValue: PropTypes.number,
    driverSettingsValue: PropTypes.number,
    setVehicleSettings: PropTypes.func,
    setDriverSettings: PropTypes.func,
    ROWLIMIT: PropTypes.number,
    EXTENDEDROWLIMIT: PropTypes.number,
    WAITINGFORDATA: PropTypes.array,
    ISUPLOADHIDDEN: PropTypes.bool,
    openSettingsDialog: PropTypes.bool,
    trClassName: PropTypes.string,
    intl: PropTypes.object,

    onSortChange: PropTypes.func,
    listFilesActivePage: PropTypes.number,
    listFilesTotalFilesCount: PropTypes.number,
    sortFieldArchive: PropTypes.string,
    sortFieldOverview: PropTypes.string,
    sortOrderOverview: PropTypes.string,
    sortOrderArchive: PropTypes.string,

    isOverviewSelected: PropTypes.bool,
    shouldRefetchArchive: PropTypes.bool,

    didShowFilterButton: PropTypes.bool,
    setFilterButtonShown: PropTypes.func,

    isExportEnabled: PropTypes.bool,
    isDownloadIntervalEnabled: PropTypes.bool,

    vehicles: PropTypes.array,
    drivers: PropTypes.array,
    selectedVehicles: PropTypes.array,
    selectedDrivers: PropTypes.array,
    currentCategoryId: PropTypes.string,
    singleCard: PropTypes.bool,
    multiCards: PropTypes.bool,
    setSingleCard: PropTypes.func,
    setMultiCards: PropTypes.func,
    toggleTransferType: PropTypes.func,
    toggleExportedTo: PropTypes.func,
    setTransferFilterToFalse: PropTypes.func,
    setExportedFilterToFalse: PropTypes.func,
    abortTempFilters: PropTypes.func,
    isAssetTreeOpen: PropTypes.bool,
    searchedDriverCard: PropTypes.string,
    searchedVehicleIdentification: PropTypes.string,
    submittedDriverCard: PropTypes.string,
    submittedVehicleIdentification: PropTypes.string,
    setSearchedDriverCard: PropTypes.func,
    setSearchedVehicleIdentification: PropTypes.func,
    setSubmittedDriverCard: PropTypes.func,
    setSubmittedVehicleIdentification: PropTypes.func,
    setDownloadInterval: PropTypes.func,
    setEventOnFilterClick: PropTypes.func,
    setEventOnTableView: PropTypes.func,
    overviewTable: PropTypes.object,
    setOverviewTableSettings: PropTypes.func,
};

ArchivePage.defaultProps = {
    listFilesData: [],
    isExportEnabled: true,
    isDownloadIntervalEnabled: true,

    vehicles: [],
    drivers: [],
};

export default injectIntl(ArchivePage);
