
import { FormattedMessage } from 'react-intl';

import {
    SET_COMPANY_CARDS,
    SET_DISABLED_NOTIFICATIONS,
    SET_OVERVIEW_FILES,
    SET_SUCCESSFUL_COMPANY_CARD_REQUEST,
} from '../actions/notifications';

import { THREE_MONTH_MILLISECONDS } from '../constants/common';

import { getDiffDays, getStatusColor } from '../views/Overview/helpers';

import { SUCCESSFUL_FETCH_RDA_STATUS } from '../actions/administration';

import { SET_RDA_SUPPORTED, SET_VEHICLES_WITH_SUBSCRIPTION } from '../actions/common';

import getNoOfSubscribedVehicles from '../utils/getNoOfSubscribedVehicles';

import { USER_PROFILE_OBTAINED } from '../common/tacho-login/actions';

import { getDueDate } from './archive';

export const notificationTypes = [
    'companyCardExpired',
    'companyCardAboutToExpire',
    'vehiclesNotAssignedToAnyCard',
    'vehiclesDueDateWarning',
    'vehiclesDueDateDanger',
    'driversDueDateWarning',
    'driversDueDateDanger',
    'rdaOffline',
    'noCompanyCardsOnline',
    'companyCardsWithoutAssignedVehicles',
    'companyCardsUnavailable',
];

export const initialState = {
    companyCards: [],
    unassignedVehicleIds: [],
    overviewFiles: [],
    errorNotifications: [],
    warningNotifications: [],
    infoNotifications: [],
    disabledNotifications: [
        'companyCardExpired',
        'companyCardAboutToExpire',
        'vehiclesNotAssignedToAnyCard',
        'vehiclesDueDateWarning',
        'vehiclesDueDateDanger',
        'driversDueDateWarning',
        'driversDueDateDanger',
        'rdaOffline',
        'noCompanyCardsOnline',
        'companyCardsWithoutAssignedVehicles',
        'companyCardsUnavailable',
    ],
    rdaStatus: {
        rdaOnline: true,
        anyCompanyCardsOnline: true,
    },
    successfulCompanyCardRequest: false,
    vehiclesWithSubscription: [],
    userProfile: {},
    rdaSupported: false,
};

const COMPANY_CARD_AVAILABLE_STATUS = 1;
const COMPANY_CARD_BUSY_STATUS = 1;

const COMPANY_CARD_AVAILABLE_STATUS_CODES = [
    COMPANY_CARD_AVAILABLE_STATUS,
    COMPANY_CARD_BUSY_STATUS,
];

const parseCompanyCardsNotificationInfo = state => {
    const companyCardsExpired = [];
    const companyCardsAboutToExpire = [];
    const assignedVehicleIds = [];

    const companyCardsUnavailable =
        (state.companyCards || [])
            .filter(companyCard => !COMPANY_CARD_AVAILABLE_STATUS_CODES.includes(companyCard.status))
            .length;
    const companyCardsWithoutAssignedVehicles =
        (state.companyCards || [])
            .filter(companyCard => !companyCard.assignedEquipmentIds || !companyCard.assignedEquipmentIds.length)
            .length;

    (state.companyCards || []).forEach(companyCard => {
        if (companyCard.expirationDate) {
            if (new Date(companyCard.expirationDate) < Date.now()) {
                companyCardsExpired.push(companyCard);
            } else if (new Date(companyCard.expirationDate) < Date.now() + THREE_MONTH_MILLISECONDS) {
                companyCardsAboutToExpire.push(companyCard);
            }
        }

        if (companyCard.assignedEquipmentIds) {
            companyCard.assignedEquipmentIds.forEach(id => !assignedVehicleIds.includes(id) &&
                assignedVehicleIds.push(id));
        }
    });

    return {
        companyCardsExpired,
        companyCardsAboutToExpire,
        assignedVehicleIds,
        companyCardsUnavailable,
        companyCardsWithoutAssignedVehicles,
    };
};

function getCompanyCardsExpiredNotifications({
    companyCardsExpired,
    companyCardsAboutToExpire,
    disabledNotifications,
    error,
    warning,
}) {

    if (companyCardsExpired.length && !disabledNotifications.includes('companyCardExpired')) {
        error.push(<FormattedMessage
            id={'notifications.companyCardsExpired'}
            defaultMessage={
                '{amount} company cards are expired.'
            }
            values={{ amount: companyCardsExpired.length }}
        />);
    }

    if (companyCardsAboutToExpire.length && !disabledNotifications.includes('companyCardAboutToExpire')) {
        warning.push(<FormattedMessage
            id={'notifications.companyCardsAboutToExpire'}
            defaultMessage={
                '{amount} company cards are about to expire within the next {amountOfDays} days.'
            }
            values={{ amount: companyCardsAboutToExpire.length, amountOfDays: 90 }}
        />);
    }
}

function getCompanyCardsVehiclesAssignmentNotifications({
    companyCardsWithoutAssignedVehicles,
    disabledNotifications,
    info,
    unassignedVehicleIds,
    successfulCompanyCardRequest,
}) {
    if (
        unassignedVehicleIds.length > 0 &&
        !disabledNotifications.includes('vehiclesNotAssignedToAnyCard') &&
        successfulCompanyCardRequest
    ) {
        info.push(<FormattedMessage
            id={'notifications.amountOfVehiclesNotAssignedToAnyCard'}
            defaultMessage={
                '{amount} vehicles not assigned to any company card.'
            }
            values={{ amount: unassignedVehicleIds.length }}
        />);
    }

    if (
        companyCardsWithoutAssignedVehicles &&
        !disabledNotifications.includes('companyCardsWithoutAssignedVehicles')
    ) {
        info.push(<FormattedMessage
            id={'notifications.dialog.companyCardsWithoutAssignedVehicles'}
            defaultMessage={
                '{amount} company cards have no assigned vehicles.'
            }
            values={{ amount: companyCardsWithoutAssignedVehicles }}
        />);
    }
}

function getCompanyCardsAvailableNotifications({
    companyCardsUnavailable,
    disabledNotifications,
    warning,
}) {
    if (
        companyCardsUnavailable &&
        !disabledNotifications.includes('companyCardsUnavailable')
    ) {
        warning.push(<FormattedMessage
            id={'notifications.dialog.companyCardsUnavailable'}
            defaultMessage={
                '{amount} company cards are unavailable.'
            }
            values={{ amount: companyCardsUnavailable }}
        />);
    }
}

function getCompanyCardsNotifications(state = initialState, error = [], warning = [], info = []) {
    const notificationsProps = {
        ...state,
        ...parseCompanyCardsNotificationInfo(state),
        error,
        warning,
        info,
    };

    getCompanyCardsExpiredNotifications(notificationsProps);
    getCompanyCardsVehiclesAssignmentNotifications(notificationsProps);
    getCompanyCardsAvailableNotifications(notificationsProps);
}

const parseOverviewFilesNotificationInfo = state => {
    const vehiclesWarning = [];
    const vehiclesDanger = [];
    const driversWarning = [];
    const driversDanger = [];

    const checkVehicleInfo = (file, statusColor) => {
        if (file.vehicleInfo) {
            if (statusColor.includes('danger')) {
                vehiclesDanger.push(file.vehicleInfo.externalEquipmentReference);
            }

            if (statusColor.includes('warning')) {
                vehiclesWarning.push(file.vehicleInfo.externalEquipmentReference);
            }
        }
    };

    const checkDriverInfo = (file, statusColor) => {
        if (file.driverInfo) {
            if (statusColor.includes('danger')) {
                driversDanger.push(file.driverInfo.externalDriverReference);
            }

            if (statusColor.includes('warning')) {
                driversWarning.push(file.driverInfo.externalDriverReference);
            }
        }
    };

    // eslint-disable-next-line immutable/no-let
    for (let index = 0; index < state.overviewFiles.length; index++) {
        const statusColor = getStatusColor(getDiffDays(state.overviewFiles[index].dueDate));

        if (state.overviewFiles[index].driverInfo === null) {
            checkVehicleInfo(
                state.overviewFiles[index],
                statusColor,
            );
            // eslint-disable-next-line no-continue
            continue;
        }

        checkDriverInfo(
            state.overviewFiles[index],
            statusColor,
        );
    }

    return {
        vehiclesWarning,
        vehiclesDanger,
        driversWarning,
        driversDanger,
    };
};

const pushOverviewVehicles = (state, vehiclesWarning, warning, vehiclesDanger, error) => {
    if (
        vehiclesWarning.length &&
        !state.disabledNotifications.includes('vehiclesDueDateWarning')
    ) {
        warning.push(<FormattedMessage
            id={'notifications.dialog.vehiclesDueDateWarning'}
            defaultMessage={
                '{amount} vehicles will reach their due date within the next 7 days.'
            }
            values={{ amount: vehiclesWarning.length, amountOfDays: 7 }}
        />);
    }

    if (
        vehiclesDanger.length &&
        !state.disabledNotifications.includes('vehiclesDueDateDanger')
    ) {
        error.push(<FormattedMessage
            id={'notifications.dialog.vehiclesDueDateDanger'}
            defaultMessage={
                '{amount} vehicles have exceeded their due date.'
            }
            values={{ amount: vehiclesDanger.length }}
        />);
    }
};

const pushOverviewDrivers = (state, driversWarning, warning, driversDanger, error) => {
    if (
        driversWarning.length &&
        !state.disabledNotifications.includes('driversDueDateWarning')
    ) {
        warning.push(<FormattedMessage
            id={'notifications.dialog.driversDueDateWarning'}
            defaultMessage={
                '{amount} drivers will reach their due date within the next 7 days.'
            }
            values={{ amount: driversWarning.length, amountOfDays: 7 }}
        />);
    }

    if (
        driversDanger.length &&
        !state.disabledNotifications.includes('driversDueDateDanger')
    ) {
        error.push(<FormattedMessage
            id={'notifications.dialog.driversDueDateDanger'}
            defaultMessage={
                '{amount} drivers have exceeded their due date.'
            }
            values={{ amount: driversDanger.length }}
        />);
    }
};

function getOverviewFilesNotifications(state = {}, error = [], warning = []) {
    if (
        state.overviewFiles &&
        state.overviewFiles.length
    ) {
        const {
            vehiclesWarning,
            vehiclesDanger,
            driversWarning,
            driversDanger,
        } = parseOverviewFilesNotificationInfo(state);

        pushOverviewVehicles(
            state,
            vehiclesWarning,
            warning,
            vehiclesDanger,
            error,
        );

        pushOverviewDrivers(
            state,
            driversWarning,
            warning,
            driversDanger,
            error,
        );
    }
}

function getRdaStatusNotifications(state = {}, error = []) {
    if (
        !state.rdaStatus.rdaOnline &&
        !state.disabledNotifications.includes('rdaOffline') &&
        (getNoOfSubscribedVehicles(
            '1',
            state.vehiclesWithSubscription,
        ) !== 0 || state.rdaSupported)
    ) {
        error.push(<FormattedMessage
            id={'notifications.dialog.rdaOffline'}
            defaultMessage={
                'There is no {rda} online.'
            }
            values={{ rda: 'Remote Download Application' }}
        />);
    }

    if (
        !state.rdaStatus.anyCompanyCardsOnline &&
        !state.disabledNotifications.includes('noCompanyCardsOnline') &&
        (getNoOfSubscribedVehicles(
            '1',
            state.vehiclesWithSubscription,
        ) !== 0 || state.rdaSupported)
    ) {
        error.push(<FormattedMessage
            id={'notifications.dialog.noCompanyCardsOnline'}
            defaultMessage={
                'There is no company card online.'
            }
        />);
    }
}

function recalculateNotifications(state) {
    const errorNotifications = [];
    const warningNotifications = [];
    const infoNotifications = [];

    getCompanyCardsNotifications(
        state,
        errorNotifications,
        warningNotifications,
        infoNotifications,
    );
    getOverviewFilesNotifications(
        state,
        errorNotifications,
        warningNotifications,
        infoNotifications,
    );
    getRdaStatusNotifications(
        state,
        errorNotifications,
        warningNotifications,
        infoNotifications,
    );

    return { ...state, errorNotifications, warningNotifications, infoNotifications };
}

const notifications = (state = initialState, action = {}) => { // eslint-disable-line max-lines-per-function
    switch (action.type) {
        case SET_COMPANY_CARDS:
            return recalculateNotifications({
                ...state,
                companyCards: action.payload.companyAssignedVehicleData,
                unassignedVehicleIds: action.payload.unassignedEquipmentIds,
            });
        case SET_DISABLED_NOTIFICATIONS:
            return recalculateNotifications({ ...state, disabledNotifications: action.payload });
        case SUCCESSFUL_FETCH_RDA_STATUS:
            return recalculateNotifications({ ...state, rdaStatus: action.payload });
        case SET_OVERVIEW_FILES:
            return recalculateNotifications({
                ...state,
                overviewFiles: action.payload.map(item => ({
                    ...item,
                    dueDate: getDueDate(
                        item.timeCreatedUtc,
                        item.fileType,
                    ),
                })),
            });
        case SET_SUCCESSFUL_COMPANY_CARD_REQUEST:
            return {
                ...state,
                successfulCompanyCardRequest: action.payload,
            };
        case SET_VEHICLES_WITH_SUBSCRIPTION:
            return {
                ...state,
                vehiclesWithSubscription: action.payload,
            };
        case USER_PROFILE_OBTAINED:
            return {
                ...state,
                userProfile: action.payload,
            };
        case SET_RDA_SUPPORTED:
            return {
                ...state,
                rdaSupported: action.payload,
            };
        default:
            return state;
    }
};

export default notifications;
