export function isCopyAvailable() {
    return document.queryCommandSupported('copy');
}

function b64toBlob(b64Data, contentType, sliceSize) {

    /* istanbul ignore next */
    const usedContentType = contentType || '';
    const usedSliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += usedSliceSize) {
        const slice = byteCharacters.slice(offset, offset + usedSliceSize);

        const byteNumbers = [slice.length];
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, {type: usedContentType});
}

// save b64 - content to a file
export function downloadFile(fileContent, type, name) {
    if (fileContent !== null) {
        const file = b64toBlob(fileContent, type);
        downloadBlob(file, name);
    }
}

// save blob to a file
export function downloadBlob(blob, name) {

    // for IE 10+
    /* istanbul ignore next */
    if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(blob, name);
    }

    const tempAnchor = document.createElement('a');
    const tempURL = window.URL.createObjectURL(blob);
    tempAnchor.href = tempURL;
    tempAnchor.download = name;
    document.body.appendChild(tempAnchor);
    tempAnchor.click();

    /* istanbul ignore next */
    setTimeout(() => {
        document.body.removeChild(tempAnchor);
        window.URL.revokeObjectURL(tempURL);
    }, 100);
}

function getHead(skipColumns, hlength, header) {
    let columns = 0;
    let nextSkipColumn = 0;
    const head = [];

    /* istanbul ignore next */
    while (columns < hlength) {
        if (!skipColumns || columns !== skipColumns[nextSkipColumn]) {
            if (header[columns].style.display !== 'none') {
                const innerText = header[columns].textContent === undefined ?
                    header[columns].innerText :
                    header[columns].textContent;
                head.push(`${innerText.replace(';', ' ')};`);
            }
        } else {
            nextSkipColumn++;
        }

        columns++;
    }

    return head.join('');
}

function getBody(skipColumns, hlength, length, body) {
    const resultBody = [];
    for (let rows = 0; rows < length / hlength; ++rows) {
        resultBody.push('\n');
        let nextSkipColumn = 0;

        for (let columns = 0; columns < hlength; ++columns) {

            /* istanbul ignore next */
            if (!skipColumns || columns !== skipColumns[nextSkipColumn]) {
                if (body[columns + rows * hlength].style.display !== 'none') {
                    const innerText = body[columns + rows * hlength].textContent === undefined ?
                        body[columns + rows * hlength].innerText :
                        body[columns + rows * hlength].textContent;
                    resultBody.push(`${innerText.replace(';', ' ')};`);
                }
            } else {
                nextSkipColumn++;
            }
        }
    }

    return resultBody.join('');
}

// skipColumns has to be a non-empty array (please set [-1] as default for non-skipping)
export function tableToCSV(table, skipColumns) {
    const header = table.querySelectorAll('tr th');
    const body = table.querySelectorAll('tr td');
    const hlength = header.length;
    const {length} = body;
    const result = [];

    result.push(getHead(skipColumns, hlength, header));
    result.push(getBody(skipColumns, hlength, length, body));

    return result.join('');
}

function selectElementContents(element) {
    const {body} = document;
    let range;
    let sel;

    /* istanbul ignore next */
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(element);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(element);
            sel.addRange(range);
        }
    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(element);
        range.select();
    }
}

function getTh(skipColumns, hlength, header) {
    const newTr = document.createElement('tr');

    let columns = 0;
    let nextSkipColumn = 0;

    /* istanbul ignore next */
    while (columns < hlength) {
        if (!skipColumns || columns !== skipColumns[nextSkipColumn]) {
            if (header[columns].style.display !== 'none') {
                const innerText = header[columns].textContent === undefined ?
                    header[columns].innerText :
                    header[columns].textContent;

                const newTh = document.createElement('th');
                newTh.innerHTML = innerText;
                newTr.appendChild(newTh);
            }
        } else {
            nextSkipColumn++;
        }
        columns++;
    }

    return newTr;
}
function getTr(skipColumns, rows, body, hlength) {
    const newTr = document.createElement('tr');
    let nextSkipColumn = 0;

    /* istanbul ignore next */
    for (let columns = 0; columns < hlength; ++columns) {
        if (!skipColumns || columns !== skipColumns[nextSkipColumn]) {
            if (body[columns + rows * hlength].style.display !== 'none') {
                const innerText = body[columns + rows * hlength].textContent === undefined ?
                    body[columns + rows * hlength].innerText :
                    body[columns + rows * hlength].textContent;

                const newTd = document.createElement('td');
                newTd.innerHTML = innerText;
                newTr.appendChild(newTd);
            }
        } else {
            nextSkipColumn++;
        }
    }

    return newTr;
}

// skipColumns has to be a non-empty array (please set [-1] as default for non-skipping)
export function copyTableToClipboard(table, skipColumns) {
    const header = table.querySelectorAll('tr th');
    const body = table.querySelectorAll('tr td');
    const hlength = header.length;
    const {length} = body;

    const cachingTable = document.createElement('table');
    const cachingThead = document.createElement('thead');
    const cachingTbody = document.createElement('tbody');

    // Place in top-left corner of screen regardless of scroll position.
    cachingTable.style.position = 'fixed';
    cachingTable.style.top = 0;
    cachingTable.style.left = 0;

    cachingThead.appendChild(getTh(skipColumns, hlength, header));

    for (let rows = 0; rows < length / hlength; ++rows) {
        cachingTbody.appendChild(getTr(skipColumns, rows, body, hlength));
    }

    cachingTable.appendChild(cachingThead);
    cachingTable.appendChild(cachingTbody);
    document.body.appendChild(cachingTable);
    selectElementContents(cachingTable);

    document.execCommand('copy');

    document.body.removeChild(cachingTable);
}
