
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
    downloadManual,
    setSettingsSidebarOpen,
} from '../../actions/administration';

import { AREPERMISSIONSHIDDEN, COMPANY_CARDS_TAB } from '../../constants/archive';
import ForbiddenPage from '../../features/DefaultPages/ForbiddenPage';
import withPermissions from '../../utils/withPermissions';
import {switchToTab} from '../../features/utils/routing';

import downloadRDA from '../../fetches/RDA/downloadRDA';

import getRDAStatus from '../../fetches/RDA/getRDAStatus';

import store from '../../setup/store';

import getCompanyCards from '../../fetches/CompanyCards/getCards';

import { getAccessToken } from '../../features/tokenHandling/selectors';

import GuidancePage from './GuidancePage';

export const GuidanceContainer = props => {

    const component = (<div className={'ArchiveContainer'}>{
        <GuidancePage
            {...props}
            openSettingsSidebar={props.openIntervalSettings}
        />
    }</div>);

    const renderComponentwithPermission = () => withPermissions(
        props.permissions,
        'rda-installer.get',
        component,
    );

    return withPermissions(
        props.permissions,
        AREPERMISSIONSHIDDEN ? '' : 'file-list.read',
        renderComponentwithPermission(),
        <ForbiddenPage/>,
    );
};

export const mapStateToProps = state => ({
    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    loadingRDA: get(
        state,
        'tachographservices.administration.loadingRDA',
    ),
    isRDAOnline: get(
        state,
        'tachographservices.administration.isRDAOnline',
    ),
    isCompanyCardAvailable: get(
        state,
        'tachographservices.administration.isCompanyCardAvailable',
    ),
    isRDAStatusFetched: get(
        state,
        'tachographservices.administration.isRDAStatusFetched',
    ),
    companyCards: get(
        state,
        'tachographservices.notifications.companyCards',
    ),
    vehiclesWithSubscription: get(
        state,
        'tachographservices.tree.vehiclesWithSubscription',
    ),
    rawVehicles: get(
        state,
        'tachographservices.tree.rawVehicles',
    ),
    accessToken: getAccessToken(state),
});

export const mapDispatchToProps = dispatch => ({

    openIntervalSettings: () => {
        dispatch(setSettingsSidebarOpen(true));
    },

    downloadRDA: async() => {
        await downloadRDA(dispatch);
    },

    downloadManual: () => {
        dispatch(downloadManual());
    },

    getRDAStatus: () => {
        getRDAStatus(
            store.getState().tachographservices.administration,
            dispatch,
        );
    },
    getCompanyCards: () => {
        getCompanyCards(dispatch);
    },
    selectCompanyCardsTab: () => {
        switchToTab(COMPANY_CARDS_TAB);
    },

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GuidanceContainer);
