
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TableWrapper from '../../common/TableWrapper';

const getActions = props => {
    if (!props.isExportEnabled) {
        return [];
    }

    return [
        {
            value: (
                <span>
                    <span className={'margin-right-5'}>
                        <FormattedMessage id={'archive.safeFiles'}/>
                    </span>
                    {`(${props.selectedElements.length})`}
                </span>
            ),
            disabled: !props.isDownloadable,
            onSelect: props.downloadSelectedFiles,
        }, {
            value: <FormattedMessage id={'archive.safeAllFiles'}/>,
            onSelect: props.downloadAllFilesForFilters,
            disabled: !props.areAllFilteredFilesDownloadable,
        },
    ];
};

const ListFilesTable = props => <TableWrapper
    {...props}
    baseKey={'Archive-ListFilesTable'}
    className={'ListFilesTable'}
    enableRowSelection={props.selectionEnabled}
    isOverviewSelected = {props.isOverviewSelected}
    clickToSelect={props.selectionEnabled}
    selectedElements={props.selectionEnabled && props.selectedElements}
    onSelectionChange={props.selectionEnabled && props.onSelectionChange}
    keyField={'fileId'}
    stickyPosition
    stickyPositionOffset={'-30px'}
    hover
    additionalBatchButtonItems={
        props.additionalBatchButtonItems ||
        getActions({
            isExportEnabled: props.isExportEnabled,
            selectedElements: props.selectedElements || [],
            isDownloadable: props.isDownloadable,
            downloadSelectedFiles: props.downloadSelectedFiles,
            downloadAllFilesForFilters: props.downloadAllFilesForFilters,
            areAllFilteredFilesDownloadable: props.areAllFilteredFilesDownloadable,
        })
    }
/>;

ListFilesTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        fileId: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.string,
            PropTypes.number,
        ]),
        fileName: PropTypes.string,
        exportedOn: PropTypes.instanceOf(Date),
        truckDisplayName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        driverDisplayName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        transferDate: PropTypes.instanceOf(Date),
        tachograph: PropTypes.string,
        driverCard: PropTypes.string,
        transferType: PropTypes.string,
        deletionDate: PropTypes.instanceOf(Date),
        dueDate: PropTypes.instanceOf(Date),
        plannedDownloadDate: PropTypes.instanceOf(Date),
    })),
    options: PropTypes.object,
    selectionEnabled: PropTypes.bool,
    isOverviewSelected: PropTypes.bool,
    selectedElements: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])),
    onSelectionChange: PropTypes.func,
    trClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),

    additionalBatchButtonItems: PropTypes.array,
    isExportEnabled: PropTypes.bool,
    isDownloadable: PropTypes.bool,

    downloadSelectedFiles: PropTypes.func,
    downloadAllFilesForFilters: PropTypes.func,
    areAllFilteredFilesDownloadable: PropTypes.bool,
};

export default ListFilesTable;
