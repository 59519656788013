import pkg from '../../package.json'; // eslint-disable-line import/no-namespace

export const currentVersion = pkg.version;

export const RELEASE_NOTES_ITEM = 'TACHOGRAPHSERVICES.RELEASENOTES';

const currentVersionNumbers = currentVersion.split('.');

export const isNewVersion = localVersion => {
    if (!localVersion) {
        return true;
    }

    const localVersionNumbers = localVersion.split('.');

    return !(
        currentVersionNumbers[0] === localVersionNumbers[0] &&
        currentVersionNumbers[1] === localVersionNumbers[1]
    );
};
