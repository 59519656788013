import { useIntl } from 'react-intl';

import {ONE_DAY_SECONDS} from '../../constants/common';
import formatWithAllSpaces from '../../utils/formatWithAllSpaces';

const dangerTextClasses = 'text-danger';
const warningTextClasses = 'text-warning';
const successTextClasses = 'text-success';

const STATUS_DANGER_DAYS = 0;
const STATUS_WARNING_DAYS = -7;
const ONE_DAY_MILLISECONDS = ONE_DAY_SECONDS * 1000; // eslint-disable-line no-magic-numbers

export const getStatusColor = diffDays => {
    const dangerColor = diffDays > STATUS_DANGER_DAYS ? dangerTextClasses : warningTextClasses;

    return diffDays >= STATUS_WARNING_DAYS ? dangerColor : successTextClasses;
};

export const getDiffText = diffDays => {
    if (diffDays > 0) {
        return `+ ${diffDays} `;
    } else if (diffDays === 0) {
        return formatWithAllSpaces('  0 ');
    }

    return `- ${0 - diffDays} `;
};

const getDaysFromMilliSeconds = date => Math.floor(date / ONE_DAY_MILLISECONDS);
export const getDiffDays = cell => getDaysFromMilliSeconds(new Date()) - getDaysFromMilliSeconds(cell);

export const GetTranslateText = textId => {
    const intl = useIntl();
    const plainText = intl.formatMessage({ id: textId });

    return plainText;
};
