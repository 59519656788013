import { env } from '../env';

const {
    runsInSandbox,
    isRunningOnProd,
} = env;

export const ISUPLOADHIDDEN = true;

export const AREPERMISSIONSHIDDEN = runsInSandbox;

export const LOADINGDATA = 'loadingData';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const UPLOAD = 'Upload';
export const MANDOWNLOAD = 'Manual Download';
export const MANDOWNLOADTRIGGER = 0;
export const AUTODOWNLOAD = 'Automatic Download';
export const AUTODOWNLOADTRIGGER = 1;

export const ROWLIMIT = 50;

export const WAITINGFORDATA = [
    {
        fileId: LOADINGDATA,
        status: LOADINGDATA,
        timeCreatedUtc: LOADINGDATA,
        fileName: LOADINGDATA,
    },
];

export const REMOTE_DOWNLOAD_TAB = 'archive';
export const MANUAL_DOWNLOAD_TAB = 'manualdownload';
export const COMPANY_CARDS_TAB = 'companycards';
export const MANUAL_TAB = 'guidance';
export const OVERVIEW_TAB = 'overview';
export const ANALYSIS_PORTAL = 'analysisportal';

export const EXPORTAZPS = {
    'b8a414b3-6f71-453e-b53b-6c02a2925004': 'frontend',
    '7c786b78-0151-4c71-9aac-40d019f46db3': 'frontend',
    '15uOlXP6YHJK9FeBreXwzQa_AH4a': 'frontend',
    '1adf7812-7f75-45bf-9900-7c7d357e4d3c': 'API',
    'c7c74411-d5d8-4010-82a6-e734e0860e8c': 'API',
    '0fa67d38-016c-4618-8400-45ce1c776da8': 'Stoneridge OPTAC3',
    '16cb2b34-3dfa-49b1-8b10-42d384c73ab7': 'TX-SOCIAL',
    '24922ed0-fe6a-43d9-a311-e81227a4b35c': 'TachoPlus',
    '24a82047-2aac-4ec4-9806-9ca993a64949': 'S3pweb',
    '3bdea07f-5e8c-4ab8-8884-77d82e0c4689': 'Nufatron',
    '45b565c1-085d-4d0f-9bff-0a2611abc645': 'Data Standards',
    '813c3de4-fc83-45aa-97de-ac7695325794': 'PROCON DATA',
    'a7d19e53-9819-475f-bea1-7ca78d0c9364': 'Volmax',
    'c4089c4a-5a9e-4dd9-b65f-fac1d71d9e80': 'idha-Online',
    'ca6636a4-743a-47bd-aa56-41ad5bdb586e': 'TachoManagement',
    'd6beb312-64f2-4259-91e2-6f2c37e303e4': 'Modulon',
    'fec785fb-ed15-4ecd-a3ec-d2860d498f86': 'DAKO TachoWeb',
    oX3cDQad8M2A62qCjySDQp0LziIa: 'VDO TIS-Web',
    'cf7b2824-110d-4361-88a4-8e16b3291ca3': 'Tachoeasy',
};

export const FRONTENDAZPS = [
    'b8a414b3-6f71-453e-b53b-6c02a2925004',
    '7c786b78-0151-4c71-9aac-40d019f46db3',
    '15uOlXP6YHJK9FeBreXwzQa_AH4a',
];

export const APIAZPS = [
    '1adf7812-7f75-45bf-9900-7c7d357e4d3c',
    'c7c74411-d5d8-4010-82a6-e734e0860e8c',
];

export const PARTNERAZPS = [
    '0fa67d38-016c-4618-8400-45ce1c776da8',
    '16cb2b34-3dfa-49b1-8b10-42d384c73ab7',
    '24922ed0-fe6a-43d9-a311-e81227a4b35c',
    '24a82047-2aac-4ec4-9806-9ca993a64949',
    '3bdea07f-5e8c-4ab8-8884-77d82e0c4689',
    '45b565c1-085d-4d0f-9bff-0a2611abc645',
    '813c3de4-fc83-45aa-97de-ac7695325794',
    'a7d19e53-9819-475f-bea1-7ca78d0c9364',
    'c4089c4a-5a9e-4dd9-b65f-fac1d71d9e80',
    'ca6636a4-743a-47bd-aa56-41ad5bdb586e',
    'd6beb312-64f2-4259-91e2-6f2c37e303e4',
    'fec785fb-ed15-4ecd-a3ec-d2860d498f86',
    'oX3cDQad8M2A62qCjySDQp0LziIa',
    'cf7b2824-110d-4361-88a4-8e16b3291ca3',
];

const DRIVERS_PROD_MIN_INTERVAL = 2;
export const DRIVERS_STAGING_MIN_INTERVAL = 1;
export const DRIVERS_MIN_INTERVAL = isRunningOnProd ? DRIVERS_PROD_MIN_INTERVAL : DRIVERS_STAGING_MIN_INTERVAL;
export const DRIVERS_MAX_INTERVAL = 28;

const VEHICLES_PROD_MIN_INTERVAL = 10;
export const VEHICLES_STAGING_MIN_INTERVAL = 1;
export const VEHICLES_MIN_INTERVAL = isRunningOnProd ? VEHICLES_PROD_MIN_INTERVAL : VEHICLES_STAGING_MIN_INTERVAL;
export const VEHICLES_MAX_INTERVAL = 90;

export const TIME_RANGE_TYPE = {
    TODAY: 'TODAY',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_WEEK: 'LAST_WEEK',
    LAST_MONTH: 'LAST_MONTH',
    CUSTOM: 'CUSTOM',
};
