/* eslint-disable immutable/no-mutation */
import moment from 'moment';

import cloneDeep from 'lodash/fp/cloneDeep';

import {
    SET_SELECTED_ROWS,
    SET_TIME_RANGE,
    SET_TOTAL_FILES_COUNT,
    SET_ACTIVE_PAGE,
    SUCCESSFUL_FETCH_DATA,
    ADD_DOWNLOADING_FILES,
    REMOVE_DOWNLOADING_FILES,
    SET_SORTING_BEHAVIOUR,
    SET_LIST_FILES_DATA,
    REFETCH_ARCHIVE_DATA,
    SET_TIME_RANGE_TYPE,
    SET_END_DATE,
    SET_START_DATE,
} from '../actions/archive';

import { TIME_RANGE_TYPE, ROWLIMIT } from '../constants/archive';

const nextElement = 1;

// eslint-disable-next-line prefer-named-capture-group
const matchesSelectedHours = (/selectedhours=([^#&=]*)/u).exec(window.location.search);

const ONE_WEEK_DAYS = 7;

// istanbul ignore next
const selectedHours = matchesSelectedHours ?
    decodeURIComponent(matchesSelectedHours[nextElement]) :
    false;
const startTime = new Date();

// istanbul ignore next
if (selectedHours) {
    startTime.setHours(startTime.getHours() - selectedHours);
}
const endTime = new Date();

const defaultStartTime = new Date(moment()
    .subtract(
        ONE_WEEK_DAYS,
        'days',
    )
    .startOf('day')
    .toISOString());
const defaultEndTime = new Date(moment()
    .endOf('day')
    .toISOString());

export const initialState = {
    objectTreeConfig: {},
    listFilesData: [],
    selectedRows: [],
    selectedTimeRangeType: TIME_RANGE_TYPE.CUSTOM,
    start: selectedHours ?
        /* istanbul ignore next */
        startTime :
        defaultStartTime,
    end: selectedHours ?
        /* istanbul ignore next */
        endTime :
        defaultEndTime,
    activePage: {},
    listFilesActivePage: 0,
    totalFilesCount: {},
    listFilesTotalFilesCount: null,
    limit: ROWLIMIT,
    listFilesLimit: ROWLIMIT,
    downloadingFiles: [],
    sortFieldArchive: 'timeCreatedUtc',
    sortFieldOverview: 'timeCreatedUtc',
    sortOrderArchive: 'desc',
    sortOrderOverview: 'desc',
    requestTimeStamp: new Date(),
    shouldRefetchArchive: false,
};

const DUE_DATE_MILLISECONDS_VEHICLE = 7776000000;
const DUE_DATE_MILLISECONDS_DRIVER = 2419200000;
// eslint-disable-next-line no-magic-numbers
const MILLISECONDS_DAY = 24 * 60 * 60 * 1000;
export const getDueDate = (createdTime, type) => {
    const createdDate = new Date(createdTime);
    const dueDateSeconds = type === 1 ? DUE_DATE_MILLISECONDS_DRIVER : DUE_DATE_MILLISECONDS_VEHICLE;
    return new Date(createdDate.getTime() + dueDateSeconds);
};

const getActivePage = action => {
    const rawActivePage = action.payload.pagination.offset / action.payload.pagination.limit;
    return rawActivePage + nextElement;
};

const calculatePlannedDownloadDate = (createdTime, interval) => {
    const createdDate = new Date(createdTime);
    // eslint-disable-next-line no-extra-parens
    return new Date(createdDate.getTime() + (interval * MILLISECONDS_DAY));
};

// eslint-disable-next-line max-statements, max-lines-per-function
const fetchDataArchive = (state, action) => {
    switch (action.type) {
        case SUCCESSFUL_FETCH_DATA: {
            if (action.payload.requestTimeStamp.getTime() < state.requestTimeStamp.getTime()) {
                return state;
            }
            return {
                ...state,
                listFilesData: action.payload.items.map(item => ({
                    ...item,
                    dueDate: getDueDate(
                        item.timeCreatedUtc,
                        item.fileType,
                    ),
                    plannedDownloadDate: item.plannedDownloadDate ?
                        calculatePlannedDownloadDate(
                            item.timeCreatedUtc,
                            item.downloadInterval,
                        ) :
                        null,
                })),
                listFilesTotalFilesCount: action.payload.totalCount,
                listFilesLimit: action.payload.pagination.limit,
                listFilesActivePage: getActivePage(action),
                requestTimeStamp: action.payload.requestTimeStamp,
                shouldRefetchArchive: false,
            };
        }
        case REFETCH_ARCHIVE_DATA: {
            return {
                ...state,
                shouldRefetchArchive: action.payload,
            };
        }
        default:
            return state;
    }
};

// eslint-disable-next-line max-lines-per-function
const extendedArchive = (state, action) => {
    switch (action.type) {
        case SET_LIST_FILES_DATA: {
            return {
                ...state,
                listFilesData: action.payload,
            };
        }
        case SET_TOTAL_FILES_COUNT:
            return {
                ...state,
                totalFilesCount: action.payload,
            };
        case SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload,
            };
        case ADD_DOWNLOADING_FILES:
            return {
                ...state,
                downloadingFiles: state.downloadingFiles.concat(action.payload),
            };
        case REMOVE_DOWNLOADING_FILES: {
            const newDownloadingFiles = cloneDeep(state.downloadingFiles);
            const removedFiles = [];

            return {
                ...state,
                downloadingFiles: newDownloadingFiles.filter(file => {
                    if (action.payload.includes(file) && !removedFiles.includes(file)) {
                        removedFiles.push(file);
                        return false;
                    }

                    return true;
                }),
            };
        }
        case SET_SORTING_BEHAVIOUR:
            if (action.payload.isOverviewSelected) {
                return {
                    ...state,
                    sortFieldOverview: action.payload.sortField,
                    sortOrderOverview: action.payload.sortOrder,
                };
            }
            return {
                ...state,
                sortFieldArchive: action.payload.sortField,
                sortOrderArchive: action.payload.sortOrder,
            };
        default:
            return fetchDataArchive(
                state,
                action,
            );
    }
};

const archive = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SELECTED_ROWS:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case SET_TIME_RANGE_TYPE:
            return {
                ...state,
                selectedTimeRangeType: action.payload,
            };
        case SET_TIME_RANGE:
            return {
                ...state,
                start: action.payload.start,
                end: action.payload.end,
            };

        case SET_END_DATE:
            return {
                ...state,
                end: action.payload,
            };

        case SET_START_DATE:
            return {
                ...state,
                start: action.payload,
            };
        default:
            return extendedArchive(
                state,
                action,
            );
    }
};

export default archive;
