import { combineReducers } from 'redux';

import { SUPPORTED_LANGUAGES } from '../lang/translationSetup';
import messagesEN from '../lang/tachographservices_en-GB.json';

import tokenHandlingReducer from '../features/tokenHandling/tokenHandlingReducer';
import tachoReducers from '../reducers/combined';

import loginReducer from '../common/tacho-login/reducer';

import getLangReducer from '../common/tacho-lang/getLangReducer';

import configReducer from './configReducer';

const allReducers = {
    config: configReducer,
    lang: getLangReducer(
        messagesEN,
        SUPPORTED_LANGUAGES,
    ),
    tokenHandling: tokenHandlingReducer,
    tachographservices: tachoReducers,
    login: loginReducer,
};

const rootReducer = combineReducers(allReducers);

export default rootReducer;
