
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import setRetentionPeriodFetch from '../../../fetches/Settings/setRetentionPeriod';
import store from '../../../setup/store';
import { cloudStorageChanged, setRetentionPeriod } from '../../../actions/common';

import CloudStorageSettingsSidebar from './CloudStorageSettingsSidebar';

export const CloudStoragesSettingsContainer = props => <CloudStorageSettingsSidebar {...props}/>;
CloudStoragesSettingsContainer.propTypes = {
    permissions: PropTypes.array,

    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
};

export const mapDispatchToProps = dispatch => ({
    saveRetentionPeriod: payload => {
        dispatch(setRetentionPeriod(payload));
        dispatch(cloudStorageChanged());
        setRetentionPeriodFetch(
            store.getState().tokenHandling.accessToken,
            payload,
        );
    },
});

export const mapStateToProps = state => ({
    retentionPeriodValue: state.tachographservices.common.retentionPeriod,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CloudStoragesSettingsContainer);
