export const localConfig = {
    homeRoute: 'https://www.https://www.home.rio.cloud',
    backend: {
        AUTHENTICATION_SERVICE: window.location.origin,
        LOCATION_SERVICE: window.location.origin,
        TACHOGRAPH_SERVICES_SERVICE: window.location.origin,
        RETENTION_PATH: `${window.location.origin}/dataremover/retention-periods/fleet`,
        TACHO_SETTINGS_PATH: `${window.location.origin}/settings`,
        VEHICLES_WITH_SUBSCRIPTION_PATH: `${window.location.origin}/downloadscheduler/vehicles-with-subscription`,
        ANALYSIS_PORTAL: 'https://man.tachoweb.eu'
    },
    id: 'env.production',
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        uri: 'https://auth.iam.rio.cloud/oauth/authorize',
        clientId: 'b8a414b3-6f71-453e-b53b-6c02a2925004',
        callbackUri: 'https://static.tachographservices.int-ccp-dev.net/redirectlogin',
        redirectUri: `https://remotedownload.rio.cloud/redirect.html`,
        silentRedirectUri: `https://remotedownload.rio.cloud/redirect.html`,
        preventRedirect: false,
    },
};
