
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import {setSuccessfulRequestDownload} from '../../actions/administration';
import {fetchDataPOST, forbidden, noContent, notFound, unauthorized} from '../../sagas/fetchUtils';

import {getDownloadSchedulerURL} from './getListOfRequests';

const url = `${getDownloadSchedulerURL()}cancel-manual-download`;

const cancelDownload = (
    dispatch,
    body,
    injectedFetch = fetchDataPOST,
) => injectedFetch(
    url,
    body,
)
    .then(res => {
        if (res.error) {
            switch (res.error) {
                case noContent:
                    dispatch(setSuccessfulRequestDownload(true));
                    return true;
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                case notFound:
                    Notification.error(<FormattedMessage
                        id={'error.404'}
                        defaultMessage={
                            'Service not found. Please try again later.'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.500'}
                        defaultMessage={
                            'An internal error has occurred. Please try again later.'
                        }
                    />);
            }
            throw new Error('tacho request download');
        }

        return res;
    })
    .then(() => {
        dispatch(setSuccessfulRequestDownload(true));
        Notification.success(<FormattedMessage
            id={'administration.downloadCancelled'}
            defaultMessage={'Download request cancelled'}
        />);

        return true;
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
        return false;
    });

export default cancelDownload;
