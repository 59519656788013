import { configureEnv } from '../../env';

export const supportsLocalStorage = window => {
    try {
        const key = 'RIO_SUPPORTS_LOCAL_STORAGE';
        window.localStorage.setItem(
            key,
            true,
        );
        const isSupported = window.localStorage.getItem(key);
        window.localStorage.removeItem(key);
        return isSupported;
    } catch (_) {
        // eslint-disable-next-line no-console
        console.warn('[feature/tokenHandling] localStorage not supported!');

        // Not supported for some reason
        return false;
    }
};

const configureStorage = (window, process) => {
    const env = configureEnv(
        window,
        process,
    );

    if (env.isLocalEnv && supportsLocalStorage(window)) {
        // eslint-disable-next-line no-console
        console.warn('[feature/tokenHandling] Using localStorage to store "access_token" for local development.');

        const { localStorage } = window;
        const key = 'access_token_tachograph_services';
        return {
            discardAccessToken: () => localStorage.removeItem(key),
            getAccessToken: () => localStorage.getItem(key),
            hasAccessToken: () => Boolean(localStorage.getItem(key)),
            saveAccessToken: token => localStorage.setItem(
                key,
                token,
            ),
        };
    }
    let accessToken = null; // eslint-disable-line immutable/no-let
    return {
        discardAccessToken: () => {
            accessToken = null;
        },
        getAccessToken: () => accessToken,
        hasAccessToken: () => Boolean(accessToken),
        saveAccessToken: token => accessToken = token, // eslint-disable-line no-return-assign
    };

};

export default configureStorage;
