/* eslint-disable default-param-last */
import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const removeCompanyCardFetch = (
    token,
    card = {},
    injectedFetch,
) => injectedFetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard/${card.companyChipId || ''}`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'DELETE',
    },
);

const removeCompanyCard = (
    token,
    card = {},
    injectedFetch = window.fetch,
) => removeCompanyCardFetch(
    token,
    card,
    injectedFetch,
)
    .then(res => {
        if (res.ok) {
            return res.json();
        }

        throw new Error('tacho edit company card call failed');
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default removeCompanyCard;
