
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { env } from '../../env';

import {fetchData, forbidden, unauthorized, getOffsetText, setRawFilter} from '../../sagas/fetchUtils';

import { getDataListRequestBody} from '../../sagas/archive/fetchData';

import { addDownloadingFiles, removeDownloadingFiles, downloadAllArchiveFiles } from '../../actions/archive';

import downloadFiles from './downloadFiles';

import { capitalizeFirstLetter } from './fetchListFiles';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getAllLatestFilesUrl = payload => {
    const { activePage, limit, sortField, sortOrder } = payload;
    const baseUrl = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/Customers/`;
    const offsetText = activePage ?
        getOffsetText(
            activePage,
            limit,
        ) :
        '';
    const limitText = `&limit=${encodeURIComponent(10000000)}`; /* eslint-disable-line no-magic-numbers */
    const sortFieldText = sortField ? `&sortField=${capitalizeFirstLetter(sortField)}` : '';
    const sortOrderText = sortOrder ? `&sortOrder=${sortOrder}` : '';

    return `${baseUrl}Latest?${offsetText}${limitText}${sortFieldText}${sortOrderText}&extraInfo=true`;
};

const getParams = payload => {
    const requestBody = getDataListRequestBody(
        payload.startValue,
        payload.endValue,
        payload.selectedIds,
        payload.onlyLatest,
        payload.authorizedParties,
        payload.overviewSelected,
        payload.notExported,
        payload.currentCategoryId,
        payload.entityIdentifier,
        payload.hasSpeedData,
    );

    setRawFilter(
        payload.rawFilter,
        requestBody,
    );

    if (payload.downloadTrigger || payload.downloadTrigger === 0) {
        requestBody.downloadTrigger = payload.downloadTrigger;
    }

    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    };

};

export const handleError = error => {
    switch (error) {
        case unauthorized:
        case forbidden:
            Notification.error(<FormattedMessage
                id={'error.401'}
                defaultMessage={
                    'Error : 401'
                }
            />);
            break;
        default:
            Notification.error(<FormattedMessage
                id={'error.fileExport'}
                defaultMessage={
                    'File export failed. Please try again later.'
                }
            />);
    }
};

export const downloadAllLatestFiles = async(
    payload,
    dispatch,
    injectedFetch = fetchData,
) => {
    const res = await injectedFetch(
        getAllLatestFilesUrl(payload),
        getParams(payload),
    );

    if (res.error) {
        handleError(res.error);
    } else {
        const fileIds = res.items.map(item => item.fileId);
        dispatch(addDownloadingFiles(fileIds));
        await downloadFiles(fileIds);
        dispatch(removeDownloadingFiles(fileIds));
    }
};

const getDownloadGroupedFilesUrl = payload => {
    const { activePage, limit, sortField, sortOrder } = payload;
    const baseUrl = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/Customers/`;
    const offsetText = activePage ?
        getOffsetText(
            activePage,
            limit,
        ) :
        '';
    const limitText = `&limit=${encodeURIComponent(10000000)}`; /* eslint-disable-line no-magic-numbers */
    const sortFieldText = sortField ? `&sortField=${capitalizeFirstLetter(sortField)}` : '';
    const sortOrderText = sortOrder ? `&sortOrder=${sortOrder}` : '';

    return `${baseUrl}Files?${offsetText}${limitText}${sortFieldText}${sortOrderText}&extraInfo=true`;
};

export const downloadGroupedFiles = async(
    payload,
    dispatch,
    injectedFetch = fetchData,
) => {
    const res = await injectedFetch(
        getDownloadGroupedFilesUrl(payload),
        getParams(payload),
    );

    if (res.error) {
        handleError(res.error);
    } else {
        const fileIds = res.items.map(item => item.fileId);
        dispatch(addDownloadingFiles(fileIds));
        await downloadFiles(fileIds);
        dispatch(removeDownloadingFiles(fileIds));
    }
};

const downloadAllFiles = async(payload, dispatch) => {
    dispatch(downloadAllArchiveFiles());
    try {
        if (payload.onlyLatest) {
            await downloadAllLatestFiles(
                payload,
                dispatch,
            );
        } else {
            await downloadGroupedFiles(
                payload,
                dispatch,
            );
        }
    } catch (err) {
        console.log(err); // eslint-disable-line no-console
    }
};

export default downloadAllFiles;
