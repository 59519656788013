import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const CompliantWidget = ({
    header,
    subHeader,
    description,
    footer,
    newFeature,
}) => (<div className={'panel margin-bottom-20'}>
    <div className={'panel-body'} style={{minHeight: '185px'}}>
        <div className={'lead marpgin-bottom-0'}>
            <div className={'dislay-flex justify-content-between'}>
                <span>
                    <span style={{fontWeight: 'bold'}}>{header || ''}</span>
                    <span className={'text-size-14'}>
                        { subHeader || ''}
                    </span>
                </span>
                {newFeature ?
                    <span style={{float: 'right'}} className={'label label-success label-filled'}>
                        <FormattedMessage id={'new'} />
                    </span> :
                    ''}
                <div className={'text-size-14'}>
                    { description || ''}
                </div>
            </div>
        </div>

    </div>
    <div className={'panel-footer'}>
        <span>
            { footer || ''}
        </span>
    </div>
</div>);

CompliantWidget.propTypes = {
    header: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    subHeader: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    description: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    footer: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    newFeature: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.bool,
    ]),

    getData: PropTypes.func,
};

export default CompliantWidget;
