
import PropTypes from 'prop-types';

import Checkbox from '@rio-cloud/rio-uikit/Checkbox';

const CheckboxFilterComponent = props => {
    const { children, toggle, checked } = props;

    return (
        <div className={'CheckboxFilterComponent form-group'}>
            <Checkbox onClick={toggle} checked={Boolean(checked)}>
                { children }
            </Checkbox>
        </div>
    );
};

CheckboxFilterComponent.propTypes = {
    toggle: PropTypes.func,
    checked: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default CheckboxFilterComponent;
