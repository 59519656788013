import { env } from '../../env';
import { setVehiclesWithSubscription } from '../../actions/common';
import saveFetch from '../../features/utils/saveFetch';

const { VEHICLES_WITH_SUBSCRIPTION_PATH } = env.runtimeConfig.backend;

const getVehiclesWithSubscriptionFetch = (
    token,
    injectedFetch = window.fetch,
) => () => injectedFetch(
    `${VEHICLES_WITH_SUBSCRIPTION_PATH}`,
    {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    store.dispatch(setVehiclesWithSubscription(data));
};

const configureFetchVehiclesWithSubscription = store => new Promise(resolve => {
    const fetchVehiclesWithSubscription = getVehiclesWithSubscriptionFetch(store.getState().tokenHandling.accessToken);

    saveFetch(
        fetchVehiclesWithSubscription,
        'vehicles with subscription',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchVehiclesWithSubscription;
