
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.defaultDownloadIntervals.step1.title'}
            defaultMessage={'Settings Button'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.defaultDownloadIntervals.step2.title'}
            defaultMessage={'Configure Intervals'}
        />,
    }, {
        icon: '3',
        label: <FormattedMessage
            id={'glossary.compliant.defaultDownloadIntervals.step3.title'}
            defaultMessage={'Save Changes'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.defaultDownloadIntervals.step1.text'}
                            defaultMessage={'Click on the Settings Button.'}
                        />
                    </div>
                    <ReleaseNotesImage image={'defaultDownloadIntervalsStep1.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 1:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.defaultDownloadIntervals.step2.text'}
                            defaultMessage={
                                'Configure the remote download intervals for tachograph files and driver card files. ' +
                                'You can adjust the default download intervals for all vehicles and drivers.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'defaultDownloadIntervalsStep2.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 2:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.defaultDownloadIntervals.step3.text'}
                            defaultMessage={
                                'Click on "Save" to change the settings.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'defaultDownloadIntervalsStep3.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        default:
            return <div/>;
    }
};

const DefaultDownloadIntervalsExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default DefaultDownloadIntervalsExplanation;
