import React from 'react';

export const ACTIVE_CLASS = 'active';
export const DATA_ATTRIBUTE = 'data-key';

export const getTextAlignClass = (details = {}) => {
    const dataAlign = details.dataAlign || 'left';
    const dataAlignString = typeof dataAlign === 'function' ? dataAlign() : dataAlign;

    return `text-${dataAlignString}`;
};

export const getSortOrder = (sortField, previousSortField, previousSortOrder) => {
    if (sortField === previousSortField) {
        return previousSortOrder === 'asc' ?
            'desc' :
            'asc';
    }
    return 'asc';
};

export const renderTableCol = (column, columnDetails) => {
    const style = columnDetails && columnDetails.width ?
        {
            minWidth: columnDetails.width,
            width: columnDetails.width,
        } :
        {};

    return <col key={column} style={style} />;
};

export const getRowByDataAttribute = (rows = [], value = '', attribute = DATA_ATTRIBUTE) => rows.find(row => {
    const dataAttribute = row.attributes[attribute];
    if (dataAttribute) {
        return dataAttribute.value === value;
    }
    return false;
});

export const highlightRow = (rows, driverId) => {
    const row = getRowByDataAttribute(
        rows,
        driverId,
    );
    if (row) {
        row.classList.add(ACTIVE_CLASS);
    }
};

export const removeHighlightFromRow = (rows, driverId) => {
    const row = getRowByDataAttribute(
        rows,
        driverId,
    );
    if (row) {
        row.classList.remove(ACTIVE_CLASS);
    }
};

export const getCheckboxCell = row => [...row.children]
    .find(child => child.className && child.className === 'table-checkbox');

export const selectRow = (rows, rowId, dataAttribute) => {
    const row = getRowByDataAttribute(
        rows,
        `${rowId}`,
        dataAttribute,
    );
    if (row) {
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = true;
        }
    }
};

export const deselectRow = (rows, rowId, dataAttribute) => {
    const row = getRowByDataAttribute(
        rows,
        `${rowId}`,
        dataAttribute,
    );
    if (row) {
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = false;
        }
    }
};

export const setHeaderCheckboxState = (rows, selectedRowIds) => { // eslint-disable-line max-statements
    const [headerRow] = rows;
    if (headerRow) {
        const checkboxCell = getCheckboxCell(headerRow);
        if (checkboxCell) {
            const checkbox = checkboxCell.firstChild.firstChild;

            const headerRowPlace = 1;

            // without the header row
            const amountRows = rows.length - headerRowPlace;
            const amountSelectedRows = selectedRowIds.length;

            if (amountRows === amountSelectedRows) {
                checkbox.indeterminate = false;
                checkbox.checked = true;
            } else if (amountSelectedRows) {
                checkbox.indeterminate = true;
            } else {
                checkbox.indeterminate = false;
                checkbox.checked = false;
            }
        }
    }
};
