// Note that IE11 support is only provided up until v5
import { merge } from 'lodash/fp';
import queryString from 'query-string';

import { localConfig } from './env/config';
import { productionConfig } from './env/config.production';
import { sandboxConfig } from './env/config.sandbox';
import { testConfig } from './env/env.test';

export const determineFeatureToggles = location => {

    /*
     * Parse URL search params for optional toggles via
     * https://www.npmjs.com/package/query-string
     * Note: when using HashRouter, the 'location.search' will be empty.
     * That's why 'location.hash' is used here. If you are using BrowserRouter,
     * use the 'location.search' instead.
     */
    const { hash } = location;
    const query = hash ? hash.slice(hash.indexOf('?'), hash.length) : '';

    /* istanbul ignore next */
    const searchParams = queryString.parse(query) || {};

    // Feature toggle to test various languages on demand
    const enforcedLocale = searchParams.enforceLocale || null;

    const noAnalytics = searchParams.noAnalytics || null;
    const newTokenHandling = searchParams.newTokenHandling || false;

    // ... add your custom feature toggles here
    return {
        enforcedLocale,
        noAnalytics,
        newTokenHandling,
    };
};

export const getRuntimeConfig = (window = window, env = {}) => {
    const defaultConfig = {
        apiGateway: `${window.location.protocol}//${window.location.host}`,
        backend: {},
        homeRoute: `https://www.home.rio.cloud`,
        id: 'env.stub',
        login: {
            clientId: 'b8a414b3-6f71-453e-b53b-6c02a2925004',
            oauthScope: [` `],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
    };

    let environmentConfig = {};
    switch (window.location.hostname) {
        case 'localhost':
            environmentConfig = localConfig;
            break;
        case 'remotedownload-sandbox.rio.cloud':
            environmentConfig = sandboxConfig;
            break;
        default:
            environmentConfig = productionConfig;
            break;
    }

    if (env.isTestEnv) {
        environmentConfig = testConfig;
    }

    return merge(merge({}, defaultConfig), environmentConfig);
};

export const configureEnv = (window, processEnv) => {
    const { location: { host } } = window;
    const { NODE_ENV } = processEnv;

    const isProdEnv = host === 'remotedownload.rio.cloud';
    const isTestEnv = NODE_ENV === 'test';
    const isStagingEnv = host === 'test.remotedownload.rio.cloud';
    const isSandboxEnv = host === 'remotedownload-sandbox.rio.cloud';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isSandboxEnv;

    const shouldSendMetrics = isProdEnv || isStagingEnv;

    const isProdBuild = NODE_ENV === 'production';
    const isRunningOnProd = host === 'remotedownload.rio.cloud';
    const isRunningOnStaging = host === 'test.remotedownload.rio.cloud';
    const runsInSandbox = isSandboxEnv;

    /*
     * "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
     * accessing the console when the developer tools are not visible
     * so we will be extra defensive here, just to be safe
     */
    const console = typeof window.console !== 'undefined' ?
        window.console :
        null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const shouldRestrictLog = NODE_ENV === 'production';
    const shouldUseConsoleLogger = false; // NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window.location);
    const runtimeConfig = getRuntimeConfig(window);

    return {
        featureToggles,
        hasConsole,
        isLocalEnv,
        isTestEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        runsInSandbox,
        runtimeConfig,
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };
};

export const env = configureEnv(window, process);
