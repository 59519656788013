/* eslint-disable no-return-await, no-await-in-loop */
import { env } from '../../env';
import {fetchDataPOST} from '../../sagas/fetchUtils';

import getArchiveQuery from './getQuery';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const TACHO_OVERVIEW_PATH = '/filearchive/Customers/Overview';

const SINGLE_DEFAULT_LIMIT = 1;
export const MULTIPLE_DEFAULT_LIMIT = 50;
const DEFAULT_OFFSET = 0;
const DEFAULT_TOTAL_COUNT = 0;

export const getQuery = (
    limit = SINGLE_DEFAULT_LIMIT,
    offset = DEFAULT_OFFSET,
) => getArchiveQuery({
    limit,
    offset,
    sortField: 'TimeCreatedUtc',
    sortOrder: 'desc',
});

const getUrl = (limit, offset) => `${TACHOGRAPH_SERVICES_SERVICE}${TACHO_OVERVIEW_PATH}${getQuery(
    limit,
    offset,
)}`;

export const makeFetch = (
    offset,
    limit,
    body,
    injectedFetch = fetchDataPOST,
) => injectedFetch(
    getUrl(
        limit,
        offset,
    ),
    body,
)
    .then(res => {
        if (res.error) {
            throw new Error('tacho overview call failed');
        }
        return res;
    });

export const getOverviewFileItems = (
    offset,
    body,
    injectedFetch = fetchDataPOST,
) => makeFetch(
    offset,
    MULTIPLE_DEFAULT_LIMIT,
    body,
    injectedFetch,
)
    .then(data => data.items)
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export const getOverviewFilesCount = (
    body,
    injectedFetch = fetchDataPOST,
) => makeFetch(
    DEFAULT_OFFSET,
    SINGLE_DEFAULT_LIMIT,
    body,
    injectedFetch,
)
    .then(data => data.totalCount)
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export const getTypedOverviewFiles = async(
    fileType = 'Vehicle',
    injectedFetch = fetchDataPOST,
) => {
    try {
        const body = { fileType };

        const totalCount = await getOverviewFilesCount(
            body,
            injectedFetch,
        ) || DEFAULT_TOTAL_COUNT;
        const allFiles = [];

        // eslint-disable-next-line immutable/no-let
        for (let index = 0; index < Math.ceil(totalCount / MULTIPLE_DEFAULT_LIMIT); index++) {
            const items = await getOverviewFileItems(
                MULTIPLE_DEFAULT_LIMIT * index,
                body,
                injectedFetch,
            ) || [];
            items.forEach(item => allFiles.push(item));
        }

        return allFiles;
    } catch (err) {
        console.log(err); // eslint-disable-line no-console
        return [];
    }
};

const getOverviewFiles = async(injectedFetch = fetchDataPOST) => {
    try {
        const vehicleFiles = await getTypedOverviewFiles(
            'Vehicle',
            injectedFetch,
        );
        const driverFiles = await getTypedOverviewFiles(
            'Driver',
            injectedFetch,
        );

        return vehicleFiles.concat(driverFiles);
    } catch (err) {
        console.log(err); // eslint-disable-line no-console
        return [];
    }
};

export default getOverviewFiles;
