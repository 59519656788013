const BASE = 'TACHOGRAPHSERVICES';
export const SET_ERROR_MESSAGE_ID = `${BASE}.SET_ERROR_MESSAGE_ID`;
export const SET_TACHO_SETTINGS = `${BASE}.SET_TACHO_SETTINGS`;
export const SET_TACHO_SETTING = `${BASE}.SET_TACHO_SETTING`;
export const SET_PERMISSIONS = `${BASE}.SET_PERMISSIONS`;
export const SET_IDENTIFIERS = `${BASE}.SET_IDENTIFIERS`;
export const SET_RETENTION_PERIOD = `${BASE}.SET_RETENTION_PERIOD`;
export const CLOUD_STORAGE_CHANGED = `${BASE}.CLOUD_STORAGE_CHANGED`;
export const SET_VEHICLES_WITH_SUBSCRIPTION = `${BASE}.SET_VEHICLES_WITH_SUBSCRIPTION`;
export const SET_TBM3_VEHICLES = `${BASE}.SET_TBM3_VEHICLES`;
export const SET_SUCCESSFUL_COMPANY_CARD_REQUEST = `${BASE}.SET_SUCCESSFUL_COMPANY_CARD_REQUEST`;
export const SET_COMPANY_CARDS = `${BASE}.SET_COMPANY_CARDS`;
export const SET_LOADING_COMPANY_CARDS = `${BASE}.SET_LOADING_COMPANY_CARDS`;
export const SET_RDA_VERSION_SUPPORTED = `${BASE}.SET_RDA_VERSION_SUPPORTED`;
export const SET_ACCOUNT_INFO = `${BASE}.SET_ACCOUNT_INFO`;
export const SET_ANALYSISPORTAL_ACCOUNT_INFO = `${BASE}.SET_ANALYSISPORTAL_ACCOUNT_INFO`;
export const SET_RDA_SUPPORTED = `${BASE}.SET_RDA_SUPPORTED`;

export function setErrorMessageId(string) {
    return {
        type: SET_ERROR_MESSAGE_ID,
        payload: string,
    };
}

export function setTachoSettings(object) {
    return {
        type: SET_TACHO_SETTINGS,
        payload: object,
    };
}

export function setTachoSetting(object) {
    return {
        type: SET_TACHO_SETTING,
        payload: object,
    };
}

export function setPermissions(object) {
    return {
        type: SET_PERMISSIONS,
        payload: object,
    };
}

export function setIdenfiers(object) {
    return {
        type: SET_IDENTIFIERS,
        payload: object,
    };
}

export function setRetentionPeriod(number) {
    return {
        type: SET_RETENTION_PERIOD,
        payload: number,
    };
}

export function cloudStorageChanged() {
    return {
        type: CLOUD_STORAGE_CHANGED,
    };
}

export function setVehiclesWithSubscription(array) {
    return {
        type: SET_VEHICLES_WITH_SUBSCRIPTION,
        payload: array,
    };
}

export function setTBM3Vehicles(array) {
    return {
        type: SET_TBM3_VEHICLES,
        payload: array,
    };
}
export function setSuccessfulCompanyCardRequest(bool) {
    return {
        type: SET_SUCCESSFUL_COMPANY_CARD_REQUEST,
        payload: bool,
    };
}

export function setCompanyCards(array) {
    return {
        type: SET_COMPANY_CARDS,
        payload: array,
    };
}

export function setLoadingCompanyCards(bool) {
    return {
        type: SET_LOADING_COMPANY_CARDS,
        payload: bool,
    };
}

export function setRdaVersionSupported(bool) {
    return {
        type: SET_RDA_VERSION_SUPPORTED,
        payload: bool,
    };
}

export function setAccountInfo(object) {
    return {
        type: SET_ACCOUNT_INFO,
        payload: object,
    };
}

export function setAnalysisPortalAccountInfo(object) {
    return {
        type: SET_ANALYSISPORTAL_ACCOUNT_INFO,
        payload: object,
    };
}

export function setRDASupported(bool) {
    return {
        type: SET_RDA_SUPPORTED,
        payload: bool,
    };
}

