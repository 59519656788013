
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../../src/env';

import { getFormattedItem, getDataListRequestBody} from '../../sagas/archive/fetchData';
import { successfulFetchData } from '../../actions/archive';
import {
    fetchData,
    forbidden,
    noContent,
    notFound,
    unauthorized,
    getOffsetText,
    internalError,
    setRawFilter,
} from '../../sagas/fetchUtils';

export const capitalizeFirstLetter = string => {
    const uppercaseFirstLetter = string.charAt(0).toUpperCase();
    const stringWithoutFirstLetter = string.slice(1);

    return `${uppercaseFirstLetter}${stringWithoutFirstLetter}`;
};

export const getParams = (payload, getDataRequestBody = getDataListRequestBody) => {
    const requestBody = getDataRequestBody(
        payload.startValue,
        payload.endValue,
        payload.selectedIds,
        payload.onlyLatest,
        payload.authorizedParties,
        payload.overviewSelected,
        payload.notExported,
        payload.currentCategoryId,
        payload.entityIdentifier,
        payload.hasSpeedData,
    );

    setRawFilter(
        payload.rawFilter,
        requestBody,
    );

    if (payload.downloadTrigger || payload.downloadTrigger === 0) {
        requestBody.downloadTrigger = payload.downloadTrigger;
    }

    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    };

    return params;
};
const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;
export const getUrl = ({
    activePage,
    limit,
    onlyLatest,
    overviewSelected,
    sortField,
    sortOrder,
}) => {
    const offsetText = activePage ?
        getOffsetText(
            activePage,
            limit,
        ) :
        '';
    const limitText = limit ? `&limit=${encodeURIComponent(limit)}` : '';
    const apiText = overviewSelected ?
        'Overview' :
        `${onlyLatest ? 'Latest' : 'Files'}`;
    const sortFieldText = `&sortField=${capitalizeFirstLetter(sortField)}`;
    const sortOrderText = `&sortOrder=${sortOrder}`;

    const filterText = `${offsetText}${limitText}${sortFieldText}${sortOrderText}&extraInfo=true`;

    const baseUrl = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/Customers/`;

    return `${baseUrl}${apiText}?${filterText}`;
};

const fetchListFiles = (
    dispatch,
    payload,
    injectedFetch = fetchData,
) => injectedFetch(
    getUrl(payload),
    getParams(payload),
)
    .then(res => {
        if (res.error) {
            switch (res.error) {
                case noContent:
                    break;
                case notFound:
                    Notification.error(<FormattedMessage
                        id={'error.404'}
                        defaultMessage={
                            'Service not found. Please try again later.'
                        }
                    />);
                    break;
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                case internalError:
                    Notification.error(<FormattedMessage
                        id={'error.500'}
                        defaultMessage={
                            'An internal error has occurred. Please try again later.'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.fileListRequest'}
                        defaultMessage={
                            'The files could not be listed. Please try again later.'
                        }
                    />);
            }
            dispatch(successfulFetchData({
                items: [],
                pagination: {
                    offset: 0,
                    limit: payload.limit,
                    totalCount: 0,
                },
                requestTimeStamp: payload.requestTimeStamp,
            }));
            throw new Error('tacho list files fetch failed');
        }
        return res;
    })
    .then(result => {
        const newData = [];
        if (result.items) {
            result.items.forEach(item => {
                newData.push(getFormattedItem(
                    item,
                    payload.currentCategoryId,
                ));
            });
        }

        result.items = newData;
        result.requestTimeStamp = payload.requestTimeStamp;
        dispatch(successfulFetchData(result));
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default fetchListFiles;

