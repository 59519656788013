
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import { setDownloadIntervals, setDefaultDownloadInterval } from '../../../actions/administration';
import setTachoDownloadIntervals from '../../../fetches/DownloadInterval/setTachoDownloadIntervals';
import getTachoDownloadIntervals from '../../../fetches/DownloadInterval/getTachoDownloadIntervals';
import store from '../../../setup/store';

import IntervalSettingsSidebar from './IntervalSettingsSidebar';

export const IntervalsSettingsContainer = props => <IntervalSettingsSidebar {...props}/>;
IntervalsSettingsContainer.propTypes = {
    permissions: PropTypes.array,

    sidebarProps: PropTypes.object,
    sidebarNavigation: PropTypes.node,
};

export const mapDispatchToProps = dispatch => ({
    getDownloadIntervals: () => {
        dispatch(setDownloadIntervals({ loading: true }));
        getTachoDownloadIntervals(dispatch);
    },
    setDownloadIntervals: payload => {
        dispatch(setDownloadIntervals(payload));
        dispatch(setDefaultDownloadInterval());
        setTachoDownloadIntervals(
            store.getState().tokenHandling.accessToken,
            payload,
            dispatch,
        );
    },
});

export const mapStateToProps = state => ({
    vehicles: get(
        state,
        'tachographservices.tree.vehicles',
        [],
    ),
    drivers: get(
        state,
        'tachographservices.tree.drivers',
        [],
    ),

    fleetDownloadIntervals: state.tachographservices.administration.fleetDownloadIntervals,
    customDownloadIntervals: state.tachographservices.administration.customDownloadIntervals,
    hasSpeedData: state.tachographservices.administration.hasSpeedData,
    shouldRefetch: state.tachographservices.archive.shouldRefetchArchive,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntervalsSettingsContainer);
