import { env } from '../../env';
import saveFetch from '../../features/utils/saveFetch';
import { setRdaVersionSupported } from '../../actions/common';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getFetchRdaInstalledVersions = (store, fetch = window.fetch) => () => fetch(
    `${TACHOGRAPH_SERVICES_SERVICE}/externalcardauthenticator/Redis/rdaversion/` +
    `${store.getState().login.userProfile.account}`,
    {
        headers: {
            Accept: 'application/json',
        },
        method: 'GET',
    },
);

export const getHandleData = store => data => {
    // handle the data
    if (data.length === 0) {
        store.dispatch(setRdaVersionSupported(true));
    } else if (data.length > 0) {
        const versions = data.map(element => element.replaceAll(
            '.',
            '',
        ));
        // Show banner if version is older than 4.2.0.0
        // eslint-disable-next-line no-magic-numbers
        if (versions.some(version => parseInt(version) < 4200)) {
            store.dispatch(setRdaVersionSupported(false));
        } else {
            store.dispatch(setRdaVersionSupported(true));
        }
    }
};

const configureFetchRdaInstalledVersions = store => new Promise(resolve => {
    const installedRdaVersions = getFetchRdaInstalledVersions(store);

    saveFetch(
        installedRdaVersions,
        'installed rda versions',
        getHandleData(store),
        resolve,
    );
});

export default configureFetchRdaInstalledVersions;
