
import PropTypes from 'prop-types';
import InfoDialog from '@rio-cloud/rio-uikit/InfoDialog';
import { NavLink, withRouter} from 'react-router-dom';

import {FormattedMessage} from 'react-intl';

import withPermissions from '../../utils/withPermissions';

import { MANUAL_TAB } from '../../constants/archive';

export const InstallRDADialog = props => withPermissions(
    props.permissions,
    'rda-installer.get',
    <InfoDialog
        show = {props.show}
        onClose = {props.onClose}
        bsSize = {props.bsSize}
        content={
            <div className={'RDAVersionInstallerDialog text-center'}>
                <div className={'margin-bottom-25'}>
                    <h2>
                        <FormattedMessage id={'callForAction'} defaultMessage={'Call for Action'}/>
                    </h2>
                    <p>
                        <span>
                            <FormattedMessage
                                id={'callForAction.InstallRDAText-2'}
                                defaultMessage={
                                    'Please install our new {rda} with version number {version}. ' +
                                    'You can download the improved version from the {guidance} page. ' +
                                    'To avoid system errors, please deinstall and remove ' +
                                    'your current version first before installing the new version. ' +
                                    'Installing the new version is essential as we will soon ' +
                                    'no longer support older versions of the Remote Download ' +
                                    'Application. If you are already using version {version}, no further ' +
                                    'action is needed.'
                                }
                                values={
                                    {
                                        rda: <NavLink
                                            onClick = {props.onClose}
                                            to={{ pathname: `/${MANUAL_TAB}`, search: props.location.search}}>
                                            Remote Download Application</NavLink>,
                                        guidance: <NavLink
                                            onClick={props.onClose}
                                            to={{ pathname: `/${MANUAL_TAB}`, search: props.location.search}}>
                                            <span>
                                                <FormattedMessage
                                                    id={'administration.manual'}
                                                    defaultMessage={'Guidance'}
                                                />
                                            </span>
                                        </NavLink>,
                                        version: '4.2.0',
                                    }
                                }
                            />
                        </span>
                    </p>
                </div>
                <button className={'btn btn-primary margin-bottom-20'} onClick={props.onClose}>
                    <FormattedMessage id={'confirm'} defaultMessage={'Confirm'}/>
                </button>
            </div>
        }
    />,
);

InstallRDADialog.propTypes = {
    onClose: PropTypes.func,
    permissions: PropTypes.array,
    show: PropTypes.bool,
    bsSize: PropTypes.string,
};

export default withRouter(InstallRDADialog);
