
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import { env } from '../../env';
import {setRDAStatusFetched, successfulFetchRdaStatus} from '../../actions/administration';
import {fetchData, forbidden, unauthorized} from '../../sagas/fetchUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/RdaStatus`;

const getRDAStatus = (
    { isCompanyCardAvailable, isRDAOnline },
    dispatch,
    injectedFetch = fetchData,
) => injectedFetch(url)
    .then(res => {
        dispatch(setRDAStatusFetched());

        if (res.error) {
            switch (res.error) {
                case unauthorized:
                case forbidden:
                    Notification.error(<FormattedMessage
                        id={'error.401'}
                        defaultMessage={
                            'Error : 401'
                        }
                    />);
                    break;
                default:
                    Notification.error(<FormattedMessage
                        id={'error.fetchRdaStatus'}
                        defaultMessage={
                            'Displaying the status failed. Please try again later.'
                        }
                    />);
            }

            dispatch(successfulFetchRdaStatus({
                isRDAOnline: false,
                isCompanyCardAvailable: false,
                multipleRdaOnline: false,
            }));
            throw new Error('tacho get rda status');
        }

        return res;
    })
    .then((data = {}) => {

        if (isCompanyCardAvailable && !data.anyCompanyCardsOnline) {
            Notification.error(<FormattedMessage
                id={'error.noCompanyCardOnline'}
                defaultMessage={
                    'Company Card no longer online.'
                }
            />);

        }

        if (isRDAOnline && !data.rdaOnline) {
            Notification.error(<FormattedMessage
                id={'error.rdaNotOnline'}
                defaultMessage={
                    'Remote Download Application is not online.'
                }
            />);

        }

        dispatch(successfulFetchRdaStatus(data));

        return data;
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getRDAStatus;
