import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { setErrorMessageId } from '../../actions/common';

const DELAY_TIME = 10;

export class ErrorMessagesContainer extends Component {
    constructor(props) {
        super(props);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    showErrorMessage(errorMessageId = this.props.errorMessageId) {
        if (errorMessageId) {
            Notification.error(this.props.intl.formatMessage({
                id: errorMessageId,
                defaultMessage: this.props.defaultMessages[errorMessageId],
            }));
        }

        this.props.setErrorMessageId(false);
    }

    render() {
        if (this.props.errorMessageId) {
            window.setTimeout(
                this.showErrorMessage,
                DELAY_TIME,
            );
        }

        return (
            <div className={'ErrorMessagesContainer'}>
                <NotificationsContainer/>
            </div>
        );
    }
}

ErrorMessagesContainer.propTypes = {
    errorMessageId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    intl: PropTypes.object,
    defaultMessages: PropTypes.object,
    setErrorMessageId: PropTypes.func,
};

export const mapStateToProps = state => ({
    errorMessageId: state.tachographservices.common.errorMessageId,
});

export const mapDispatchToProps = dispatch => ({
    setErrorMessageId: payload => {
        dispatch(setErrorMessageId(payload));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ErrorMessagesContainer));
