import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { FormattedMessage } from 'react-intl';

import { setTachoSetting as setTachoSettingAction } from '../../actions/common';

import { setSettingsSidebarOpen } from '../../actions/administration';

import { getAccessToken } from '../../features/tokenHandling/selectors';
import setTachoSetting from '../../fetches/Settings/setTachoSetting';
import withPermissions from '../../utils/withPermissions';
import store from '../../setup/store';

export const AdministrationSettingsContainer = ({
    permissions,
    archiveCloudStoragePeriodOnboardingTipShown,
    setArchiveCloudStoragePeriodOnboardingTipShown,
    openIntervalSettings,
}) => withPermissions(
    permissions,
    'download-interval.read',
    withPermissions(
        permissions,
        'retention-period.update',
        <OnboardingTip
            show={!archiveCloudStoragePeriodOnboardingTipShown}
            placement={'bottom'}
            title={<FormattedMessage
                id={'newFeature'}
                defaultMessage={'New Feature'} />}
            content={<FormattedMessage
                id={'onboarding.setCloudStoragePeriod'}
                defaultMessage={'Set the cloud storage period of all files in the settings.'} />}
            className={'max-width-200'}
            onHide={setArchiveCloudStoragePeriodOnboardingTipShown}
        >
            <ActionBarItem id={'administrationSettings'}>
                <ActionBarItem.Icon>
                    <div className={'ActionBarItemIcon'} onClick={openIntervalSettings}>
                        <span className={'icon rioglyph rioglyph-cog'}/>
                    </div>
                </ActionBarItem.Icon>
            </ActionBarItem>
        </OnboardingTip>,
        <ActionBarItem id={'administrationSettings'}>
            <ActionBarItem.Icon>
                <div className={'ActionBarItemIcon'} onClick={openIntervalSettings}>
                    <span className={'icon rioglyph rioglyph-cog'}/>
                </div>
            </ActionBarItem.Icon>
        </ActionBarItem>,
    ),
);

AdministrationSettingsContainer.propTypes = {
    permissions: PropTypes.array,
    openIntervalSettings: PropTypes.func,
    setArchiveCloudStoragePeriodOnboardingTipShown: PropTypes.func,
    archiveCloudStoragePeriodOnboardingTipShown: PropTypes.bool,
};

export const mapStateToProps = state => ({

    permissions: get(
        state,
        'tachographservices.common.permissions',
        [],
    ),
    archiveCloudStoragePeriodOnboardingTipShown:
        get(
            state,
            'tachographservices.common.tachoSettings.archiveCloudStoragePeriodOnboardingTipShown',
            false,
        ),
    archiveExportStatusOnboardingTipShown:
        get(
            state,
            'tachographservices.common.tachoSettings.archiveExportStatusOnboardingTipShown',
            false,
        ),
});

export const mapDispatchToProps = dispatch => ({

    openIntervalSettings: () => {
        dispatch(setSettingsSidebarOpen(true));
    },

    setArchiveCloudStoragePeriodOnboardingTipShown: () => {
        dispatch(setTachoSettingAction({ archiveCloudStoragePeriodOnboardingTipShown: true }));
        setTachoSetting(
            getAccessToken(store.getState()),
            'archiveCloudStoragePeriodOnboardingTipShown',
            true,
        );
    },

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdministrationSettingsContainer);

