import { env } from '../../env';

import {fetchDataPOST} from '../../sagas/fetchUtils';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const getUrl = ({ companyChipId }) => `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard/${companyChipId || ''}`;

const editCompanyCard = (
    card = {},
    injectedFetch = fetchDataPOST,
) => injectedFetch(
    getUrl(card),
    card,
)
    .then(res => {
        if (res.error) {
            throw new Error('tacho edit company card call failed');
        }
        return res;
    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default editCompanyCard;
