import {SHOULD_INITIALLY_BE_RESPONSIVE} from '../constants/common';

import {
    SET_SINGLE_CARD,
    SET_MULTI_CARDS,
    TOGGLE_TRANSFER_TYPE,
    TOGGLE_EXPORTED_TO,
    ABORT_TEMP_FILTERS,
    SET_SEARCHED_DRIVER_CARD,
    SET_SEARCHED_VEHICLE_IDENTIFICATION,
    SET_SUBMITTED_DRIVER_CARD,
    SET_SUBMITTED_VEHICLE_IDENTIFICATION,
} from '../actions/tableFilters';

export const initialState = {
    singleCard: false,
    multiCards: SHOULD_INITIALLY_BE_RESPONSIVE,
    transferTypeFilters: {
        autoDownload: false,
        manualDownload: false,
        speedData: false,
        upload: false,
    },
    exportedToFilters: {
        notExported: false,
        computer: false,
        partner: false,
        api: false,
    },
    searchedDriverCard: '',
    searchedVehicleIdentification: '',
    submittedDriverCard: '',
    submittedVehicleIdentification: '',
};

const extendedTablefilters = (state, action) => {
    switch (action.type) {
        case SET_SEARCHED_DRIVER_CARD:
            return {
                ...state,
                searchedDriverCard: action.payload,
            };
        case SET_SEARCHED_VEHICLE_IDENTIFICATION:
            return {
                ...state,
                searchedVehicleIdentification: action.payload,
            };
        case SET_SUBMITTED_DRIVER_CARD:
            return {
                ...state,
                submittedDriverCard: action.payload,
            };
        case SET_SUBMITTED_VEHICLE_IDENTIFICATION:
            return {
                ...state,
                submittedVehicleIdentification: action.payload,
            };
        default:
            return state;
    }
};

const tableFilters = (state = initialState, action = {}) => {
    switch (action.type) {

        case SET_SINGLE_CARD:
            return {
                ...state,
                singleCard: action.payload,
            };
        case SET_MULTI_CARDS:
            return {
                ...state,
                multiCards: action.payload,
            };
        case TOGGLE_TRANSFER_TYPE:
            return {
                ...state,
                transferTypeFilters: {
                    ...state.transferTypeFilters,
                    [action.payload]: !state.transferTypeFilters[action.payload],
                },
            };
        case TOGGLE_EXPORTED_TO:
            return {
                ...state,
                exportedToFilters: {
                    ...state.exportedToFilters,
                    [action.payload]: !state.exportedToFilters[action.payload],
                },
            };
        case ABORT_TEMP_FILTERS:
            return {
                ...state,
                transferTypeFilters: {
                    autoDownload: action.payload.transferType.autoDownload,
                    manualDownload: action.payload.transferType.manualDownload,
                    speedData: action.payload.transferType.speedData,
                    upload: action.payload.transferType.upload,
                },
                exportedToFilters: {
                    notExported: action.payload.exportedTo.notExported,
                    computer: action.payload.exportedTo.computer,
                    partner: action.payload.exportedTo.partner,
                    api: action.payload.exportedTo.api,
                },
            };
        default:
            return extendedTablefilters(
                state,
                action,
            );
    }
};

export default tableFilters;
