/* eslint-disable import/prefer-default-export */

import { trace } from 'console';

import moment from 'moment';

import { getMomentLocale, importLocale } from '../../../src/lang/translationSetup';

import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';

import getFetchTranslation from './getFetchTranslation';

export const sendError = exception => {
    console.log(exception); // eslint-disable-line no-console
};

export const configureFetchLanguageData = (
    store,
    fetchLanguageData = getFetchTranslation(
        'en-GB',
        {},
    ),
    getSupportedLocale = defaultGetSupportedLocale,
) => locale => {

    if (!locale) {
        // eslint-disable-next-line no-console
        console.warn(`No "locale" supplied when fetching language data!`);
        return Promise.reject();
    }

    store.dispatch(changeLocale(locale));
    const supportedLocale = getSupportedLocale(store.getState());
    const momentLocale = getMomentLocale(supportedLocale);
    const momentPromise = importLocale(momentLocale)
        .then(() => {
            trace(`imported momentLocale of country: ${momentLocale}`);
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error(
                `momentLocale of country ${momentLocale} could not be imported`,
                error,
            );
            sendError(error);
            return error;
        });
    momentPromise.then(() => {
        moment.locale(momentLocale);
    });
    return fetchLanguageData(supportedLocale).then(languageData => {
        store.dispatch(languageDataFetched(
            supportedLocale,
            languageData,
        ));
    })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error(error);
            sendError(error);
        });
};

