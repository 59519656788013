const BASE = 'TACHOGRAPHSERVICES.ARCHIVE.';
export const SET_SELECTED_ROWS = `${BASE}SET_SELECTED_ROWS`;
export const SET_TIME_RANGE = `${BASE}SET_TIME_RANGE`;
export const SET_START_DATE = `${BASE}SET_START_DATE`;
export const SET_END_DATE = `${BASE}SET_END_DATE`;
export const SET_TIME_RANGE_TYPE = `${BASE}SET_TIME_RANGE_TYPE`;
export const SET_SORTING_BEHAVIOUR = `${BASE}SET_SORTING_BEHAVIOUR`;
export const SET_TOTAL_FILES_COUNT = `${BASE}SET_TOTAL_FILES_COUNT`;
export const SET_ACTIVE_PAGE = `${BASE}SET_ACTIVE_PAGE`;
export const SET_LIST_FILES_DATA = `${BASE}SET_LIST_FILES_DATA`;
export const SUCCESSFUL_FETCH_DATA = `${BASE}SUCCESSFUL_FETCH_DATA`;
export const ADD_DOWNLOADING_FILES = `${BASE}ADD_DOWNLOADING_FILES`;
export const REMOVE_DOWNLOADING_FILES = `${BASE}REMOVE_DOWNLOADING_FILES`;
export const DOWNLOAD_ALL_FILES = `${BASE}DOWNLOAD_ALL_FILES`;
export const DOWNLOAD_ALL_ARCHIVE_FILES = `${BASE}DOWNLOAD_ALL_ARCHIVE_FILES`;
export const DOWNLOAD_FILES = `${BASE}DOWNLOAD_FILES`;
export const REFETCH_ARCHIVE_DATA = `${BASE}REFETCH_ARCHIVE_DATA`;

export function setSelectedRows(array) {
    return {
        type: SET_SELECTED_ROWS,
        payload: array,
    };
}

export function setTimeRangeType(type) {
    return {
        type: SET_TIME_RANGE_TYPE,
        payload: type,
    };
}

export function setTimeRange(object) {
    return {
        type: SET_TIME_RANGE,
        payload: object,
    };
}

export function setStartDate(date) {
    return {
        type: SET_START_DATE,
        payload: date,
    };
}

export function setEndDate(date) {
    return {
        type: SET_END_DATE,
        payload: date,
    };
}

export function setSortingBehaviour(object) {
    return {
        type: SET_SORTING_BEHAVIOUR,
        payload: object,
    };
}

export function setTotalFilesCount(object) {
    return {
        type: SET_TOTAL_FILES_COUNT,
        payload: object,
    };
}

export function setActivePage(object) {
    return {
        type: SET_ACTIVE_PAGE,
        payload: object,
    };
}

export function setListFilesData(object) {
    return {
        type: SET_LIST_FILES_DATA,
        payload: object,
    };
}

export function successfulFetchData(object) {
    return {
        type: SUCCESSFUL_FETCH_DATA,
        payload: object,
    };
}

export function addDownloadingFiles(array) {
    return {
        type: ADD_DOWNLOADING_FILES,
        payload: array,
    };
}

export function removeDownloadingFiles(array) {
    return {
        type: REMOVE_DOWNLOADING_FILES,
        payload: array,
    };
}

export function downloadAllArchiveFiles() {
    return {
        type: DOWNLOAD_ALL_ARCHIVE_FILES,
    };
}

export function downloadAllFiles(object) {
    return {
        type: DOWNLOAD_ALL_FILES,
        payload: object,
    };
}

export function downloadFiles(array) {
    return {
        type: DOWNLOAD_FILES,
        payload: array,
    };
}

export function refetchArchiveData(bool) {
    return {
        type: REFETCH_ARCHIVE_DATA,
        payload: bool,
    };
}
