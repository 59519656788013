
import { FormattedMessage } from 'react-intl';

import ReleaseNotesImage from '../../releaseNotes/ReleaseNotesImage';

import ExplanationContainer from '../ExplanationContainer';

const steps = [
    {
        icon: '1',
        label: <FormattedMessage
            id={'glossary.compliant.manualDownload.step1.title'}
            defaultMessage={'Manual Download Page'}
        />,
    }, {
        icon: '2',
        label: <FormattedMessage
            id={'glossary.compliant.manualDownload.step2.title'}
            defaultMessage={'Select vehicles'}
        />,
    }, {
        icon: '3',
        label: <FormattedMessage
            id={'glossary.compliant.manualDownload.step3.title'}
            defaultMessage={'Request download'}
        />,
    },
];

export const getExplanation = step => {
    switch (step) {
        case 0:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manualSpeedData.step1.text'}
                            defaultMessage={'Go to the "Manual Download" tab and click on "Request download" ' +
                            'if you wish to request Speed Data for individual vehicles.'}
                        />
                    </div>
                    <ReleaseNotesImage image={'manualDownloadStep1.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 1:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manualSpeedData.step2.text'}
                            defaultMessage={
                                'Select the vehicles for which you wish to request Speed Data.'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'manualDownloadStep2.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        case 2:
            return (
                <div>
                    <div>
                        <FormattedMessage
                            id={'glossary.compliant.manualSpeedData.step3.text'}
                            defaultMessage={
                                'Turn on the "Speed Data" button ' +
                                'then click on "Request download".'
                            }
                        />
                    </div>
                    <ReleaseNotesImage image={'manualSpeedDataStep3.png'} widthClass={'max-width-100pct'}/>
                </div>
            );
        default:
            return <div/>;
    }
};

const ManualSpeedDataExplanation = () => <ExplanationContainer
    steps={steps}
    getExplanation={getExplanation}
/>;

export default ManualSpeedDataExplanation;
