
/* eslint-disable no-magic-numbers */
export const ONE_DAY_SECONDS = 24 * 60 * 60;
export const ONE_DAY_MILLISECONDS = ONE_DAY_SECONDS * 1000;
export const ONE_MONTH_MILLISECONDS = ONE_DAY_MILLISECONDS * 30;
export const THREE_MONTH_MILLISECONDS = ONE_DAY_MILLISECONDS * 90;
export const ONE_MINUTE_MILLISECONDS = 60 * 1000;
export const ONE_SECOND_MILLISECONDS = 1000;
/* eslint-enable no-magic-numbers */

export const TABLE_VIEW = 'Normal';
export const SINGLE_CARD = 'Single_Card';
export const MULTI_CARD = 'Multi_Cards';

export const dateProps = { // eslint-disable-line import/prefer-default-export
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

export const RESPONSIVE_MAX_WIDTH = 1200;
export const SHOULD_INITIALLY_BE_RESPONSIVE = window.innerWidth < RESPONSIVE_MAX_WIDTH;
