import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import { injectIntl } from 'react-intl';

import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';

import { TIME_RANGE_TYPE } from '../../constants/archive';
import { setTimeRangeType, setStartDate, setEndDate } from '../../actions/archive';

const ONE = 1;
const WEEK = 7;

// eslint-disable-next-line max-lines-per-function
const TimeRangeSelection = props => {
    const {
        from,
        to,
        timeRangeType,
        onSelectFrom,
        onSelectTo,
        fetchData,
        intl,
        onOpen,
        disabled,
    } = props;

    const [fromDate, setFromDate] = useState(moment(from));
    const [toDate, setToDate] = useState(moment(to));
    const [shouldOpenToPicker, setShouldOpenToPicker] = useState(false);

    const setAllowedDateOfInputTo = selectedDate => {
        if (selectedDate.isAfter(toDate)) {
            setToDate(selectedDate
                .clone()
                .endOf('day'));
        }
    };

    const isValidDateForToSelection = currentDate => {
        const start = moment(fromDate).startOf('day');
        const end = moment().endOf('day');
        return (
            currentDate.isBetween(
                start,
                end,
                'minutes',
                '[]',
            )
        );
    };

    const isValidDateForFromSelection = currentDate => {
        const endOfToday = moment().endOf('day');
        return currentDate.isBefore(endOfToday);
    };

    const isEndDateBeforeStart = (firstDate, secondDate) => firstDate.clone()
        .isBefore(secondDate.clone());

    const didMountRef = useRef(false);
    useEffect(
        () => {
            if (didMountRef.current) {
                if (shouldOpenToPicker) {
                    const elementNodeListOf = document.querySelectorAll('.DatePicker');
                    if (elementNodeListOf.length > 1) {
                        elementNodeListOf[1].querySelector('input').focus();
                    }
                }
            } else {
                didMountRef.current = true;
            }
        },
        [from],
    );

    useEffect(
        // eslint-disable-next-line max-statements
        () => {
            if (timeRangeType === TIME_RANGE_TYPE.TODAY) {
                const start = moment().startOf('day');
                const end = moment().endOf('day');
                setFromDate(start);
                setToDate(end);
                fetchData(
                    start.toDate(),
                    end.toDate(),
                );
            } else if (timeRangeType === TIME_RANGE_TYPE.LAST_7_DAYS) {
                const start = moment().subtract(
                    WEEK,
                    'days',
                )
                    .startOf('day');
                const end = moment().endOf('day');
                setFromDate(start);
                setToDate(end);
                fetchData(
                    start.toDate(),
                    end.toDate(),
                );
            } else if (timeRangeType === TIME_RANGE_TYPE.LAST_WEEK) {
                const start = moment().subtract(
                    ONE,
                    'weeks',
                )
                    .startOf('isoWeek');
                const end = moment().subtract(
                    ONE,
                    'weeks',
                )
                    .endOf('isoWeek');
                setFromDate(start);
                setToDate(end);
                fetchData(
                    start.toDate(),
                    end.toDate(),
                );
            } else if (timeRangeType === TIME_RANGE_TYPE.LAST_MONTH) {
                const start = moment().subtract(
                    ONE,
                    'month',
                )
                    .startOf('month');
                const end = moment().subtract(
                    ONE,
                    'month',
                )
                    .endOf('month');
                setFromDate(start);
                setToDate(end);
                fetchData(
                    start.toDate(),
                    end.toDate(),
                );
            }

        },
        [timeRangeType],
    );

    return (
        <div className={'display-flex'}>
            <div
                data-testid={'timeRangeSelectionFrom'}
                className={'flex-1-0 display-flex padding-right-5 min-width-150 '}>
                {disabled &&
                    <DatePicker
                        className={'width-0 min-width-auto flex-1-0'}
                        locale={intl.locale}
                        inputProps={{
                            className: 'form-control',
                            disabled,
                        }}
                    />}
                {!disabled && <DatePicker
                    value={fromDate}
                    locale={intl.locale}
                    onOpen={() => {
                        onOpen();
                    }}
                    onChange={selectedDate => {
                        setFromDate(moment(selectedDate.clone()));
                    }}
                    inputProps={{
                        readOnly: true,
                        className: 'form-control bg-white',
                    }}
                    isValidDate={isValidDateForFromSelection}
                    timeFormat={true}
                    className={'width-0 min-width-auto flex-1-0'}
                    onClose={() => {
                        setAllowedDateOfInputTo(fromDate);
                        setShouldOpenToPicker(true);
                        onSelectFrom(fromDate);
                    }}
                />}
            </div>
            <div
                data-testid={'timeRangeSelectionTo'}
                className={'flex-1-0 display-flex padding-left-5 min-width-150'}>
                {disabled &&
                    <DatePicker
                        className={'width-0 min-width-auto flex-1-0'}
                        locale={intl.locale}
                        inputProps={{
                            className: 'form-control',
                            disabled,
                        }}
                    />}
                {!disabled && <DatePicker
                    value={toDate}
                    locale={intl.locale}
                    onOpen={() => {
                        onOpen();
                    }}
                    onChange={selectedDate => {
                        if (isEndDateBeforeStart(
                            selectedDate,
                            fromDate,
                        )) {
                            setToDate(selectedDate.clone().endOf('day'));
                        } else {
                            setToDate(selectedDate.clone());
                        }
                    }}
                    onClose={() => {
                        onSelectTo(toDate);
                        setShouldOpenToPicker(false);
                        fetchData(
                            fromDate,
                            toDate,
                        );
                    }}

                    inputProps={{
                        readOnly: true,
                        className: disabled ? 'form-control' : 'form-control bg-white',
                        disabled,
                    }}
                    isValidDate={isValidDateForToSelection}
                    timeFormat={true}
                    className={' width-0 min-width-auto flex-1-0'}
                    alignRight={true}
                />}
            </div>
        </div>
    );
};

TimeRangeSelection.propTypes = {
    timeRangeType: PropTypes.string,
    fetchData: PropTypes.func,
    onOpen: PropTypes.func,
    onSelectFrom: PropTypes.func,
    onSelectTo: PropTypes.func,
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    intl: PropTypes.object,
    disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    from: get(
        state,
        'tachographservices.archive.start',
    ),
    to: get(
        state,
        'tachographservices.archive.end',
    ),
    timeRangeType: get(
        state,
        'tachographservices.archive.selectedTimeRangeType',
    ),
});

const mapDispatchToProps = dispatch => ({
    onSelectFrom: from => {
        dispatch(setStartDate(from.toDate()));
    },
    onSelectTo: to => {
        dispatch(setEndDate(to.toDate()));
    },
    onOpen: () => {
        dispatch(setTimeRangeType(TIME_RANGE_TYPE.CUSTOM));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(TimeRangeSelection));

