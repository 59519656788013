const BASE = 'TACHOGRAPHSERVICES.TABLEFILTERS.';
export const SET_SINGLE_CARD = `${BASE}SET_SINGLE_CARD`;
export const SET_MULTI_CARDS = `${BASE}SET_MULTI_CARDS`;
export const TOGGLE_TRANSFER_TYPE = `${BASE}TOGGLE_TRANSFER_TYPE`;
export const TOGGLE_EXPORTED_TO = `${BASE}TOGGLE_EXPORTED_TO`;
export const ABORT_TEMP_FILTERS = `${BASE}ABORT_ALL_FILTERS`;
export const SET_SEARCHED_DRIVER_CARD = `${BASE}SET_SEARCHED_DRIVER_CARD`;
export const SET_SEARCHED_VEHICLE_IDENTIFICATION = `${BASE}SET_SEARCHED_VEHICLE_IDENTIFICATION`;
export const SET_SUBMITTED_DRIVER_CARD = `${BASE}SET_SUBMITTED_DRIVER_CARD`;
export const SET_SUBMITTED_VEHICLE_IDENTIFICATION = `${BASE}SET_SUBMITTED_VEHICLE_IDENTIFICATION`;
export const SET_EVENT_ON_FILTER_CLICK = `${BASE}SET_EVENT_ON_FILTER_CLICK`;
export const SET_EVENT_ON_TABLE_VIEW = `${BASE}SET_EVENT_ON_TABLE_VIEW`;

export function setSingleCard(bool) {
    return {
        type: SET_SINGLE_CARD,
        payload: bool,
    };
}

export function setMultiCards(bool) {
    return {
        type: SET_MULTI_CARDS,
        payload: bool,
    };
}

export function setEventOnTableView() {
    return {
        type: SET_EVENT_ON_TABLE_VIEW,
    };
}

export function toggleTransferType(filterType) {
    return {
        type: TOGGLE_TRANSFER_TYPE,
        payload: filterType,
    };
}

export function toggleExportedTo(filterType) {
    return {
        type: TOGGLE_EXPORTED_TO,
        payload: filterType,
    };
}

export function setEventOnFilterClick() {
    return {
        type: SET_EVENT_ON_FILTER_CLICK,
    };
}

export function abortTempFilters(chosenFilters) {
    return {
        type: ABORT_TEMP_FILTERS,
        payload: chosenFilters,
    };
}

export function setSearchedDriverCard(string) {
    return {
        type: SET_SEARCHED_DRIVER_CARD,
        payload: string,
    };
}

export function setSearchedVehicleIdentification(string) {
    return {
        type: SET_SEARCHED_VEHICLE_IDENTIFICATION,
        payload: string,
    };
}

export function setSubmittedDriverCard(string) {
    return {
        type: SET_SUBMITTED_DRIVER_CARD,
        payload: string,
    };
}

export function setSubmittedVehicleIdentification(string) {
    return {
        type: SET_SUBMITTED_VEHICLE_IDENTIFICATION,
        payload: string,
    };
}
