/* eslint-disable immutable/no-mutation */
import isEmpty from 'lodash/fp/isEmpty';

import { getAuthenticatedFetchJSON, getAuthenticatedFetchBlob } from '../utils/fetch';

export const noContent = 204;
export const notFound = 404;
export const unauthorized = 401;
export const forbidden = 403;
export const internalError = 500;

export function fetchData(path, params, injectedFetch = getAuthenticatedFetchJSON(
    fetch,
    document,
)) {
    return injectedFetch(
        path,
        params,
    )
        .then(
            post => {
                if (isEmpty(post)) {
                    return { error: noContent };
                }
                return post;
            },
            reason => {

                // if there is no data
                if (reason.message === 'Unexpected end of JSON input' ||
                reason.message === 'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data') {
                    return { error: noContent };
                }

                if (!reason.status) {
                    return { error: notFound };
                }

                return {
                    error: reason.status,
                };
            },
        );
}

export function fetchDataPOST(path, body, injectedFetch) {
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(body),
    };

    return fetchData(
        path,
        params,
        injectedFetch,
    );
}

export function fetchBlobData(path, params, injectedFetch = getAuthenticatedFetchBlob(
    fetch,
    document,
)) {
    return injectedFetch(
        path,
        params,
    )
        .then(
            blob => blob,
            reason => ({
                error: reason.status,
            }),
        );
}

const pageCorecteur = 1;
export const getOffsetText =
    (activePage, limit) => `&offset=${encodeURIComponent((activePage - pageCorecteur) * limit)}`;

export function getFormattedAssetTreeVehicleItem(item, vehicles, externalEquipmentReference) {
    item.isSpeedDataFile = item.hasSpeedData === true;

    const vehicleItemPosition = vehicles.map(vehicle => vehicle.id).indexOf(externalEquipmentReference);

    item.vehicle = vehicleItemPosition < 0 ?
        '' :
        vehicles[vehicleItemPosition].name;
    item.tcoVariant = vehicleItemPosition < 0 ?
        '' :
        'Not in Asset Tree';
    return item;
}

// eslint-disable-next-line max-statements
export function getFormattedAssetTreeDriverItem(item, drivers, externalDriverReference) {
    if (externalDriverReference !== null && externalDriverReference !== '') {
        const assetTreeDriverItem = drivers.find(driver => driver.driverId === externalDriverReference ||
            (driver.driverIds || []).includes(externalDriverReference));

        if (assetTreeDriverItem) {
            item.driver = `${
                assetTreeDriverItem.first_name ?
                    `${assetTreeDriverItem.first_name} ` :
                    ''
            }${
                assetTreeDriverItem.last_name ?
                    assetTreeDriverItem.last_name :
                    ''
            }`;
            item.driverCard = assetTreeDriverItem.driverCard;
        } else {
            item.driver = '';
            item.driverCard = '';
        }
    }

    return item;
}

export function setRawFilter(rawFilter, requestBody) {
    if (rawFilter) {
        requestBody.rawFilter = rawFilter;
    }
}
