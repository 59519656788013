import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import { DEFAULT_LANGUAGE } from './translationSetup';

export const getLocale = getOr(
    DEFAULT_LANGUAGE,
    'lang.displayLocale',
);

export const getLanguageData = get('lang.displayMessages');

export const getSupportedLocale = get('lang.supportedLocale');
