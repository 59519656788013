const formatSubscription = (cell, row) => {
    if (!cell) {
        return '';
    }
    if (cell.startsWith('1')) {
        return (<div>
            <span>
                {'Compliant'}
                <iframe
                    className={'buyButton'}
                    // eslint-disable-next-line max-len
                    src={`https://buybutton.marketplace.rio.cloud/?productId=man-sku00000004&level=21&internalCampaign=upselling-Compliant&noPrice&notButtonText&resourceIds=${row.vehicleInfo.externalEquipmentReference}`}>
                </iframe>
            </span>
        </div>);
    } else if (cell.startsWith('2')) {
        return (<div>
            <span>
                {'Compliant M'}
            </span>
        </div>);
    } else if (cell.startsWith('3')) {
        return (<div>
            <span>
                {'Compliant L'}
            </span>
        </div>);
    }
    return '';

};

export default formatSubscription;
