import {
    SET_SELECTED_BUTTON,
    SET_SELECTED_COMPANY_CARDS,
    SET_LOADING_RDA,
    SUCCESSFUL_FETCH_RDA_STATUS,
    SET_RDA_STATUS_FETCHED,
    FETCH_LIST_OF_VEHICLES,
    SUCCESSFUL_FETCH_LIST_OF_VEHICLES,
    SET_SUCCESSFUL_REQUEST_MANUAL_DOWNLOAD,
    SET_LIST_OF_REQUESTS,
    SET_SETTINGS_SIDEBAR_OPEN,
    SET_DEFAULT_INTERVAL_SETTINGS,
    SET_DOWNLOAD_INTERVALS,
    SET_COMPANY_CARDS_SIDEBAR_OPEN,
    SET_EDITING_COMPANY_CARD,
    SET_SEND_COMPANY_CARD_CHANGES,
    SET_SEND_COMPANY_CARD_DELETION,
    SET_SEND_COMPANY_CARD_RESTORATION,
} from '../actions/administration';

export const initialState = {
    objectTreeConfig: {},
    selectedButton: 3,
    selectedCompanyCards: [],
    loadingRDA: false,
    rdaStatusFetched: false,
    isRDAOnline: false,
    isCompanyCardAvailable: false,
    isRDAStatusFetched: false,
    multipleRdaOnline: false,
    listOfVehicles: [],
    listOfVehiclesLoading: false,
    successfulRequestDownload: false,
    listOfRequests: [],
    isSettingsSidebarOpen: false,
    isCompanyCardsSidebarOpen: false,
    editingCompanyCard: {},
    defaultTachoValue: 30,
    defaultDriverValue: 7,
    fleetDownloadIntervals: {
        driverDownloadInterval: 7,
        vehicleDownloadInterval: 30,
    },
    customDownloadIntervals: {
        driverDownloadIntervals: [],
        vehicleDownloadIntervals: [],
        loading: true,
    },
    hasSpeedData: false,
};

const extendedAdministration = (state, action) => { // eslint-disable-line max-lines-per-function, complexity
    switch (action.type) {
        case SET_LIST_OF_REQUESTS:
            return {
                ...state,
                listOfRequests: action.payload,
            };
        case SET_SETTINGS_SIDEBAR_OPEN:
            return {
                ...state,
                isSettingsSidebarOpen: action.payload,
            };
        case SET_COMPANY_CARDS_SIDEBAR_OPEN:
            return {
                ...state,
                isCompanyCardsSidebarOpen: action.payload,
            };
        case SET_EDITING_COMPANY_CARD:
            return {
                ...state,
                editingCompanyCard: action.payload,
            };
        case SET_SEND_COMPANY_CARD_CHANGES:
            return {
                ...state,
                changedCard: action.payload,
            };
        case SET_SEND_COMPANY_CARD_DELETION:
            return {
                ...state,
                deletedCard: action.payload,
            };
        case SET_SEND_COMPANY_CARD_RESTORATION:
            return {
                ...state,
                restoredCard: action.payload,
            };
        case SET_DEFAULT_INTERVAL_SETTINGS:
            return {
                ...state,
                defaultTachoValue: action.payload.defaultTachoValue,
                defaultDriverValue: action.payload.defaultDriverValue,
            };
        case SET_DOWNLOAD_INTERVALS:
            if (action.payload.loading) {
                return {
                    ...state,
                    customDownloadIntervals: {
                        driverDownloadIntervals: [],
                        vehicleDownloadIntervals: [],
                        loading: true,
                    },
                };
            }

            return {
                ...state,
                customDownloadIntervals: action.payload.customDownloadIntervals,
                fleetDownloadIntervals: action.payload.fleetDownloadIntervals,
                hasSpeedData: action.payload.useSpeedData || false,
            };
        default:
            return state;
    }
};

const administration = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SELECTED_BUTTON:
            return {
                ...state,
                selectedButton: action.payload,
            };
        case SET_SELECTED_COMPANY_CARDS:
            return {
                ...state,
                selectedCompanyCards: action.payload,
            };
        case SET_LOADING_RDA:
            return {
                ...state,
                loadingRDA: action.payload,
            };
        case SUCCESSFUL_FETCH_RDA_STATUS:
            return {
                ...state,
                isRDAOnline: action.payload.rdaOnline,
                isCompanyCardAvailable: action.payload.anyCompanyCardsOnline,
                multipleRdaOnline: action.payload.multipleRdaOnline,
                rdaStatusFetched: true,
            };
        case SET_RDA_STATUS_FETCHED:
            return {
                ...state,
                isRDAStatusFetched: true,
            };
        case FETCH_LIST_OF_VEHICLES:
            return {
                ...state,
                listOfVehiclesLoading: true,
            };
        case SUCCESSFUL_FETCH_LIST_OF_VEHICLES:
            return {
                ...state,
                listOfVehicles: action.payload,
                listOfVehiclesLoading: false,
            };
        case SET_SUCCESSFUL_REQUEST_MANUAL_DOWNLOAD:
            return {
                ...state,
                successfulRequestDownload: action.payload,
            };
        default:
            return extendedAdministration(
                state,
                action,
            );
    }
};

export default administration;
