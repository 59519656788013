/* eslint-disable no-magic-numbers */
import has from 'lodash/has';

import sha256 from '../utils/sha256';

import store from '../setup/store';

export const ANALYTICS_BLACKLIST = ['9cfcc144dccb585c1758b34f8486cc35e6000b9063434367f38e0aad0f532f6b'];

export const isUserBlacklisted = (injectedStore = store) => has(
    injectedStore.getState(),
    'login.userProfile.email',
) && ANALYTICS_BLACKLIST.includes(sha256(injectedStore.getState().login.userProfile.email));
