
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Tag from '@rio-cloud/rio-uikit/Tag';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';

import max from 'lodash/max';

import {
    LOADINGDATA,
    FAILED,
    LOADING,
    AUTODOWNLOAD,
    UPLOAD,
    MANDOWNLOAD,
    FRONTENDAZPS,
    EXPORTAZPS,
    APIAZPS,
} from '../../constants/archive';

import formatWithAllSpaces from '../../utils/formatWithAllSpaces';
import formatAsSpan from '../../utils/formatAsSpan';
import { dateProps } from '../../constants/common';

export const formatRowState = row => row.status === FAILED ? 'danger' : '';

export const formatTransferDate = cell => {
    if (cell === LOADINGDATA || !cell) {
        return '';
    }

    return <span>
        <FormattedDate value={new Date(cell)} {...dateProps}/>
        {', '}
        <FormattedTime value={new Date(cell)}/>
    </span>;
};

export const formatStatus = cell => {
    switch (cell) {
        case LOADINGDATA:
            return (
                <span className={'status'}>
                    <div className={'LoadingSpinner'}><Spinner /></div>
                </span>
            );
        case LOADING:
            return (
                <span>
                    <div className={'LoadingSpinner'}><Spinner /></div>
                    {cell}
                </span>
            );
        case FAILED:
            return (
                <span className={'status'}>
                    <span className={'rioglyph rioglyph-remove'}/>

                    <FormattedMessage id={'archive.failed'}/>
                </span>
            );
        default:
            return cell;
    }
};

export function displayDownloadForbidden(notification = Notification) {
    notification.error(<span>
        <FormattedMessage id={'forbiddenHeadline'} defaultMessage={'403 Forbidden'}/>
        {': '}
        <FormattedMessage
            id={'noPermissionForAction'}
            defaultMessage={'You have no permissions to perform this action.'}
        />
        <span className={'notification-close'}>
            <span className={'rioglyph rioglyph-remove'}/>
        </span>
    </span>);
}

export function formatMultipleFiles(row, downloadFiles, isExportEnabled) {
    let icon = <span className={'rioglyph rioglyph-download'}/>; // eslint-disable-line immutable/no-let
    if (row.downloading) {
        icon = <div className={'DownloadSpinner'}><Spinner /></div>;
    }

    return (
        <div>
            <div
                className={'btn btn-default btn-sm justify-content-start DownloadFileLink'}
                onClick={event => {
                    if (isExportEnabled) {
                        event.preventDefault();
                        downloadFiles(row.fileId);
                    } else {
                        displayDownloadForbidden();
                    }
                }}
            >
                {icon} <b>{row.fileId.length}</b>
            </div>
        </div>
    );
}

const getFileEnding = fileName => {
    if (!fileName) {
        return '';
    }

    const pieces = fileName.split('.');
    return pieces[pieces.length - 1];
};

function formatSingleFile(cell, row, downloadFiles, isExportEnabled) {
    const fileEnding = getFileEnding(cell);
    const tip = <span>
        <FormattedMessage
            id = {'noPermissionForAction'}
            defaultMessage = {'You have no permissions to perform this action.'}/>
    </span>;
    // eslint-disable-next-line immutable/no-let
    let icon = <span className={'rioglyph rioglyph-download'}/>;
    if (row.downloading) {
        icon = <div className={'DownloadSpinner'}><Spinner /></div>;
    }

    return row.isDownloadPermitted ?
        (
            <div>
                <div
                    className={'btn btn-default btn-sm justify-content-start DownloadFileLink'}
                    onClick={event => {
                        if (isExportEnabled) {
                            event.preventDefault();
                            downloadFiles([row.fileId]);
                        } else {
                            displayDownloadForbidden();
                        }
                    }}
                >{icon} <b>{fileEnding}</b>
                </div>
            </div>
        ) :
        (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={'tooltip'} className={'top'}>
                        {tip}
                    </Tooltip>
                }
            >
                <span className={'cursor-not-allowed'}>
                    <div
                        className={'btn btn-default disabled btn-sm justify-content-start DownloadFileLink'}
                    >{icon} <b>{fileEnding}</b>
                    </div>
                </span>
            </OverlayTrigger>
        );
}

function formatDownloadFileLink(cell, row, downloadFiles, isExportEnabled) {
    if (Array.isArray(row.fileId)) {
        return formatMultipleFiles(
            row,
            downloadFiles,
            isExportEnabled,
        );
    }

    if (cell !== '-' && cell !== '') {
        return formatSingleFile(
            cell,
            row,
            downloadFiles,
            isExportEnabled,
        );
    }

    return formatStatus(row.status);
}

export const getFormatFile = (downloadFiles, isExportEnabled) => (cell, row) => formatDownloadFileLink(
    cell,
    row,
    downloadFiles,
    isExportEnabled,
);

export const formatTransferType = cell => {
    switch (cell) {
        case AUTODOWNLOAD:
            return <div><Tag size={'small'}><FormattedMessage id={'archive.autoDownload'}/></Tag></div>;
        case UPLOAD:
            return <div><Tag size={'small'}><FormattedMessage id={'archive.upload'}/></Tag></div>;
        case MANDOWNLOAD:
            return <div><Tag size={'small'}><FormattedMessage id={'archive.manualDownload'}/></Tag></div>;
        default:
            return cell;
    }
};

export const formatTransferAndContentType = (cell, row) => {
    const tip = <span>
        <FormattedMessage
            id = {'speedDataToolTip'}
            defaultMessage = {'File contains speed data block'}/>
    </span>;

    const speedDataFileTag = row.isSpeedDataFile ?
        <OverlayTrigger
            placement={'top'}
            overlay={
                <Tooltip id={'formatUtils.speedDataToolTip'} className={'top'}>
                    {tip}
                </Tooltip>
            }
        >
            <Tag size={'small'}>
                <div className={'text-color-info align-self-center text-size-20 rioglyph rioglyph-speed'}/>
            </Tag>

        </OverlayTrigger> :
        <div/>;

    switch (cell) {
        case AUTODOWNLOAD:
            return <div className={'display-flex flex-row'}>
                <Tag className={'text-center'} size={'small'}><FormattedMessage id={'archive.autoDownload'}/></Tag>
                {speedDataFileTag}
            </div>;
        case UPLOAD:
            return <div className={'display-flex flex-row'}>
                <Tag className={'text-center'} size={'small'}><FormattedMessage id={'archive.upload'}/></Tag>
                {speedDataFileTag}
            </div>;
        case MANDOWNLOAD:
            return <div className={'display-flex flex-row'}>
                <Tag className={'text-center'} size={'small'}><FormattedMessage id={'archive.manualDownload'}/></Tag>
                {speedDataFileTag}
            </div>;
        default:
            return cell;
    }
};

export const formatDriverCard = (cell, row) => {
    if (Array.isArray(row.fileId)) {
        return '';
    }

    if (!cell && row.driverInfo && row.driverInfo.driverCardNumber) {
        return formatWithAllSpaces(row.driverInfo.driverCardNumber);
    }

    return formatWithAllSpaces(cell);
};

export const formatIdentificationNumber = (cell, row) => {
    if (Array.isArray(row.fileId)) {
        return '';
    }

    if (!cell && row.vehicleInfo && row.vehicleInfo.vin) {
        return formatWithAllSpaces(row.vehicleInfo.vin);
    }

    return formatWithAllSpaces(cell);
};

const getLastNamePart = fileName => {
    const nameParts = fileName.split('_');
    return nameParts[nameParts.length - 1];
};

const deletedVehicleWithSubscription = 36;
const deletedVehicleWithoutSubscription = 20;
const inactiveDriverWithSubscription = 40;
const inactiveDriverWithoutSubscription = 24;

const getMissingNameTooltip = fileTypeStatus => {
    switch (fileTypeStatus) {
        case deletedVehicleWithSubscription:
            return <FormattedMessage
                id={'vehicleRemovedFromFleet'}
                defaultMessage={'Vehicle was removed from the fleet'}
            />;
        case deletedVehicleWithoutSubscription:
            return <FormattedMessage
                id={'vehicleHasNoSubscription'}
                defaultMessage={'Vehicle has no valid service subscription'}
            />;
        case inactiveDriverWithSubscription:
            return <FormattedMessage
                id={'driverSetToInactive'}
                defaultMessage={'Driver was set to inactive'}
            />;
        case inactiveDriverWithoutSubscription:
            return <FormattedMessage
                id={'driverHasNoSubscription'}
                defaultMessage={'Driver has no valid service subscription'}
            />;
        default:
            return false;
    }
};

export const formatWithNameFallbacks = (cell, row) => {

    if (cell && cell !== '') {
        return formatAsSpan(
            cell,
            getMissingNameTooltip(row.fileTypeStatus),
        );
    }

    if (row.fileName && getFileEnding(row.fileName) === 'DDD') {
        return formatAsSpan(
            getLastNamePart(row.fileName).split('.')[0],
            getMissingNameTooltip(row.fileTypeStatus),
        );
    }

    return '';
};

export const formatVehicleName = (cell, row) => formatWithNameFallbacks(
    cell,
    row,
);

export const formatDriver = (cell, row) => {
    if (!cell && row.driverInfo && (!row.driverInfo.isActive || row.driverInfo.flaggedAsDeleted)) {
        return formatWithAllSpaces(row.driverInfo.driverCardNumber);
    }

    if (!cell && row.driverInfo && row.driverInfo.lastName) {
        return formatWithNameFallbacks(
            `${row.driverInfo.firstName} ${row.driverInfo.lastName}`,
            row,
        );
    }

    return formatWithNameFallbacks(
        cell,
        row,
    );
};

const getExportedToDisplay = (date, id) => <div key={`table-exportedTo-${id}`}>
    <span>
        <FormattedDate value={date} {...dateProps}/>
        {' '}
        <FormattedMessage id={`archive.partner.${id}`} defaultMessage={id}/>
    </span>
</div>;

export const formatExportedTo = (cell, row) => { // eslint-disable-line max-statements
    if (cell && typeof cell.forEach === 'function') {
        const frontendDates = [];
        const apiDates = [];
        const partnerRows = [];
        cell.forEach(exportedInfo => {
            if (FRONTENDAZPS.includes(exportedInfo.authorizedParty)) {
                if (!row.downloadedByFrontend) {
                    frontendDates.push(new Date(exportedInfo.exportTimestampUtc));
                }
                return;
            }

            if (APIAZPS.includes(exportedInfo.authorizedParty)) {
                apiDates.push(new Date(exportedInfo.exportTimestampUtc));
                return;
            }

            partnerRows.push(getExportedToDisplay(
                new Date(exportedInfo.exportTimestampUtc),
                EXPORTAZPS[exportedInfo.authorizedParty] || 'partner',
            ));
        });

        if (row.downloadedByFrontend) {
            frontendDates.push(new Date(row.downloadedByFrontend));
        }

        if (!frontendDates.length && !apiDates.length && !partnerRows.length) {
            return <span className={'text-muted'}>
                <FormattedMessage
                    id={'archive.notExported'}
                    defaultMessage={'not exported yet'}
                />
            </span>;
        }

        return <div>
            { frontendDates.length ?
                getExportedToDisplay(
                    max(frontendDates),
                    'computer',
                ) :
                '' }
            { apiDates.length ?
                getExportedToDisplay(
                    max(apiDates),
                    'api',
                ) :
                '' }
            { partnerRows }
        </div>;
    }

    return cell ? cell : '';
};

