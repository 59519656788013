const BASE = 'TACHOGRAPHSERVICES.NOTIFICATIONS.';
export const SET_COMPANY_CARDS = `${BASE}SET_COMPANY_CARDS`;
export const SET_DISABLED_NOTIFICATIONS = `${BASE}SET_DISABLED_NOTIFICATIONS`;
export const SET_OVERVIEW_FILES = `${BASE}SET_OVERVIEW_FILES`;
export const SET_SUCCESSFUL_COMPANY_CARD_REQUEST = `${BASE}SET_SUCCESSFUL_COMPANY_CARD_REQUEST`;

export function setCompanyCards(array) {
    return {
        type: SET_COMPANY_CARDS,
        payload: array,
    };
}

export function setDisabledNotifications(array) {
    return {
        type: SET_DISABLED_NOTIFICATIONS,
        payload: array,
    };
}

export function setOverviewFiles(array) {
    return {
        type: SET_OVERVIEW_FILES,
        payload: array,
    };
}

export function setSuccessfulCompanyCardRequest(bool) {
    return {
        type: SET_SUCCESSFUL_COMPANY_CARD_REQUEST,
        payload: bool,
    };
}
