
import Notification from '@rio-cloud/rio-uikit/Notification';

import { FormattedMessage } from 'react-intl';

import { env } from '../../env';

import { fetchData } from '../../sagas/fetchUtils';

import {
    setSuccessfulCompanyCardRequest,
    setCompanyCards,
    setLoadingCompanyCards,
} from '../../actions/common';

import {
    setCompanyCards as setCompanyCardsForNotifications,
    setSuccessfulCompanyCardRequest as setSuccessfulRequestforNotifications,
} from '../../actions/notifications';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const url = `${TACHOGRAPH_SERVICES_SERVICE}/filearchive/CompanyCard`;

const getCards = (dispatch, injectedFetch = fetchData) => injectedFetch(url)
    .then(res => {
        if (res.error) {
            dispatch(setSuccessfulCompanyCardRequest(false));
            dispatch(setCompanyCards({
                unassignedEquipmentIds: [],
                companyAssignedVehicleData: [],
            }));
            dispatch(setLoadingCompanyCards(false));
            dispatch(setSuccessfulRequestforNotifications(false));
            dispatch(setCompanyCardsForNotifications({
                unassignedEquipmentIds: [],
                companyAssignedVehicleData: [],
            }));
            Notification.error(<FormattedMessage
                id={'error.displayCardFail'}
                defaultMessage={
                    'Displaying company cards failed. Please wait or try again later.'
                }
            />);

            throw new Error('tacho cards call failed');
        }
        return res;
    })
    .then(result => {
        dispatch(setCompanyCards(result));
        dispatch(setLoadingCompanyCards(false));
        dispatch(setSuccessfulCompanyCardRequest(true));
        dispatch(setSuccessfulRequestforNotifications(true));
        dispatch(setCompanyCardsForNotifications(result));

    })
    .catch(err => {
        console.log(err); // eslint-disable-line no-console
    });

export default getCards;
