
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { FormattedMessage } from 'react-intl';

const NoMatch = () => <div className={'display-flex justify-content-center align-items-center height-100pct'}>
    <NotFoundState
        headline={<span>404 <FormattedMessage id={'notFound'} defaultMessage={'Not Found'}/></span>}
        message={<FormattedMessage
            id={'notFoundMessage'}
            defaultMessage={'The requested page could not be found.'}
        />}
    />
</div>;

export default NoMatch;
