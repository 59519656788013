import es6Promise from "es6-promise";
import "isomorphic-fetch";
/* istanbul ignore file */
import _ from 'lodash';
import store from '../setup/store';
es6Promise.polyfill();
function FetchResponseError(response, body) {
    Error.call(this);
    this.name = 'FetchResponseError';
    this.message = response.statusText;
    this.status = response.status;
    this.body = body;
}
function ParseResponseError(message, rawString, url) {
    Error.call(this);
    this.name = 'ParseResponseError';
    this.message = message;
    this.rawString = rawString;
    this.url = url;
}
function validateFetch(fetch) {
    if (!fetch || !_.isFunction(fetch)) {
        throw new Error('fetch needs to be injected');
    }
}
function validateDocument(document) {
    if (!document || !document.dispatchEvent || !_.isFunction(document.dispatchEvent)) {
        throw new Error('document needs to be injected');
    }
}
function fixRequestObject(requestObject) {
    if (!_.isObject(requestObject)) {
        return {};
    }
    return requestObject;
}
function enrichRequestObject(requestObject) {
    const { accessToken } = store.getState().tokenHandling;
    const authorizationBearer = `Bearer ${accessToken}`;
    requestObject = fixRequestObject(requestObject);
    if (typeof requestObject.headers === 'undefined') {
        requestObject.headers = {
            Authorization: authorizationBearer,
        };
    }
    else {
        requestObject.headers.Authorization = authorizationBearer;
    }
    return requestObject;
}
function parseRequestObjectBody(requestObject) {
    requestObject = fixRequestObject(requestObject);
    if (requestObject && _.isObject(requestObject.body)) {
        requestObject.body = JSON.stringify(requestObject.body);
    }
    return requestObject;
}
function rejectResponse(response, injectedDocument) {
    if (response.text) {
        return response.text().then(message => {
            const error = new FetchResponseError(response, message);
            const event = new CustomEvent('FetchResponseError', { detail: error });
            injectedDocument.dispatchEvent(event);
            return Promise.reject(error);
        });
    }
    const error = new FetchResponseError(response, '');
    const event = new CustomEvent('FetchResponseError', { detail: error });
    injectedDocument.dispatchEvent(event);
    return Promise.reject(error);
}
function handleTextResponse(response, injectedDocument) {
    if (response.ok) {
        return response.text().then(message => Promise.resolve(message));
    }
    return rejectResponse(response, injectedDocument);
}
function handleBlobResponse(response, injectedDocument) {
    if (response.ok) {
        return response.blob().then(blob => Promise.resolve(blob));
    }
    return rejectResponse(response, injectedDocument);
}
function handleRawResponse(response, injectedDocument) {
    if (response.ok) {
        return Promise.resolve(response);
    }
    return rejectResponse(response, injectedDocument);
}
export function authenticatedFetch(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    requestObject = enrichRequestObject(requestObject);
    requestObject = parseRequestObjectBody(requestObject);
    return injectedFetch(requestUrl, requestObject)
        .then(response => handleTextResponse(response, injectedDocument));
}
export function authenticatedFetchRaw(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    requestObject = enrichRequestObject(requestObject);
    requestObject = parseRequestObjectBody(requestObject);
    return injectedFetch(requestUrl, requestObject)
        .then(response => handleRawResponse(response, injectedDocument));
}
export function unauthenticatedFetchRaw(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    requestObject = fixRequestObject(requestObject);
    requestObject = parseRequestObjectBody(requestObject);
    return injectedFetch(requestUrl, requestObject)
        .then(response => handleRawResponse(response, injectedDocument));
}
export function authenticatedFetchBlob(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    requestObject = enrichRequestObject(requestObject);
    requestObject = parseRequestObjectBody(requestObject);
    return injectedFetch(requestUrl, requestObject)
        .then(response => handleBlobResponse(response, injectedDocument));
}
export function authenticatedFetchJSON(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    if (!requestObject.headers) {
        requestObject.headers = {};
    }
    requestObject.headers.Accept = 'application/json';
    requestObject = parseRequestObjectBody(requestObject);
    return authenticatedFetch(requestUrl, requestObject, injectedFetch, injectedDocument).then(response => {
        try {
            if (!response) {
                return Promise.resolve({});
            }
            const parsedResult = JSON.parse(response);
            return Promise.resolve(parsedResult);
        }
        catch (parseError) {
            return Promise.reject(new ParseResponseError(parseError.message, response, requestUrl));
        }
    });
}
export function getAuthenticatedFetchBlob(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    return function (requestUrl, requestObject) {
        return authenticatedFetchBlob(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}
export function getAuthenticatedFetchJSON(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    return function (requestUrl, requestObject) {
        return authenticatedFetchJSON(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}
export function getAuthenticatedFetch(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    return function (requestUrl, requestObject) {
        return authenticatedFetch(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}
export function getAuthenticatedFetchRaw(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    return function (requestUrl, requestObject) {
        return authenticatedFetchRaw(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}
export function getUnauthenticatedFetchRaw(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);
    return function (requestUrl, requestObject) {
        return unauthenticatedFetchRaw(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}
