import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/Select';

// eslint-disable-next-line complexity, max-lines-per-function
const CompanyCardReturnForm = props => {
    const countries = [
        {id: '1', label: 'AT - Austria'},
        {id: '2', label: 'BE - Belgium'},
        {id: '3', label: 'BG - Bulgaria'},
        {id: '4', label: 'HR - Croatia'},
        {id: '5', label: 'CY - Cyprus'},
        {id: '6', label: 'CZ - Czechia'},
        {id: '7', label: 'DK - Denmark'},
        {id: '8', label: 'EE - Estonia'},
        {id: '9', label: 'FI - Finland'},
        {id: '10', label: 'FR - France'},
        {id: '11', label: 'DE - Germany'},
        {id: '12', label: 'GR - Greece'},
        {id: '13', label: 'HU - Hungary'},
        {id: '14', label: 'IE - Ireland'},
        {id: '15', label: 'IT - Italy'},
        {id: '16', label: 'LV - Latvia'},
        {id: '17', label: 'LT - Lithuania'},
        {id: '18', label: 'LU - Luxembourg'},
        {id: '19', label: 'MT - Malta'},
        {id: '20', label: 'NL - Netherlands'},
        {id: '21', label: 'PL - Poland'},
        {id: '22', label: 'PT - Portugal'},
        {id: '23', label: 'RO - Romania'},
        {id: '24', label: 'SK - Slovakia'},
        {id: '25', label: 'SI - Slovenia'},
        {id: '26', label: 'ES - Spain'},
        {id: '27', label: 'SE - Sweden'},
        {id: '28', label: 'IS - Iceland'},
        {id: '29', label: 'LI - Liechtenstein'},
        {id: '30', label: 'NO - Norway'},
        {id: '31', label: 'CH - Switzerland'},
        {id: '32', label: 'AL - Albania'},
        {id: '33', label: 'BA - Bosnia and Herzegovina'},
        {id: '34', label: 'MD - Moldova, Republic of'},
        {id: '35', label: 'ME - Montenegro'},
        {id: '36', label: 'MK - North Macedonia'},
        {id: '37', label: 'RS - Serbia'},
        {id: '38', label: 'TR - Turkey'},
        {id: '39', label: 'UA - Ukraine'},
        {id: '40', label: 'AD - Andorra'},
        {id: '41', label: 'BY - Belarus'},
        {id: '42', label: 'MC - Monaco'},
        {id: '43', label: 'RU - Russian Federation'},
        {id: '44', label: 'SM - San Marino'},
        {id: '45', label: 'GB - United Kingdom'},
        {id: '46', label: 'VA - Vatican City'},
    ];
    const {
        register,
        formState: { errors },
        trigger,
        getValues,
        control,
    } = useForm({
        mode: 'onChange',
    });

    const preFilledCountryId = countries
        .find(c => c.label.startsWith(props.accountInfo?.legal_address?.country_code))?.id;
    const [selectedOptionId, setSelectedOptionId] = useState(preFilledCountryId);
    const onChangeHandler = event => {
        setSelectedOptionId(event.id);
    };

    useEffect(
        () => {
            trigger();
            if (props.confirmRestoreClicked) {
                if (Object.keys(errors).length === 0) {
                    props.closeConfirmDeletion();
                    setTimeout(
                        () => {
                            const data = getValues();
                            data.country = countries.find(item => item.id === selectedOptionId).label;
                            props.handleReturnFormCallback(data);
                            props.closeSidebar();
                        },
                        // eslint-disable-next-line no-magic-numbers
                        1000,
                    );
                }
            }
        },
        [props.confirmRestoreClicked],
    );

    return (
        // eslint-disable-next-line max-len
        <div>
            <p>
                <FormattedMessage
                    id={'confirmRequestSubtitle1'}
                    // eslint-disable-next-line max-len
                    defaultMessage={'Are you sure that you want to request your company card back from the card hotel?'}/>
            </p>
            <p>
                <FormattedMessage
                    id={'confirmRequestSubtitle2'}
                    // eslint-disable-next-line max-len
                    defaultMessage={'Be aware that we cannot perform tachograph remote downloads when there is no company card at the card hotel. We recommend to send new company cards to us first before requesting cards back.'}/>
            </p>
            <p>
                <FormattedMessage
                    id={'confirmRequestSubtitle3'}
                    // eslint-disable-next-line max-len
                    defaultMessage={'Please provide the address you would like us to send the card to.'}/>
            </p>
            <input
                id={'companyChipId'}
                type={'hidden'}
                {...register(
                    'companyChipId',
                    { required: true, value: props.companyChipId },
                )}
            />
            <div className={`form-group ${errors.name ? 'has-error has-feedback' : ''}`}>
                <label className={'control-label'} htmlFor={'name'}>
                    {'Name'}
                </label>
                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'name'}
                        type={'text'}
                        className={'form-control'}
                        placeholder={'Please enter company name'}
                        {...register(
                            'name',
                            { required: true, value: props.accountInfo?.name },
                        )}
                    />
                    {errors.name && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Company name is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={`form-group ${errors.email ? 'has-error has-feedback' : ''}`}>
                <label className={'control-label'} htmlFor={'email'}>
                    {'Email'}
                </label>
                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'email'}
                        type={'email'}
                        className={'form-control'}
                        placeholder={'Please enter your email'}
                        {...register(
                            'email',
                            {
                                required: true,
                                pattern: {
                                    // eslint-disable-next-line require-unicode-regexp
                                    value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
                                    message: 'invalid email address',
                                },
                                value: props.email,
                            },
                        )}
                    />
                    {errors.email && errors.email.type === 'required' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Email is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                    {errors.email && errors.email.type === 'pattern' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Email is invalid'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={`form-group ${errors.addressLine1 ? 'has-error has-feedback' : ''}`}>
                <label className={'control-label'} htmlFor={'addressLine1'}>
                    {'Address Line 1'}
                </label>
                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'addressLine1'}
                        type={'text'}
                        className={'form-control'}
                        placeholder={'Please enter the address where you want to recieve this company card'}
                        {...register(
                            'addressLine1',
                            {
                                required: true,
                                value: props.accountInfo?.legal_address?.line_1,
                            },
                        )}
                    />
                    {errors.addressLine1 && errors.addressLine1.type === 'required' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Address Line 1 is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={`form-group ${errors.addressLine2 ? 'has-error has-feedback' : ''}`}>
                <label className={'control-label'} htmlFor={'addressLine2'}>
                    {'Address Line 2'}
                </label>
                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'addressLine2'}
                        type={'text'}
                        className={'form-control'}
                        placeholder={'Apartment number, suite, or other secondary address information'}
                        {...register(
                            'addressLine2',
                            {value: props.accountInfo?.legal_address?.line_2},
                        )}

                    />
                </div>
            </div>
            <div className={`form-group ${errors.zipCode ? 'has-error has-feedback' : ''}`}>

                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'zipCode'}
                        type={'text'}
                        className={'form-control'}
                        placeholder={'Please enter your zip code'}
                        {...register(
                            'zipCode',
                            {
                                required: true,
                                value: props.accountInfo?.legal_address?.postal_code,
                            },
                        )}
                    />
                    {errors.zipCode && errors.zipCode.type === 'required' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Zip code is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={`form-group ${errors.city ? 'has-error has-feedback' : ''}`}>
                <div className={'form-control-feedback-wrapper'}>
                    <input
                        id={'city'}
                        type={'text'}
                        className={'form-control'}
                        placeholder={'Please enter your city'}
                        {...register(
                            'city',
                            {
                                required: true,
                                value: props.accountInfo?.legal_address?.city,
                            },
                        )}
                    />
                    {errors.city && errors.city.type === 'required' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'City is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={`form-group ${errors.country ? 'has-error has-feedback' : ''}`}>
                <div className={'form-control-feedback-wrapper'}>
                    <Controller
                        control={control}
                        name={'country'}
                        render={({
                            field: { name, ref },
                        }) => (
                            <Select
                                options={countries}
                                placeholder={'Please select country'}
                                name={name}
                                ref={ref}
                                value={[selectedOptionId]}
                                onChange={onChangeHandler}
                            />
                        )}
                    />
                    {errors.country && errors.country.type === 'required' && (
                        <React.Fragment>
                            <span className={'form-control-feedback rioglyph rioglyph-error-sign'}></span>
                            <span className={'help-block'}>
                                <span>{'Country is required'}</span>
                            </span>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={'margin-top-20'}>
                <span className={'rioglyph rioglyph-info-sign'}>
                </span>
                <FormattedMessage
                    id={'confirmRequestNote'}
                    // eslint-disable-next-line max-len
                    defaultMessage={'We will work on your card return request right away. You will receive the card via post mail in the next days.'}/>
            </div>
        </div>

    );
};

CompanyCardReturnForm.propTypes = {
    confirmRestoreClicked: PropTypes.bool,
    closeConfirmDeletion: PropTypes.func,
    closeSidebar: PropTypes.func,
    handleReturnFormCallback: PropTypes.func,
    email: PropTypes.string,
    name: PropTypes.string,
    companyChipId: PropTypes.string,
    accountInfo: PropTypes.object,
};

CompanyCardReturnForm.defaultProps = {
    accountInfo: {},

};
export default CompanyCardReturnForm;

