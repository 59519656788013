import qs from 'qs';
import get from 'lodash/get';

import store, { history } from '../../setup/store';

// Options for the "qs" libaray
const OPTIONS = {
    comma: true,
    arrayFormat: 'comma',
    indices: false,
    encode: false,
    decode: false,
    skipNulls: true,
};

const HASH_QUERY_PARAMETER_INDEX = 1;

export const parseRoute = (injectedHistory = history, injectedWindow = window) => {
    const location = (injectedHistory || {}).location || {};
    const searchParams = location.search ?
        location.search.replace(
            '?',
            '',
        ) :
        get(
            injectedWindow,
            'location.hash',
            '?',
        ).split('?')[HASH_QUERY_PARAMETER_INDEX];

    return qs.parse(
        searchParams,
        OPTIONS,
    );
};

export const getRouteParam = (
    paramString,
    injectedWindow,
) => get(
    parseRoute(injectedWindow),
    paramString,
    null,
);

export const getRouteParamAsArray = paramString => {
    const param = getRouteParam(paramString);

    if (!param) {
        return param;
    }

    if (typeof param === 'object') {
        return param;
    }

    return [param];
};

export const saveStateToRoute = (state, injectedHistory = history) => {
    const usedHistory = injectedHistory || {};
    try {
        usedHistory
            .push({
                ...usedHistory.location,
                search: qs.stringify(
                    {
                        ...parseRoute(injectedHistory),
                        ...state,
                    },
                    OPTIONS,
                ),
            });
        store.dispatch({ type: 'HISTORY_CHANGED', payload: usedHistory });
    } catch (err) {

        // eslint-disable-next-line no-console
        console.log(err);
    }
};

export const switchToTab = (tab, injectedHistory = history) => {
    const usedHistory = injectedHistory || {};
    try {
        usedHistory
            .push({
                ...usedHistory.location,
                pathname: `/${tab}`,
            });
        store.dispatch({ type: 'HISTORY_CHANGED', payload: usedHistory });
    } catch (err) {

        // eslint-disable-next-line no-console
        console.log(err);
    }
};
