
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import Table from '../../../src/common/tacho-table/Table';

export const Column = props => <div {...props} />;

const getTableView = (singleCard, multiCards) => {
    if (singleCard) {
        return TableViewToggles.VIEW_TYPE_SINGLE_CARD;
    }

    if (multiCards) {
        return TableViewToggles.VIEW_TYPE_MULTI_CARDS;
    }

    return TableViewToggles.VIEW_TYPE_TABLE;
};

const getChildren = props => (props.children || []).filter(child => Boolean(child));

const getColumnOrder = props => getChildren(props).map(child => get(
    child,
    'props.dataField',
));
const getHiddenColumns = props => getChildren(props)
    .filter(child => get(
        child,
        'props.hidden',
    ))
    .map(child => get(
        child,
        'props.dataField',
    ));

const getColumnLabels = props => {
    const labelObject = {};

    getChildren(props).forEach(child => {
        labelObject[get(
            child,
            'props.dataField',
        )] = get(
            child,
            'props.children',
            [],
        );
    });

    return labelObject;
};

const getColumnsDetails = props => {
    const labelObject = {};

    getChildren(props).forEach(child => {
        labelObject[get(
            child,
            'props.dataField',
        )] = {
            width: get(
                child,
                'props.width',
            ),
            dataFormat: get(
                child,
                'props.dataFormat',
            ),
            dataAlign: get(
                child,
                'props.dataAlign',
            ),
            dataSort: get(
                child,
                'props.dataSort',
            ),
            tdStyle: get(
                child,
                'props.tdStyle',
            ),
            thStyle: get(
                child,
                'props.thStyle',
            ),
            thClassName: get(
                child,
                'props.thClassName',
            ),
            columnClassName: get(
                child,
                'props.columnClassName',
            ),
        };
    });

    return labelObject;
};

const getNewTableProps = props => ({
    baseKey: 'Archive-ListFilesTable',
    keyField: 'fileId',
    className: 'ListFilesTable',

    noDataHeadLine: <FormattedMessage id={'noDataHeadline'} defaultMessage={'No Data'}/>,
    noDataText: <FormattedMessage id={'noData'} defaultMessage={'No Data available'}/>,

    viewType: getTableView(
        props.singleCard,
        props.multiCards,
    ),

    multiSelect: props.selectionEnabled,
    enableSelection: props.selectionEnabled,
    isOverviewSelected: props.isOverviewSelected,
    selectedRowIds: props.selectedElements,
    onSelectionChange: props.onSelectionChange,
    selectAllText: <FormattedMessage id={'selectAll'} defaultMessage={'Select all'}/>,
    deSelectAllText: <FormattedMessage id={'deSelectAll'} defaultMessage={'Deselect all'}/>,

    onSortChange: get(
        props,
        'options.onSortChange',
    ),
    sortField: get(
        props,
        'options.defaultSortName',
    ),
    sortOrder: get(
        props,
        'options.defaultSortOrder',
    ),

    columnOrder: getColumnOrder(props),
    columnLabels: getColumnLabels(props),
    hiddenColumns: getHiddenColumns(props),
    columnsDetails: getColumnsDetails(props),

    ...props,
});

const TableWrapper = props => <Table {...getNewTableProps(props)} />;

export default TableWrapper;
