
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';

import {fetchDataPOST} from '../../sagas/fetchUtils';

import { refetchArchiveData } from '../../actions/archive';

import { env } from '../../env';

const { TACHOGRAPH_SERVICES_SERVICE } = env.runtimeConfig.backend;

const DRIVER_PATH = '/downloadscheduler/download-interval-drivers';
const VEHICLE_PATH = '/downloadscheduler/download-interval-vehicles';

export const getBody = (ids, interval, isDriver) => ids.map(id => {
    const item = {
        downloadInterval: interval,
    };

    if (isDriver) {
        item.fullDriverCard = id;
    } else {
        item.equipmentId = id;
    }
    return item;
});

const setTachoDownloadInterval = (
    ids,
    interval,
    isDriver,
    dispatch,
    displayNotification = false,
    injectedFetch = fetchDataPOST,
    injectedNotification = Notification,
) => injectedFetch(
    `${TACHOGRAPH_SERVICES_SERVICE}${isDriver ? DRIVER_PATH : VEHICLE_PATH}`,
    getBody(
        ids,
        interval,
        isDriver,
    ),
)
    .then(res => {
        if (displayNotification) {
            if (res.error) {
                injectedNotification.error(<FormattedMessage
                    id={'saveTachoSettingFailed'}
                    defaultMessage={'Saving failed'}
                />);
            } else {
                injectedNotification.success(<FormattedMessage id={'saved'} defaultMessage={'Saved'}/>);
            }
        }
        dispatch(refetchArchiveData(true));

    })
    .catch(err => {
        if (displayNotification) {
            injectedNotification.error(<FormattedMessage
                id={'savingFailed'}
                defaultMessage={'Saving failed'}
            />);
        }
        console.log(err); // eslint-disable-line no-console
    });

export default setTachoDownloadInterval;
