import { noContent } from '../../sagas/fetchUtils';

const forbiddenStatusCode = 403;

const saveFetch = (configuredFetch, name, handleData, resolve, onForbidden) => {
    try {
        configuredFetch()
            .then(res => {
                if (res.ok) {
                    if (res.status === noContent) {
                        return [];
                    }
                    return res.json();
                }

                if (onForbidden && res.status === forbiddenStatusCode) {
                    onForbidden();
                }

                throw new Error(
                    `"${name}" call failed`,
                    res,
                );
            })
            .then(data => {
                handleData(data);
                resolve();
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                resolve();
            });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        resolve();
    }
};

export default saveFetch;
